<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%;margin-bottom:10px;">
      <el-row :gutter="10">
        <el-col :md="12">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="searchKeyword" style="max-width: 200px;" class="mr-2"
            @keyup.enter.native="searchRead(searchKeyword)">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="searchRead(searchKeyword)"></i>
          </el-input>
          <el-date-picker class="mr-2" size="mini" style="max-width:150px;" v-model="request.objSearchMapper.searchDate"
            type="date" format="yyyy 년 MM 월 dd 일" value-format="yyyy-MM-dd" placeholder="검색날자">
          </el-date-picker>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="searchRead(searchKeyword)">
            {{$t('SEARCH')}}
          </el-button>
          <el-button type="primary" icon="el-icon-tickets" size="mini" v-if="searchKeyword"
            @click="searchRead(null)">{{$t('VIEWALL')}}</el-button>
        </el-col>
  
      </el-row>
    </card>
    <div>
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
        :page-size="request.size" layout="total, sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
      <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13">
        <el-table-column label="ID" width="80" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.seq }}
          </template>
        </el-table-column>
        <el-table-column :label="'접속시간'" width="130" :align="'center'">
          <template slot-scope="scope">
            {{ $YYYYMMDD_HHMMSS(scope.row.accessDate) }}
          </template>
        </el-table-column>
        <el-table-column :label="'상호'" width="120" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.companyName }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'이름'" width="120" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.username }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'계정'" width="130" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip hand underline" @click="showMemberInfo(scope.row)">{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'ip'" width="130" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.ip }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'Front'" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.fullpath }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'Path'" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.apipath }}</span>
          </template>
        </el-table-column>
      </el-table>
  
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
        :page-size="request.size" layout="total, sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
    </div>
    <mallmanagerlogpwview v-if="selectItem" @callback="bacllbackMemberInfo" :data="selectItem" />
  </div>
</template>
<script>
import yesNoBox from './component-alert-continue.vue'
import mallmanagerlogpwview from './component-mall-manager-log-pw-view.vue'
export default {
  name: 'mallmanagerlog',
  components: {
    yesNoBox, mallmanagerlogpwview
  },
  data() {
    return {
      count: [],
      selectItem: null,
      pageSizes: [15, 50, 100],
      searchKeyword: '',
      request: {
        objSearchMapper: {
          state: 0,
          searchKeyword: '',
          searchDate: null,
        },
        page: 1,
        size: 15
      },
      tmpTableData: [],
      totalCount: 0,
    }
  },

  created() {
    this.read()
  },
  methods: {
    showMemberInfo(item) {
      this.selectItem = item
    },
    bacllbackMemberInfo(data) {
      this.selectItem = null
    },
    searchRead(searchKeyword) {
      this.searchKeyword = searchKeyword
      this.request.page = 1
      this.request.objSearchMapper.searchKeyword = this.$trimLR(searchKeyword)
      this.read()
    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getMallLogInfo`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.count = res.data.body.count
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
          } else {
            this.tmpTableData = []
          }
        })
    },
    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }

  }
}
</script>
<style></style>
