<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%">
      <el-row :gutter="10">
        <el-col :md="12" style="min-width:200px;">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="request.objSearchMapper.searchKeyword"
            @keyup.enter.native="read()" style="max-width:200px;">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="read()"></i>
          </el-input>
          <el-button class="ml-2" type="primary" icon="el-icon-search" size="mini" @click="read()">
            {{$t('SEARCH')}}</el-button>
        </el-col>
        <el-col :md="12" class="right">
          <el-button type="primary" icon="el-icon-success" size="mini" @click="insertUpdatebox()">
            {{$t('ADD')}}</el-button>

        </el-col>
      </el-row>
    </card>
    <div>
      <!-- <el-pagination class="mt-2 mb-2 text-right" layout="prev, pager, next" :total="totalCount">
      </el-pagination> -->
      <template>
        <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13">
          <el-table-column :label="$t('DATE')" width="100" align="center">
            <template slot-scope="scope">
              {{ $YYYYMMDD(scope.row.created) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('TXN.NO.')" width="200">
            <template slot-scope="scope">
              <div style="max-width: 300px;" class="ellip">
                  {{ scope.row.sn }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('STORAGENAME')" width="150">
            <template slot-scope="scope">
              <div style="max-width: 300px;" class="ellip">
                <span>
                  {{ scope.row.name }}
                </span>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column :label="$t('SMI')" width="120">
            <template slot-scope="scope">
              <div style="max-width: 300px;" class="ellip">
                  {{ scope.row.bizUsage==1? '사용안함':'사용' }}
              </div>
            </template>
          </el-table-column> -->
          <el-table-column :label="$t('WA')" width="300">
            <template slot-scope="scope">
              <div style="max-width: 100px;" class="ellip">
                {{ scope.row.addr }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('MEMO')">
            <template slot-scope="scope">
              <div style="max-width: 100px;" class="ellip">
                {{ scope.row.memo }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="수정" width="50" :align="'center'">
          <template slot="header">
            <i class="fas fa-pen-to-square"></i>
          </template>
          <template slot-scope="scope">
            <i class="fas fa-pen-to-square hand"
            @click="insertUpdatebox(scope.row)"></i>
          </template>
        </el-table-column>
          <el-table-column width="50" :align="'right'">
            <template slot="header">
              <i class="fas fa-trash-alt hand"></i>
            </template>
            <template slot-scope="scope">
              <i class="fas fa-trash-alt hand" @click="stateinsertUpdatebox(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-size="request.size"
        :page-sizes="[15, 50, 100]" layout="total, sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
    </div>
    <!-- // 등록-갱신창 -->
    <storagecreate v-if="cratevisible" :data="selectItem" @callback="createcallback" />
    <yesNoBox v-if="yesNoBoxvisible" @callback="deletecallback" :title="selectItem.name" :data="{
    content: this.$t('Would you like to delete the item?'),
    contentSub: this.$t('Deleted items cannot be recovered.')
  }" />
  </div>
</template>
<script>
import storagecreate from './component-storage-create.vue'
import yesNoBox from './component-alert-continue.vue'
export default {
  name: 'storagelist',
  components: {
    storagecreate, yesNoBox
  },
  data() {
    return {
      yesNoBoxvisible: false,
      selectItem: null,
      cratevisible: false,
      request: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 15
      },

      // tables
      tmpTableData: [],
      totalCount: null,
    }
  },

  created() {
    this.read()
  },
  methods: {
    insertUpdatebox(item) {
      this.selectItem = item
      this.cratevisible = true
    },
    deletecallback(data) {
      this.yesNoBoxvisible = false
      if (data) {
        this.stateStorageInfo(this.selectItem)
      }
    },
    createcallback(data) {
      this.cratevisible = false
      if (data.status) {
        this.read()
      }
    },
    emits(item) {
      this.$emit('callback', item)
    },

    async read() {
      await this.$http.post(`${this.$serverurl}/api/getStorageInfo_new`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results.filter(item=>item.name!='자동창고')
            this.totalCount = res.data.totalCount
          }else{
            this.tmpTableData=[]
          }
        })
    },
    stateinsertUpdatebox(item) {
      this.selectItem = item
      this.yesNoBoxvisible = true
    },
    async stateStorageInfo(item) {
      this.$http.post(`${this.$serverurl}/api/stateStorageInfo`, item, this.$setHeader())
        .then(res => {
          alert(res.data.message)
          this.read();
        })
        .catch(error => this.$axiosCatch(error))
        .then(() => { this.chkDoubleSubmit = false; });
    },

    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }

  }
}
</script>
<style></style>
