<template>
  <div class="container mt-3 relative" :style="$pageWidth">
    <TopBar />
    <div class="mt-2">
      <el-row>
        <el-col :xl="20" :md="20">
          <el-button v-for="(item, index) in tmpStorageData" :key="index" size="mini"
            :type="activeName == item.seq ? 'primary' : ''" @click="handleButtons(item.seq)">
            <i class="fas fa-house-laptop"></i> {{ item.name }}</el-button>
          <el-button size="mini" :type="activeName == 0 ? 'primary' : ''" @click="handleButtons(0)"
            v-if="userlogininfo.authority.stockPremi==0">
            <i class="fas fa-house-laptop"></i> {{ this.$t('ALLWAREHOUSE') }}</el-button>
        </el-col>
      </el-row>
      <div class="mt-2">
        <invenlist :storageId="activeName" @callback="invenlistcallback" />
      </div>
      <invendetailview v-if="showinvendetailview" :tmpStorageData="tmpStorageData" :data="selectItem"
        @callback="inveindetailcallback" />
      <div v-if="!tmpStorageData.length">{{ $t('CREATESTOCKINFO') }}</div>
    </div>
  </div>
</template>
<script>
import invendetailview from './component-inven-detail-view.vue'
import invenlist from './component-inven-list.vue'
import TopBar from './component-top.vue'
export default {
  components: {
    TopBar, invenlist, invendetailview,
  },
  data() {
    return {
      userlogininfo: null,
      showinvendetailview: false,
      selectItem: null,
      activeName: 0,
      tmpStorageData: [],
      request: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 100
      },
    }
  },
  created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    this.readStorageInfo()

  },
  methods: {
    inveindetailcallback() {
      this.showinvendetailview = false
    },
    invenlistcallback(data) { // 상세보기콜백
      this.showinvendetailview = true
      this.selectItem = data.item
    },
    async readStorageInfo() {
      this.tmpStorageData = []
      await this.$http.post(`${this.$serverurl}/api/getStorageInfo_new`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            res.data.body.results.map(item => {
              if (item.seq == this.userlogininfo.authority.accessStore || this.userlogininfo.authority.accessStore == 0) {
                this.tmpStorageData.push(item)
                this.activeName = 0 //.this.tmpStorageData[0].seq
              }
            })
            // this.tmpStorageData = res.data.body.results
          }
        })
    },
    handleButtons(params) {
      this.activeName = params
    },

  }
}
</script>
<style></style>
