<template>
  <div class="pt-3">
    <div class="font15 bold mb-2">
      {{$t('CI')}}
    </div>
    <div class="p-2 mb-2">
  
      <el-row :gutter="5">
        <el-col :xs="24" :md="6" :sm="24">
          <div class="input-container">
            <input ref="license" type="text" maxlength="12" class="formtextbox" autocomplete="off"
              v-model="formData.license" @focus="onFocus('license')" @blur="onBlur('license')"
              @keyup="onlyNumber(formData.license,'license');setLength($event,10)" />
            <label class="placeholder coloryellow" :class="{ 'active': isFocused.license }">
              {{$t('BRN')}}*</label>
          </div>
        </el-col>
        <el-col :xs="24" :md="6" :sm="24">
          <div class="input-container">
            <input ref="name" type="text" maxlength="10" class="formtextbox" autocomplete="off"
              v-model="formData.startDate" @focus="onFocus('startDate')" @blur="onBlur('startDate')"
              @keyup="onlyNumber(formData.startDate,'startDate');setLength($event,8)" />
            <label class="placeholder coloryellow" :class="{ 'active': isFocused.startDate }">
              {{$t('DBD')}}*</label>
          </div>
        </el-col>
        <el-col :xs="24" :md="6" :sm="24">
          <div class="input-container">
            <input ref="name" type="text" maxlength="50" class="formtextbox" autocomplete="off" v-model="formData.name"
              @focus="onFocus('name')" @blur="onBlur('name')" />
            <label class="placeholder coloryellow" :class="{ 'active': isFocused.name }">
              {{$t('COMPANYNAME')}}*</label>
          </div>
        </el-col>
        <el-col :xs="24" :md="6" :sm="24">
          <div class="input-container">
            <div class="input-container">
              <input ref="ceo" type="text" maxlength="20" class="formtextbox" autocomplete="off" v-model="formData.ceo"
                @focus="onFocus('ceo')" @blur="onBlur('ceo')" />
              <label class="placeholder coloryellow" :class="{ 'active': isFocused.ceo }">
                {{$t('CEO')}}*</label>
              <span class="absolute btn btn-gray btn-mini" style="bottom:0px;right:0px;"
                @click="checklicense()">
                {{$t('BV')}}</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col :xs="24" :md="8" :sm="24">
          <div class="input-container">
            <input ref="postNum" type="text" maxlength="10" class="formtextbox cursor-hand" autocomplete="off"
              v-model="formData.postNum" @focus="onFocus('postNum')" @blur="onBlur('postNum')" readonly
              @click="loadDaumPostcodeScript" />
            <label class="placeholder coloryellow" :class="{ 'active': true }">{{$t('P.C.')}}*</label>
          </div>
        </el-col>
        <el-col :xs="24" :md="8" :sm="24">
          <div class="input-container">
            <input ref="addr" type="text" maxlength="150" class="formtextbox cursor-hand" autocomplete="off"
              v-model="formData.addr" @focus="onFocus('addr')" @blur="onBlur('addr')" readonly
              @click="loadDaumPostcodeScript" />
            <label class="placeholder coloryellow" :class="{ 'active': true }">{{$t('ADDR')}}*</label>
          </div>
        </el-col>
        <el-col :xs="24" :md="8" :sm="24">
          <div class="input-container">
            <input ref="addrSub" type="text" maxlength="150" class="formtextbox" autocomplete="off"
              v-model="formData.addrSub" @focus="onFocus('addrSub')" @blur="onBlur('addrSub')" />
            <label class="placeholder coloryellow" :class="{ 'active': true }">{{$t('DET.ADDR.')}}*</label>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col :xs="24" :md="24" :sm="24">
          <div class="input-container">
            <input ref="tel" type="text" maxlength="12" class="formtextbox" autocomplete="off" v-model="formData.tel"
              @focus="onFocus('tel')" @blur="onBlur('tel')" @keyup="onlyNumber(formData.tel,'tel')" />
            <label class="placeholder coloryellow" :class="{ 'active': isFocused.tel }">{{$t('TEL')}}</label>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col :xs="24" :md="24" :sm="24">
          <div class="input-container">
            <input ref="tel" type="text" maxlength="12" class="formtextbox" autocomplete="off" v-model="formData.fax"
              @focus="onFocus('fax')" @blur="onBlur('fax')" @keyup="onlyNumber(formData.fax,'fax')" />
            <label class="placeholder coloryellow" :class="{ 'active': isFocused.fax }">{{$t('FAX')}}</label>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col :xs="24" :md="24" :sm="24">
          <div class="input-container">
            <input ref="mail" type="text" maxlength="50" class="formtextbox" autocomplete="off" v-model="formData.mail"
              @focus="onFocus('mail')" @blur="onBlur('mail')" />
            <label class="placeholder coloryellow" :class="{ 'active': isFocused.mail }">{{$t('MAIL')}}*</label>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col :xs="24" :md="24" :sm="24">
          <div class="input-container">
            <input ref="tel" type="text" maxlength="12" class="formtextbox" autocomplete="off" v-model="formData.phone"
              @focus="onFocus('phone')" @blur="onBlur('phone')" @keyup="onlyNumber(formData.phone,'phone')" />
            <label class="placeholder coloryellow" :class="{ 'active': isFocused.phone }">{{$t('MOBILE')}}</label>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col class="center">
        <el-button type="info" @click="sendData(0,false)">
          {{$t('PREV.STEP')}}
        </el-button>
        <el-button type="primary" v-if="checkValid()" @click="sendData(2,true)">
          {{$t('NEXT.STEP')}}
        </el-button>
        <el-button type="info" disabled v-if="!checkValid()">
          {{$t('NEXT.STEP')}}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'company',
  components: {

  },
  data() {
    return {
      ckLicense: false,
      agree1: false,
      agree2: false,
      isFocused: {
        startDate: false,
        license: false,
        name: false,
        ceo: false,
        postNum: false,
        addr: false,
        addrSub: false,
        tel: false,
        fax: false,
        mail: false,
        phone: false,

      },
      formData: {
        startDate: '',
        license: '',
        name: '',
        ceo: '',
        postNum: '',
        addr: '',
        addrSub: '',
        tel: '',
        fax: '',
        mail: '',
        phone: '',
      }
    }
  },

  methods: {
    async checklicense() {
      if (!this.formData.license || this.formData.license.toString().trim() == '') {
        alert(this.$transToInput([this.$t('BRN')],this.$t('PLEASSEENTERYOURBRN')))
        return
      }
      if (!this.formData.startDate || this.formData.startDate.toString().trim() == '') {
        alert(this.$transToInput([this.$t('DBD')],this.$t('PLEASSEENTERYOURBRN')))
         return
      }
      if (!this.formData.ceo || this.formData.ceo.toString().trim() == '') {
        alert(this.$transToInput([this.$t('CEO')],this.$t('PLEASSEENTERYOURBRN')))
         return
      }

      var data = {
          businesses:
          {
            b_no: this.formData.license, // 사업자등록번호 4478602481
            b_nm: this.formData.name,
            start_dt: this.formData.startDate,   // 영업개시일 20211101
            p_nm: this.formData.ceo  // 대표자이름 YAN GUANGRI
          }
        }
      try {
        const res = await this.$http.post(this.$serverurl + '/api/businessmanValidate', data);
        switch (res.data.code) {
          case 200: {
            this.ckLicense = true
            alert(res.data.message)
            break
          }
          case 202: {
            alert(res.data.message)
            this.subAccount = true
            break
          }
          default: {
            this.ckLicense = false
            alert(res.data.message)
            break
          }
        }
      } catch (error) {
        console.log('errpr',error)
        alert('사업자검증시 오류가 발생하였습니다.')
      }
    },
    checkValid() {
      if (
        this.formData.license.trim() != '' && this.formData.name.trim() != '' &&
        this.formData.ceo.trim() != '' && this.formData.postNum.trim() != '' &&
        this.formData.addr.trim() != '' && this.formData.addrSub.trim() != '' &&
        this.formData.mail.trim() != ''
      ) { return true } else { return false }
    },
    sendData(step, status) {
      if (!this.ckLicense) {
        alert('사업자검증을 진행하세요.')
        return
      }
      this.$emit('callback', {
        step: step,
        companyInfo: this.formData,
        status: status
      })
    },
    onFocus(field) {
      this.isFocused[field] = true;
    },
    onBlur(field) {
      if (!this[field] && !this.formData[field]) {
        this.isFocused[field] = false;
      }
    },
    onlyNumber(params, field) {
      this.formData[field] = params.replace(/[^0-9]/g, '')
    },

    setLength(event,len){
      event.target.maxLength = len
    },

    loadDaumPostcodeScript() {
      const script = document.createElement('script')
      script.src =
        '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'
      script.async = true
      script.onload = () => {
        this.initializeDaumPostcode()
      }
      document.head.appendChild(script)
    },
    initializeDaumPostcode() {
      const daum = null
      new (window).daum.Postcode({
        oncomplete: (data) => {
          this.formData.postNum = data.zonecode
          this.formData.addr = data.address
        }
      }).open()
    },
  }
}
</script>

<style scope>

</style>
