<template>
  <div>
    <el-dialog center :title="title" :visible.sync="visible" :width="'500px'" :modal=true :show-close="true"
      :before-close="notClose" v-draggable>
      <div>
        <img :src="imgElement(imageUrl.url)" style="width:100%;height:100%;" />
      </div>
      <div class="mt-2">
        <span v-for="item, index in imageUrl.srcList" :key="index" class="mr-2 hand">
          <img :src="imgElement(item)" style="width:50px;height:50px;" @click="changeImage(item)"
            @mousemove="changeImage(item)" />
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'mallmanagerimageview',
  props: ['data',],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue) {
          this.title = newValue.name
          this.imageUrl.path = this.$imageurl
          this.imageUrl.url = newValue.images[0]
          this.imageUrl.srcList = newValue.images
        }
      }
    },
  },
  data() {
    return {
      title: '',
      visible: true,
      imageUrl: {},

    }
  },
  async created() {
  },
  methods: {
    imgElement(item) {
      console.log(item)
     if(!item){return}
      let imgElements = [];
          const https = item.includes('://')
          if (https) {
            imgElements = item
          } else {
            imgElements = this.$imageurl + item
          }
      return imgElements
    },
    changeImage(item) {
      this.imageUrl = {
        ...this.imageUrl,
        url: item
      }
    },
    setCallback() {
      this.$emit('callback', true)
    },
    notClose() {
      this.$emit('callback', false)
    },
    handleClose(done) {
      this.$emit('callback', false)
    },
  }
}
</script>
<style></style>