<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'800px'" :modal=true :show-close="false"
    :before-close="handleClose" :close-on-press-escape="true" v-draggable>
    <el-row :gutter="10">
      <el-col :xl="12" :md="12">
        <el-input placeholder="거래처검색" v-model="request.objSearchMapper.searchKeyword" size="mini"
          @keyup.enter.native="read()">
          <i slot="prefix" class="el-input__icon el-icon-search hand" @click="read()"></i>
        </el-input>
      </el-col>
      <el-col :xl="12" :md="12" class="text-right">
        <el-button size="mini" type="primary" @click="showcustomerCreatebox">
          {{ $t('BI.REG.') }}
        </el-button>
      </el-col>
    </el-row>
    <table class="table-params table-hover w100p table-hover font13 mt-2">
      <tr>
        <th class="w50 center">{{ $t('SELECT') }}</th>
        <th>{{ $t('ITEM') }}</th>
        <th class="w100">{{ $t('TYPE') }}</th>
        <th class="w120">{{ $t('PID') }}</th>
        <th class="w100">{{ $t('WP') }}</th>
        <th class="w100">{{ $t('RP') }}</th>
      </tr>
      <tr v-for="(item, index) in tmpBaseExList" :key="index">
        <td class="center">
          <span class="badge font12 badge-orange hand" @click="updateCallback(item,false)">
            {{ $t('SELECT') }}
          </span>
        </td>
        <td>{{ item.name }}</td>
        <td>{{ $t($productType.find(types=>types.value==item.type).label) }}</td>
        <td>{{ item.pid }}</td>
        <td>{{ $comma(item.inPrice) }}</td>
        <td>{{ $comma(item.priceD) }}</td>
      </tr>
      <tr v-if="!tmpBaseExList.length">
        <td colspan="6" class="center">{{ $t('NIF') }}</td>
      </tr>
    </table>
    <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page="request.page" :page-size="request.size"
      :page-sizes="[15, 50, 100]" layout="  prev, pager, next" :total="totalCount">
    </el-pagination>
    <div class="row mt-3">
      <div class="col right">
        <el-button size="mini" @click="handleClose">
          {{ $t('CLOSE') }}
        </el-button>
  
      </div>
    </div>
    <customerCreate v-if="showcustomerCreate" @callback="callbackCustomerCreate" />
  </el-dialog>
</template>
<script>
export default {
  name: 'selectBaseExInfo',
  components: {},
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.request.objSearchMapper.searchKeyword = newValue
        await this.read()
      }
    }
  },
  data() {
    return {
      showcustomerCreate: false,
      visible: true,
      title: `${this.$t('ITEM')}${this.$t('SELECT')}`,
      tmpBaseExList: [],
      totalCount: 0,
      request: {
        objSearchMapper: {
          searchKeyword: '',
        },
        page: 1,
        size: 10
      },
    }
  },
  async created() {
  },
  methods: {
    showcustomerCreatebox() {
      this.showcustomerCreate = true
      this.$emit('callback', { item: null, status: false, new: true })
    },
    callbackCustomerCreate(data) {
      this.showcustomerCreate = false
      if (data) this.read()
    },
    async read() {
      const res = await this.$http.post(`${this.$serverurl}/api/getSelectBaseExInfo`, this.request)
      if (200 == res.data.code) {
        this.tmpBaseExList = res.data.body.results
        this.totalCount = res.data.totalCount
        if (this.tmpBaseExList.length == 1) {
          this.updateCallback(this.tmpBaseExList[0], false)
        }

      } else {
        this.tmpBaseExList = []
        this.totalCount = 0
        this.updateCallback(null, true)
      }

    },

    updateCallback(item, newStatus) {
      this.$emit('callback', { item, status: true, new: newStatus })
    },
    handleClose(done) {
      this.$emit('callback', { item: null, status: false, new: false })
    },

    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }
  }
}
</script>
<style></style>