<template>
  <div class="pt-3">
    <div class="font15 bold mb-2">
      {{ $t($siteTitle) }} {{ $t('TOR') }}
    </div>
    <div class="borderbox p-2 mb-2">
      <div v-html="$pilicyContent($policySerivce)" style="height:230px;overflow: hidden;overflow-y: auto;"></div>
    </div>
    <div class="mb-4">
      <el-checkbox v-model="agree1">{{$t('TOS')}}</el-checkbox>
    </div>
    <div class="font15 bold mb-2">
      {{ $t($siteTitle) }} {{$t('PP')}}
    </div>
    <div class="borderbox p-2 mb-2" style="height:200px;overflow: hidden;overflow-y: auto;">
      <div v-html="$pilicyContent($privacyPolist)" style="height:230px;overflow: hidden;overflow-y: auto;">
      </div>
    </div>
    <div class="mb-4">
      <el-checkbox v-model="agree2">{{$t('RPP')}}</el-checkbox>
    </div>
    <el-row>
      <el-col class="center">
        <el-button type="primary" v-if="agree1 && agree2" @click="sendData(1,true)">
          {{$t('AAP')}}
        </el-button>
        <el-button type="info" disabled v-if="!agree1 || !agree2">
          {{$t('AAP')}}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'policy',
  components: {

  },
  data() {
    return {
      agree1: false,
      agree2: false,
      step: 0,
    }
  },
  async created() {
  },
  methods: {
    sendData(step, status) {
      this.$emit('callback', {
        step: step,
        item: null,
        status: status
      })
    },
  }
}
</script>

<style></style>
