<template>
  <div>
    <el-dialog center :title="title" :visible.sync="visible" :width="'500px'" :modal=true :show-close="true"
      :before-close="notClose" v-draggable>
      <div style="background-color: #000;color:#fff; font-family: dotum;" class="p-2 font13">
        <span class="mr-2 colorGreenLight">로그번호:</span>
        {{ dataInfo.seq }}<br/>
        <span class="mr-2 colorGreenLight">접속시간:</span>
        {{ dataInfo.accessDate }}<br/>
        <span class="mr-2 colorGreenLight">소속회사:</span>
        {{ dataInfo.companyName }}<br/>
        <span class="mr-2 colorGreenLight">접속자:</span>
        {{ dataInfo.username }}<br/>
        <span class="mr-2 colorGreenLight">계정:</span>
        {{ dataInfo.id }}<br/>
        <span class="mr-2 colorGreenLight">접속ip:</span>
        {{ dataInfo.ip }}<br/>
        <span class="mr-2 colorGreenLight">접속도메인:</span>
        {{ dataInfo.fullpath }}<br/>
        <span class="mr-2 colorGreenLight">요청API:</span>
        {{ dataInfo.apipath }}<br/>
        <span class="mr-2 colorGreenLight">암호:</span>
        {{ JSON.parse(dataInfo.body).pw }}<br/>
        <span class="coloryelowlight"> 처리시간: {{ new Date().toISOString() }}</span>
      </div>
      <div class="mt-2 right">
        <el-button size="mini" type="" @click="handleClose">
          {{$t('CLOSE')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'mallmanagerlogpwview',
  props: ['data',],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue) {
          this.dataInfo = newValue
          this.title = newValue.username + ' LOG'
        }
      }
    },
  },
  data() {
    return {
      dataInfo: null,
      title: '',
      visible: true,
      imageUrl: {},

    }
  },
  async created() {
  },
  methods: {
    imgElement(item) {
      console.log(item)
      if (!item) { return }
      let imgElements = [];
      const https = item.includes('://')
      if (https) {
        imgElements = item
      } else {
        imgElements = this.$imageurl + item
      }
      return imgElements
    },
    changeImage(item) {
      this.imageUrl = {
        ...this.imageUrl,
        url: item
      }
    },
    setCallback() {
      this.$emit('callback', true)
    },
    notClose() {
      this.$emit('callback', false)
    },
    handleClose(done) {
      this.$emit('callback', false)
    },
  }
}
</script>
<style></style>