<template>
  <div>
    <card style="margin-bottom: 10px;">
      <table class="table-params w100p">
        <tbody>
          <tr>
            <th width="100">{{ $t('BRN') }}</th>
            <td>
              {{ userlogininfo.companyInfo.license }}
            </td>
          </tr>
          <tr>
            <th width="100">{{ $t('COMPANYNAME') }}</th>
            <td>
              {{ userlogininfo.companyInfo.name }}
            </td>
          </tr>
          <tr>
            <th>{{ $t('ADDR') }}</th>
            <td>
              ({{ userlogininfo.companyInfo.postNum }}) {{ userlogininfo.companyInfo.addr }}
              {{ userlogininfo.companyInfo.addrSub }}
            </td>
          </tr>
          <tr>
            <th>{{ $t('CEO') }}</th>
            <td>
              {{ userlogininfo.companyInfo.ceo }}
            </td>
          </tr>
          <tr>
            <th>{{ $t('TEL') }}</th>
            <td>
              {{ userlogininfo.companyInfo.tel }}
            </td>
          </tr>
          <tr>
            <th>{{ $t('FAX') }}</th>
            <td>
              {{ userlogininfo.companyInfo.fax }}
            </td>
          </tr>
          <tr>
            <th>{{ $t('MOBILE') }}</th>
            <td>
              {{ userlogininfo.companyInfo.phone }}
            </td>
          </tr>
          <tr>
            <th>{{ $t('NO.OF.REG.ACC.') }}</th>
            <td>
              {{ userlogininfo.staffInfo.length }}
            </td>
          </tr>
          <tr>
            <th>{{ $t('NO.OF.REG.WHS.') }}</th>
            <td>
              {{ userlogininfo.storageInfo.length }}
            </td>
          </tr>
          <tr>
            <th>{{'판매자상태' }}</th>
            <td>
              <div class="" v-if="userlogininfo.authority.salePermi!=0">
                <i class="fa-solid fa-universal-access mr-2"></i>
                {{salePermiStatus()}}
              </div>
              <div class="hand" v-else @click="$changeRoute('saleApply')">
                판매자신청하기
              </div>
            </td>
          </tr>
          <tr>
            <th>{{ $t('VAT') }}</th>
            <td>
              <span v-if="userlogininfo.companyInfo.vat!=null">
                {{ userlogininfo.companyInfo.vat == 0 ? `부가세포함제공` : `부가세미포함제공` }}</span>
              <span v-else>{{ '미설정' }}
                <span class="ml-2 colorgray underline">판매자 신청시 (우측상단 메뉴> 판매자신청) 설정합니다.</span>
              </span>
            </td>
          </tr>
  
        </tbody>
      </table>
    </card>
    <!-- <json-viewer :value="userlogininfo" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  </div>
</template>
<script>
import erpcompanyvatupdate from './component-erp-company-vat-update.vue'
export default {
  components: { erpcompanyvatupdate, },
  name: 'mycompany',
  data() {
    return {
      userlogininfo: {},
      formData: {
        ecountCode: '',
        ecountId: '',
        ecountKey: '',
        seq: null,
      }
    }
  },
  async created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    // this.read()
  },
  methods: {
    salePermiStatus() {
      switch (this.userlogininfo.authority.salePermi) {
        case 0: { return '판매자신청하기' }
        case 1: { return '판매자 신청중' }
        case 2: { return '판매권한 - ECOUNT' }
        case 22: { return '판매권한 - 서오재고' }
        case 3: { return '판매권한 - 자사재고' }
        case 4: { return '판매자 승인취소' }
        case 5: { return '판매자 신청취소' }
      }
    },
    // async read() {
    //   await this.$http.post(`${this.$serverurl}/api/getMyCompanyInfo`, null, this.$setHeader())
    //     .then(res => {
    //       if (200 == res.data.code) {
    //         const tmpData = res.data.body.results
    //         this.formData = {
    //           ecountCode: tmpData.ecountSessionEx[0]?.ecountCode || '',
    //           ecountId: tmpData.ecountSessionEx[0]?.ecountId || '',
    //           ecountKey: tmpData.ecountSessionEx[0]?.ecountKey || '',
    //           seq: tmpData.ecountSessionEx[0]?.seq || null
    //         }
    //       }
    //     })
    // },
    // async setEcountSessionEx() {
    //   await this.$http.post(`${this.$serverurl}/api/setEcountSessionEx`, this.formData, this.$setHeader())
    //     .then(res => {
    //       if (201 == res.data.code) {
    //         alert(res.data.message)
    //         this.read()
    //       }
    //     })
    // }
  }
}
</script>
<style></style>