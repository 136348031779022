<template>
  <div>
    <div class="mt-3 mb-2 font15 bold">신청약관</div>
    <card style="margin-bottom:20px; max-height: 250px; overflow: hidden;overflow-y: auto;"
      v-if="!userlogininfo.authority.salePermi">
      <el-row :gutter="10">
        <el-col :xl="24" :md="24" class="lh25">
          본 약관은 (주)008파츠(이하 “회사”)가 제공하는 파츠얼리언스전용몰 서비스에 판매자로 가입하여 회사가 제공하는 전자상거래 관련 서비스 및 기타 서비스(이하 “서비스”)를 이용하여 재화 또는 용역
          등(이하 "상품 등")을 판매하는 자(이하 “판매자”) 간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
          <br />
          <br />
          <b>신청요건</b><br /> 판매자는 정식 사업자등록증 및 운영되는 사업장이 있는 사업자 만 신청 할수있습니다.
          <br />
          <br />
          <b>책임손실</b><br /> 판매에 관한 소비자 분쟁 발생 시 책임은 판매자에게 있습니다.
          <br />
          <br />
          <b>이용 및 제한 통지</b><br /> 회사는 관련 서비스결과 혹은 이용상태를 웹페이지 화면에 게재하거나 전자우편 또는
          메세지(채팅어플포함)를 통해 7일및 이전에 판매자에게 통지합니다.
          <br />
          <br />
          <b>서비스 변경 및 중단</b><br /> 회사는 자사내 운영결책에 따라 서비스를 변경,중단,추가할 수 있습니다.
          서비스변경 및 중단사항은 <이용 및 제한 통지>에 정한 방법으로 판매자에게 통지합니다.
            <br />
            <br />
            <b>이용제한요건</b><br /> 다음 각 호에 해당하는 경우 회사는 판매자의 서비스를 중단할 수 있습니다.
            <br />
            1. 판매물 허위게시 로 인한 분쟁이 발생하는 경우.<br />
            2. 판매거래로 인한 분쟁에 소비자보호법을 위반하는 경우.<br />
            3. 회사는 판매자가 서비스이용에 적합하지 않다고 판단할 경우.<br />
        </el-col>
      </el-row>
    </card>
    <div v-if="!userlogininfo.authority.salePermi">
      <div class="mb-2">
        <el-radio-group v-model="companyVat">
            <el-radio :label="0">{{ '판매상품>부가세포함' }}</el-radio>
            <el-radio :label="1">{{ '판매상품>부가세미포함' }}</el-radio>
          </el-radio-group>
      </div>
      <div class="mb-2">
        <el-checkbox v-model="plicyAgree">
          신청약관을 확인하였스며 판매자신청을 진행합니다.</el-checkbox>
      </div>
      <div>
        <el-button v-if="plicyAgree" size="mini" type="primary" icon="el-icon-success" @click="handleUpdate">
          판매자 신청하기
        </el-button>
      </div>
    </div>
    <card style="margin-bottom:20px; overflow: hidden;overflow-y: auto;" v-if="userlogininfo.authority.salePermi == 1">
      판매자 신청중입니다.
    </card>
    <card style="margin-bottom:20px; overflow: hidden;overflow-y: auto;" v-if="userlogininfo.authority.salePermi == 2">
      판매자 권한이 승인되었습니다.
    </card>
  
  </div>
</template>
<script>

export default {
  name: 'saleapply',
  components: {},
  data() {
    return {
      companyVat: 0,
      plicyAgree: false,
      userlogininfo: null,
    }
  },
  async created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
  },
  methods: {
    async handleUpdate() {
      this.userlogininfo.authority.salePermi = 1
      this.userlogininfo.authority.companyVat = this.companyVat
      await this.$http.post(`${this.$serverurl}/api/setsalePrdPermi`, this.userlogininfo.authority, this.$setHeader())
        .then(res => {
          if (201 == res.data.code) {
            this.userlogininfo.authority = res.data.body.results.authority
            localStorage.setItem('userlogininfo', JSON.stringify(this.userlogininfo))
            alert(res.data.message)
          }
        })
    },
  }
}
</script>
<style></style>