<template>
  <el-dialog center :title="'VIN 식별'" :visible.sync="visible" :width="'650px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable>
    <card>
      <el-row>
        <el-col :xl="4" :md="4" class="lh25">이미지</el-col>
        <el-col :xl="20" :md="20">
          <!-- <input type="file" @change="onFileChange" />
              <div contenteditable="true" @paste="onPaste">
                이미지를 여기에 붙여넣기하세요.
              </div> -->
          <div class="upload-container">
            <div class="editable-area" contenteditable="true" @paste="onPaste" @dblclick="triggerFileInput"
              placeholder="더블클릭 하거나 이미지를 여기에 붙여넣기하세요.">
              더블클릭하거나 이미지를 여기에 붙여넣기하세요.
            </div>
            <input type="file" @change="onFileChange" ref="fileInput" style="display: none;" />
          </div>
          <img style="max-width: 473px;" class="mt-2" v-if="imageSrc" :src="imageSrc" alt="Uploaded Image" />
          <div v-if="vin" class="font18 editable-area mt-2">
            {{ vin }}
          </div>

        </el-col>
      </el-row>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">{{ $t('CLOSE') }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'readimage',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
      }
    },
  },
  data() {
    return {
      visible: true,
      image: null,
      vin: '',
      imageSrc: '',
    }
  },
  async created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
  },
  methods: {
    triggerFileInput() {
    this.$refs.fileInput.click(); // 파일 입력 클릭
  },
    onFileChange() {
      const file = event.target.files[0];
      this.uploadImage(file);
    },
    onPaste() {
      const items = event.clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          const file = items[i].getAsFile();
          this.uploadImage(file);
        }
      }
    },
    async uploadImage(file) {
      const reader = new FileReader();

      reader.onload = async () => {
        const base64String = reader.result.split(',')[1]; // Base64 문자열 부분만 가져오기
        try {
          const response = await this.$http.post(this.$imageurl + "/api/ocr", {
            image: base64String, // Base64 이미지 데이터 전송
          });
          this.vin = response?.data?.text
          this.imageSrc = `data:image/png;base64,${base64String}`
        } catch (error) {
          console.error("Error:", error);
        }
      };

      reader.readAsDataURL(file); // 파일을 읽기 시작
    },
    setCallback(item) {
      this.$emit('callback', { status: true, item })
    },
    notClose() { },
    handleClose(done) {
      // done()
      this.$emit('callback', { status: false, item: null })
    },

  }
}
</script>
<style scoped>
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #409EFF;
  /* Element UI primary color */
  color: white;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 10px;
}

.custom-file-upload input {
  display: none;
  /* 숨겨진 파일 입력 */
}

.editable-area {
  border: 1px solid #dcdfe6;
  /* 기본 경계 색상 */
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  min-height: 100px;
  text-align: left;
  outline: none;
  /* 포커스 아웃라인 제거 */
  background-color: #f5f7fa;
  /* 밝은 배경 색상 */
}

.editable-area:focus {
  border-color: #409EFF;
  /* 포커스 시 테두리 색상 변경 */
}
</style>