<template>
  <el-dialog center :title="$t(title)" :visible.sync="visible" :width="'800px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable>
    <card style="margin-bottom:20px;">
      <table class="table-params w100p font12">
        <tbody>
          <tr>
            <th class="w100 center"> {{$t('OR.DATE')}}</th>
            <td calss="w150 center"> {{ $YYYYMMDD(itemInfo.created, '-') }} </td>
            <th class="w100 center"> {{$t('COMPANYNAME')}}</th>
            <td> {{ itemInfo.companyInfo.name }} ({{ itemInfo.companyInfo.phone }}) </td>
          </tr>
          <tr>
            <th class="w100 center"> {{$t('LC')}}</th>
            <td colspan="3" calss="w150">{{ }}
              {{ $deliType[itemInfo.shippingValue].label }}
              ({{ itemInfo.shippingPayType == 1 ? $t('COD') : $t('PREPAID') }})
              <span class="ml-2" v-if="itemInfo.arrive">
                화물지점: {{ itemInfo.arrive }}
              </span>
            </td>
            <!-- <th class="w100 center">{{$t('FR')}}</th>
                          <td calss="w150"> {{ $comma(itemInfo.shipping) }} </td> -->
          </tr>
        </tbody>
      </table>
      <table class="table-params w100p mt-2 font12">
        <tbody>
          <tr>
            <th class="w100 center">{{$t('SENDM')}}</th>
            <td calss="w150">{{ itemInfo.fromName }} </td>
            <th class="w100 center">{{$t('TEL')}}</th>
            <td>{{ itemInfo.fromPhone }} </td>
          </tr>
        </tbody>
      </table>
      <table class="table-params w100p mt-2 font12">
        <tbody>
          <tr>
            <th class="w100 center">{{$t('RCP')}}</th>
            <td calss="w150">{{ itemInfo.name }} </td>
          </tr>
          <tr>
            <th class="w100 center">{{$t('TEL')}}</th>
            <td calss="w150">
              {{ itemInfo.phone }}
            </td>
          </tr>
          <tr>
            <th class="w100 center">{{$t('ADDR')}}</th>
            <td calss="w150">
              <div v-if="itemInfo.postNum">
                ({{ itemInfo.postNum }}) {{ itemInfo.address }} {{ itemInfo.addrSub }}
              </div>
              <div v-else>
                -
              </div>
            </td>
          </tr>
          <tr>
            <th class="w100 center">{{$t('MEMO')}}</th>
            <td calss="w150">
              {{ itemInfo.memo }}
            </td>
          </tr>
        </tbody>
      </table>
    </card>
    <card style="margin-bottom:20px;">
      <table class="table-params w100p mt-2 font12">
        <tbody>
          <tr>
            <th class="w100 center">{{$t('STATUS')}}</th>
            <th style="width:100px;">{{$t('STORAGE')}}</th>
            <th style="width:300px;">{{$t('ITEM')}}</th>
            <th class="w100 center">{{$t('PID')}}</th>
            <th class="w100 right">{{$t('AMOUNT')}}</th>
            <th class="w50 right">{{$t('QTY')}}</th>
            <th class="w50 center" v-if="itemInfo.status==0">{{$t('CANCEL')}}</th>
          </tr>
          <tr v-for="(item, index) in itemInfo.mallOrderProductInfo" :key="index">
            <td class="center">
              {{ $t($orderProcess[item.status].label) }}
            </td>
            <td>
              {{ item.storageInfo.name || '-' }}
            </td>
            <td>
              {{ item.salesProductInfo.name || '-' }}
            </td>
            <td>
              {{ item.salesProductInfo.pid || '-' }}
            </td>
            <td class="right">
              <!-- {{ $totalInTax(item.salesProductInfo.inPrice, 1, true) }} -->
              {{ $comma(userlogininfo.companyInfo.vat==0?
              item.salesProductInfo.inPrice:Math.round(item.salesProductInfo.inPrice*1.1)) }}
            </td>
            <td class="right">
              {{ item.qty || '-' }}
            </td>
            <td class="center" v-if="itemInfo.status==0">
              <i class="hand fas fa-xmark" @click="getYesNoBoxDelete(item)"></i>
              <yesNoBox :data="massageData" :title="YesNoBoxTitle" v-if="showYesNoBoxDelete"
                @callback="yesNoBoxDeletecallback" />
  
            </td>
          </tr>
        </tbody>
      </table>
    </card>
    <card style="margin-bottom:20px;" v-if="itemInfo.status > 3">
      <table class="table-params w100p mt-2 font12">
        <tbody>
          <tr>
            <th width="100" class="center">{{$t('REASONCLASS')}}</th>
            <td>
              {{ itemInfo && $t($reasonType.find(item => item.value == itemInfo.reasonType).label )}}
            </td>
          </tr>
          <tr>
            <th class="center">{{$t('REASONFR')}}</th>
            <td>
              {{ itemInfo.detailInfo }}
            </td>
          </tr>
        </tbody>
      </table>
    </card>
    <!-- 배송번호등록 -->
    <card v-if="status >= 1 && itemInfo.status>=1">
      <table class="table-params w100p mt-2">
        <tbody>
          <tr>
            <th width="100" class="center">{{itemInfo.shippingValue==1? $t('TN'):'기타' }}</th>
            <td>
              <el-input type="text" :placeholder="itemInfo.shippingValue==1? $t('TN'):'기타'" maxlength="20"
                v-model="itemInfo.barcode" size="mini">
              </el-input>
            </td>
          </tr>
        </tbody>
      </table>
    </card>
    <card v-if="oldItemInfo.barcode">
      <table class="table-params w100p mt-2">
        <tbody>
          <tr>
            <th width="100" class="center">{{ itemInfo.shippingValue==1? $t('TN'):'기타' }}</th>
            <td>
              {{ oldItemInfo.barcode }}
            </td>
          </tr>
          <tr>
            <th width="100" class="center">{{ $t('STATUS') }}</th>
            <td>
              <span v-if="oldItemInfo.deliveryInfo">
                {{ oldItemInfo.deliveryInfo.state.text }}
              </span>
              <span v-else>
                READY
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </card>
    <div class="text-right" style="max-width:800px;">
      <!-- 조작버튼들 $orderProcess 참조-->
      <el-button size="mini" type="danger" @click="setShippiingBarcode" v-if="itemInfo.status==2&&userlogininfo.companyInfo.level!=0">
        {{ $t('SIS') }}</el-button>
  
      <span v-if="userlogininfo.companyInfo.level!=0" class="mr-2">
        <el-button size="mini" type="danger"
        v-for="(item, index) in $orderProcess.filter(
                        item => statusProcesss.find(items => items.status == itemInfo.status).process.includes(item.value))" :key="index"
        @click="getYesNoBox(item.value)">
        {{ $t(item.label) }}</el-button>
      </span>
      <el-button size="mini" type="" @click="handleClose">
        {{ $t('CLOSE') }}</el-button>
    </div>
    <yesNoBox :data="massageData" :title="YesNoBoxTitle" v-if="showYesNoBox" @callback="yesNoBoxcallback" />
    <!-- <json-viewer :value="itemInfo" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  </el-dialog>
</template>
<script>
import yesNoBox from './component-alert-continue.vue'
export default {
  components: { yesNoBox },
  name: 'ordersaleview',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.status = newValue.status
        this.oldItemInfo = JSON.parse(JSON.stringify(newValue))
        this.itemInfo = JSON.parse(JSON.stringify(newValue))
      }
    },
  },
  data() {
    return {
      deleteItem: null,
      statusProcesss: [
        { status: 0, process: [1, 8] }, // 입고요청시
        { status: 1, process: [2, 8] }, // 출고등록시
        { status: 2, process: [] }, // 배송등록시
        { status: 4, process: [5, 6] },  // 반품요청시
        { status: 5, process: [6, 7] },  // 반품요청시
        { status: 6, process: [7] },  // 반품보류시
        { status: 7, process: [] },  // 반품승인시
        { status: 8, process: [9] }, // 거래취소시
      ],
      status: 5,
      YesNoBoxTitle: '',
      messageData: {},
      showYesNoBox: false,
      showYesNoBoxDelete: false,
      deleteItemList: [],
      disableButton: false,
      visible: true,
      title: 'OR',
      total: 0,
      oldItemInfo: {},
      itemInfo: {},
      request: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 10
      },
      userlogininfo: null,
    }
  },
  async created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
  },
  methods: {



    yesNoBoxcallback(data) {
      // data === true,false
      if (data) {
        switch (this.status) {
          case 1: { // 출고등록
            this.setOrderOutstatusInfo()
            break
          }
          case 2: { // 배송등록
            this.setOrderOutShippingInfo_new()
            break
          }
          case 5: { // 반품접수
            this.setMallOrderReturnStatusInfo()
            break
          }
          case 6: { // 반품보류
            this.setMallOrderReturnStatusInfo()
            break
          }
          case 7: { // 반품승인
            this.setMallOrderReturnStatusInfo()
            break
          }
          case 8: { // 거래취소
            this.cancelOrder()
            break
          }
          case 9: { // 거래거래복구
            this.setRecoveryTrans()
            break
          }
        }
      }
      this.showYesNoBox = false
    },
    notClose() { },
    handleClose() {
      const data = {
        status: false
      }
      this.$emit('callback', data)
    },

    async getYesNoBox(status) {
      this.status = status
      this.YesNoBoxTitle = `처리확인`
      this.massageData = {
        content: `진행처리가 ${this.$t(this.$orderProcess[this.status].label)} 로 변경됩니다.`,
        contentSub: '제출된 절차는 돌이킬수 없습니다.'
      }
      this.showYesNoBox = true
    },
    getYesNoBoxDelete(item) {
      this.deleteItem = item
      this.YesNoBoxTitle = `처리확인`
      this.massageData = {
        content: `${item.salesProductInfo.name} 구매거래가 취소됩니다.`,
        contentSub: '제출된 절차는 돌이킬수 없습니다.'
      }
      this.showYesNoBoxDelete = true
    },

    async yesNoBoxDeletecallback(data) {
      if (data) {
        this.itemInfo.mallOrderProductInfo = this.itemInfo.mallOrderProductInfo.filter(items =>
          items.seq != this.deleteItem.seq)
        await this.cancelOrder()
      }
      this.showYesNoBoxDelete = false
    },

    // 배송정보저장
    async setShippiingBarcode() {
      this.disableButton = true;
      this.$http.post(this.$serverurl + "/api/setShippiingBarcode", this.itemInfo, this.$setHeader())
        .then(res => {
          if (res.data.code === 201) {
            this.disableButton = false;
            alert(res.data.message)
            this.$emit('callback', { item: null, status: true })
          } else {

          }
        })
    },
    // 거래취소
    async cancelOrder() {
      this.disableButton = true;
      this.$http.post(this.$serverurl + "/api/cancelOrderItem", this.itemInfo, this.$setHeader())
        .then(res => {
          if (res.data.code === 201) {
            this.disableButton = false;
            alert(res.data.message)
            this.$emit('callback', { item: null, status: true })
          } else {

          }
        })
    },
    // 취소된 거래 복구
    async setRecoveryTrans() {
      this.disableButton = true;
      this.$http.post(this.$serverurl + "/api/setRecoveryTrans", this.itemInfo, this.$setHeader())
        .then(res => {
          if (res.data.code === 201) {
            this.disableButton = false;
            alert(res.data.message)
            this.$emit('callback', { item: null, status: true })
          } else {

          }
        })
    },
    // 출고등록
    async setOrderOutstatusInfo() {
      const params = { ...this.itemInfo, }
      params.status = this.status
      this.$http.post(`${this.$serverurl}/api/setMallOrderstatusInfo_new`, params, this.$setHeader())
        .then(res => {
          if (res.data.code === 201) {
            alert(res.data.message)
            this.$emit('callback', { item: null, status: true })
          }
        })
    },
    // 반품승인,반품보류,반품접수등
    async setMallOrderReturnStatusInfo() {
      const params = { ...this.itemInfo, }
      params.status = this.status
      this.$http.post(`${this.$serverurl}/api/setMallOrderReturnStatusInfo`, params, this.$setHeader())
        .then(res => {
          if (res.data.code === 201) {
            alert(res.data.message)
            this.$emit('callback', { item: null, status: true })
          }
        })
    },

    //배송등록
    async setOrderOutShippingInfo_new() {
      // if (this.itemInfo.shippingValue==1 && !this.itemInfo.barcode) { alert('운송장번호를 입력하세요.'); return }
      const params = { ...this.itemInfo, }
      params.status = this.status
      this.$http.post(`${this.$serverurl}/api/setOrderOutShippingInfo_new`, params, this.$setHeader())
        .then(res => {
          if (res.data.code === 201) {
            alert(res.data.message)
            this.$emit('callback', { item: null, status: true })
          }
        })
    },
  },

}
</script>
<style></style>