<template>
  <div class="mt-2">
    <el-row :gutter="5" class="topbar" v-if="$route.name != 'SearchCenter' && $route.name != 'ProductView'">
      <!-- Logo -->
      <el-col :xs="15" :sm="15" :span="3">
        <span v-if="userlogininfo.companyInfo.level == 0" class="link_logo font20">{{ $t(this.$siteTitle) }}
          <span style="text-decoration: underline;" class="ml-3 font15 colorbluedeep">
            <span class="mr-2">PLATFORM</span>
            {{ this.userlogininfo.companyInfo.name }}
          </span>
        </span>
        <span v-else class="link_logo font20">{{ $t(this.$siteTitle) }}
          <span style="text-decoration: underline;" class="ml-3 font15 colororange">
            <span class="mr-2">VIP {{$t('DEDI')}} </span>
            {{ this.userlogininfo.companyInfo.name }}
          </span>
        </span>
      </el-col>
      <!-- PC 상단Menu -->
      <el-col :xs="9" :sm="9" :span="21" class="relative">
        <div class="topSmallmenu text-right noselect">
          <span @click="$changeRoute('mallqna')" v-if="userlogininfo.authority.accountPermi == 0">
            {{ $t('1:1 IQ') }}</span>
          <span @click="$changeRoute('epcqna')" v-if="userlogininfo.authority.accountPermi == 0">
            {{ $t('PI') }}</span>
          <span>
            <el-dropdown trigger="click" v-if="userlogininfo">
              <label class="el-dropdown-link">
                <i class="fas fa-bars"></i>
                {{ userlogininfo.name }}<i class="el-icon-arrow-down el-icon--right"></i>
              </label>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="$changeRoute('erpmanager')"
                  v-if="userlogininfo.authority.accountPermi==0">
                  <i class="fa fa-gear"></i>{{ $t('EMM') }}
                </el-dropdown-item>
                <el-dropdown-item @click.native="$changeRoute('setMyInfo')">
                  <i class="fa fa-gear"></i>{{ $t('MI') }}
                </el-dropdown-item>
                <el-dropdown-item v-if="!userlogininfo.authority.salePermi" @click.native="$changeRoute('saleApply')">
                  <i class="fa fa-user"></i>
                  {{ $t('SALLER') }}
                </el-dropdown-item>
                <el-dropdown-item v-if="userlogininfo.authority.salePermi == 1">
                  <i class="fa fa-user"></i>
                  {{ $t('SAIP') }}
                </el-dropdown-item>
                <el-dropdown-item v-if="userlogininfo.authority.salePermi == 2">
                  <i class="fa fa-user"></i>
                  {{ $t('SAE') }}
                </el-dropdown-item>
                <el-dropdown-item v-if="userlogininfo.authority.salePermi == 3">
                  <i class="fa fa-user"></i>
                  {{ $t('SAL') }}
                </el-dropdown-item>
                <el-dropdown-item v-if="userlogininfo.authority.salePermi == 6">
                  <i class="fa fa-user"></i>
                  {{ $t('SASEO') }}
                </el-dropdown-item>
                <el-dropdown-item v-if="userlogininfo.authority.salePermi == 4">
                  <i class="fa fa-user"></i>
                  {{ $t('CANCELS') }}
                </el-dropdown-item>
                <el-dropdown-item v-if="userlogininfo.authority.salePermi == 5">
                  <i class="fa fa-user"></i>
                  {{ $t('CANCELS') }}
                </el-dropdown-item>
                <el-dropdown-item @click.native="logout()">
                  <i class="fa fa-arrow-right"></i>
                  {{ $t('LOGOUT') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </div>
      </el-col>
    </el-row>
    <el-row class="hom emenu relative noselect d-none d-md-block">
      <!-- PC메뉴 -->
      <el-col :xl="24" :md="24" :sm="24" class="h40">
        <el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect" menu-trigger="hover" :router="true"
          :aria-expanded="false">
          <template v-for="(item, index) in menulist">
            <el-menu-item v-if="checkAuth(item.router)" :key="'menu-' + index" :index="item.router">
              <div class="relative">
                <span v-if="item.label=='DM'" class="absolute badge badge-red-circle"
                  style="left:0px;top:8px;">{{
                  realTimes.newMallOrder }}</span>
                <span :class="$route.name == item.router ? 'borderbottom3px-orange' : ''">
                  {{ $t(item.label) }}
                </span>
              </div>
            </el-menu-item>
          </template>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
  // props: ['searchKeywordsValue'],
  components: {},
  mounted() { },
  data() {
    return {
      visible: false,
      activeIndex: '1',
      menulist: [
        { label: 'MAIN', router: 'main' },
        {
          label: 'IM', router: 'orderin',
        },
        {
          label: 'OUTM', router: 'orderout',
        },
        {
          label: 'I.I', router: 'inven',
        },
        {
          label: 'COUNTS', router: 'countlist',
        },
        {
          label: 'E-COUNT', router: 'ecount',
        },
        {
          label: 'SEO', router: 'seo',
        },
        {
          label: 'SMI', router: 'bizmain',
        },
        { label: 'DM', router: 'ordersale' },

        { label: 'CONFIGS', router: 'basicSetting' },

        { label: 'OAM', router: 'userlist' },
        {
          label: 'SM', router: 'salemanager',
        },
        {
          label: 'SMM', router: 'mallmanager',
        },

      ],
      userlogininfo: null,
      newsTitles: [],
      searchKeyword: null,
      myInfo: null,
      realTimes: { newMallOrder: 0 },
      eventSource: null,
    }
  },
  async created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    if (!this.userlogininfo) {
      this.$changeRoute('login')
    }
    if (!this.checkAuth(this.$route.name, true)) {
      alert(this.$t('ACCESSDENIED'))
      this.$router.go(-1)
    }
    // console.log('component-top',this.userlogininfo)
    this.read()
    this.getMyInfo()
    this.event_time()
  },
  mounted() {
    this.REAL_TIME()
  },
  beforeDestroy() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  },
  methods: {
    logout() {
      this.$router.push('/login')
    },
    async REAL_TIME() {
      const token = localStorage.getItem('nestJsToken')
      this.eventSource = new EventSource(`${this.$serverurl}/event?ExJwtAuthorization=${token}`)
      this.eventSource.onmessage = (event) => {
        this.realTimes = JSON.parse(event.data)
      };
      this.eventSource.onerror = (error) => {
        console.error('SSE error:', error);
        this.eventSource.close();
      };
    },

    async event_time() {
      await this.$http.post(`${this.$serverurl}/event/event`, this.$setHeader())
        .then(res => {
            this.realTimes = res.data
        })
    },

    checkAuth(path, goto) {
      switch (path) {
        case 'seo': { //서오
          if (this.userlogininfo.authority.seoStockPermi != 0) {
            // if (this.userlogininfo.authority.accountPermi == 0) { return true }
            return false
          } else { return true }
          break
        }
        case 'bizmain': { //영업통합관리
          if (this.userlogininfo.authority.bizPermi != 0) {
            return false
          } else { return true }
          break
        }
        case 'mallmanager': { //쇼핑몰관리
          if (this.userlogininfo.authority.mallPermi != 0) {
            return false
          } else { return true }
          break
        }
        case 'salemanager': { //판매자설정
          if (this.userlogininfo.authority.saleManager != 0) {
            return false
          } else { return true }
          break
        }
        case 'ecount': { //이카운트
          if (this.userlogininfo.authority.ecountPermi != 0) {
            // if (this.userlogininfo.authority.accountPermi == 0) { return true }
            return false
          } else {
            return true
          }
          break
        }
        case 'userlist': { //자사계정설정
          if (this.userlogininfo.authority.accountType != 0) {
            return false
          } else { return true }
          break
        }
        case 'basicSetting': { //구성설정
          if (this.userlogininfo.authority.baseitemPermi != 0) {
            if (goto) { this.$router.go(-1) }
            return false
          } else { return true }
          break
        }
        case 'ordersale': { //배송관리

          if (this.userlogininfo.authority.deliPermi != 0) {
            if (this.userlogininfo.authority.salePermi == 2) { return true }
            if (this.userlogininfo.authority.accountPermi == 0) { return true }
            return false
          } else { return true }
          break
        }
        case 'inven': { // 재고관리
          if (this.userlogininfo.authority.stockPremi != 0) {
            return false
          } else { return true }
          break
        }
        // case 'countlist': { // 장부현황
        //   if (this.userlogininfo.authority.accountBookPermi != 0) {
        //     return false
        //   } else { return true }
        //   break
        // }
        case 'orderin': {    //입고거래
          if (this.userlogininfo.authority.inPermi != 0) {
            return false
          } else { return true }
          break
        }
        case 'orderout': { // 출고관리
          if (this.userlogininfo.authority.outPermi != 0) {
            return false
          } else { return true }
          break
        }
        default: {
          return true
        }
      }
    },

    handleSelect(index) {

    },

    async read() {
      const request = {
        objSearchMapper: {
          sarchKeyword: null,
          category: 0,
          type: 0
        },
        page: 1,
        size: 10
      }
      await this.$http.post(`${this.$serverurl}/api/getMallNoticeInfo`, request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.newsTitles = res.data.body.results
          }
        })
    },
    async getMyInfo() {
      const request = {
        objSearchMapper: {
          sarchKeyword: null,
          category: 0,
          type: 0
        },
        page: 1,
        size: 10
      }
      await this.$http.post(`${this.$serverurl}/api/getMyInfo`, null, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.myInfo = res.data.body.results
            if (this.userlogininfo.authority != this.myInfo.authority) {
              this.userlogininfo.authority = this.myInfo.authority
              this.userlogininfo.companyInfo = this.myInfo.companyInfo
              localStorage.setItem('userlogininfo', JSON.stringify(this.userlogininfo))
            }

          }
        })
    },
  }
}
</script>

<style scoped></style>