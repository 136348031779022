<template>
  <el-dialog center :title="$t(title)" :visible.sync="visible" :width="'600px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable style="z-index: 99999999;">
    <card>
      <el-row>
        <el-col :xl="4" :md="4" class="lh25">{{$t('AT')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-select size="mini" :placeholder="$t('AT')" v-model="formData.type">
            <el-option v-for="option in $accountpermi" :value="option.value" :label="option.label" :key="option.label">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('NAME')}}*</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('NAME')" maxlength="10" v-model="formData.name" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('TEL')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('TEL')" maxlength="50" v-model="formData.tel" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('MOBILE')}}*</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('MOBILE')" maxlength="50" v-model="formData.phone" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('MAIL')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('MAIL')" maxlength="50" v-model="formData.mail" size="mini">
          </el-input></el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('AIP')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('AIP')" maxlength="50" v-model="formData.availableIp" size="mini">
          </el-input></el-col>
      </el-row>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">{{$t('CLOSE')}}</el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-blue" @click="initPw">
          {{'암호초기화(123456)'}}</el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange" @click="setInfo">
          {{$t('SUBMIT')}}</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'malllmemberCreate',
  props: ['data',],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.formData = {
          availableIp: newValue?.availableIp || '',
          mail: newValue?.mail || '',
          tel: newValue?.tel || '',
          phone: newValue?.phone || '',
          name: newValue?.name || '',
          addrSub: newValue?.addrSub || '',
          seq: newValue?.seq || null,
          type: newValue?.type == 0 ? 0 : 1,

        }
      }
    },
  },
  data() {
    return {
      visible: true,
      title: 'MEMBER.M',
      tmpCustomerList: [],
      total: 0,
      formData: {
        availableIp: '',
        mail: '',
        tel: '',
        phone: '',
        name: '',
        seq: null,
        type: 1,
      }
    }
  },
  async created() {
  },
  methods: {
    async setInfo() {
      if (this.formData.name.trim() == '') {
        alert('상호를 입력하세요.')
        return
      }
      if (this.formData.phone.trim() == '') {
        alert('연락처를 입력하세요.')
        return
      }
      const res = await this.$http.post(`${this.$serverurl}/api/setMallMemberInfo`, this.formData, this.$setHeader())
      if (201 == res.data.code) {
        alert(res.data.message)
        this.setCallback(res.data.body.result)
      }
    },
    async initPw() {
      const res = await this.$http.post(`${this.$serverurl}/api/initPw`, this.formData, this.$setHeader())
      if (201 == res.data.code) {
        alert(res.data.message)
      }
    },

    setCallback(item) {
      this.$emit('callback', { item: item, status: true })
    },
    notClose() { },
    handleClose(done) {
      this.$emit('callback', { item: null, status: false })
    },
    // daum 주소검색
    loadDaumPostcodeScript() {
      const script = document.createElement('script')
      script.src =
        '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'
      script.async = true
      script.onload = () => {
        this.initializeDaumPostcode()
      }
      document.head.appendChild(script)
    },
    initializeDaumPostcode() {
      const daum = null
      new (window).daum.Postcode({
        oncomplete: (data) => {
          this.formData.postNumMain = data.zonecode
          this.formData.addrMain = data.address
        }
      }).open()
    },
  }
}
</script>
<style></style>