<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%">
      쇼핑몰관리통계페이지
    </card>
  </div>
</template>
<script>
export default {
  name: 'mallmanagermain',
  components: {

  },
  data() {
    return {}
  },

  created() {
  },
  methods: {


    async read() {
      await this.$http.post(`${this.$serverurl}/api/getStorageInfo_new`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
          } else {
            this.tmpTableData = []
          }
        })
    },

  }
}
</script>
<style></style>
