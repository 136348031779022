<template>
  <div class="pt-3">
    <div class="font18 bold mb-2">
      <el-row>
            <el-col :xl="18" :md="18">
              {{ $t($siteTitle) }} {{ $t('JOINUS') }}
            </el-col>
            <el-col :xl="6" :md="6" class="right">
              <el-dropdown @command="handleCommand" trigger="click" class="mr-2">
                <span class="el-dropdown-link">
                  <i class="fas fa-globe font15 hand"
                    style="padding:1px;border-radius: 50%;background-color: #000;color:#fff;"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="0">한국어</el-dropdown-item>
                  <el-dropdown-item command="1">中文简体</el-dropdown-item>
                  <el-dropdown-item command="2">Việt Nam</el-dropdown-item>
                  <el-dropdown-item command="3">ENGLISH</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <i class="el-icon-s-home hand" @click="$changeRoute('login')"></i>
            </el-col>
          </el-row>
    </div>
    <el-tabs v-model="activeRoute" @tab-click="handleClick">
      <el-tab-pane v-for="item,index in tabs" :key="index" :label="$t(item.label)" :name="item.route" disabled>
        <policy v-if="activeRoute== 'policy'" @callback="callback" />
        <company v-if="activeRoute == 'company'" @callback="callback"/>
        <member v-if="activeRoute == 'member'" :companyInfo="companyInfo" @callback="callback"/>
        <!-- <component :is="item.route" :data="companyInfo" @callback="callback"></component> -->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import policy from './component-Joinus-policy.vue'
import company from './component-Joinus-company.vue'
import member from './component-Joinus-member.vue'
export default {
  name: 'tab',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.step = newValue
      }
    },
  },
  components: {
    policy, company, member
  },
  data() {
    return {
      activeRoute: 'policy',
      companyInfo: null,
      step: 0,
      tabs: [
        { label: 'TOR', route: 'policy' },
        { label: 'CI', route: 'company' },
        { label: 'MEMBER.MI', route: 'member' }
      ]
    }
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case '0': {
          this.$i18n.locale = 'ko'
          break
        }
        case '1': {
          this.$i18n.locale = 'cn'
          break
        }
        case '2': {
          this.$i18n.locale = 'vtn'
          break
        }
        case '3': {
          this.$i18n.locale = 'en'
          break
        }
        default: {
          this.$i18n.locale = 'ko'
        }
      }
    },
    callback(data) {
      if (data.status) {
        switch (data.step) {
          case 1: {
            this.activeRoute = 'company'
            break
          }
          case 2: {
            this.activeRoute = 'member'
            this.companyInfo =  data.companyInfo
            break
          }
        }
      }else{
        switch (data.step) {
          case 0: {
            this.activeRoute = 'policy'
            break
          }
          case 1: {
            this.activeRoute = 'company'
            break
          }
        }
      }
    },
    handleClick() { }
  }
}
</script>

<style></style>
