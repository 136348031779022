<template>
  <div class="container fontsize16all">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <div class="mt-5">
           
          </div>
          <form @submit.prevent="handleSubmit(submit)">
            <card style="border-radius:2px !important;">
              <div slot="header">
                <h3 class="card-title text-center text-bold fontsize16">Login</h3>
              </div>
              <div>
                <ValidationProvider name="username" rules="required|alpha_num|min:5|max:20" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? '띄어쓰기 없는 5~10자의 영/숫자' : null" :hasSuccess="passed"
                    label="username" name="username" v-model="username" :class="'loginInput'">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider name="password" rules="required|min:5|max:15" v-slot="{ passed, failed }">
                  <fg-input type="password" :error="failed ? '띄어쓰기 없는 5~15자의 영/숫자' : null" :hasSuccess="passed"  :class="'loginInput'"
                    name="password" label="Password" v-model="password">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="text-center">
                <br>
                <button type="submit" class="btn btn-fill btn-info btn-round btn-wd ">Login</button>
                <br>
                
              </div>
            </card>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { required, min, max, alpha_num } from "vee-validate/dist/rules";

extend("required", required);
extend("alpha_num", alpha_num);
extend("min", min);
extend("max", max);

export default {
  components: {},
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    // --------------------------------------------------
    // -- common
    // --------------------------------------------------
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },

    // --------------------------------------------------
    // -- action
    // --------------------------------------------------
    submit() {
      this.$http.post(this.$serverurl + "/api/LoginByInventory_new", { 'id': this.username, 'pw': this.password })
        .then(res => {
          // this.$chLogOut(res.data.code)
          if (undefined != res.data.body) {
            localStorage.setItem('accessToken', res.data.body.accessToken);
            localStorage.setItem('nestJsToken', res.data.body.nestJsToken);
            localStorage.setItem('refreshToken', res.data.body.refreshToken);
            localStorage.setItem('userlogininfo', JSON.stringify(res.data.body.accountInfo));
            this.$http.defaults.headers.common['ExJwtAuthorization'] = `${res.data.body.nestJsToken}`;
            this.$http.defaults.headers.common['JwtAuthorization'] = `${res.data.body.accessToken}`;
            this.$router.push("/loginPhotoSub");
          }
        })
        .catch(error => {
          console.group(error)
          this.$notifyVue("warning", "아이디 또는 비밀번호를 잘못 입력했습니다2.");
        })
    },

  },
  created() {
    localStorage.removeItem('token');
    localStorage.removeItem('r_token');
    localStorage.removeItem('userlogininfo'); //01-11
    this.$http.defaults.headers.common['ExJwtAuthorization'] = null;
    this.$http.defaults.headers.common['JwtAuthorization'] = null;
  },
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.loginInput input{
 min-height:40px !important; 
}
</style>
