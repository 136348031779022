var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container fontsize16all"},[_c('div',{staticClass:"row d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-8"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"mt-5"}),_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',{staticStyle:{"border-radius":"2px !important"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h3',{staticClass:"card-title text-center text-bold fontsize16"},[_vm._v("Login")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"username","rules":"required|alpha_num|min:5|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{class:'loginInput',attrs:{"type":"text","error":failed ? '띄어쓰기 없는 5~10자의 영/숫자' : null,"hasSuccess":passed,"label":"username","name":"username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{class:'loginInput',attrs:{"type":"password","error":failed ? '띄어쓰기 없는 5~15자의 영/숫자' : null,"hasSuccess":passed,"name":"password","label":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('div',{staticClass:"text-center"},[_c('br'),_c('button',{staticClass:"btn btn-fill btn-info btn-round btn-wd ",attrs:{"type":"submit"}},[_vm._v("Login")]),_c('br')])])],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }