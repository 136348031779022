<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'600px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable style="z-index: 99999999;">
    <card>
      <el-row class="">
        <el-col :xl="4" :md="4" class="lh25">작성자</el-col>
        <el-col :xl="20" :md="20">
          {{ tmpTableData.mallMemberInfo.name }}
        </el-col>
      </el-row>
      <el-row class="mt-2 ">
        <el-col :xl="4" :md="4" class="lh25">연락처</el-col>
        <el-col :xl="20" :md="20">
          {{ tmpTableData.mallMemberInfo.phone }}
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2 borderbottom1px">
        <el-col :xl="4" :md="4" class="lh25">문의글</el-col>
        <el-col :xl="20" :md="20">
          {{ tmpTableData.content }}
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-3">
        <el-col :xl="4" :md="4" class="lh25">답글</el-col>
        <el-col :xl="20" :md="20">
          <el-input type="textarea" placeholder="답글" maxlength="200" v-model="formData.reply" size="mini" show-word-limit>
          </el-input>
        </el-col>
      </el-row>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">창닫기</el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange"
          @click="setMallBoardReplyInfo">정보제출</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'mallqnacreate',
  props: ['data',],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.tmpTableData = newValue
        this.formData = {
          seq: newValue.seq,
          reply: newValue.reply || '',
        }
      }
    },
  },
  data() {
    return {
      visible: true,
      title: '문의글관리',
      tmpTableData: null,
      total: 0,
      formData: {
        seq: null,
        reply: ''
      }
    }
  },
  async created() { },
  methods: {
    async setMallBoardReplyInfo() {
      if (this.formData.reply.trim() == '') {
        alert('답장내용을 입력하세요.')
        return
      }
      const res = await this.$http.post(`${this.$serverurl}/api/setMallBoardReplyInfo`, this.formData)
      if (201 == res.data.code) {
        alert(res.data.message)
        this.setCallback()
      }
    },
    setCallback(item) {
      this.$emit('callback', { item: null, status: true })
    },
    notClose() { },
    handleClose(done) {
      this.$emit('callback', { item: null, status: false })
    },
    // daum 주소검색
    loadDaumPostcodeScript() {
      const script = document.createElement('script')
      script.src =
        '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'
      script.async = true
      script.onload = () => {
        this.initializeDaumPostcode()
      }
      document.head.appendChild(script)
    },
    initializeDaumPostcode() {
      const daum = null
      new (window).daum.Postcode({
        oncomplete: (data) => {
          this.formData.postNumMain = data.zonecode
          this.formData.addrMain = data.address
        }
      }).open()
    },
  }
}
</script>
<style></style>