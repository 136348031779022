<template>
  <div class="container mt-3 relative" :style="'max-width:100%'">
    <!-- $pageWidth -->
    <TopBar />
    <div class="mt-2">
      <el-button v-for="(item, index) in userlogininfo.staffInfo" :key="index" size="mini"
        :type="activeName == item.seq ? 'primary' : ''" @click="handleButtons(item.seq)">{{ item.name }}</el-button>
      <div class="mt-2">
        <bizmainlist v-if="activeName!=null" :creator="activeName" />
      </div>
    </div>
  
    <!-- <json-viewer :value="userlogininfo" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  </div>
</template>
<script>
import invendetailview from './component-inven-detail-view.vue'
import bizmainlist from './component-biz-main-list.vue'
import TopBar from './component-top.vue'
export default {
  components: {
    TopBar, bizmainlist, invendetailview
  },
  data() {
    return {
      userlogininfo: {},
      selectItem: null,
      activeName: 0,
      tmpStorageData: null,
      request: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 100
      },
    }
  },
  created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    this.activeName = this.userlogininfo.seq
    this.readStorageInfo()

  },
  methods: {
    async readStorageInfo() {
      await this.$http.post(`${this.$serverurl}/api/getStorageInfo`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.tmpStorageData = res.data.body.results
          }
        })
    },

    handleButtons(params) {
      // const random_number = Math.floor(Math.random() * (999999 - 99999 + 1)) + 99999;
        this.activeName = null;
       setTimeout(() => {
        this.activeName = params;
       }, 1);
    },

  }
}
</script>
<style></style>
