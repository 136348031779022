<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%">
      <el-row :gutter="10">
        <el-col :md="12">
          <el-input placeholder="기초항목 검색" size="mini" v-model="request.objSearchMapper.searchKeyword"
            @keyup.enter.native="read()" style="max-width:200px;">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="read()"></i>
          </el-input>
          <el-button class="ml-2" type="primary" icon="el-icon-search" size="mini" @click="read()">검색</el-button>
        </el-col>
        <el-col :md="12" class="right">
          <el-button type="primary" icon="el-icon-success" size="mini" @click="insertUpdatebox()">품번등록</el-button>
          <el-button v-if="request.objSearchMapper.state == 0" type="primary" icon="el-icon-delete" size="mini"
            @click="readState(1)">삭제정보</el-button>
          <el-button v-else type="primary" icon="el-icon-s-fold" size="mini" @click="readState(0)">일반정보</el-button>
        </el-col>
      </el-row>
    </card>
    <div>
      <!-- <el-pagination class="mt-2 mb-2 text-right" layout="prev, pager, next" :total="totalCount">
      </el-pagination> -->
      <template>
        <el-table :data="tmpTableData" border style="width: 100%" class="font13">
          <el-table-column label="등록" width="100" align="center">
            <template slot-scope="scope">
              {{ $YYYYMMDD(scope.row.created) }}
            </template>
          </el-table-column>
          <el-table-column label="정품품번" width="150">
            <template slot-scope="scope">
              <div style="max-width: 300px;" class="ellip">
                <span class="badge bigbadge-light hand" @click="insertUpdatebox(scope.row)">
                  {{ scope.row.pid }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="호환품번">
            <template slot-scope="scope">
              {{ scope.row.pidList }}
            </template>
          </el-table-column>
          <el-table-column label="비고" width="150">
            <template slot-scope="scope">
              <span> {{ scope.row.memo || '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column width="50" align="right">
            <template slot="header">
              <i class="el-icon-error hand"></i>
            </template>
            <template slot-scope="scope">
              <i class="el-icon-error hand" @click="stateinsertUpdatebox(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <el-pagination v-if="totalCount" background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-size="request.size"
        :page-sizes="[15, 50, 100]" layout="total, sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
    </div>
    <!-- // 등록-갱신창 -->
    <substitutecreate v-if="cratevisible" :data="selectItem" @callback="createcallback" />
    <yesNoBox v-if="yesNoBoxvisible" @callback="deletecallback" :title="selectItem.pid" :data="{
      content: request.objSearchMapper.state == 0 ? ' 항목을 삭제 하시겠습니까?' : '항목을 복구하시겠습니까?',
      contentSub: request.objSearchMapper.state == 0 ? '삭제한 항목은 복구할수 없습니다.' : '항목을 재사용 합니다.'
    }" />
  </div>
</template>
<script>
import substitutecreate from './component-sub-stitute-create.vue'
import yesNoBox from './component-alert-continue.vue'
export default {
  name: 'substitutelist',
  components: {
    substitutecreate, yesNoBox
  },
  data() {
    return {
      yesNoBoxvisible: false,
      selectItem: null,
      cratevisible: false,
      request: {
        objSearchMapper: {
          state: 0,
          searchKeyword: null,
        },
        page: 1,
        size: 15
      },

      // tables
      tmpTableData: [],
      totalCount: null,
    }
  },

  created() {
    this.read()
  },
  methods: {
    insertUpdatebox(item) {
      this.selectItem = item
      this.cratevisible = true
    },
    deletecallback(data) {
      this.yesNoBoxvisible = false
      if (data) {
        this.stateSubstituteInfo(this.selectItem)
      }
    },
    createcallback(data) {
      this.cratevisible = false
      if (data) {
        this.read()
      }
    },
    emits(item) {
      this.$emit('callback', item)
    },
    readState(parmas) {
      this.tmpTableData =[]
      this.request.objSearchMapper.state = parmas
      this.read()
    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getSubstitute_new`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
          }else{
            this.tmpTableData=[]
            this.totalCount=0
            // alert(res.data.message)
          }
        })
    },
    stateinsertUpdatebox(item) {
      this.selectItem = item
      this.yesNoBoxvisible = true
    },
    async stateSubstituteInfo(item) {
      this.$http.post(`${this.$serverurl}/api/stateSubstitute`, item, this.$setHeader())
        .then(res => {
          alert(res.data.message)
          this.read();
        })
        .catch(error => this.$axiosCatch(error))
        .then(() => { this.chkDoubleSubmit = false; });
    },

    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }

  }
}
</script>
<style></style>
