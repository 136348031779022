
// https://soobindeveloper8.tistory.com/245
import * as crypto from 'crypto'

const methods = {

    pageWidth: 'max-width: 1300px !important;',
    siteTitle: '파츠얼리언스 ERP',

    회사이름한글: '주식회사 008파츠',
    도메인: 'www.partsalliane.co.kr',
    회사전화: '02-6925-4127',
    팩스: '02-6925-4127',
    사이트사용문의: '070-5222-6117',
    개인정보보호책임자: '심철운',
    개인정보보호책임자이메일: 'admin0440@gmail.com',
    대표자: '엄경평',
    사업자등록번호: '447-86-02481',
    통신판매업신고번호: '준비중',
    회사메일: 'car008parts@naver.com',
    회사우편번호: '04793',
    회사주소: '서울시 성동구 아차산로73 동고빌딩 1층 101호',
    운영시간: '오전9:30 ~ 오후 5:30 (토.일.공휴일휴무)',

    imageurl: 'https://api.008parts.co.kr',
    springurl: 'https://api-spring.008parts.co.kr',
    serverurl: 'https://api-test.008parts.co.kr',
    mallserverurl: 'https://api-new.008parts.co.kr',

    // serverurl: 'http://127.0.0.1:1234',
    // mallserverurl: 'http://127.0.0.1:2345',



    isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    overay(params) {
        if (!params) { return }
        this.$nextTick(() => {
            params.close();
        })
    },
    // 라우터변경 및 체크
    changeRoute(path, routeType) {
        if (this.$route.fullPath === '/' + path && !routeType) {
            return;
        }

        switch (routeType) {
            case 'new': {
                window.open('/' + path, '_blank');
                break
            }
            case 'old': {
                window.location.href = ('/' + path)
                break
            }
            default: {
                this.$router.push('/' + path);
                break
            }
        }
    },

    numberFormat(data, field, type, min, max) {
        switch (type) {
            case 'focus':
                if (0 == data[field] || '' == data[field]) {
                    return '';
                } else {
                    let tmpdata = this.$commaReplace(data[field])
                    return tmpdata
                }
            case 'input':
                let tmpdata = this.$commaReplace(data[field])
                return tmpdata
            case 'blur':
                if ('' == data[field]) {
                    return 0;
                } else {
                    let tmpdata = this.$delComma(data[field])
                    if (tmpdata < min) { tmpdata = min }
                    if (tmpdata > max) { tmpdata = max }
                    return this.$comma(tmpdata)
                }
            default:
                return 0;
        }
    },

    setHeader(params) {
        var tmpHeaderType = null;
        if (undefined == params) {
            tmpHeaderType = { "Content-Type": 'application/json;charset=UTF-8' };
        } else {
            tmpHeaderType = { "Content-Type": 'multipart/form-data' };
        }
        if (undefined == this.$http.defaults.headers.common['JwtAuthorization']) {
            const spring = localStorage.getItem('accessToken')
            const nestjs = localStorage.getItem('nestJsToken')
            if (null != nestjs && null != spring) {
                this.$http.defaults.headers.common['JwtAuthorization'] = `${spring}`
                this.$http.defaults.headers.common['ExJwtAuthorization'] = `${nestjs}`
            }
        }
        return {
            headers: {
                tmpHeaderType
            }
        }
    },

    // comma(params) {
    //     params = params || 0;
    //     console.log(Math.floor(params) )
    //     params  = (params + '').length >= 4 ? params.toLocaleString('ko-KR', 0).split('.')[0] : params;
    //     return Math.floor(params) 
    // },
    comma(params) {
        if (!params) { return 0 }
        params = Math.round(params)
        // params = Math.floor(params)
        return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    delComma(params) {
        params = params || 0
        const returnValue = (params + '').replace(/[^\d]/g, '')
        const value = isNaN(returnValue) ? '' : returnValue;
        return parseFloat(value);
    },
    commaReplace(params) {
        params = params ? params.toString().replace(/[^\d]/g, '') : '0'
        return params.length >= 4 ? parseInt(params).toLocaleString('ko-KR') : params
    },
    YYYYMMDD(data, type) {
        if (!type) { type = '.' }
        if (!data) { return false }
        let tmpDate = data.toString().substr(0, 10)
        return tmpDate.replace(/-/g, type);
    },

    dateToYYYYMMDD(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`
    },
    YYYYMMDD_HHMM(dateString, type) {
        if (!type) { type = '.' }
        const date = new Date(dateString);
        // const year = date.getFullYear();
        const year = ('' + date.getYear()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        return `${year}${type}${month}${type}${day} ${hours}:${minutes}`
    },
    YYYYMMDD_HHMMSS(dateString, type) {
        if (!type) { type = '.' }
        const date = new Date(dateString);
        // const year = date.getFullYear();
        const year = ('' + date.getYear()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);
        return `${year}${type}${month}${type}${day} ${hours}:${minutes}:${seconds}`;
    },

    createSecuryNumber() {
        const currentDate = new Date();
        const milliseconds = currentDate.getMilliseconds();
        const randomSixDigitNumber = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
        const result = `${milliseconds}${randomSixDigitNumber}`;
        return result.substr(0, 6);
    },
    subreduce(items, nameA, nameB) {
        if (nameB) {
            return items.reduce((total, item) => {
                return total + item[nameA] * item[nameB]
            }, 0)
        } else {
            return items.reduce((total, item) => {
                return total + item[nameA]
            }, 0)
        }
    },

    totalInTax(a, b, isComma) {
        if (isComma) {
            return this.$comma(a * b + a * b * 0.1)
        } else {
            return a * b + a * b * 0.1
        }

    },

    amountSplitTax(totalAmount, vat) {
        totalAmount = this.$delComma(totalAmount)
        var SAL_AMT = 0;
        var supplyAmount = 0;
        var vatAmount = 0;
        SAL_AMT = totalAmount; // 판매금액
        if (vat == 'Y') // 부가세포함일경우
        {
            vatAmount = ((SAL_AMT / 1.1) * 0.1);    // 부가세(VAT)
            supplyAmount = Math.round(SAL_AMT - vatAmount); // 단가(반올림)
            vatAmount = Math.round(vatAmount);    // 부가세(반올림)
        }
        else if (vat == 'N') // 부가미세포함일경우
        {
            vatAmount = 0;
            supplyAmount = SAL_AMT;
        }
        return { vatAmount, supplyAmount }
    },

    listMoreBaseItems(params) {
        if (!params.length) { return false }
        return params?.length > 1 ?
            params[0].baseInfo.name + ' 외 ' + (params.length - 1) + '건' :
            params[0].baseInfo.name
    },

    // ----------------------------------------------------
    // -- notify
    // ----------------------------------------------------
    notifyVue(a_type, a_text) {
        // noti_type: ["", "info", "success", "warning", "danger"],
        this.$notify({
            // icon: 'nc-icon nc-app',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: a_type,
            title: a_text
        })
    },
    toast_msg(string) {
        const toast = document.getElementById("toast_msg");
        let removeToast;
        toast.classList.contains("reveal") ?
            (clearTimeout(removeToast), removeToast = setTimeout(function () {
                document.getElementById("toast_msg").classList.remove("reveal")
            }, 1000)) :
            removeToast = setTimeout(function () {
                document.getElementById("toast_msg").classList.remove("reveal")
            }, 1000)
        toast.classList.add("reveal"),
            toast.innerText = string
    },
    // --------------------------------------------------
    // -- modal
    // --------------------------------------------------
    addTodo() {
        if (this.doItem) {
            // this.$emit('이벤트이름', 인자1, 인자2);
            this.$emit("addOne", this.doItem);
            this.clearInput();
        } else {
            this.show_modal = !this.show_modal;
        }
    },
    clearInput() {
        this.doItem = "";
    },

    // ----------------------------------------------------
    // -- ad-grid
    // ----------------------------------------------------
    defaultColDef: {
        editable: false,
        enableValue: true,
        sortable: true,
        resizable: true,
        filter: false,
        flex: 1,
        minWidth: 70,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
    },
    onBtExport() {
        // console.log("onBtExport()")
        this.gridApi.exportDataAsExcel();
    },
    onPageSizeChanged(a_element_id) {
        // console.log("onPageSizeChanged")
        var value = document.getElementById(a_element_id).value;
        this.gridApi.paginationSetPageSize(Number(value));
    },
    onFilterTextBoxChanged(a_element_id) {
        // console.log("onFilterTextBoxChanged")
        this.gridApi.setQuickFilter(document.getElementById(a_element_id).value);
    },
    autoSizeAll() {
        var allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds, true);
    },
    formatNumber(params) {
        var value = Math.floor(params.value);
        if (isNaN(value))
            return '';
        return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
    formatNumberEx(params) {
        var value = Math.floor(params.value);
        if (isNaN(value))
            return '';
        return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + "원";
    },
    numberValueSetter(field) {
        function fn(params) {
            // console.log("numberValueSetter")
            if (isNaN(parseFloat(params.newValue)) || !isFinite(params.newValue)) {
                return false; // don't set invalid numbers!
            }
            if (0 > parseFloat(params.newValue)) {
                return false;
            }

            params.data[field] = params.newValue;
            return true;
        }
        return fn;
    },


    trimLR(params) {
        if (!params) { params = '' }
        return params.trim()
    },
    formatTel(a_telNum, a_fenge) {
        if (undefined === a_telNum || null === a_telNum || 9 > a_telNum.length) {
            return '';
        }
        var tmpFenge = a_fenge;
        if (undefined === tmpFenge || null === tmpFenge) {
            tmpFenge = '';
        }
        var tmpTel;
        switch (a_telNum.length) {
            case 11: { tmpTel = tmpFenge + '' + a_telNum.substring(0, 3) + '-' + a_telNum.substring(3, 7) + '-' + a_telNum.substring(7, 11); break; }
            case 10: { tmpTel = a_telNum.substring(0, 3) + '-' + a_telNum.substring(3, 6) + '-' + a_telNum.substring(6, 10); break; }
            case 9: { tmpTel = a_telNum.substring(0, 2) + '-' + a_telNum.substring(2, 5) + '-' + a_telNum.substring(5, 9); break; }
            default: { tmpTel = ''; break; }
        }
        return tmpTel;
    },
    snPlusFor(sn) {
        if (!sn) { return }
        const YMD = sn.substr(2, 6)
        const HMS = sn.substr(8, 4)
        const CODE = sn.substr(12, 8)
        return YMD + '-' + CODE
    },
    formatLicense(a_license) {
        if (undefined === a_license || null === a_license || 10 != a_license.length) {
            return '000-00-00000';
        }
        return a_license.substring(0, 3) + '-' + a_license.substring(3, 5) + '-' + a_license.substring(5, 11);
    },
    doPrint() {
        window.print();
    },
    printView(title, pathTitle, a_params, width, height) {//정보프린터
        let routerUrl = this.$router.resolve({
            name: pathTitle
        })
        localStorage.setItem('printInfo', JSON.stringify(a_params))
        window.open(routerUrl.href, title, 'width=' + width + ',height=' + height)
    },

    vatType: [
        { label: 'VAT', value: 0 }, //부가세
        { label: 'CASH', value: 1 }, //현금
    ],

    customerBizType: [
        { label: 'W&R', value: 0 }, //도매 및 소매업
        { label: 'T&W', value: 1 }, //운송 및 창고업
        { label: 'ICT', value: 2 }, //정보통신업
        { label: 'FIA', value: 3 }, //금융 및 보험업
        { label: 'REA', value: 4 }, //부동산업
        { label: 'RST', value: 5 }, //전문, 과학 및 기술 서비스업
        { label: 'OS', value: 6 }, //기타 서비스업
    ],

    productType: [
        { label: "RB", value: 0, title: '리빌드' }, //리빌드
        { label: "AF", value: 1, title: '애프터' }, //애프터
        { label: "OE", value: 2, title: '정품' }, //정품
        { label: "RT", value: 3, title: '재생' }, //재생
        { label: "UP", value: 4, title: '중고' }, //중고
    ],
    reasonType: [
        { label: 'RTN', value: 0 }, //반품
        { label: 'EXC', value: 1, hidden: true }, //교환
        { label: 'CT', value: 2, hidden: true }, //거래취소
    ],
    returnSubType: [
        { label: '초기불량', value: 0 },
        { label: '단순변심', value: 1 },
        { label: '장착중불량', value: 2 },
        { label: '상품정보상이', value: 3 },
        { label: '배송지연', value: 4 },
        { label: '판매자취소', value: 5 },
    ],

    saleProcess: [
        { label: "UPRCS", value: 0, seo: true, }, // 미처리
        { label: "AR", value: 1, seo: false, }, // 승인요청
        { label: "AP", value: 2, seo: true, }, // 승인허가
        { label: "RC", value: 3 }, // 요청취소
        { label: "AC", value: 4 }, // 승인취소
        { label: "SC", value: 5, seo: true }, // 판매취소
    ],
    //	0	출고준비	입고요청
    //	1	출고완료	출고확인
    //	2	배송중	    배송등록
    //	3	판매취소	출고취소
    //	4	반품요청	반품요청
    //	5	반품완료	거래취소
    orderProcess: [
        { label: "IN.R", value: 0 }, //입고요청
        { label: "DR", value: 1 }, //출고등록
        { label: "DELI.R", value: 2 }, //배송등록
        { label: "CD", value: 3, hidden: true }, //출고취소
        { label: 'RFR', value: 4 }, //반품요청
        { label: 'RR', value: 5 }, //반품접수
        { label: 'RH', value: 6 }, //반품보류
        { label: 'RA', value: 7 }, //반품승인
        { label: 'CT', value: 8 }, //거래취소
        // { label: 'WOC', value: 9, hidden: true }, //취소철회 거래복구
    ],
    orderProcesssub: [
        { label: "DELI.R", value: 2 }, //배송등록
        { label: 'RA', value: 7 }, //반품승인
        { label: 'CT', value: 8 }, //거래취소
    ],
    deliType: [
        { label: "경동화물", value: 0 },
        { label: "경동택배", value: 1 },
        { label: "퀵배송", value: 2 },
        { label: "버스탁송", value: 3 },
        { label: "방문수령", value: 4 },
    ],

    returnGoodsType: [ //delete
        { label: '반품요청', value: 0 },
        { label: '반품접수', value: 1 },
        { label: '반품보류', value: 2 },
        { label: '반품승인', value: 3 },
        { label: '거래취소', value: 4 },
    ],
    levels: [
        { label: "SEMP", value: 0 },    // 사원
        { label: "SSUPV", value: 1 },    //주임
        { label: "SASST", value: 2 },    //대리
        { label: "SSC", value: 4 },    //과장
        { label: "SDSC", value: 5 },    //차장
        { label: "STL", value: 6 },    //팀장
        { label: "SDH", value: 7 },    //부장
        { label: "SDG", value: 8 },    //실장
        { label: "SED", value: 9 },    //상무
        { label: "SEVP", value: 10 },   //전무
        { label: "SDR", value: 11 },   //이사
        { label: "PRES", value: 12 },   //사장
        { label: "CEO", value: 13 },   //대표
    ],
    accountpermi: [
        { label: "대표계정", value: 0 },
        { label: "일반계정", value: 1 },
    ],
    language: [
        { label: "한국어", value: 0, file: 'ko' },
        { label: "中国大陆", value: 1, file: 'cn' },
        { label: "Việt Nam", value: 2, file: 'vtn' },
        { label: "English", value: 3, file: 'en' },
    ],
    salePrdPermis: [
        { value: 0, label: 'UPRCS' }, //미신청 --신청중
        { value: 1, label: 'SALLER' }, //판매자신청--신청중
        { value: 2, label: 'SALLER.A.ECOUNT' }, // 판매자승인--승인 이카운트
        { value: 3, label: 'SALLER.A.LOCAL' }, // 판매자승인--승인 자사
        { value: 6, label: 'SALLER.A.SEO' }, // 판매자승인--승인  SEO재고
        { value: 4, label: 'SALLER.RJ' }, // 판매자취소--취소
        { value: 5, label: 'DENIAL.A' }, // 신청취소--취소
    ],
    getProdType(item) {

        item.cont4 = item.cont4.replace('에프터', '애프터')
        item.cont4 = item.cont4.replace('리빌트', '리빌드')
        const items = this.$productType.find(items => {
            if (items.title == item.cont4) { return item }
        })
        if (!items) {
            return this.$productType[0].label
        }
        return items.label
    },
    companyLevel: [
        { level: 'a', label: 'A' },
        { level: 'b', label: 'B' },
        { level: 'c', label: 'C' },
        { level: 'd', label: 'D' },
        { level: 'e', label: 'E' },
    ],

    noticeTypeList: [
        { value: 0, label: 'NOTICE', class: 'fas fa-volume-high' }, //공지
        { value: 2, label: 'GUIDE', class: 'fas fa-hands-asl-interpreting' }, //사용가이드
        { value: 3, label: 'FAQ', class: 'fas fa-circle-question' },
    ],
    noticeCategory: [
        { label: 'NOTICE', value: 0 }, //일반공지
        { label: 'PG', value: 1 }, //정책안내
        { label: 'DC', value: 2 }, //분쟁사례
    ],
    // 입출금카테고리
    accountBookType: [
        { value: 'license', label: 'LP' },//라이센스구매
        { value: 'order', label: 'PT' },//상품구매
        { value: 'point', label: 'CR' },//캐시충전
        { value: 'cancel', label: 'CT' },//거래취소
        { value: 'recovery', label: 'WOC' },//거래복구
        { value: 'approval', label: 'RTN' },//반품
        { value: 'shipping', label: 'S.C' },//배송비용
    ],
    shippingtype: [
        { label: '선불', value: 0 },
        { label: '후불', value: 1 },
    ],
    filterDate(params, action) {
        var sDate = JSON.parse(JSON.stringify(params))
        if (0 == params.length) {
            sDate[0] = new Date();
            sDate[0] = sDate[0].getTime() - (1 * 24 * 60 * 60 * 1000)
            sDate[0] = new Date(sDate[0])
            sDate[1] = new Date();
        } else {
            sDate[0] = new Date(sDate[0])
            sDate[0] = sDate[0].getTime() + (1 * 24 * 60 * 60 * 1000)
            sDate[0] = new Date(sDate[0])
        }
        sDate[1] = new Date(sDate[1])
        sDate[1] = sDate[1].getTime() + (1 * 24 * 60 * 60 * 1000)
        sDate[1] = new Date(sDate[1])
        const startD = sDate[0].toISOString().substr(0, 10)
        const endD = sDate[1].toISOString().substr(0, 10)
        this.request.objSearchMapper.startDate = startD
        this.request.objSearchMapper.endDate = endD
        this.read(action);
    },
    // 빈자리 없앰
    removeSpace(params) {
        if (params !== null && typeof params === 'string') {
            return params.replace(/\s+/g, '')
        } else {
            return params
        }
    },
    policySerivce: `제1조(목적)
    이 약관은 xxxcompanyname(전자거래 사업자)이 운영하는 xxxcompanyname(이하 "홈페이지"이라 한다)에서 제공하는 인터넷 관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 사이버홈페이지과 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
    ※ 「PC통신 등을 이용하는 전자거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다」
    
    
    제2조(정의)
    ① "홈페이지" 이란 사업자가 재화 또는 용역을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 또는 용역을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버홈페이지을 운영하는 사업자의 의미로도 사용합니다.
    
    ② "이용자"란 "홈페이지"에 접속하여 이 약관에 따라 "홈페이지"이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
    
    ③ '회원'이라 함은 "홈페이지"에 개인정보를 제공하여 회원등록을 한 자로서, "홈페이지"의 정보를 지속적으로 제공받으며, "홈페이지"이 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
    
    ④ 비회원'이라 함은 회원에 가입하지 않고 "홈페이지"이 제공하는 서비스를 이용하는 자를 말합니다.
    
    
    제3조 (약관의 명시와 개정)
    ① "홈페이지"은 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처(전화, 팩스, 전자우편 주소 등) 등을 이용자가 알 수 있도록 사이트의 초기 서비스화면(전면)에 게시합니다.
    
    ② "홈페이지"은 약관의 규제 등에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용촉진 등에 관한 법률, 방문판매 등에 관한법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
    
    ③ "홈페이지"이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 홈페이지의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.
    
    ④ "홈페이지"이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 '홈페이지"에 송신하여 "홈페이지"의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
    
    ⑤ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한 전자거래소비자보호지침 및 관계법령 또는 상관례에 따릅니다.
    
    
    제4조(서비스의 제공 및 변경)
    ① "홈페이지"은 다음과 같은 업무를 수행합니다.
    1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
    2. 구매계약이 체결된 재화 또는 용역의 배송
    3. 기타 "홈페이지"이 정하는 업무
    
    ② "홈페이지"은 재화의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화·용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화·용역의 내용 및 제공일자를 명시하여 현재의 재화·용역의 내용을 게시한 곳에 그 제공일자 이전 7일부터 공지합니다.
    
    ③ "홈페이지"이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 "홈페이지"은 이로 인하여 이용자가 입은 손해를 배상합니다. 단, "홈페이지"에 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.
    
    
    제5조(서비스의 중단)
    ① "홈페이지"은 컴퓨터 등 정보통신설비의 보수점검·교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
    
    ② 제1항에 의한 서비스 중단의 경우에는 "홈페이지"은 제8조에 정한 방법으로 이용자에게 통지합니다.
    
    ③ "홈페이지"은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단 "홈페이지"에 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.
    
    
    제6조(회원가입)
    ① 이용자는 "홈페이지"이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
    
    ② "홈페이지"은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
    1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 "홈페이지"의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
    2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
    3. 기타 회원으로 등록하는 것이 "홈페이지"의 기술상 현저히 지장이 있다고 판단되는 경우
    
    ③ 회원가입계약의 성립 시기는 "홈페이지"의 승낙이 회원에게 도달한 시점으로 합니다.
    
    ④ 회원은 제15조제1항에 의한 등록사항에 변경이 있는 경우, 즉시 전자우편 기타 방법으로 "홈페이지"에 대하여 그 변경사항을 알려야 합니다.
    
    
    제7조(회원 탈퇴 및 자격 상실 등)
    ① 회원은 "홈페이지"에 언제든지 탈퇴를 요청할 수 있으며 "홈페이지"은 즉시 회원탈퇴를 처리합니다.
    
    ② 회원이 다음 각 호의 사유에 해당하는 경우, "홈페이지"은 회원자격을 제한 및 정지시킬 수 있습니다.
    1. 가입 신청 시에 허위 내용을 등록한 경우
    2. "홈페이지"을 이용하여 구입한 재화·용역 등의 대금, 기타 "홈페이지"이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
    3. 다른 사람의 "홈페이지" 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우
    4. "홈페이지"을 이용하여 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
    
    ③ "홈페이지"이 회원 자격을 제한·정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 "홈페이지"은 회원자격을 상실시킬 수 있습니다.
    
    ④ "홈페이지"이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.
    
    
    제8조(회원에 대한 통지)
    ① "홈페이지"이 회원에 대한 통지를 하는 경우, 회원이 "홈페이지"에 제출한 전자우편 주소로 할 수 있습니다.
    
    ② "홈페이지"은 불특정다수 회원에 대한 통지의 경우 1주일이상 "홈페이지" 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다.
    
    
    제9조(구매신청)
    "홈페이지"이용자는 "홈페이지"상에서 이하의 방법에 의하여 구매를 신청합니다.
    1. 성명, 비밀번호, 전화번호, 핸드폰번호, 주소, E-MAIL 입력
    2. 재화 또는 용역의 선택
    3. 결제방법의 선택
    4. 이 약관에 동의한다는 표시(예, 마우스 클릭)
    
    
    제10조 (계약의 성립)
    ① "홈페이지"은 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하지 않는 한 승낙합니다.
    1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
    2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우
    3. 기타 구매신청에 승낙하는 것이 "홈페이지" 기술상 현저히 지장이 있다고 판단하는 경우
    
    ② "홈페이지"의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
    
    
    제11조(지급방법)
    홈페이지"에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각호의 하나로 할 수 있습니다.
    1. 계좌이체
    2. 신용카드결제
    3. 온라인무통장입금
    4. 전자화폐에 의한 결제
    5. 수령 시 대금지급 등
    
    
    제12조(수신확인통지·구매신청 변경 및 취소)
    ① "홈페이지"은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.
    
    ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있습니다.
    
    ③ "홈페이지"은 배송 전 이용자의 구매신청 변경 및 취소 요청이 있는 때에는 지체 없이 그 요청에 따라 처리합니다.
    
    
    제13조(배송)
    "홈페이지"은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 "홈페이지"의 고의·과실로 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상합니다.
    
    
    제14조(환급, 반품 및 교환)
    ① "홈페이지"은 이용자가 구매 신청한 재화 또는 용역이 품절 등의 사유로 재화의 인도 또는 용역의 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고, 사전에 재화 또는 용역의 대금을 받은 경우에는 대금을 받은 날부터 3일 이내에, 그렇지 않은 경우에는 그 사유발생일로부터 3일 이내에 계약해제 및 환급절차를 취합니다.
    
    ② 다음 각 호의 경우에는 "홈페이지"은 배송된 재화일지라도 재화를 반품 받은 다음 영업일 이내에 이용자의 요구에 따라 즉시 환급, 반품 및 교환 조치를 합니다. 다만 그 요구기한은 배송된 날로부터 20일 이내로 합니다.
    1. 배송된 재화가 주문내용과 상이하거나 "홈페이지"이 제공한 정보와 상이할 경우
    2. 배송된 재화가 파손, 손상되었거나 오염되었을 경우
    3. 재화가 광고에 표시된 배송기간보다 늦게 배송된 경우
    4. 방문판매 등에 관한 법률 제18조에 의하여 광고에 표시하여야 할 사항을 표시하지 아니한 상태에서 이용자의 청약이 이루어진 경우
    
    
    제15조(개인정보보호)
    ① "홈페이지"은 이용자의 정보 수집시 구매계약 이행에 필요한 최소한의 정보를 수집합니다.
    다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.
    1. 희망ID(회원의 경우)
    2. 비밀번호(회원의 경우)
    3. 이름
    4. E-MAIL
    5. 주소
    6. 전화번호
    7. 휴대번호
    8. 메일링서비스
    9. SMS 수신여부
    
    ② "홈페이지"이 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.
    
    ③ 제공된 개인정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 "홈페이지"이 집니다. 다만, 다음의 경우에는 예외로 합니다.
    1. 배송업무상 배송업체에게 배송에 필요한 최소한의 이용자의 정보(성명, 주소, 전화번호)를 알려주는 경우
    2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
    
    ④ "홈페이지"이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공 받는자, 제공목적 및 제공할 정보의 내용)등 정보통신망이용촉진 등에 관한 법률 제16조제3항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
    
    ⑤ 이용자는 언제든지 "홈페이지"이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 "홈페이지"은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 "홈페이지"은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
    
    ⑥ "홈페이지"은 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
    
    ⑦ "홈페이지" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
    
    
    제16조("홈페이지"의 의무)
    ① "홈페이지은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화·용역을 제공하는 데 최선을 다하여야 합니다.
    
    ② "홈페이지"은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
    
    ③ "홈페이지"이 상품이나 용역에 대하여 「표시·광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시·광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
    
    ④ "홈페이지"은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.
    
    
    제17조( 회원의 ID 및 비밀번호에 대한 의무)
    ① 제15조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
    
    ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.
    
    ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "홈페이지"에 통보하고 "홈페이지"의 안내가 있는 경우에는 그에 따라야 합니다.
    
    
    제18조(이용자의 의무)
    이용자는 다음 행위를 하여서는 안 됩니다.
    1. 신청 또는 변경 시 허위내용의 등록
    2. "홈페이지"에 게시된 정보의 변경
    3. "홈페이지"이 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시
    4. "홈페이지" 기타 제3자의 저작권 등 지적재산권에 대한 침해
    5. "홈페이지" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
    6. 외설 또는 폭력적인 메시지·화상·음성 기타 공서양속에 반하는 정보를 홈페이지에 공개 또는 게시하는 행위
    
    
    제19조(연결"홈페이지"과 피연결"홈페이지" 간의 관계)
    ① 상위 "홈페이지"과 하위 "홈페이지"이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 "홈페이지"(웹 사이트)이라고 하고 후자를 피연결 "홈페이지"(웹사이트)이라고 합니다.
    
    ② 연결 "홈페이지"은 피연결 "홈페이지"이 독자적으로 제공하는 재화·용역에 의하여 이용자와 행하는 거래에 대해서 보증 책임을 지지 않는다는 뜻을 연결 "홈페이지"의 사이트에서 명시한 경우에는 그 거래에 대한 보증 책임을 지지 않습니다.
    
    
    제20조(저작권의 귀속 및 이용제한)
    ① "홈페이지"이 작성한 저작물에 대한 저작권 기타 지적재산권은 "홈페이지"에 귀속합니다.
    
    ② 이용자는 "홈페이지"을 이용함으로써 얻은 정보를 "홈페이지"의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
    
    
    제21조(분쟁해결)
    ① "홈페이지"은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치·운영합니다.
    
    ② "홈페이지"은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
    
    ③ "홈페이지"과 이용자간에 발생한 분쟁은 전자거래기본법 제28조 및 동 시행령 제15조에 의하여 설치된 전자거래분쟁조정위원회의 조정에 따를 수 있습니다.
    
    
    제22조(재판권 및 준거법)
    ① "홈페이지"과 이용자간에 발생한 전자거래 분쟁에 관한 소송은 민사소송법상의 관할법원에 제기합니다.
    
    ② "홈페이지"과 이용자간에 제기된 전자거래 소송에는 한국법을 적용합니다.`,
    privacyPolist: `'xxxcompanyname'(이하 '회사') 은(는) 이용자의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.
    회사는 개인정보취급방침을 통하여 이용자께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
   회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
    ● 본 방침은  : 2024 년 5 월 1 일 부터 시행됩니다.
   ■ 수집하는 개인정보 항목
   회사는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
   ● 수집항목 :  이름 , 로그인ID , 비밀번호 , 자택 전화번호 , 자택 주소 , 휴대전화번호 , 이메일 , 서비스 이용기록 , 접속 로그 , 접속 IP 정보 , 결제기록
   ● 개인정보 수집방법 : 홈페이지(xxxmalldomain)
   ■ 개인정보의 수집 및 이용목적
   회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
    ● 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
   콘텐츠 제공 , 구매 및 요금 결제 , 물품배송 또는 청구지 등 발송
    ● 회원 관리
   회원제 서비스 이용에 따른 본인확인 , 개인 식별 , 불량회원의 부정 이용 방지와 비인가 사용 방지 , 가입 의사 확인 , 연령확인 , 불만처리 등 민원처리
    ● 마케팅 및 광고에 활용
   이벤트 등 광고성 정보 전달 , 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계
   ■ 개인정보의 보유 및 이용기간
   원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
    보존 항목 : 이름 , 자택 전화번호 , 자택 주소 , 휴대전화번호 , 이메일 , 결제기록
    보존 근거 : 관계법령에 의거
    보존 기간 : 관계법령에 의거
    계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
   대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
   소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)
   신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (신용정보의 이용 및 보호에 관한 법률)
   ■ 개인정보의 파기절차 및 방법
   회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
    ● 파기절차
   회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다.
   별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
    ● 파기방법
      - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
    ■ 개인정보 제공
    회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
   - 이용자들이 사전에 동의한 경우
   - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
    ■ 수집한 개인정보의 위탁
    회사는 이용자의 동의없이 이용자의 정보를 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 이용자에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.
   ■ 이용자 및 법정대리인의 권리와 그 행사방법
   이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
   이용자 혹은 만 14세 미만 아동의 개인정보 조회?수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’ 등)을 가입해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
   혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.
   귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.
   ○○○ 는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 “○○○가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그 외의
   용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
    ■ 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
   회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’,‘로컬스토리지(LocalStorage)’ 등을 운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 회사은(는) 다음과 같은 목적을 위해 쿠키를 사용합니다.
   로컬스토리지란 로그인 된 이용자의 정보를 웹브라우저가 제공하는 임시 공간을 의미합니다. 회사은(는) 다음과 같은 목적을 위해 사용합니다.
    ▷ 쿠키, 로컬스토리지 등 사용 목적
    - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
     귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 귀하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
    ▷ 쿠키, 로컬스토리지 설정 거부 방법
   예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
   설정방법 예(인터넷 익스플로어의 경우)
   : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보
   로컬스토리지는 이용자가 로그인 시 필수로 사용되는 기능이며 거부를 원하시면 로그아웃 및 서비스 이용을 중단을 하여 거부행사를 하실수 있습니다.
   이용자가 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
   ■ 개인정보에 관한 민원서비스
   회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.
   고객서비스담당 부서 : 고객센터
   전화번호 : xxxcompanytel
   이메일 : xxxcompanymail
   개인정보관리책임자 : xxx개인정보보호책임자
   전화번호 : xxx사이트사용문의
   이메일 : xxx개인정보보호책임자이메일
   이용자는 회사의 서비스 이용중에 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 
   회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드리겠습니다.
   기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
   1.개인분쟁조정위원회 (www.1336.or.kr/1336)
   2.대검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr/02-3480-3600)
   3.경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330) 
       `,
    회원탈퇴안내: `회원탈퇴 즉시 파츠얼리언스는 서비스 제공을 중지하며 회원탈퇴 요청인의 정보를 삭제합니다.
   삭제된 정보는 복원되지 않으니 사전 확인 바랍니다.
   회원탈퇴 후 기존 사업자정보 및 기존 계정정보로 재가입을 원하시면 파츠얼리언스의 사전 동의를 거쳐야합니다.
   파츠얼리언스의 사전동의 없이 기존 탈퇴정보와 중복되는 재가입을 진행하여 거래활동 재개 시 해당계정은 사용정지되며 모든 불이익과 책임은 가입자가 부담하셔야 합니다.
   삭제되는 정보: 개인정보(이름,주소,연락처,휴대폰번호,메일)`,
    개인정보보관안내: `회원의 정보가 보존필요가 있다고 판단될 시 전자상거래 등에서의
    소비자보호에 관한 법률에 의거하여
    표시,광고에 관한 기록: 6개월
    대금결제 및 재화 등에 관한 기록: 5년
    소비자의 불만 또는 분쟁처리에 관한기록 3년 까지 보존합니다.`,
    회원탈퇴동의약관: `1. 회원탈퇴 후 파츠얼리언스에서 제공하는 일체 서비스가 중단됩니다.
   2. <회원탈퇴 후 잔액 포인트는 사용할수 없음>에 서약(동의)합니다.
   3. 사전 파츠얼리언스에 환불처리요청 없이 회원탈퇴 후 포인트 환불처리가 불가함을 인지하였으며 서약(동의) 합니다.
   4. 본인 손오순(이하 '동의자')은 상기 내용을 확인하였으며 회원탈퇴 후 발생되는 불이익 및 손실은 동의자가 일방적으로 부담하며 이로 인해 발생되는 불이익과 손실책임에 대해 어떤 이의도 제기하지 않음을 서약(동의)합니다.`,
    대표인사말: `파츠얼리언스는 주로 수입 및 유통 판매를 전문으로 하는 기업으로, 높은 품질의 자동차 부품 및 액세서리를 다양한 시장에 공급하고 있습니다.
   또한 자동차 유지 보수 전문가분들과 정비소 엔지니어분들에게 최상의 제품과 서비스를 제공 합니다.
   파츠얼리언스는 광범위한 제품 라인업과 전문 지식을 보유하고 있는 믿을 수 있는 비즈니스 파트너이며, 고객님과의 소통 효율 생산성 및 성공과 만족을 최우선으로 합니다.
   빠르게 변화하는 시장경제의 리듬에 발맞추어 신속하고 정확한 서비스를 제공할것을 재차 약속드리며 항상 고객님들에게 힘이 되고 도움이 되기위해 노력에 노력을 다하겠습니다.`,
    pilicyContent(text) {
        let tmpvalue = text.replace(/\n/g, "<br>")
        tmpvalue = tmpvalue.replace(/xxxcompanyname/g, this.$회사이름한글)
        tmpvalue = tmpvalue.replace(/xxxmalldomain/g, this.$도메인)
        tmpvalue = tmpvalue.replace(/xxxcompanytel/g, this.$회사전화)
        tmpvalue = tmpvalue.replace(/xxxcompanymail/g, this.$회사메일)
        tmpvalue = tmpvalue.replace(/xxx개인정보보호책임자/g, this.$개인정보보호책임자)
        tmpvalue = tmpvalue.replace(/xxx사이트사용문의/g, this.$사이트사용문의)
        tmpvalue = tmpvalue.replace(/xxx개인정보보호책임자이메일/g, this.$개인정보보호책임자이메일)
        return tmpvalue
    },
    convert_br(content) {
        if (!content) return "";
        content = content.replace(/<Br \\>/g, "\n"); // <Br \\> 태그 제거
        content = content.replace(/<br\s*\/?>/gi, "\n"); content = content.replace(/<br\s*\/?>/gi, "\n"); // <br> 또는 <br/> 태그를 줄바꿈 문자로 변경
        content = content.replace(/===/g, ""); // === 문자열 제거
        content = content.replace(/I=I/g, ""); // I=I 문자열 제거
        return content;
    },
    getlevel(value) {
        const level = this.$levels.find(item => item.value == value)
        return this.$t(level.label)
    },
    transToInput(args, content) {
        for (let i = 0; i < args.length; i++) {
            content = content.replace('xx', args[i]);
        }
        return content
    },

    onlyEngNum(params) {
        if (!params || params.trim() == '') { return params }
        return params.replace(/[^a-zA-Z0-9]/g, '');
    },
    onlyEngNumFuhao(params) {
        if (!params || params.trim() == '') { return params }
        return params.replace(/[^a-zA-Z0-9\-\/]/g, '');
    },

    afterMenuClick(type, selectionRangeKeys, selectionRangeIndexes, tmpTableData) {
        // console.log("type::", type);
        // console.log("selectionRangeKeys::", selectionRangeKeys);
        // console.log("selectionRangeIndexes::", selectionRangeIndexes);
        const selectRow = tmpTableData[selectionRangeIndexes.endRowIndex]
        const tmpTypeName = this.$productType.find(typeItems => typeItems.value == selectRow.type).label
        // 임시용기추가
        const textarea = document.createElement('textarea')
        textarea.style.position = 'fixed'
        textarea.style.left = '-9999px'
        switch (type) {
            case 'COPY-INFO': {
                const returnData = `${selectRow.customerSale}
${selectRow.partsNum}
${selectRow.model}
${selectRow.partsName} ${selectRow.pid}
${this.$comma(selectRow.priceD)}`
                textarea.value = returnData
                document.body.appendChild(textarea)
                textarea.select();
                document.execCommand('copy')
                document.body.removeChild(textarea)
                break
            }
            case 'COPY-ORDER': {
                const returnData = `${selectRow.customerSale}
${selectRow.partsNum}
${selectRow.model}
${selectRow.partsName} ${selectRow.pid}
${this.$t(tmpTypeName)} ${this.$comma(selectRow.priceD + selectRow.priceD * 0.1)} 재고`
                textarea.value = returnData
                document.body.appendChild(textarea)
                textarea.select();
                document.execCommand('copy')
                document.body.removeChild(textarea)
                break
            }
        }
    },
    contextmenus: [
        { type: 'COPY-INFO', label: '정보복사', },
        { type: "SEPARATOR", },
        { type: 'COPY-ORDER', label: '견적복사', },
    ],

    checkAuth(permis) {
        const userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
        if (userlogininfo?.authority[permis] == 0 ||
            userlogininfo?.authority['accounPermi' == 0]
        ) {
            return true
        } else { return false }
    },

    nulltom(params) {
        return params ? params : '-'
    },

    // 날자구간설정
    dateArea(month) {
        const today = new Date()
        const lastMonth = new Date(today)
        lastMonth.setMonth(today.getMonth() - month)
        const startDate = lastMonth
        return { sDate: startDate, eDate: new Date() }
    },
}

export default {
    install(Vue) {
        Vue.prototype.$YYYYMMDD_HHMMSS = methods.YYYYMMDD_HHMMSS
        Vue.prototype.$dateArea = methods.dateArea
        Vue.prototype.$springurl = methods.springurl
        Vue.prototype.$amountSplitTax = methods.amountSplitTax
        Vue.prototype.$nulltom = methods.nulltom
        Vue.prototype.$checkAuth = methods.checkAuth
        Vue.prototype.$contextmenus = methods.contextmenus
        Vue.prototype.$afterMenuClick = methods.afterMenuClick
        Vue.prototype.$onlyEngNumFuhao = methods.onlyEngNumFuhao
        Vue.prototype.$onlyEngNum = methods.onlyEngNum
        Vue.prototype.$shippingtype = methods.shippingtype
        Vue.prototype.$mallserverurl = methods.mallserverurl
        Vue.prototype.$YYYYMMDD_HHMM = methods.YYYYMMDD_HHMM
        Vue.prototype.$accountBookType = methods.accountBookType
        Vue.prototype.$transToInput = methods.transToInput
        Vue.prototype.$removeSpace = methods.removeSpace
        Vue.prototype.$getlevel = methods.getlevel
        Vue.prototype.$convert_br = methods.convert_br
        Vue.prototype.$개인정보보호책임자이메일 = methods.개인정보보호책임자이메일
        Vue.prototype.$개인정보보호책임자 = methods.개인정보보호책임자
        Vue.prototype.$policySerivce = methods.policySerivce
        Vue.prototype.$privacyPolist = methods.privacyPolist
        Vue.prototype.$pilicyContent = methods.pilicyContent
        Vue.prototype.$filterDate = methods.filterDate
        Vue.prototype.$noticeCategory = methods.noticeCategory
        Vue.prototype.$noticeTypeList = methods.noticeTypeList
        Vue.prototype.$companyLevel = methods.companyLevel
        Vue.prototype.$salePrdPermis = methods.salePrdPermis
        Vue.prototype.$getProdType = methods.getProdType
        Vue.prototype.$language = methods.language
        Vue.prototype.$accountpermi = methods.accountpermi
        Vue.prototype.$levels = methods.levels
        Vue.prototype.$reasonType = methods.reasonType
        Vue.prototype.$returnSubType = methods.returnSubType
        Vue.prototype.$returnGoodsType = methods.returnGoodsType
        Vue.prototype.$deliType = methods.deliType
        Vue.prototype.$orderProcesssub = methods.orderProcesssub
        Vue.prototype.$orderProcess = methods.orderProcess
        Vue.prototype.$saleProcess = methods.saleProcess
        Vue.prototype.$printView = methods.printView
        Vue.prototype.$doPrint = methods.doPrint
        Vue.prototype.$formatLicense = methods.formatLicense
        Vue.prototype.$snPlusFor = methods.snPlusFor
        Vue.prototype.$formatTel = methods.formatTel
        Vue.prototype.$changeRoute = methods.changeRoute
        Vue.prototype.$trimLR = methods.trimLR
        Vue.prototype.$overay = methods.overay
        Vue.prototype.$numberFormat = methods.numberFormat
        Vue.prototype.$setHeader = methods.setHeader
        Vue.prototype.$customerBizType = methods.customerBizType
        Vue.prototype.$comma = methods.comma
        Vue.prototype.$totalInTax = methods.totalInTax
        Vue.prototype.$commaReplace = methods.commaReplace
        Vue.prototype.$delComma = methods.delComma
        Vue.prototype.$listMoreBaseItems = methods.listMoreBaseItems
        Vue.prototype.$subreduce = methods.subreduce
        Vue.prototype.$vatType = methods.vatType
        Vue.prototype.$pageWidth = methods.pageWidth
        Vue.prototype.$siteTitle = methods.siteTitle
        Vue.prototype.$isMobile = methods.isMobile
        Vue.prototype.$productType = methods.productType
        Vue.prototype.$imageurl = methods.imageurl
        Vue.prototype.$serverurl = methods.serverurl
        Vue.prototype.$운영시간 = methods.운영시간
        Vue.prototype.$회사우편번호 = methods.회사우편번호
        Vue.prototype.$회사주소 = methods.회사주소
        Vue.prototype.$팩스 = methods.팩스
        Vue.prototype.$통신판매업신고번호 = methods.통신판매업신고번호
        Vue.prototype.$대표자 = methods.대표자
        Vue.prototype.$사업자등록번호 = methods.사업자등록번호
        Vue.prototype.$회사메일 = methods.회사메일
        Vue.prototype.$YYYYMMDD = methods.YYYYMMDD
        Vue.prototype.$dateToYYYYMMDD = methods.dateToYYYYMMDD
        Vue.prototype.$개인정보보호책임자 = methods.개인정보보호책임자
        Vue.prototype.$도메인 = methods.도메인
        Vue.prototype.$회사전화 = methods.회사전화
        Vue.prototype.$사이트사용문의 = methods.사이트사용문의
        Vue.prototype.$policiesService = methods.policiesService
        Vue.prototype.$privacyPolist = methods.privacyPolist
        Vue.prototype.$회사이름한글 = methods.회사이름한글
        Vue.prototype.$createSecuryNumber = methods.createSecuryNumber
        Vue.prototype.$comma = methods.comma
        // notifyVue
        Vue.prototype.$notifyVue = methods.notifyVue
        Vue.prototype.$toast_msg = methods.toast_msg

        // modal
        Vue.prototype.$addTodo = methods.addTodo

        // ad-grid
        Vue.prototype.$defaultColDef = methods.defaultColDef
        Vue.prototype.$onBtExport = methods.onBtExport
        Vue.prototype.$autoSizeAll = methods.autoSizeAll
        Vue.prototype.$onPageSizeChanged = methods.onPageSizeChanged
        Vue.prototype.$onFilterTextBoxChanged = methods.onFilterTextBoxChanged
        Vue.prototype.$formatNumber = methods.formatNumber
        Vue.prototype.$formatNumberEx = methods.formatNumberEx
        Vue.prototype.$numberValueSetter = methods.numberValueSetter

        // etc
        // Vue.prototype.$getOptionDays = methods.getOptionDays
    }
}