<template>
    <div class="row border-bottom-dark border-top-dark fontweight500 h30">
        
        <div class="col ellip border-right-dark border-left-dark" style="min-width:350px !important; max-width:350px;">
          부품이름
        </div>
        <div class="col ellip border-right-dark" style="max-width:150px;">
          정품품번
        </div>
        <div class="col ellip border-right-dark" style="max-width:150px;">
          공장품번
        </div>
        <div class="col ellip border-right-dark" style="max-width:80px;">
          수량
        </div>
        <div class="col border-right-dark">
          메모
        </div>
    </div>
</template>
<script>
// get 파라미터 얻기
export default {
    name: 'inven_area_print_head',
    components: {

    },
}
</script>