<template>
    <div style="overflow: hidden;" class="fontsize16all">
        <div class="align-items-center w100p">
            <!-- 메뉴 -->
            <div style="background-color: #252528;color:#fff;width:100%;padding:5px;">
                <div class="row" style="height:35px">
                    <!-- 새로고침 -->
                    <!-- <div class="col-1 v-middle" style="padding-left: 25px;" @click="reLoad()">
                        <span class="fa fa-solid fa-arrows-rotate"></span>
                    </div> -->
                    <div class="col text-bold text-center v-middle">
                        Parts Alliance upload
                    </div>
                    <!-- 로그아웃 -->
                    <div class="col-1 text-right v-middle" style="padding-right:40px;" @click="logout()">
                        <span class="fa fa-solid fa-right-from-bracket"></span>
                    </div>
                </div>
            </div>
            <!-- 리스트 -->
            <div class="container mt-3">
                <div class="row">
                    <div class="col">
                        <input type="text" id="filter-text-box-product" :placeholder="''"
                            class="form-control form-controlx form-control-smx me-3" autocomplete="off"
                            v-model="request.objSearchMapper.searchKeyword" @keyup.enter="read()"
                            style="padding:20px !important;font-size: 16px !important;height:35px;" />
                    </div>
                    <div class="col-1" v-if="newPid">
                        <span class="btn btn-primary" @click="getEcountNoSend()">등록</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col" v-if="tmpTableData.length > 0">
                        <div class="mt-3" style="text-align: center;">
                            <el-pagination background @current-change="handleCurrentChange"
                                :current-page.sync="request.page" layout="prev, pager, next" :total="totalCount"
                                :page-size="request.size">
                            </el-pagination>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 p-2" v-for="(item, index) in tmpTableData" :key="index">
                        <div class="product_box">
                            <div ref="Product_image relative">
                                <div class="text-center pt-3">
                                    <img class="product_image" :src="$imageurl + getFirstImage(item)" alt="">
                                </div>
                                <div class="row mt-2 pl-3" style="height:30px;">
                                    <div class="col mr-2" style="max-width: 30px;"
                                        v-for="(img, subindex) in getImageList(item)" :key="subindex">
                                        <img class="product_sub_image" :src="$imageurl + img" alt="">
                                    </div>
                                </div>
                                <div class="absolute badge-info badge-small"
                                    style="top:8px; right:8px;border-radius: 3px;opacity: 0.5;">
                                    {{ item.pid }}
                                </div>
                                <div class="absolute badge-red badge-small"
                                    style="top:38px; right:8px;border-radius: 3px;opacity: 0.5;">
                                    {{ salePermis[item.saleStatus].label }}
                                </div>
                                <div class="absolute badge-secondary badge-small" v-if="item.stituteImage"
                                    style="top:68px;right:8px;border-radius: 3px;opacity: 0.5;">
                                    연동이미지
                                </div>
                                <div class="absolute badge-black badge-small" v-if="!item.ecountListExInfo"
                                    style="top:98px;right:8px;border-radius: 3px;opacity: 0.5;">
                                    BAD-ECOUNT-ID: {{ item.ecountId }}
                                </div>
                            </div>
                            <div class="p-3 text-bold">
                                {{ item.name }}
                            </div>
                            <div class="pl-3 pr-3 pb-3 text-bold">
                                <!--  -->
                                <span class="btn-upload w100p">
                                    <label :for="'imgPath' + item.seq" class="btn btn-primary w100p"
                                        style="border-radius: 2px !important;color:#fff !important;">
                                        <i class={this.imgIconClass}></i>
                                        {{ uploadButtonTitle(item) }}</label>
                                    <input type="file" :name="'imgPath' + item.seq" :id="'imgPath' + item.seq"
                                        multiple="multiple" accept="image/*" :class="'ex_file' + item.seq"
                                        :file-list="fileList" @change="imageupload(item)">
                                </span>
                                <!--  -->
                            </div>
                            <div>

                            </div>
                        </div>


                    </div>
                </div>
                <div class="row">
                    <div class="col" v-if="tmpTableData.length > 0">
                        <div class="mt-3" style="text-align: center;">
                            <el-pagination background @current-change="handleCurrentChange"
                                :current-page.sync="request.page" layout="prev, pager, next" :total="totalCount"
                                :page-size="request.size">
                            </el-pagination>
                        </div>
                    </div>
                    <div class="col" v-if="tmpEcountList">
                        <div class="mb-3 mt-3">
                            전산에 등록되지않은 품목입니다.
                        </div>
                        <div class="mb-3 text-right">
                            <span class="mr-3" style="font-size: 10px !important;">
                                연동및 상품요청으로 등록됩니다.
                            </span>
                            <span style="
                                        background-color: #252528;
                                        color:#fff;
                                        font-size: 13px !important;
                                        padding:5px 10px 5px 10px;" @click="upTOecountANDproduct()">등록하기</span>
                        </div>
                        <div class="row row-cols-1" style="border-top:1px #ababac solid;">
                            <div class="col">
                                <div class="row" style="border-bottom:1px #ababac solid; padding:3px 0 3px 0 ;"
                                    v-for="(value, key) in tmpEcountList" :key="key">
                                    <div class="col w150" style="font-size: 12px !important;">{{ key }}</div>
                                    <div class="col" style="width:130px;font-size: 13px !important;">{{ value }}</div>
                                </div>
                                <div class="mb-3 mt-3"
                                    style="font-size: 12px !important;padding-top:5px; text-align: right;">
                                    <span class="mr-3" style="font-size: 10px !important;">
                                        연동및 상품요청으로 등록됩니다.
                                    </span>
                                    <span style="
                                        background-color: #252528;
                                        color:#fff;
                                        font-size: 13px !important;
                                        padding:5px 10px 5px 10px;" @click="upTOecountANDproduct()">등록하기</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col" v-if="tmpDBecountList">
                        <div class="mb-3">상품에 등록되지 않았습니다.</div>
                        <div class="mb-3 text-right">
                            <span class="mr-3" style="font-size: 10px !important;">
                                상품요청으로 등록됩니다.
                            </span>
                            <span style="
                                        background-color: #252528;
                                        color:#fff;
                                        font-size: 13px !important;
                                        padding:0 5px 0 5px;" @click="upTOSalesProduct()">상품등록하기</span>
                        </div>
                        <div class="row" style="border-bottom:1px #ababac solid; padding:3px 0 3px 0 ;"
                            v-for="(value, key) in tmpDBecountList" :key="key">
                            <div class="col w150" style="font-size: 12px !important;">{{ key }}</div>
                            <div class="col" style="width:130px;font-size: 13px !important;">{{ value }}</div>
                        </div>
                        <div class="mb-3 mt-3" style="font-size: 12px !important;padding-top:5px; text-align: right;">
                            <span class="mr-3" style="font-size: 10px !important;">
                                상품요청으로 등록됩니다.
                            </span>
                            <span style="
                                        background-color: #252528;
                                        color:#fff;
                                        font-size: 13px !important;
                                        padding:0 5px 0 5px;" @click="upTOSalesProduct()">상품등록하기</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- LODING -->
        <div v-if="loading"
            style="position: fixed;top:0px;left:0px;z-index: 99;width:100vw; height: 100vh; background-color:rgba(0,0,5,0.5);"
            class="v-middle">
            <div style="color:#fff; text-align: center;">
                Loding...
            </div>
        </div>

    </div>
</template>

<script>
import { extend } from "vee-validate";
import { required, min, max, alpha_num } from "vee-validate/dist/rules";

extend("required", required);
extend("alpha_num", alpha_num);
extend("min", min);
extend("max", max);

export default {
    components: {},
    data() {
        return {
            totalCount: 0,
            loading: false,
            fileList: [],
            tmpTableData: [],
            tmpEcountList: [],
            tmpDBecountList: {},
            loginData: null,
            request: {
                objSearchMapper: {
                    seq: null,
                    searchKeyword: null,
                    saleStatus: null,
                },
                page: 1,
                size: 20,
            },
            salePermis: [
                { label: "미처리", value: 0 },
                { label: "승인요청", value: 1 },
                { label: "승인허가", value: 2 },
                { label: "요청취소", value: 3 },
                { label: "승인취소", value: 4 },
                { label: "판매취소", value: 5 },
            ],
            newPid: false,
        };
    },
    methods: {
        reLoad() {
            this.loading = true
            this.request.objSearchMapper.searchKeyword ? this.request.objSearchMapper.searchKeyword = this.request.objSearchMapper.searchKeyword.trim() : false
            this.$http.post(this.$imageurl + "/api/getSalesProductInfoNoimage", this.request, this.$setHeader())
                .then(res => {
                    if(res.data.code==200){
                        this.totalCount = res.data.body.total
                    this.tmpTableData = res.data.body.results;
                    this.tmpEcountList = res.data.body.ecountList;
                    this.tmpDBecountList = res.data.body.DBecountList;
                    this.loading = false
                    this.newPid = this.tmpTableData.length == 0 ? true : false

                    }else{
                        this.totalCount = 0
                        this.tmpTableData = []
                    }
                })
                .catch(error => {
                    this.loading = false
                    if (error.response != undefined)
                        this.$notifyVue("danger", error.response.data.msg)
                    else
                        this.tmpTableData = []
                    this.$notifyVue("info", '정보가 잘못되었습니다.')

                })
        },
        logout() {
            this.$router.push("/loginPhoto");
        },
        getFirstImage(item) {
            if (item?.images?.length > 0) {
                return item.images[0]
            } else {
                return '/upload/no-image.webp'
            }
        },
        getImageList(item) {
            if (item?.images?.length > 0) {
                return item.images
            } else {
                return []
            }
        },
        uploadButtonTitle(item) {
            if (item.images != null) {
                return '다시업로드'
            } else {
                return '업로드하기'
            }
        },
        // --------------------------------------------------
        // -- action
        // --------------------------------------------------
        async upTOSalesProduct() {
            // const URL =  "http://127.0.0.1:1976/api/upTOSalesProduct"
            const URL = this.$imageurl + "/api/upTOSalesProduct"

            this.$http.post(URL, this.tmpDBecountList, this.$setHeader())
                .then(res => {
                    // this.request.objSearchMapper.searchKeyword = ''
                    alert(res.data.message)
                    this.loading = false
                })
                .catch(error => {
                    alert(error)
                })
        },
        async upTOecountANDproduct() {
            // const URL =  "http://127.0.0.1:1976/api/upTOecountANDproduct"
            const URL = this.$imageurl + "/api/upTOecountANDproduct"

            this.$http.post(URL, this.tmpEcountList, this.$setHeader())
                .then(res => {
                    // this.request.objSearchMapper.searchKeyword = ''
                    alert(res.data.message)
                    this.loading = false
                })
                .catch(error => {
                    alert(error)
                })
        },
        async getEcountNoSend() {
            this.loading = true
            const data = { pid: this.request.objSearchMapper.searchKeyword }
            this.$http.post(this.$imageurl + "/api/setEcountNoSend", data, this.$setHeader())
                .then(res => {
                    this.request.objSearchMapper.searchKeyword = ''
                    alert(res.data.message)
                    this.loading = false
                })
                .catch(error => {
                    alert(error)
                })
            this.newPid = false
            this.loading = false
        },
        async imageupload(params) {
            this.loading = true
            var fileInput = window.document.getElementsByClassName("ex_file" + params.seq)
            //업로드부분
            let tmp_formData = new FormData();
            for (let i = 0; i < fileInput[0].files.length; i++) {
                tmp_formData.append("images", fileInput[0].files[i]);
            }
            this.$http.defaults.headers.common['JwtAuthorization'] = JSON.parse(this.loginData).mainToken
            tmp_formData.append('seq', params.seq);
            this.$http.defaults.headers.common['productId'] = params.seq;
            await this.$http.post(this.$imageurl + "/api/uploadSalesProductByImage", tmp_formData, this.$setHeader("mult"))
                .then(res => {
                    if (res.status == 201) {
                        setTimeout(() => {
                            this.getSalesProductInfo()
                            this.loading = false
                        }, 300);
                    }
                })
        },

        handleCurrentChange(val) { // 검색하는페이지 변수
            this.request.page = val
            this.getSalesProductInfo()
        },

        read(){
            this.request.page=1
            this.getSalesProductInfo()
        },

        getSalesProductInfo() {
            this.loading = true
            this.request.objSearchMapper.searchKeyword ? this.request.objSearchMapper.searchKeyword = this.request.objSearchMapper.searchKeyword.trim() : false
            this.$http.post(this.$imageurl + "/api/getSalesProductInfo", this.request, this.$setHeader())
                .then(res => {
                   if(res.data.code==200){
                    this.totalCount = res.data.body.total
                    this.tmpTableData = res.data.body.results;
                    this.tmpEcountList = res.data.body.ecountList;
                    this.tmpDBecountList = res.data.body.DBecountList;
                    this.loading = false
                    this.newPid = this.tmpTableData.length == 0 ? true : false
                   }else{
                    this.totalCount = 0
                    this.tmpTableData = []
                    this.tmpEcountList = []
                    this.tmpDBecountList = []
                    this.loading = false
                    this.newPid = false
                   }

                })
                .catch(error => {
                    console.log(error)
                    this.loading = false
                    if (error.response != undefined)
                        this.$notifyVue("danger", error.response.data.msg)
                    else
                        this.tmpTableData = []
                    this.$notifyVue("info", '정보가 잘못되었습니다.')

                })

        }

    },
    created() {
        this.loginData = localStorage.getItem('userlogininfo')
        if (!this.loginData) {
            this.$router.push("/loginPhoto");
        }
        this.getSalesProductInfo()
    },
    mounted() {

    }
}
</script>

<style scoped>
.product_image {
    border-radius: 2px;
    width: 160px;
    height: 160px;
}

.product_sub_image {
    border: 1px #e8e8e8 solid;
    border-radius: 2px;
    width: 30px;
    height: 30px;
}

.product_box {
    border-radius: 2px;
    border: 1px #e8e8e8 solid;
}

.btn-upload input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
}
</style>