<template>
  <div class="container noselect" :style="pageWidth">
    <tab :data="tabStep"/>
  </div>
</template>

<script>
import tab from './component-Joinus-tab.vue'
export default {
  components: {
    tab
  },
  data() {
    return {
      pageWidth: 'width:600px',
      tabStep: 1
    }
  },
  created(){
    const ddd= localStorage.getItem('userlogininfo')
  },
  methods: {
  }
}
</script>

<style></style>
