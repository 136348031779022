<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%;margin-bottom:20px;">
      <el-row :gutter="10">
        <el-col :md="12">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="searchKeyword" style="max-width: 200px;" class="mr-2"
            @keyup.enter.native="searchRead(searchKeyword)">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="searchRead(searchKeyword)"></i>
          </el-input>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="searchRead(searchKeyword)">
            {{ $t('SEARCH') }}</el-button>
          <el-button type="primary" icon="el-icon-tickets" size="mini" v-if="searchKeyword" @click="searchRead(null)">{{
            $t('VIEWALL') }}</el-button>
        </el-col>
        <el-col :md="12" class="right">
          <el-button type="primary" :icon="request.objSearchMapper.state ? 'el-icon-success' : 'el-icon-delete'"
            size="mini" @click="searchStateRead(request.objSearchMapper.state ? 0 : 1)">
            {{ request.objSearchMapper.state ? $t('AI') : $t('DI') }}</el-button>
  
  
          <!-- <el-button type="primary" icon="el-icon-tickets" size="mini" @click="resetProduct(0)"
                      title="판매승인이 되었지만 상품정보가 등록되지않은 서오재고처리">
                      {{ 'A' }}</el-button>
                    <el-button type="primary" icon="el-icon-tickets" size="mini" @click="resetProduct(1)" title="상품정보가 4개이하일때">
                      {{ 'B' }}</el-button>
                    <el-button type="primary" icon="el-icon-tickets" size="mini" @click="resetProduct(2)"
                      title="상품이 등록되었지만 서오재고에서 상태가 0일때">
                      {{ 'C' }}</el-button> 
                      <el-button type="primary" icon="el-icon-tickets" size="mini" @click="resetProduct(3)"
                      title="기초항목에 서오코드가 등록되어있지않는것들 교정">
                      {{ 'D' }}</el-button>
                      <el-button type="primary" icon="el-icon-tickets" size="mini" @click="resetProduct(4)"
                      title="천천히 올리는거">
                      {{ 'E' }}</el-button>
                      <el-button type="primary" icon="el-icon-tickets" size="mini" @click="resetProduct(2)"
                      title="상품이 등록되었지만 서오재고에서 상태가 0일때">
                      {{ 'C' }}</el-button>  -->
  
        </el-col>
      </el-row>
    </card>
    <div class="relative">
      <div class="right">
        <div :calss="sortClass(0)" @click="sortRead(0)"
          class="middlebadge-blue fa fa-solid fa-arrow-up-a-z fontsize14 hand" style="width:20px"></div>
        <div :calss="sortClass(1)" @click="sortRead(1)"
          class="ml-1 middlebadge-blue fa fa-solid fa-arrow-down-z-a fontsize14 hand" style="width:20px"></div>
        <div :calss="sortClass(2)" @click="sortRead(2)" class="ml-1 middlebadge-blue fa fa-arrow-down-9-1 fontsize14 hand"
          style="width:20px"></div>
        <div :calss="sortClass(3)" @click="sortRead(3)" class="ml-1 middlebadge-blue fa fa-arrow-down-1-9 fontsize14 hand"
          style="width:20px"></div>
      </div>
      <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13 mt-2">
        <el-table-column :label="$t('SELECT')" width="50" :align="'center'">
          <template slot="header" slot-scope="scope">
            <span class=" hand font15" :class="!checkedIds.length ?
                        'far fa-square-check' : 'fas fa-square-check colorbluedeep'
                        " @click="checkAll()"></span>
          </template>
          <template slot-scope="scope">
            <input type="checkbox" :checked="checkedIds.find(item => item.seq == scope.row.seq)"
              @change="pushId(scope.row, $event.target)" :disabled="!scope.row.balQtyEx">
          </template>
        </el-table-column>
        <el-table-column label="ID" width="70" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.seq }}
          </template>
        </el-table-column>
        <el-table-column label="PHOTO" width="70" :align="'center'">
          <template slot-scope="scope">
            <img :src="scope.row.images[0]" style="height: 30px;" @click="showImg(scope.row)">
          </template>
        </el-table-column>
        <el-table-column :label="$t('ITEM')" style="max-width: 300px;" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip" :class="!scope.row.status ? 'fontline-through' : ''"> {{
              scope.row.item_name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('PID')" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip"> {{ scope.row.pid }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('FACTORYNUM')" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip"> {{ scope.row.item_code }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TYPE')" width="70" :align="'center'">
          <template slot-scope="scope">
            {{ $productType.find(item => item.value == scope.row.type).title }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('IV.QTY')" :align="'center'">
          <el-table-column :label="'본사'" width="55" :align="'right'">
            <template slot-scope="scope">
              {{ $comma(scope.row.item_stock_1) }}
            </template>
          </el-table-column>
          <el-table-column :label="'서울'" width="55" :align="'right'">
            <template slot-scope="scope">
              {{ $comma(scope.row.item_stock_2) }}
            </template>
          </el-table-column>
          <el-table-column :label="'부산'" width="55" :align="'right'">
            <template slot-scope="scope">
              {{ $comma(scope.row.item_stock_4) }}
            </template>
          </el-table-column>
          <el-table-column :label="'광주'" width="55" :align="'right'">
            <template slot-scope="scope">
              {{ $comma(scope.row.item_stock_6) }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('AMOUNT')" :align="'center'">
          <el-table-column :label="$t('PREVPRICE')" width="80" :align="'right'">
            <template slot-scope="scope">
              {{ $comma(scope.row.originPrice) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('CURRENTPRICE')" width="130" :align="'right'">
            <template slot-scope="scope">
              {{ $comma(scope.row.item_price) }}
              <span :class="scope.row.item_price - scope.row.originPrice  > 0 ? 'colorred' : 'colorGreen'">
                ({{ scope.row.originPrice>0? $comma(scope.row.item_price - scope.row.originPrice):0 }})
              </span>
            </template>
          </el-table-column>
        </el-table-column>
        <!-- <el-table-column :label="$t('AMOUNT')" width="80" show-overflow-tooltip :align="'right'">
                                  <template slot-scope="scope">
                                    <span class="ellip"> {{ $comma(scope.row.item_price) }}</span>
                                  </template>
                                </el-table-column> -->
        <el-table-column :label="$t('PRICE')" :align="'center'">
          <!-- <el-table-column :label="'A'" style="max-width: 300px;" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                          <input class="inputbox center" :placeholder="$t('AMOUNT') + 'A'" maxlength="20" v-model="scope.row.priceA"
                                          autocomplete="off" @focus="() => { scope.row.priceA = $numberFormat(scope.row, 'priceA', 'focus') }"
                                          @input="() => { scope.row.priceA = $numberFormat(scope.row, 'priceA', 'input') }"
                                          @blur="scope.row.priceA = $numberFormat(scope.row, 'priceA', 'blur');"
                                          @change="setchangeClass($event);setProductPriceNOread(scope.row,$event)"
                                          @keyup.enter="setProductPriceNOread(scope.row,$event)" />
                                        </template>
                                      </el-table-column> -->
          <!-- <el-table-column :label="'B'" style="max-width: 300px;" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                          <input class="inputbox center" :placeholder="$t('AMOUNT') + 'B'" maxlength="20" v-model="scope.row.priceB"
                                          autocomplete="off" @focus="() => { scope.row.priceB = $numberFormat(scope.row, 'priceB', 'focus') }"
                                          @input="() => { scope.row.priceB = $numberFormat(scope.row, 'priceB', 'input') }"
                                          @blur="scope.row.priceB = $numberFormat(scope.row, 'priceB', 'blur');"
                                          @change="setchangeClass($event);setProductPriceNOread(scope.row,$event)"
                                          @keyup.enter="setProductPriceNOread(scope.row,$event)" />
                                        </template>
                                      </el-table-column> -->
  
          <el-table-column :label="$t('WPRICE')" :align="'center'" width="80">
            <template slot-scope="scope">
              <input class="inputbox center" :placeholder="$t('AMOUNT') + 'C'" maxlength="10" v-model="scope.row.priceC"
                autocomplete="off" @focus="() => { scope.row.priceC = $numberFormat(scope.row, 'priceC', 'focus') }"
                @input="() => { scope.row.priceC = $numberFormat(scope.row, 'priceC', 'input') }"
                @blur="scope.row.priceC = $numberFormat(scope.row, 'priceC', 'blur');"
                @change="setchangeClass($event); setProductPriceNOread(scope.row, $event)"
                @keyup.enter="setProductPriceNOread(scope.row, $event)" />
            </template>
          </el-table-column>
          <el-table-column :label="$t('RPRICE')" :align="'center'" width="80">
            <template slot-scope="scope">
              <input class="inputbox center" :placeholder="$t('AMOUNT') + 'D'" maxlength="10" v-model="scope.row.priceD"
                autocomplete="off" @focus="() => { scope.row.priceD = $numberFormat(scope.row, 'priceD', 'focus') }"
                @input="() => { scope.row.priceD = $numberFormat(scope.row, 'priceD', 'input') }"
                @blur="scope.row.priceD = $numberFormat(scope.row, 'priceD', 'blur');"
                @change="setchangeClass($event); setProductPriceNOread(scope.row, $event)"
                @keyup.enter="setProductPriceNOread(scope.row, $event)" />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="로그" width="50" :align="'center'">
          <template slot-scope="scope">
            <span class="hand" @click="showLog(scope.row)">
              {{ scope.row.logs.length}}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="정지/삭제" width="50" :align="'center'">
          <template slot="header">
            <i :class="'fas fa-trash-alt'"></i>
          </template>
          <template slot-scope="scope">
            <i class="hand" :class="scope.row.state == 0 ? 'fas fa-trash-alt' : 'fas fa-trash'"
              @click="stateinsertUpdatebox(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt-2">
        <el-button size="mini" v-if="판매승인버튼유효성()" @click="setSeoToProductMulti(2)">
          판매승인
        </el-button>
        <el-button size="mini" v-if="판매취소버튼유효성()" @click="setSeoToProductMulti(5)">
          {{ $t('SC') }}
        </el-button>
      </div>
      <el-pagination v-if="tmpTableData.length" background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
        :page-size="request.size" layout="sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
    </div>
  
    <logview v-if="logsSelectItem.logs.length" :data="logsSelectItem" @callback="closeLog" />
    <!-- <json-viewer :value="tmpTableData" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
    <yesNoBox v-if="yesNoBoxvisible" @callback="deletecallback" :title="selectItem.prodDes" :data="yesNoBoxContent" />
    <mallmanagerimageview v-if="showimage" :data="selectItem" @callback="iamgeViewCallback" />
  
  </div>
</template>
<script>
import { Loading } from 'element-ui';
import logview from './component-seo-log-view.vue'
import yesNoBox from './component-alert-continue.vue'
import mallmanagerimageview from './component-mall-manager-image-view.vue'
export default {
  name: 'seolist',
  components: {
    yesNoBox, mallmanagerimageview, logview
  },
  props: ['append'],
  watch: {
    append: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.request.objSearchMapper.saleStatus = newValue
        this.request.page = 1
        this.read()
      }
    },
  },
  data() {
    return {
      selectItem: null,
      showimage: false,
      stockList: [
        { label: '본사창고', code: 1 },
        { label: '서울창고', code: 2 },
        { label: '부산창고', code: 4 },
        { label: '광주창고', code: 6 },
      ],
      pageSizes: [12, 50, 100],
      checkedIds: [],
      formData: {
        tradeDate: null,
        vat: 0,
        custoemrId: null,
      },
      yesNoBoxvisible: false,
      yesNoBoxContent: null,
      startDate: null,
      endDate: null,
      searchKeyword: null,
      request: {
        objSearchMapper: {
          sort: 0,
          balQtyExNe: 0,
          state: 0,
          saleStatus: null,
          searchKeyword: null,
          // startDate: null,
          // endDate: null,
          ecountListExFilter: false,

        },
        page: 1,
        size: 12
      },
      // tables
      tmpTableData: [],
      totalCount: null,
      tmpAppendCount: [],
      logsSelectItem: { logs: [] },
    }
  },

  created() {
    // this.read()
  },
  mounted() {
  },
  methods: {
    showLog(item) {
      if (!item.logs.length) {
        return
      }
      this.logsSelectItem = item
    },
    closeLog() {
      this.logsSelectItem = { logs: [] }
    },
    setchangeClass(event) {
      event.target.className = 'inputboxchange right'
    },
    showImg(item) {
      this.selectItem = item
      this.showimage = true
    },
    iamgeViewCallback(data) {
      this.showimage = false
    },
    setProductPriceNOread(data, event) {
      if (data.priceC == 0 && data.priceD == 0) { return }
      this.selectItem = data
      this.selectItem.priceC = data.priceC
      this.selectItem.priceD = data.priceD
      this.setInfoNOread()
      event.target.className = 'inputbox right'
    },
    async setInfoNOread() {
      const res = await this.$http.post(`${this.$serverurl}/api/setSeoProductInfo`, this.selectItem, this.$setHeader())
      if (201 == res.data.code) {
        this.selectItem = res.data.body.results
        this.tmpTableData.map(item => {
          if (item.seq == this.selectItem.seq) {
            item = this.selectItem
          }
        })
      }
    },
    searchStateRead(params) {
      this.request.objSearchMapper.state = params
      this.request.page = 1
      this.read()
    },

    sortRead(params) {
      this.request.objSearchMapper.sort = params
      this.read()
    },
    searchRead(searchKeyword) {
      this.searchKeyword = searchKeyword
      this.request.objSearchMapper.searchKeyword = this.$trimLR(searchKeyword)
      this.read()
    },


    async read() {
      this.tmpTableData = []
      this.checkedIds = [] // 선택항목초기화
      if (this.request.objSearchMapper.saleStatus == 2) { this.request.objSearchMapper.balQtyExNe = '' } else {
        this.request.objSearchMapper.balQtyExNe = 0
      }
      this.loading = Loading.service({ fullscreen: true })
      this.request.objSearchMapper.searchKeyword = this.$trimLR(this.searchKeyword)
      if (this.startDate && this.endDate) {
        this.request.objSearchMapper.startDate = this.$dateToYYYYMMDD(this.startDate)
        this.request.objSearchMapper.endDate = this.$dateToYYYYMMDD(this.endDate)
      }
      await this.$http.post(`${this.$serverurl}/api/getSeoProductInfo`, this.request, this.$setHeader())
        .then(res => {
          this.tmpAppendCount = res?.data?.body?.count
          this.callback(this.tmpAppendCount, false)
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
          } else {
            this.tmpTableData = []
            // this.tmpAppendCount = res.data.body.appendCount
            this.totalCount = 0
          }
        })
      this.$overay(this.loading)
    },
    deletecallback(data) {
      this.yesNoBoxvisible = false
      if (data) {
        this.stateSeoProductListEx(this.selectItem)
      }
    },
    stateinsertUpdatebox(item) {
      this.selectItem = item
      this.yesNoBoxvisible = true
      if (item.state) {
        this.yesNoBoxContent = {
          content: this.$t('Would you like to restore the item?'),
          contentSub: this.$t('The information is being used normally.')
        }
      } else {
        this.yesNoBoxContent = {
          content: this.$t('Would you like to delete the item?'),
          contentSub: this.$t('Deleted items cannot be recovered.')
        }
      }
    },
    async resetProduct(params) {
      this.loading = Loading.service({ fullscreen: true })
      this.$http.post(`${this.$serverurl}/api/resetProduct`, { type: params }, this.$setHeader())
        .then(res => {
          alert(res.data.message)
          this.read();
        })
        .catch(error => this.$axiosCatch(error))
        .then(() => { this.chkDoubleSubmit = false; });
      this.$overay(this.loading)
    },
    async stateSeoProductListEx(item) {
      this.loading = Loading.service({ fullscreen: true })
      var params = {};
      params.seq = item.seq
      params.state = item.state
      this.$http.post(`${this.$serverurl}/api/stateSeoProductListEx`, params, this.$setHeader())
        .then(res => {
          alert(res.data.message)
          this.read();
        })
        .catch(error => this.$axiosCatch(error))
        .then(() => { this.chkDoubleSubmit = false; });
      this.$overay(this.loading)
    },

    async setSeoToProductMulti(status) {
      this.loading = Loading.service({ fullscreen: true })
      for (var item of this.checkedIds) {
        item.saleStatus = status
      }
      const url = `${this.$serverurl}/api/setSeoToProductInfo`
      this.$http.post(url, this.checkedIds, this.$setHeader()).then(res => {
        if (res.data.code == 201) {
          this.read()
          alert(res.data.message)
          this.read()
        } else {
          alert(res.data.message)
        }
      })
      this.$overay(this.loading)
    },


    callback(item, status) {
      this.$emit('callback', {
        item, status: status
      })
    },
    pushId(item, isChecked) {
      if ((!item.priceC || !item.priceD) && isChecked.checked) {
        isChecked.checked = false
        alert('단가C,단가D를 입력하세요.')
        return
      }
      let pattern = new RegExp(`(^|,)${item.seq}($|,)`);
      const exists = this.checkedIds.some(id => pattern.test(id.seq))
      if (isChecked.checked && !exists) {
        this.checkedIds.push(item)
      } else if (!isChecked.checked && exists) {
        const index = this.checkedIds.findIndex(id => pattern.test(id.seq))
        if (index !== -1) {
          this.checkedIds.splice(index, 1)
        }
      }
    },
    checkAll() {
      var check = true;
      this.tmpTableData.forEach(item => {
        if (!item.priceC || !item.priceD) {
          check = false;
          item.checked = false;  // 해당 기록의 체크박스를 해제
        }
      });

      if (this.checkedIds.length !== this.tmpTableData.length) {
        this.checkedIds = this.tmpTableData
          .filter(item => item.priceC && item.priceD && item.balQtyEx)
          .map(item => item)
      } else {
        this.checkedIds = [];
      }
    },

    checkAll_() {
      var check = true
      this.tmpTableData.map(item => {
        if (!item.priceC || !item.priceD) {
          check = false
          return
        }
      })
      if (!check) {
        alert('일부항목의 단가C,단가D를 설정하세요.')
        reutrn
      }
      if (this.checkedIds.length !== this.tmpTableData.length) {
        this.checkedIds = this.tmpTableData.filter(item => item.seq)
      } else {
        this.checkedIds = []
      }
    },
    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    },

    판매승인버튼유효성() {
      for (var tmpitems of this.checkedIds) {
        if (!tmpitems.balQtyEx) { return false }
      }
      return (
        this.request.objSearchMapper.saleStatus == 0 ||
        this.request.objSearchMapper.saleStatus == 5)
        && this.checkedIds.length > 0
    },

    요청취소버튼유효성() {
      console.log(this.request.objSearchMapper.saleStatus, this.checkedIds.length)
      return this.request.objSearchMapper.saleStatus == 1 && this.checkedIds.length > 0
    },
    판매취소버튼유효성() {
      return this.request.objSearchMapper.saleStatus == 2 && this.checkedIds.length > 0
    },

    sortClass(params) {
      if (this.request.objSearchMapper.sort == params) {
        return 'middlebadge-blue-select'
      } else {
        return 'middlebadge-blue'
      }
    }
  }
}
</script>
<style></style>
