<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'400px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable style="z-index: 99999999;">
    <card style="margin-bottom: 20px;">
      <el-row>
        <el-col :xl="6" :md="6" class="lh25">{{$t('COMPANYNAME')}}</el-col>
        <el-col :xl="18" :md="18">
          {{ tmpTableData.name }}
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="6" :md="6" class="lh25">{{$t('LEVE')}}</el-col>
        <el-col :xl="18" :md="18">
          <el-select size="mini"
                           placeholder="Single Select"
                           v-model="tmpTableData.level">
                  <el-option v-for="option in $companyLevel"
                             class="select-danger"
                             :value="option.level"
                             :label="option.label+$t('LEVE')"
                             :key="option.level">
                  </el-option>
                </el-select>
        </el-col>
      </el-row>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">{{ $t('CLOSE') }}</el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange" @click="setInfo">
          {{ $t('SUBMIT') }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'malllcompanysetlevel',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.tmpTableData = newValue

      }
    },
  },
  data() {
    return {
      tmpTableData: {},
      visible: true,
      title: '회사등급설정',
      total: 0,
      tmpMallConfigInfo :{},

    }
  },
  async created() {
  },
  methods: {
    async setInfo() {
      const res = await this.$http.post(`${this.$serverurl}/api/setMallCompanylevel`, this.tmpTableData,this.$setHeader())
      if (201 == res.data.code) {
        alert(res.data.message)
        this.setCallback(res.data.body.result)
      }
    },

    setCallback(item) {
      this.$emit('callback', { item: item, status: true })
    },
    notClose() { },
    handleClose(done) {
      this.$emit('callback', { item: null, status: false })
    },
    // daum 주소검색
    loadDaumPostcodeScript() {
      const script = document.createElement('script')
      script.src =
        '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'
      script.async = true
      script.onload = () => {
        this.initializeDaumPostcode()
      }
      document.head.appendChild(script)
    },
    initializeDaumPostcode() {
      const daum = null
      new (window).daum.Postcode({
        oncomplete: (data) => {
          this.formData.postNumMain = data.zonecode
          this.formData.addrMain = data.address
        }
      }).open()
    },
  }
}
</script>
<style></style>