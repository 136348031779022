<template>
  <el-dialog center :title="$t(title)" :visible.sync="visible" :width="'800px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable style="z-index: 99999999;" class="font13">
    <card style="margin-bottom:20px;">
      <table class="table-params w100p">
        <tbody>
          <tr>
            <th class="w100"> {{$t('DATE')}}</th>
            <td calss="w150"> {{ $YYYYMMDD(itemInfo.tradeDate, '-') }} </td>
            <th class="w100"> {{$t('TYPE')}}</th>
            <td> {{ $t($vatType[itemInfo.vat].label) }} </td>
          </tr>
          <tr>
            <th class="w100"> {{$t('CUSTOMER')}}</th>
            <td calss="w150"> {{ itemInfo.customerInfo.name }} </td>
            <th class="w100"> {{$t('STORAGE')}}</th>
            <td> {{ itemInfo.storageInfo.name }} </td>
          </tr>
          <tr>
            <th class="w100"> {{$t('MEMO')}}</th>
            <td colspan="4"> {{ itemInfo.memoA }} </td>
          </tr>
          <tr>
            <th class="w100">{{$t('AMOUNT')}}</th>
            <td calss="w150"> {{ $comma(supplyAmount(itemInfo).amount) }} </td>
            <th class="w100"> {{$t('VAT')}}</th>
            <td> {{ $comma(supplyAmount(itemInfo).vat) }}</td>
          </tr>
        </tbody>
      </table>
    </card>
    <card style="margin-bottom:20px;">
      <template>
        <el-table size="mini" :data="itemInfo.orderOutItemInfo" style="width: 100%" border class="font13" height="250">
          <el-table-column prop="storagePosition" :label="$t('IVL')" width="100">
          </el-table-column>
          <el-table-column :label="$t('PID')" width="100">
            <template slot-scope="scope">
              <span class="ellip">{{ scope.row.baseInfo.pid }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('FACTORYNUM')" width="100">
            <template slot-scope="scope">
              <span class="ellip">{{ scope.row.baseInfo.factoryNum }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('ITEM')">
            <template slot-scope="scope">
              <span class="ellip">{{ scope.row.baseInfo.name }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('TYPE')" width="80">
            <template slot-scope="scope">
              {{ $t($productType[scope.row.baseInfo.type].label) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('QTY')" width="70">
            <template slot-scope="scope">
              {{ $comma(scope.row.outQty) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('AMOUNT')" width="100">
            <template slot-scope="scope">
              {{ $comma(scope.row.outPrice) }}
            </template>
          </el-table-column>
        </el-table>
      </template>
    </card>
    <div class="text-right" style="max-width:800px;">
      <el-button v-if="0==itemInfo.optType" size="mini" type="primary" @click="handleUpdateClose">
        {{$t('MI')}}</el-button>
        <el-button v-else size="mini" type="primary" disabled>
        {{$t('MI')}}</el-button>
      <el-button size="mini" type="" @click="handleClose">
        {{$t('CLOSE')}}</el-button>
    </div>
    <div>
    </div>

  </el-dialog>

</template>
<script>
export default {
  name: 'orderoutcview',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.itemInfo = newValue

      }
    },
  },
  data() {
    return {
      visible: true,
      title: 'DL',
      total: 0,
      itemInfo: {},
      request: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 10
      },
    }
  },
  async created() {
  },
  methods: {
    notClose() { },
    handleUpdateClose() {
      const data = {
        item: this.itemInfo,
        status: true
      }
      this.$emit('callback', data)
    },
    handleClose() {
      const data = {
        status: false
      }
      this.$emit('callback', data)
    },
    subreduce(item, type) {
      switch (type) {
        case 'total': {
          return item.orderOutItemInfo.reduce((acc, items) =>
            acc + (items.outQty * items.outPrice + items.outQty * items.outPrice * 0.1), 0
          )
          // break
        }
        case 'vatonly': {
          return item.orderOutItemInfo.reduce((acc, items) =>
            acc + (items.outQty * items.outPrice * 0.1), 0
          )
          // break
        }
        default: {
          return item.orderOutItemInfo.reduce((acc, items) =>
            acc + (items.outQty * items.outPrice), 0
          )
          // break
        }
      }
    },


    supplyAmount(item) {
      let amount = item.orderOutItemInfo.reduce((acc, item) => {
        return acc + item.outQty * item.outPrice
      }, 0) || 0
      amount = item.vat == 0 ? Math.round(amount / 1.1) : amount
      let vat = item.vat == 0 ? amount * 0.1 : 0
      return { amount, vat }
    },

  }
}
</script>
<style></style>