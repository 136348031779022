<template>
  <div class="container mt-3 relative" :style="$pageWidth">
    <TopBar />
    <div class="mt-2">
        <setmyinfo @callback="callback" />
      </div>
  </div>
</template>
<script>
import setmyinfo from './component-set-my-info.vue'
import TopBar from './component-top.vue'
export default {
  components: {
    TopBar, setmyinfo

  },
  data() {
    return {
    }
  },
  created() {
    // this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
  },
  methods: {
    callback(data){
    },

  }
}
</script>
<style></style>
