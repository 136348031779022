<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%;margin-bottom:10px;">
      <el-row :gutter="10">
        <el-col :md="12">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="searchKeyword" style="max-width: 200px;" class="mr-2"
            @keyup.enter.native="searchRead(searchKeyword)">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="searchRead(searchKeyword)"></i>
          </el-input>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="searchRead(searchKeyword)">
            {{ $t('SEARCH') }}</el-button>
          <el-button type="primary" icon="el-icon-tickets" size="mini" v-if="searchKeyword" @click="searchRead(null)">
            {{ $t('VIEWALL') }}
          </el-button>
          <!-- <el-button class="ml-4" :type="listType == 0 ? 'primary' : ''" size="mini" @click="listType = 0">
                          {{ $t('SI') }}
                        </el-button>
                        <el-button :type="listType == 1 ? 'primary' : ''" size="mini" @click="listType = 1">
                          {{ $t('I.I') }}
                        </el-button>
                        <el-button :type="listType == 2 ? 'primary' : ''" size="mini" @click="listType = 2">
                          {{ $t('A.I') }}
                        </el-button> -->
        </el-col>
        <el-col :md="12" class="right">
          <el-button type="primary" :icon="request.objSearchMapper.state ? 'el-icon-success' : 'el-icon-delete'"
            size="mini" @click="searchStateRead(request.objSearchMapper.state ? 0 : 1)">
            {{ request.objSearchMapper.state ? $t('NU') : $t('SU') }}</el-button>
        </el-col>
      </el-row>
    </card>
    <div>
      <el-row v-if="count.length">
        <el-col :xl="12" :md="12" class="mb-2">
          <el-button v-for="item, index in saleStatusList" :key="index"
            :type="request.objSearchMapper.saleStatus == item.value ? 'warning' : ''" size="mini"
            @click="statusRead(item.value)">
            {{ $t(item.label) }}({{ count[item.value].count }})
          </el-button>
        </el-col>
        <el-col :xl="12" :md="12" class="mb-2 right">
          <el-button :type="request.objSearchMapper.seo ? 'danger' : ''" size="mini" @click="seo()" icon="fas fa-filter">
            {{ $t('SEO') }}
          </el-button>
          <el-button :type="request.objSearchMapper.hasAmountZero ? 'danger' : ''" size="mini" @click="hasAmountZero()"
            icon="fas fa-filter">
            {{ $t('HASAMOUNTZERO') }}
          </el-button>
          <el-button :type="request.objSearchMapper.OnlyPriceZero ? 'danger' : ''" size="mini" @click="priceZero()"
            icon="fa-solid fa-filter-circle-dollar">
            {{ $t('AMOUNTCNOTSET') }}
          </el-button>
        </el-col>
      </el-row>
      <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13" v-if="listType == 0">
        <el-table-column :label="$t('SELECT')" width="50" :align="'center'">
          <template slot="header" slot-scope="scope">
            <span class=" hand font15" :class="!checkedIds.length? 
                        'far fa-square-check':'fas fa-square-check colorbluedeep'
                        " @click="checkAll()"></span>
          </template>
          <template slot-scope="scope">
            <input type="checkbox" :disabled="request.objSearchMapper.state == 1"
              :checked="checkedIds.find(item => item.seq == scope.row.seq)"
              @change="pushId(scope.row, $event.target.checked)">
          </template>
        </el-table-column>
        <!-- <el-table-column :label="$t('PHOTO')" width="150" :align="'center'">
                        <template slot="header">
                         <i></i>
                        </template>
                        <template slot-scope="scope">
                          <label v-html="imgElement(scope.row)"></label>
                        </template>
                      </el-table-column> -->
        <el-table-column label="UP" width="150" :align="'left'">
          <template slot-scope="scope">
            <label v-html="imgElement(scope.row)" class="hand" @click="showImg(scope.row)"></label>
            <span class="filebox">
              <label :for="'imgPath' + scope.row.seq" v-html="imgUp()"></label>
              <input type="file" multiple="multiple" accept="image/*" :name="'imgPath' + scope.row.seq"
                :id="'imgPath' + scope.row.seq" :ref="'avatarInput' + scope.row.seq" :class="'ex_file' + scope.row.seq"
                :file-list="fileList" @change="imageupload(scope.row)" />
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="ecount" width="80" :align="'center'">
            <template slot-scope="scope">
              {{ scope.row.ecountId }}
            </template>
          </el-table-column> -->
        <el-table-column :label="$t('PRODUCT')" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('PID')" width="80" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.pid }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('FACTORYNUM')" width="80" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.factoryNum }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TYPE')" width="90" :align="'center'">
          <template slot-scope="scope">
            <span class="ellip bigbadge-orange hand noselect" @click="showProductTypeWindow(scope.row)">
              {{ $t($productType.find(item => item.value == scope.row.type).label) }}
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column :label="$t('WARNING')" width="60" :align="'center'">
                  <template slot-scope="scope">
                    <i class="fas fa-triangle-exclamation colorgraylight" :class="{'colorred': !invenWarning(scope.row)}"></i>
                  </template>
                </el-table-column> -->
        <el-table-column :label="$t('IV.QTY')+'/'+$t('MINQTY')" width="70" :align="'right'">
          <template slot-scope="scope">
            <span class="ellip">{{ $comma(scope.row.hasAmount) }}</span>
            / <span class="ellip">{{ scope.row.minAmount || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('SUPPLY.P')" width="70" :align="'right'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ $comma(scope.row.inPrice) }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('AMOUNT') + 'C'" width="80" :align="'right'">
          <template slot-scope="scope">
            <!-- <span class="ellip">{{ $comma(scope.row.priceC) }}</span> -->
            <input class="inputbox center" :placeholder="$t('AMOUNT') + 'C'" maxlength="20" v-model="scope.row.priceC"
              autocomplete="off" @focus="() => { scope.row.priceC = $numberFormat(scope.row, 'priceC', 'focus') }"
              @input="() => { scope.row.priceC = $numberFormat(scope.row, 'priceC', 'input') }"
              @blur="scope.row.priceC = $numberFormat(scope.row, 'priceC', 'blur');"
              @change="setchangeClass($event);setProductPriceNOread(scope.row,$event)"
              @keyup.enter="setProductPriceNOread(scope.row,$event)" />
          </template>
        </el-table-column>
        <el-table-column :label="$t('AMOUNT') + 'D'" width="80" :align="'right'">
          <template slot-scope="scope">
            <!-- <span class="ellip">{{ $comma(scope.row.priceD) }}</span> -->
            <input class="inputbox right" :placeholder="$t('AMOUNT') + 'D'" maxlength="20" v-model="scope.row.priceD"
              autocomplete="off" @focus="() => { scope.row.priceD = $numberFormat(scope.row, 'priceD', 'focus') }"
              @input="() => { scope.row.priceD = $numberFormat(scope.row, 'priceD', 'input') }"
              @blur="scope.row.priceD = $numberFormat(scope.row, 'priceD', 'blur');"
              @change="setchangeClass($event);setProductPriceNOread(scope.row,$event)"
              @keyup.enter="setProductPriceNOread(scope.row,$event)" />
          </template>
        </el-table-column>
        <el-table-column :label="$t('STORAGE')" width="80" :align="'left'"
          :style="{ display: listType === 2 ? '' : 'none' }" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.storageInfo && scope.row.storageInfo.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="수정" width="50" :align="'center'">
          <template slot="header">
            <i class="fas fa-pen-to-square"></i>
          </template>
          <template slot-scope="scope">
            <i class="fas fa-pen-to-square hand" @click="insertUpdatebox(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column label="정지/삭제" width="50" :align="'right'">
          <template slot="header">
            <i :class="'fas fa-trash-alt'"></i>
          </template>
          <template slot-scope="scope">
            <i class="hand" :class="scope.row.state == 0 ? 'fas fa-trash-alt' : 'fas fa-trash'"
              @click="stateinsertUpdatebox(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
      <!-- 입고/재고 -->
      <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13" v-if="listType == 1">
        <el-table-column label="선택" width="50" :align="'center'">
          <template slot="header">
            <input type="checkbox" disabled>
          </template>
          <template slot-scope="scope">
            <input type="checkbox" :disabled="request.objSearchMapper.state == 1"
              :checked="checkedIds.find(item => item.seq == scope.row.seq)"
              @change="pushId(scope.row, $event.target.checked)">
          </template>
        </el-table-column>
        <el-table-column label="ID" width="70" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.seq }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('PHOTO')" width="150" :align="'left'">
          <template slot-scope="scope">
            <span class="filebox">
              <label :for="'imgPath' + scope.row.seq" v-html="imgElement(scope.row)"></label>
              <input type="file" multiple="multiple" accept="image/*" :name="'imgPath' + scope.row.seq"
                :id="'imgPath' + scope.row.seq" :ref="'avatarInput' + scope.row.seq" :class="'ex_file' + scope.row.seq"
                :file-list="fileList" @change="imageupload(scope.row)" />
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('PRODUCT')" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('PID')" width="120" :align="'left'">
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.pid }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TYPE')" width="100" :align="'center'">
          <template slot-scope="scope">
            <span class="ellip">{{ $productType.find(item => item.value == scope.row.type).label }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('MINQTY')" width="90" :align="'right'">
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.minAmount || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('IV.QTY')" width="70" :align="'right'">
          <template slot-scope="scope">
            <span class="ellip">{{ $comma(scope.row.hasAmount) }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('SUPPLY.P')" width="90" :align="'right'">
          <template slot-scope="scope">
            <span class="ellip">{{ $comma(scope.row.inPrice) }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('STATUS')" width="80" :align="'center'"
          :style="{ display: listType === 2 ? '' : 'none' }">
          <template slot-scope="scope">
            {{ scope.row.state == 0 ? $t('N') : $t('S') }}
          </template>
        </el-table-column>
        <el-table-column label="수정" width="50" :align="'center'">
          <template slot="header">
            <i class="fas fa-pen-to-square"></i>
          </template>
          <template slot-scope="scope">
            <i class="fas fa-pen-to-square hand" @click="insertUpdatebox(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column label="정지/삭제" width="50" :align="'right'">
          <template slot="header">
            <i :class="'fas fa-trash-alt'"></i>
          </template>
          <template slot-scope="scope">
            <i class="hand" :class="scope.row.state == 0 ? 'fas fa-trash-alt' : 'fas fa-trash'"
              @click="stateinsertUpdatebox(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
      <!-- 부가정보 -->
      <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13" v-if="listType == 2">
        <el-table-column label="선택" width="50" :align="'center'">
          <template slot="header">
            <input type="checkbox" disabled>
          </template>
          <template slot-scope="scope">
            <input type="checkbox" :disabled="request.objSearchMapper.state == 1"
              :checked="checkedIds.find(item => item.seq == scope.row.seq)"
              @change="pushId(scope.row, $event.target.checked)">
          </template>
        </el-table-column>
        <el-table-column label="ID" width="70" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.seq }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('PHOTO')" width="150" :align="'left'">
          <template slot-scope="scope">
            <span class="filebox">
              <label :for="'imgPath' + scope.row.seq" v-html="imgElement(scope.row)"></label>
              <input type="file" multiple="multiple" accept="image/*" :name="'imgPath' + scope.row.seq"
                :id="'imgPath' + scope.row.seq" :ref="'avatarInput' + scope.row.seq" :class="'ex_file' + scope.row.seq"
                :file-list="fileList" @change="imageupload(scope.row)" />
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('PRODUCT')" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('PID')" width="120" :align="'left'">
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.pid }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('FACTORYNUM')" width="120" :align="'left'">
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.factoryNum }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('STORAGE')" width="80" :align="'left'"
          :style="{ display: listType === 2 ? '' : 'none' }" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.storageInfo && scope.row.storageInfo.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('STATUS')" width="80" :align="'center'"
          :style="{ display: listType === 2 ? '' : 'none' }">
          <template slot-scope="scope">
            {{ scope.row.state == 0 ? $t('N') : $t('S') }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('DESC')" width="150" :align="'left'" show-overflow-tooltip
          :style="{ display: listType === 2 ? '' : 'none' }">
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.memo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="수정" width="50" :align="'center'">
          <template slot="header">
            <i class="fas fa-pen-to-square"></i>
          </template>
          <template slot-scope="scope">
            <i class="fas fa-pen-to-square hand" @click="insertUpdatebox(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column label="정지/삭제" width="50" :align="'right'">
          <template slot="header">
            <i :class="'fas fa-trash-alt'"></i>
          </template>
          <template slot-scope="scope">
            <i class="hand" :class="scope.row.state == 0 ? 'fas fa-trash-alt' : 'fas fa-trash'"
              @click="stateinsertUpdatebox(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
  
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="12" :md="12">
          <div>
            <el-button size="mini" v-if="판매승인버튼유효성()" @click="setSaleStatus(2)">
              판매승인
            </el-button>
            <el-button size="mini" v-if="요청취소버튼유효성()" @click="setSaleStatus(3)">
              요청취소
            </el-button>
            <el-button size="mini" v-if="승인취소버튼유효성()" @click="setSaleStatus(4)">
              승인취소
            </el-button>
          </div>
        </el-col>
        <el-col :xl="checkedIds.length ? 12 : 24" :md="checkedIds.length ? 12 : 24" class="right">
          <el-pagination background class="mb-2 text-right" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
            :page-size="request.size" layout="total, sizes, prev, pager, next" :total="totalCount">
          </el-pagination>
        </el-col>
      </el-row>
    </div>
    <mallmanagerimageview v-if="showimage" :data="selectItem" @callback="iamgeViewCallback" />
    <selectsalesproducttype v-if="showProductType" :prodType="selectItem.type" @callback="productTypeCallback" />
    <malllproductCreate v-if="createvisible" :data="selectItem" @callback="createCallback" />
    <yesNoBox v-if="yesNoBoxvisible" @callback="deletecallback" :title="selectItem.name" :data="yesNoBoxContent" />
  </div>
</template>
<script>
import { Loading } from 'element-ui'
import yesNoBox from './component-alert-continue.vue'
import malllproductCreate from './component-mall-product-create.vue'
import selectsalesproducttype from './component-select-sales-product-type.vue'
import mallmanagerimageview from './component-mall-manager-image-view.vue'
export default {
  name: 'mallmanagermember',
  components: {
    yesNoBox, malllproductCreate, selectsalesproducttype,
    mallmanagerimageview
  },
  data() {
    return {
      checkedIds: [],
      fileList: [],
      count: [],
      listType: 0,
      selectItem: null,
      showimage: false,
      showProductType: false,
      createvisible: false,
      yesNoBoxvisible: false,
      yesNoBoxContent: {},
      pageSizes: [12, 50, 100],
      searchKeyword: '',
      request: {
        objSearchMapper: {
          seo: false,
          hasAmountZero: false,
          OnlyPriceZero: false,
          searchKeyword: '',
          state: 0,
          saleStatus: 1,
        },
        page: 1,
        size: 12
      },
      tmpTableData: [],
      totalCount: 0,
      saleStatusList: [
        { value: 1, label: 'SALER' }, //판매요청
        { value: 2, label: 'AP' }, //판매승인
        { value: 3, label: 'RC' }, //요청취소
        { value: 4, label: 'AC' }, //승인취소
        { value: 5, label: 'SC' }, //판매취소
      ]
    }
  },

  async created() {
    await this.read()
  },
  methods: {
    iamgeViewCallback(data) {
      this.showimage = false
    },
    showProductTypeWindow(item) {
      this.selectItem = item
      this.showProductType = true
    },
    productTypeCallback(data) {
      this.showProductType = false
      if (data.status) {
        this.selectItem.type = data.item.value
        this.setInfo()
      }
    },
    setProductPrice(data, event) {
      this.selectItem = data
      this.selectItem.priceC = data.priceC
      this.selectItem.priceD = data.priceD
      this.setInfo()
      event.target.className = 'inputbox right'
    },
    setProductPriceNOread(data, event) {
      if (data.priceC == 0 && data.priceD == 0) { return }
      this.selectItem = data
      this.selectItem.priceC = data.priceC
      this.selectItem.priceD = data.priceD
      this.setInfoNOread()
      event.target.className = 'inputbox right'
    },

    setProductImport(data) {
      this.selectItem = data
      this.setInfo()
    },
    setchangeClass(event) {
      event.target.className = 'inputboxchange right'
    },
    insertUpdatebox(item) {
      this.selectItem = item
      this.createvisible = true
    },
    createCallback(data) {
      if (data.status) {
        this.searchRead()
      }
      this.createvisible = false
    },
    stateinsertUpdatebox(item) {
      this.selectItem = item
      this.yesNoBoxvisible = true
      if (item.state) {
        this.yesNoBoxContent = {
          content: this.$t('Would you like to restore the item?'),
          contentSub: this.$t('The information is being used normally.'),
        }
      } else {
        this.yesNoBoxContent = {
          content: this.$t('Would you like to suspend the item for sale?'),
          contentSub: this.$t('The item will be suspended for sale.')
        }
      }
    },
    deletecallback(data) {
      if (data) {
        this.satateInfo()
      }
      this.yesNoBoxvisible = false
    },

    searchStateRead(params) {
      this.request.objSearchMapper.state = params
      this.request.page = 1
      this.read()
    },
    hasAmountZero() {
      this.request.page = 1
      const opz = this.request.objSearchMapper.hasAmountZero
      this.request.objSearchMapper.hasAmountZero = opz ? false : true
      this.read()
    },
    priceZero() {
      this.request.page = 1
      const opz = this.request.objSearchMapper.OnlyPriceZero
      this.request.objSearchMapper.OnlyPriceZero = opz ? false : true
      this.read()
    },
    seo() {
      this.request.page = 1
      const opz = this.request.objSearchMapper.seo
      this.request.objSearchMapper.seo = opz ? false : true
      this.read()
    },
    statusRead(value) {
      this.request.page = 1
      this.request.objSearchMapper.saleStatus = value
      this.read()
    },

    searchRead(searchKeyword) {
      this.searchKeyword = searchKeyword
      this.request.page = 1
      this.request.objSearchMapper.searchKeyword = this.$trimLR(searchKeyword)
      this.read()
    },
    async read() {
      this.tmpTableData = []
      await this.$http.post(`${this.$serverurl}/api/getSalesProductInfo`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.checkedIds = []
            this.tmpTableData = res.data.body.results
            this.tmpTableData.map(item => {
              item.priceA = this.$comma(item.priceA)
              item.priceB = this.$comma(item.priceB)
              item.priceC = this.$comma(item.priceC)
              item.priceD = this.$comma(item.priceD)
            })
            this.totalCount = res.data.body.total
            this.count = []
            this.count = [
              { count: res.data.body.salesStatusA.count },
              { count: res.data.body.salesStatusB.count },
              { count: res.data.body.salesStatusC.count },
              { count: res.data.body.salesStatusD.count },
              { count: res.data.body.salesStatusE.count },
              { count: res.data.body.salesStatusF.count },
            ]
          } else {
            this.tmpTableData = []
          }
        })
    },
    async setInfo() {
      const res = await this.$http.post(`${this.$serverurl}/api/setSalesProductInfo`, this.selectItem, this.$setHeader())
      if (201 == res.data.code) {
        this.read()
      }
    },
    async setInfoNOread() {
      const res = await this.$http.post(`${this.$serverurl}/api/setSalesProductInfo`, this.selectItem, this.$setHeader())
      if (201 == res.data.code) {
        this.selectItem = res.data.body.results
        this.tmpTableData.map(item => {
          if (item.seq == this.selectItem.seq) {
            item = this.selectItem
          }
        })
      }
    },
    async satateInfo() {
      const formData = { seq: this.selectItem.seq }
      await this.$http.post(`${this.$serverurl}/api/deleteSalesProductInfo`, formData, this.$setHeader())
        .then(res => {
          if (201 == res.data.code) {
            alert(res.data.message)
            this.read()
          }
        })
    },

    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    },

    imgElement(item) {
      let imgElements = [];
      if (item.images && item.images.length > 0) {

        for (var img of item.images) {
          const https = img.includes('://')
          if (https) {
            imgElements += `<img src='${img}' width='25' height='25'
                   style='filter:brightness(120%);margin-left:5px;'
                />`
          } else {
            imgElements += `<img src='${this.$imageurl + img}' width='25' height='25'
                   style='filter:brightness(120%);margin-left:5px;'
                />`
          }

        }
      } else {
        imgElements = `<div></div>`
      }
      return imgElements
    },
    imgUp() {
      return `<div></div>`
    },
    showImg(item) {
      this.selectItem = item
      this.showimage = true
    },
    pushId(seq, isChecked) {
      const exists = this.checkedIds.includes(seq);
      if (isChecked && !exists) {
        this.checkedIds.push(seq);
      } else if (!isChecked && exists) {
        const index = this.checkedIds.indexOf(seq);
        this.checkedIds.splice(index, 1);
      }
    },
    checkAll() {
      if (this.checkedIds.length !== this.tmpTableData.length) {
        this.checkedIds = this.tmpTableData.filter(item => item.seq)
      } else {
        this.checkedIds = []
      }
    },
    판매승인버튼유효성() {
      return (
        this.request.objSearchMapper.saleStatus == 1 ||
        this.request.objSearchMapper.saleStatus == 3 ||
        this.request.objSearchMapper.saleStatus == 4 ||
        this.request.objSearchMapper.saleStatus == 5
      )
        && this.checkedIds.length > 0
    },
    요청취소버튼유효성() {
      return (
        this.request.objSearchMapper.saleStatus == 1)
        && this.checkedIds.length > 0
    },
    승인취소버튼유효성() {
      return (
        this.request.objSearchMapper.saleStatus == 2)
        && this.checkedIds.length > 0
    },
    async setSaleStatus(saleStatus) {
      const formData = []
      this.checkedIds.map(item => {
        item.saleStatus = saleStatus
        if (item.priceC && item.priceD) {
          formData.push(item)
        }
      })
      if (!formData.length) {
        alert('승인조건이 부합되지 않습니다.')
        return
      }
      this.loading = Loading.service({ fullscreen: true })
      const url = `${this.$serverurl}/api/setSalesProductStatusInfo`
      this.$http.post(url, formData, this.$setHeader()).then(res => {
        if (res.data.code == 201) {
          alert(res.data.message)
          this.read()
        }
      })
      this.$overay(this.loading)
    },


    async imageupload(params) {
      var fileInput = window.document.getElementsByClassName("ex_file" + params.seq)
      //업로드부분
      let tmp_formData = new FormData();
      for (let i = 0; i < fileInput[0].files.length; i++) {
        tmp_formData.append("imgFiles", fileInput[0].files[i]);
      }

      tmp_formData.append('seq', params.seq);
      // 추가부분
      this.$http.defaults.headers.common['productId'] = params.seq;
      await this.$http.post(this.$imageurl + "/api/uploadSalesProductByImage", tmp_formData, this.$setHeader("mult"))
        .then(res => {
          if (res.status == 201) {
            setTimeout(() => {
              this.read()
            }, 300);
          }
        })
    },
    invenWarning(item) {
      const ecountQty = item.ecountListExinfo?.balQtyEx || 0
      const invenQty = item.invenListInfo?.inQty - item.invenListInfo?.outQty || 0
      if (
        item.hasAmount <= item.minAmount ||
        item.hasAmount <= ecountQty ||
        item.hasAmount <= invenQty
      ) { return false } else { return true }
    }

  }
}
</script>
<style scope lang="scss">
.filebox label {
  vertical-align: middle;
  background-color: transparent;
  cursor: pointer;
  height: 25px;
  padding-top: 2px !important;
  padding-left: 10px !important;

  &::after {
    content: '🌄';
    border: 1px solid #eee;
    padding: 5px 3px 5px 2px;
    margin-left: 5px;
    border-radius: 5px;
  }
}

.filebox input[type="file"] {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}
</style>
