<template>
    <div>
        <div class="mb-1">
            <span style="border-bottom:0px #000 solid;font-size: 2rem;font-weight: 500;letter-spacing:5px;">
              재고명세서
            </span>
            -
            <span class="fontsize16" style="font-weight: 500;letter-spacing:5px;">
              재고실사용
            </span>
        </div>
    </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {}
}
}
</script>
<style scoped>

</style>