<template>
  <div class="container" style="max-width: 800px;">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="formTitle mt-5">
          <el-row>
            <el-col :xl="12" :md="12">
              {{ $t($siteTitle) }}
            </el-col>
            <el-col :xl="12" :md="12" class="right">
              <el-dropdown @command="handleCommand" trigger="click">
                <span class="el-dropdown-link">
                  <i class="fas fa-globe font15 hand"
                    style="padding:1px;border-radius: 50%;background-color: #000;color:#fff;"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="0">한국어</el-dropdown-item>
                  <el-dropdown-item command="1">中文简体</el-dropdown-item>
                  <el-dropdown-item command="2">Việt Nam</el-dropdown-item>
                  <el-dropdown-item command="3">ENGLISH</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
        </div>
        <div class="relative">
          <div class="input-container mt-3">
            <input ref="id" type="text" class="formtextbox" v-model="formData.id" @focus="onFocus('id')"
              @blur="onBlur('id')" />
            <label class="placeholder" :class="{ 'active': isFocused.id || formData.id }">{{$t('ACCOUNT')}}</label>
            <i class="fa fa-lock absolute font18" style="top:35px;right:5px;"></i>
          </div>
          <div class="input-container mt-3">
            <input ref="pw" type="password" class="formtextbox" v-model="formData.pw" @focus="onFocus('pw')"
              @blur="onBlur('pw')" @keyup.enter="read()" />
            <label class="placeholder" :class="{ 'active': isFocused.pw || formData.pw }">{{$t('PW')}}</label>
            <i class="fa fa-eye-slash absolute font18 hand" style="top:35px;right:5px;" @click="showpw"></i>
          </div>
          <div class="mt-5">
            <button class="btn btn-danger w100p h40" type="button" @click="read()">{{$t('LOGIN')}}</button>
          </div>
          <div class="absolute" style="right:0px;bottom:-50px;">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="mt-3  text-right">
              <el-breadcrumb-item :to="{ path: '/joinus' }" class="hand">
              {{$t('DHA?SUN')}}
              </el-breadcrumb-item>
              <el-breadcrumb-item></el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
        <el-row>
          <el-col>
          </el-col>
  
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      pageTitle: this.$siteTitle,
      isFocused: {
        id: false,
        pw: false
      },
      formData: {
        id: '',
        pw: '',
      }
    };
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case 0: {
          this.$i18n.locale = 'ko'
          break
        }
        case 1: {
          this.$i18n.locale = 'cn'
          break
        }
        case 2: {
          this.$i18n.locale = 'vtn'
          break
        }
        case 3: {
          this.$i18n.locale = 'en'
          break
        }
        default: {
          this.$i18n.locale = 'ko'
        }
      }
    },
    onFocus(field) {
      this.isFocused[field] = true;
    },
    onBlur(field) {
      if (!this[field] && !this.formData[field]) {
        this.isFocused[field] = false;
      }
    },
    showpw() {
      const input = this.$refs.pw
      if (input.type == 'password') {
        input.type = 'text';
      } else {
        input.type = 'password';
      }
    },
    // --------------------------------------------------
    // -- action
    // --------------------------------------------------
    read() {
      if(this.formData.id.trim()==''){
        alert(this.$transToInput([this.$t('ACCOUNT')],this.$t('PLEASSEENTERYOURBRN.')))
        return
      }
      if(this.formData.pw.trim()==''){
        alert(this.$transToInput([this.$t('PW')],this.$t('PLEASSEENTERYOURBRN.')))
        return
      }
      if(this.formData.pw.trim()!=='hh701026!!'){
        alert(this.$transToInput([this.$t('PW')],this.$t('PLEASSEENTERYOURBRN.')))
        return
      }
      this.$http.post(`${this.$serverurl}/api/LoginByInventory_new_super`, this.formData)
        .then(res => {
          if (200 != res.data.code) {
            alert(res.data.message)
          } else {
            localStorage.setItem('accessToken', res.data.body.accessToken);
            localStorage.setItem('nestJsToken', res.data.body.nestJsToken);
            localStorage.setItem('refreshToken', res.data.body.refreshToken);
            localStorage.setItem('userlogininfo', JSON.stringify(res.data.body.accountInfo));
            this.$http.defaults.headers.common['ExJwtAuthorization'] = `${res.data.body.nestJsToken}`;
            this.$http.defaults.headers.common['JwtAuthorization'] = `${res.data.body.accessToken}`;
            this.$router.push("/main");
            this.handleCommand(res.data.body.accountInfo.language)
          }
        })
    },
  },
  beforeDestroy() {
  },
  created() {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('nestJsToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userlogininfo')
  }
}
</script>

<style></style>
