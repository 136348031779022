<template>
  <el-dialog center :title="tmpTableData.name+' '+$t(title)" :visible.sync="visible" :width="'500px'" :modal=true
    :show-close="false" :before-close="notClose" v-draggable style="z-index: 99999999;">
    <el-row class="mb-2">
      <el-col :xl="4" :md="4" class="lh25">{{$t('LICENSE')}}</el-col>
      <el-col :xl="18" :md="18">
        <el-select size="mini" placeholder="Single Select" v-model="tmpTableData.remaDays">
          <el-option v-for="option in tmpMallConfigInfo.licenseValidBuy" class="select-danger" :value="option.days"
            :label="option.title+' ('+option.days+')'+$t('DAY')" :key="option.seq">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <!-- <div style="margin-bottom: 20px;">
        {{ $t('SLPBCEUP') }}
      </div> -->
  
    <el-table :data="tmpLoglist" :border="true" style="width: 100%" class="font13">
      <el-table-column label="ID" width="80" :align="'center'">
        <template slot-scope="scope">
          {{ scope.row.seq }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('DATE')" width="120" :align="'center'">
        <template slot-scope="scope">
          {{ $YYYYMMDD_HHMM(scope.row.transaction_date) }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('AMOUNT')" width="100" :align="'center'">
        <template slot-scope="scope">
          {{ $comma(scope.row.amount) }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('DESC')" :align="'center'">
        <template slot-scope="scope">
          {{ scope.row.service_name }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes" :page-size="request.size"
      layout=" prev, pager, next" :total="tmpLogCount">
    </el-pagination>
  
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">{{$t('CLOSE')}}</el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange" @click="setInfo">
          {{$t('SUBMIT')}}</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'malllcompanylicenseCreate',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.tmpTableData = newValue
        this.tmpTableData.remaDays = 30

      }
    },
  },
  data() {
    return {
      pageSizes: [10, 50, 100],
      tmpTableData: {},
      visible: true,
      title: 'LICENSE',
      total: 0,
      tmpMallConfigInfo: {},
      tmpLoglist: [],
      tmpLogCount: 0,
      request: {
        objSearchMapper: {
          companyId: null,
        },
        page: 1,
        size: 10,
      }

    }
  },
  async created() {
    this.getMallConfigInfo()
    this.getMallCompanyLicenseLogInfo()
  },
  methods: {
    async setInfo() {
      const formData = {
        seq: this.tmpTableData.seq,
        name: this.tmpTableData.name,
        remaDays: this.tmpTableData.remaDays
      }
      const res = await this.$http.post(`${this.$serverurl}/api/setCompanyLicenseInfo`, formData, this.$setHeader())
      if (201 == res.data.code) {
        alert(res.data.message)
        this.setCallback(res.data.body.result)
      }
    },
    async getMallConfigInfo() {
      const res = await this.$http.post(`${this.$serverurl}/api/getMallConfigInfo`, null, this.$setHeader())
      if (200 == res.data.code) {
        this.tmpMallConfigInfo = res.data.body.results
      }
    },
    async getMallCompanyLicenseLogInfo() {
      this.request.objSearchMapper.companyId = this.tmpTableData.seq
      const res = await this.$http.post(`${this.$serverurl}/api/getMallCompanyLicenseLogInfo`, this.request, this.$setHeader())
      if (200 == res.data.code) {
        this.tmpLoglist = res.data.body.results
        this.tmpLogCount = res.data.totalCount
      }
    },
    setCallback(item) {
      this.$emit('callback', { item: item, status: true })
    },
    notClose() { },
    handleClose(done) {
      this.$emit('callback', { item: null, status: false })
    },

    handleSizeChange(val) {
      this.request.size = val
      this.getMallCompanyLicenseLogInfo()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.getMallCompanyLicenseLogInfo()
    }
  }
}
</script>
<style></style>