<template>
  <div class="container mt-3 relative" :style="$pageWidth">
    <TopBar />
    <div class="mt-2">
      <el-row>
        <el-col :xl="24" :md="24">
          <el-button v-for="(item, index) in categorys" :key="index" size="mini"
            :type="activeName == item.route ? 'primary' : ''" @click="handleButtons(item.route)">
            {{ $t(item.label) }}
          </el-button>
        </el-col>
      </el-row>
        <div class="mt-2">
        <component :is="activeName"></component>
      </div>
    </div>
  </div>
</template>
<script>
import mallmanagermain from './component-mall-manager-main.vue'
import mallmanagercompany from './component-mall-manager-company.vue'
import mallmanagermember from './component-mall-manager-member.vue'
import mallmanagerproducts from './component-mall-manager-products.vue'
import mallmanagernotices from './component-mall-manager-notices.vue'
import mallmanagerconfig from './component-mall-manager-config.vue'
import mallmanagercashcount from './component-mall-manager-cash-count.vue'
import mallmanagersearchproductcount from './component-mall-manager-search-product-count.vue'
import mallmanagersearchkeywordcount from './component-mall-manager-search-count.vue'
import mallmanagersearchvin from './component-mall-manager-search-epc-vin.vue'
import mallmanagersearchparts from './component-mall-manager-search-epc-parts.vue'
import mallmanagersalescount from './component-mall-manager-sales-count.vue'
import mallmanagerlog from './component-mall-manager-log.vue'
import TopBar from './component-top.vue'
export default {
  components: {
    TopBar, mallmanagermain, mallmanagercompany, mallmanagermember, mallmanagerproducts, mallmanagernotices,
    mallmanagerconfig, mallmanagercashcount,mallmanagersearchkeywordcount,mallmanagersearchvin,mallmanagersearchparts
    ,mallmanagersearchproductcount,mallmanagersalescount,mallmanagerlog
  },
  data() {
    return {
      activeName: 'mallmanagercompany',
      categorys: [
        // { value: 0, label: '통계메인', route: 'mallmanagermain' },
        { value: 1, label: 'COMPANY.M', route: 'mallmanagercompany' }, //회사관리
        { value: 2, label: 'MEMBER.M', route: 'mallmanagermember' }, //회원관리
        { value: 3, label: 'PRODUCT.M', route: 'mallmanagerproducts' }, //상품관리
        { value: 4, label: 'NOTICE.M', route: 'mallmanagernotices' }, //공지관리
        { value: 5, label: 'COFIG.S', route: 'mallmanagerconfig' }, //구성설정
        { value: 5, label: 'PS', route: 'mallmanagercashcount' }, //포인트통계
        { value: 5, label: 'S.PS', route: 'mallmanagersearchproductcount' }, //상품키워드검색통계
        { value: 5, label: 'S.SS', route: 'mallmanagersearchkeywordcount' }, //상품키워드검색통계
        { value: 5, label: 'S.VIN', route: 'mallmanagersearchvin' }, //차대검색통계
        { value: 5, label: 'S.PARTS', route: 'mallmanagersearchparts' }, //부품검색통계
        { value: 5, label: 'TS', route: 'mallmanagersalescount' }, //거래된상품통계
        { value: 5, label: 'LOG', route: 'mallmanagerlog' }, //로그인로그
      ]
    }
  },
  created() { },
  methods: {

    handleButtons(params) {
      this.activeName = params
    },

  }
}
</script>
<style></style>
