<template>
  <div class="container mt-3 relative" :style="$pageWidth">
    <TopBar />
    <div class="mt-2">
             <useritemlist />
    </div>
  </div>
</template>
<script>
import TopBar from './component-top.vue'
import useritemlist from './component-user-list.vue'

export default {
  components: {
    TopBar, useritemlist,
  },
  data() {
    return {
      initem: {},
    }
  },
  methods: {
    createcallback(data) {

    },
  
  }
}
</script>
<style></style>
