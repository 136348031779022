<template>
  <div class="container mt-3 relative" :style="'max-width:100%'" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%;margin-bottom: 10px;">
      <el-row :gutter="10">
        <el-col :md="12" style="min-width:200px;">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="request.objSearchMapper.searchKeyword"
            @keyup.enter.native="read('search')" style="max-width:200px;">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="read('search')">
            </i>
          </el-input>
          <el-button class="ml-2" type="primary" icon="el-icon-search" size="mini" @click="read('search')">{{
            $t('SEARCH') }}</el-button>
        </el-col>
        <el-col :md="12" class="right">
          <el-button type="primary" icon="el-icon-success" size="mini" @click="ocrShow()">
            {{ 'OCR' }}(VIN식별)</el-button>
          <el-button type="primary" icon="el-icon-success" size="mini" @click="colhiddenFilter()">
            {{ $t(filter.colHiddenTitle) }}</el-button>
          <el-button type="primary" icon="el-icon-success" size="mini" @click="addnewline()">
            {{ $t('AIL') }}</el-button>
        </el-col>
      </el-row>
    </card>
    <div class="mb-2">
      <el-row>
        <el-col :xl="12" :md=12>
            <label class="ecountcolor" title="이카운트에서 등록한 상품"></label>
            <label class="businesscolor" title="영업관리에서 등록한 상품"></label>
            <label class="seocolor" title="서오재고에서 등록한 상품"></label>
            <label class="bizmecolor" title="등록한 현재상품"></label>
        </el-col>
        <el-col :xl="12" :md="12" class="right">
          <el-date-picker size="mini" v-model="startDate" type="date" :placeholder="$t('STARTDATE')" format="yyyy-MM-dd"
        style="max-width: 200px;">
      </el-date-picker>
      ~
      <el-date-picker size="mini" v-model="endDate" type="date" :placeholder="$t('ENDDATE')" class="mr-2"
        style="max-width: 200px;">
      </el-date-picker>
      <!-- $filterDate([startDate,endDate], 'searchDate') -->
      <el-button type="" size="mini" @click="read('searchDate')">{{
        $t('SEARCH')
        }}</el-button>
        </el-col>
      </el-row>
      
    </div>
    <transition name="slide">
      <div class="drawer mb-1" v-if="tmpTableData.length" ref="drawer">
        <i class="fas fa-expand hand absolute bigbadge-gray" style="top:10px;right:60px;" @click="rePosition"></i>
        <i class="fas fa-circle-xmark hand absolute  bigbadge-red" style="top:10px;right:30px;" @click="closeDrawer"></i>
        <div>
          <div class="bold mb-2">
            검색결과
          </div>
          <div class="relative">
            <ve-table :columns="tmpTablecolumns" :table-data="tmpTableData" :row-style-option="rowStyleOption"
              :cell-style-option="cellStyleOption" rowKeyFieldName="seq" :clipboard-option="clipboardOption2"
              :border-around="true" :border-x="true" :border-y="true" :editOption="editOption"
              :virtual-scroll-option="virtualScrollOption" :columnHiddenOption="columnHiddenOption"
              :event-custom-option="eventCustomOption" ref="dataList"
              :column-width-resize-option="columnwidthResizeOption" max-height="calc(50vh - 100px)"
              :cell-autofill-option="cellAutofillOption" />
            <span v-if="saveMessage" class="absolute bigbadge-dark" style="top: 10px;right:10px;z-index: 999;">
              {{ saveMessage }}
            </span>
          </div>
        </div>
        <div>
        </div>
      </div>
    </transition>
  
    <div class="relative">
      <ve-table :columns="tmpTablecolumns" :table-data="tmpTableDataMe" :row-style-option="rowStyleOption"
        :cell-style-option="cellStyleOption" rowKeyFieldName="seq" :clipboard-option="clipboardOption"
        :border-around="true" :border-x="true" :border-y="true" :editOption="editOption"
        :virtual-scroll-option="virtualScrollOption" :columnHiddenOption="columnHiddenOption"
        :event-custom-option="eventCustomOption" ref="dataListMe" id="dataListMe"
        :column-width-resize-option="columnwidthResizeOption" max-height="calc(100vh - 330px)"
        :contextmenu-body-option="contextmenuBodyOptionMe" :cell-autofill-option="cellAutofillOption" />
      <span v-if="saveMessage" class="absolute bigbadge-dark" style="top: 10px;right:10px;z-index: 999;">
        {{ saveMessage }}
      </span>
    </div>
  
    <selectsalesproduct :pid="selectItem.pid" :list="tmpProductResult" v-if="showProductList"
      @callback="selectproductcallback" />
    <selectsalesproducttype v-if="showProductType" :prodType="selectItem.type" @callback="productTypeCallback" />
    <regproduct v-if="showRegProductList && selectItem" :data="selectItem" @callback="regProductCallback" />
    <!-- <json-viewer :value="selectItem" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
    <readimage v-if="showocr" @callback="ocrCallback" />
  
  </div>
</template>
<script>


import SplitPane from 'vue-splitpane';
import readimage from './component-biz-main-read-image'

import regproduct from './component-reg-product.vue'
import selectsalesproducttype from './component-select-sales-product-type.vue'
import yesNoBox from './component-alert-continue.vue'
import selectsalesproduct from './component-select-sales-product-list.vue'
import { Switch } from 'element-ui';
export default {
  name: 'bizmainlist',
  components: {
    yesNoBox, selectsalesproduct, selectsalesproducttype, regproduct,
    SplitPane,readimage
  },
  props: ['creator'],
  watch: {
    creator: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (!newValue) { return }
        this.tmpCreator = newValue
        this.request.objSearchMapper.startDate = null
        this.request.objSearchMapper.endDate = null
        this.startDate = null
        this.endDate = null
        this.readMe()
      }
    }
  },
  data() {
    return {

      // 우클릭메뉴
      contextmenuBodyOptionMe: {
        beforeShow: ({ isWholeRowSelection, selectionRangeKeys, selectionRangeIndexes,
        }) => { /*...*/ },
        afterMenuClick: ({ type, selectionRangeKeys, selectionRangeIndexes }) => {
          this.$afterMenuClick(type, selectionRangeKeys, selectionRangeIndexes, this.tmpTableDataMe)
        }, contextmenus: this.$contextmenus,
      },

      cellAutofillOption: { directionX: true, directionY: true, },

      historyStack: [],


      saleProcess: [
        { label: this.$t('PR'), value: 0 },
        { label: this.$t('REQUEST'), value: 1 },
        { label: this.$t('SP'), value: 2 },
        { label: this.$t('RC'), value: 3 },
        { label: this.$t('AC'), value: 4 },
        { label: this.$t('SC'), value: 5 },
      ],
      showProductType: false,
      tmpProductLists: [],
      tmpProductResult: {},
      saveMessage: '',
      filter: {
        colHiddenTitle: 'ALLFIELD',
        colHiddenStatus: 1,
      },
      tmpOldValue: null,
      tmpnewValue: null,
      tmpCreator: null,
      currentUserId: null,
      userlogininfo: null,
      showRegProductList: false,
      showProductList: false,
      yesNoBoxvisible: false,
      selectItemOld: null,
      selectItem: null,
      selectItems: [],
      selectColumn: null,
      cratevisible: false,
      startDate: null,
      endDate: null,
      request: {
        objSearchMapper: {
          creator: null,
          companyId: null,
          searchKeyword: null,
          startDate: null,
          endDate: null,
        },
        page: 1,
        size: 100,
        sizes: 100,
      },
      tmpTableData: [],
      modalByCreate: {
        content: null,
        rowIndex: null,
        params: null,
        formData: {
          baseId: null,
          model: null,
          companyId: 0,
          customerBuy: '',
          customerSale: '',
          factoryNum: null,
          partsName: null,
          partsNum: null,
          pid: null,
          minQty: 0,
          inQty: 0,
          invenQty: 0,
          priceA: 0,
          priceB: 0,
          priceC: 0,
          priceD: 0,
          salesProductId: null,
          storageId: null,
          type: 1,
          saleSatus: 1,
          updated: null,
        },
      },

      virtualScrollOption: {
        enable: true,
      },
      columnwidthResizeOption: {
        // default false
        enable: true,
        // column resize min width
        minwidth: 30,
        // column size change
        sizeChange: ({ column, differwidth, columnwidth }) => {
          this.columnResizeInfo.column = column;
          this.columnResizeInfo.differwidth = differwidth;
          this.columnResizeInfo.columnwidth = columnwidth;
        },
      },
      columnResizeInfo: {
        column: "",
        differwidth: "",
        columnwidth: "",
        tablewidth: "",
      },
      eventCustomOption: {  //마우스이벤트
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            click: (event) => {
              this.selectItem = row
            },
            dblclick: (event) => {
              // this.$notify.success({
              //   title: '',
              //   message: '복사되었습니다.',
              //   position: 'bottom-right',
              //   showClose: true,
              //   duration: 1000,
              // });
            },
          };
        },
        bodyCellEvents: ({ row, column, cell, rowIndex, columnIndex, $event }) => {
          return {
            click: (event) => {
              this.selectColumn = column.key
              if (
                row.salesProductInfo?.saleStatus == 1
                && row.salesProductInfo?.bizMainId
                && row.seq == row.salesProductInfo?.bizMainId) {
                if (
                  column.key == 'partsName' ||
                  column.key == 'pid' ||
                  column.key == 'factoryNum' ||
                  column.key == 'type'
                )
                  column.edit = false
              } else {
                if (column.key != 'type') {
                  column.edit = true
                }
              }
            },
            dblclick: (event) => {
              if (column.key == 'type') {
                this.showProductTypeWindow()
              }
            }
          }
        },
      },
      columnHiddenOption: {//기본열 감춤
        defaultHiddenColumnKeys: [
          'regProdButton', 'creatorName', '','updatedDateName', 'updaterName', 'minQty',
          'inQty', 'priceA', 'priceB'],
      },
      editOption: {
        cellValueChange: ({ row, column }) => { },
        beforeCellValueChange: ({ row, column, changeValue }) => {
          this.tmpOldValue = row.pid
          this.backupData()
          this.selectItemOld = JSON.parse(JSON.stringify(row))
          this.selectItem = JSON.parse(JSON.stringify(row))
        },
        afterCellValueChange: async ({ row, column, changeValue }) => {   //변경후 
          this.selectItem = JSON.parse(JSON.stringify(row))
          // row.partsNum = this.$onlyEngNum(row.partsNum.toUpperCase())
          // row.pid = this.$onlyEngNum(row.pid.toUpperCase())
          // row.factoryNum = this.$onlyEngNumFuhao(row.factoryNum.toUpperCase())
          this.tmpnewValue = row.pid
          if (column.key == 'pid') {
            if (this.tmpOldValue != this.tmpnewValue) {
              const oldSalesProductId = row.salesProductId
              row.baseId = null
              row.salesProductInfo = null
              row.salesProductId = null
              this.selectItem = JSON.parse(JSON.stringify(row))
              this.selectItem.oldSalesProductId = oldSalesProductId
              this.showSelectProduct(this.selectItem.pid)
            }
          }
          else {
            this.saveProcess()
          }
        },
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {    //헤드셀 양식
          return "table-header-cell-class nowrap fontsize13 bgggraytable";
        },
        bodyCellClass: ({ row, column, rowIndex }) => {   //바디셀 양식nowrap
          return " fontsize13" //orderin.vue
        },
      },
      rowStyleOption: {
        stripe: true,
      },
      clipboardOption: {  // 이지테이블 복사가능 ,붙여넣기,잘라내기,삭제 안됨
        copy: true,
        paste: true,
        cut: true,
        delete: true,
        beforeCopy: ({ data, selectionRangeIndexes, selectionRangeKeys }) => {
          // alert('정보가 복사되었습니다.');
        },
        beforePaste: ({ data, selectionRangeIndexes, selectionRangeKeys }) => {
          this.backupData()
          const fieldsName = Object.keys(data[0])[0]
          const fieldsValue = Object.keys(data[0])[1]
          this.selectItem[fieldsName] = fieldsValue
        },
        afterPaste: ({ data, selectionRangeIndexes, selectionRangeKeys }) => {
          this.selectItems = []
          for (var i = selectionRangeKeys.startRowKey; i <= selectionRangeKeys.endRowKey; i++) {
            this.pushItems(this.tmpTableDataMe.find(item => item.seq == i));
          }
          if (this.selectItems.length < 2) {
            this.selectItem = JSON.parse(JSON.stringify(this.selectItems[0]))
            this.selectItems = []
            if (Object.keys(data[0])[0] == 'pid') {
              this.showSelectProduct(this.selectItem.pid)
            } else {
              this.saveProcess()
            }
          } else {
            this.onActionMulti()
          }
        },
        beforeDelete: ({ data, selectionRangeIndexes, selectionRangeKeys }) => {
          // console.log(data[0])
          // 삭제전 처리문
        },
        afterDelete: ({ data, selectionRangeIndexes, selectionRangeKeys }) => {
          this.selectItems = []
          for (var i = selectionRangeKeys.startRowKey; i <= selectionRangeKeys.endRowKey; i++) {
            this.pushItems(this.tmpTableDataMe.find(item => item.seq == i));
          }
          if (this.selectItems.length < 2) {
            if (this.selectColumn = 'pid')
              this.selectItem.baseId = null,
                this.selectItem.baseInfo = null,
                this.selectItem.salesProductId = null,
                this.selectItem.salesProductInfo = null,
                this.saveProcess()
          } else {
            this.selectItems.map(selectItem => {
              if (!selectItem.pid) {
                selectItem.baseId = null
                selectItem.baseInfo = null
                selectItem.salesProductId = null
                selectItem.salesProductInfo = null
              }
            })
            this.onActionMulti()
          }
        }
        // 삭제후 품번변동시 기본정보 삭제

      },
      clipboardOption2: {  // 이지테이블 복사가능 ,붙여넣기,잘라내기,삭제 안됨
        copy: true,
        paste: true,
        cut: true,
        delete: true,
        beforeCopy: ({ data, selectionRangeIndexes, selectionRangeKeys }) => {
          // alert('정보가 복사되었습니다.');
        },
        beforePaste: ({ data, selectionRangeIndexes, selectionRangeKeys }) => {
          this.backupData()
          const fieldsName = Object.keys(data[0])[0]
          const fieldsValue = Object.keys(data[0])[1]
          this.selectItem[fieldsName] = fieldsValue
        },
        afterPaste: ({ data, selectionRangeIndexes, selectionRangeKeys }) => {
          this.selectItems = []
          for (var i = selectionRangeKeys.startRowKey; i <= selectionRangeKeys.endRowKey; i++) {
            this.pushItems(this.tmpTableData.find(item => item.seq == i));
          }
          if (this.selectItems.length < 2) {
            this.selectItem = this.selectItems[0] //JSON.parse(JSON.stringify(this.selectItems[0]))
            this.selectItems = []
            if (Object.keys(data[0])[0] == 'pid') {
              this.showSelectProduct(this.selectItem.pid)
            } else {
              this.saveProcess()
            }
          } else {
            this.onActionMulti()
          }
        },
        beforeDelete: ({ data, selectionRangeIndexes, selectionRangeKeys }) => {
          // console.log(data[0])
          // 삭제전 처리문

        },
        afterDelete: ({ data, selectionRangeIndexes, selectionRangeKeys }) => {
          this.selectItems = []
          for (var i = selectionRangeKeys.startRowKey; i <= selectionRangeKeys.endRowKey; i++) {
            this.pushItems(this.tmpTableDataMe.find(item => item.seq == i));
          }
          if (this.selectItems.length < 2) {
            if (this.selectColumn = 'pid')
              this.selectItem.baseId = null,
                this.selectItem.baseInfo = null,
                this.selectItem.salesProductId = null,
                this.selectItem.salesProductInfo = null,
                this.saveProcess()
          } else {
            this.onActionMulti()
          }

        }
        // 삭제후 품번변동시 기본정보 삭제

      },
      // tables
      totalCount: null,
      tmpTableDataMe: [],
      backupTableDataMe: [],
      backupTableData: [],
      tmpTablecolumns: [
        {
          field: "ID", key: "ID", title: "ID", width: 50, align: "center", fixed: "left",
          operationColumn: true,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (undefined == row.lineId) { row.lineId = JSON.parse(JSON.stringify('noSave99999' + row.seq)) }
            return row.seq
          },
        },
        {// 상품등록버튼
          field: "regProdButton", key: "regProdButton", title: this.$t('PR'), width: 80, align: "center", fixed: "left",
          operationColumn: true,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const saleStatus = row?.salesProductInfo ?
              row?.salesProductInfo?.saleStatus : 0
            row.saleStatus = saleStatus
            let regButtonTitle = this.saleProcess.find(item => item.value == saleStatus)
            if ((row.partsName && row.pid && row.minQty && row.invenQty && row.inPrice && row.priceD && !row.salesProductInfo?.ecountId) || row.seq == row.salesProductInfo?.bizMainId
            ) {
              return (
                <span class='middlebadge-light font12 hand' onClick={this.showRegProdbutton} >
                  {regButtonTitle.label}
                </span>
              )
            } else {
              return (
                <span class='middlebadge-light font12 colorgray'>
                  {regButtonTitle.label}
                </span>
              )
            }
          },
        },
        {
          field: "creatorName", key: "creatorName", title: this.$t('OWNER'), align: "center",
          width: 80, fixed: "left",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            row.creatorName = this.userlogininfo.staffInfo.find(item => item.seq == row.creator).name
            return row.creatorName
          },
        },
        {
          field: "createdName", key: "createdName", title: this.$t('CREATED'), align: "center",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (undefined != row.created) {
              row.createdName = JSON.parse(JSON.stringify(row.created.substr(0, 10)))
              return row.createdName
            }
          },
        },
        {
          field: "updatedName", key: "updatedDateName", title: this.$t('UPDATED'), align: "center",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (undefined != row.updated) {
              row.updatedName = JSON.parse(JSON.stringify(row.updated.substr(0, 10)))
              return row.updatedName
            }
          },
        },
        {
          field: "customerSale", key: "customerSale", title: this.$t('PUR'), align: "left",
          width: 80,
          ellipsis: { showTitle: true },
          edit: true,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (row.customerSale == undefined) { row.customerSale = '' }
            return (
              <span class="fontsize13">
                <span class="ml-2 fontcolorgraynormal"></span>
                <span class={this.getfontcolor(row)}>
                  {' ' + row.customerSale}
                </span>
              </span>
            )
          },
        },
        {
          field: "partsNum", key: "partsNum", title: this.$t('VIN'), align: "left",
          width: 100,
          ellipsis: { showTitle: true },
          edit: true,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (row.partsNum == undefined || !row.partsNum) { row.partsNum = '' }
            row.partsNum = this.$onlyEngNum(row.partsNum.toUpperCase())
            if (row.partsNum == undefined) { row.partsNum = '' }
            return (
              <span class={this.getfontcolor(row)}>
                {row.partsNum}
              </span>
            )
          },
        },
        {
          field: "model", key: "model", title: this.$t('MN'), align: "left",
          width: 100,
          edit: true,
          ellipsis: { showTitle: true },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (null === row.model) { row.model = '' }
            return (
              <span class={this.getfontcolor(row)}>
                {row.model}
              </span>
            )
          },
        },
        {
          field: "partsName", key: "partsName", title: this.$t('PN'), align: "left",
          width: 200,
          edit: true,
          ellipsis: { showTitle: true },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (row.partsName == undefined) { row.partsName = '' }
            return (
              <span class={this.getfontcolor(row)}>
                {row.partsName}
              </span>
            )
          },
        },
        {
          field: "pid", key: "pid", title: "🔍" + this.$t('PID'), align: "left",
          width: 130,
          edit: true,
          ellipsis: { showTitle: false },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (row.pid == undefined || !row.pid) { row.pid = '' }
            row.pid = this.$onlyEngNum(row.pid.toUpperCase())
            if (null == row.baseId) {
              this.pidClass = 'fa fa-solid fa-magnifying-glass ml-1 fontcolorgraynormal'
            } else {
              this.pidClass = 'fa fa-solid fa-check ml-1 fontcolorgraynormal'
            }
            return (
              <span>
                <span class={this.pidClass}></span>
                <span class={this.getfontcolor(row)}>
                  {' ' + row.pid}
                </span>
              </span>
            )
          },
        },
        {
          field: "factoryNum", key: "factoryNum", title: this.$t('FACTORYNUM'), align: "left",
          width: 100,
          edit: true,
          ellipsis: { showTitle: true },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (row.factoryNum == undefined || !row.factoryNum) { row.factoryNum = '' }
            row.factoryNum = this.$onlyEngNumFuhao(row.factoryNum.toUpperCase())
            if (row.factoryNum == undefined) { row.factoryNum = '' }
            return (
              <span class={this.getfontcolor(row)}>
                {row.factoryNum}
              </span>
            )
          },
        },
        {
          field: "type", key: "type", title: this.$t('TYPE'), align: "center",
          width: 70,
          edit: false,
          ellipsis: { showTitle: false },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const tmpTypeName = this.$productType.find(typeItems => typeItems.value == row.type).label
            return (
              <span class={this.getfontcolor(row)}>
                {this.$t(tmpTypeName)}
              </span>
            )
          }
        },
        {
          field: "customerBuy", key: "customerBuy", title: this.$t('SPR'), align: "left", width: 130, edit: true,
          ellipsis: { showTitle: true },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span class={this.getfontcolor(row)}>
                {row.customerBuy}
              </span>
            )
          },
        },
        { //  최소수량
          field: "minQty", key: "minQty", title: this.$t('MQTY'), align: "left",
          edit: true,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (!row.minQty) { row.minQty = 0 }
            return (
              <span class={this.getfontcolor(row)}>
                {row.minQty}
              </span>
            )
          },
        },
        {
          field: "invenQty", key: "invenQty", title: this.$t('SQTY'), align: "left", width: 60, edit: true,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (!row.invenQty) { row.invenQty = 0 }
            return (
              <span class={this.getfontcolor(row)}>
                {row.invenQty}
              </span>
            )
          },
        },
        {
          field: "inPrice", key: "inPrice", title: this.$t('WP'), align: "left", width: 60, edit: true,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (!row.inPrice) { row.inPrice = 0 }
            return (
              <span class={this.getfontcolor(row)}>
                {this.$comma(row.inPrice)}
              </span>
            )
          },
        },
        {
          field: "priceA", key: "priceA", title: this.$t('RP') + 'A', align: "left", width: 70, edit: true,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (!row.priceA) { row.priceA = 0 }
            return (
              <span class={this.getfontcolor(row)}>
                {this.$comma(row.priceA)}
              </span>
            )
          },
        },
        {
          field: "priceB", key: "priceB", title: this.$t('RP') + 'B', align: "left", width: 70, edit: true,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (!row.priceB) { row.priceB = 0 }
            return (
              <span class={this.getfontcolor(row)}>
                {this.$comma(row.priceB)}
              </span>
            )
          },
        },
        {
          field: "priceC", key: "priceC", title: this.$t('RP') + 'C', align: "left", width: 70, edit: true,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (!row.priceC) { row.priceC = 0 }
            return (
              <span class={this.getfontcolor(row)}>
                {this.$comma(row.priceC)}
              </span>
            )
          },
        },
        {
          field: "priceD", key: "priceD", title: this.$t('RP') + 'D', align: "left", width: 70, edit: true,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (!row.priceD) { row.priceD = 0 }
            return (
              <span class={this.getfontcolor(row)}>
                {this.$comma(row.priceD)}
              </span>
            )
          },
        },
        {
          field: "storageId", key: "storageId", title: this.$t('STORAGE'), align: "center", width: 100,
          edit: false,
          ellipsis: { showTitle: true },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            let baseStorageInfo = this.userlogininfo.storageInfo.find(item => { return item.bizUsage == 0 }
            )
            if (row.storageId) { baseStorageInfo = row.storageInfo }
            return (
              <span class={this.getfontcolor(row)}>
                {baseStorageInfo?.name}
              </span>
            )
          },
        },
        {
          field: "memo", key: "memo", title: this.$t('MEMO'), align: "left", width: 100, edit: true,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (!row.memo) { row.memo = '' }
            return (
              <span class={this.getfontcolor(row)}>
                {row.memo}
              </span>
            )
          },
        },
        {
          field: "detailInfo", key: "detailInfo", title: this.$t('DETAIL'), align: "left", edit: true,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            if (!row.detailInfo) { row.detailInfo = '' }
            return (
              <span class={this.getfontcolor(row)}>
                {row.detailInfo}
              </span>
            )
          },
        },
      ],
      showocr: false,

    }
  },

  created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    // this.read()
  },
  methods: {
    ocrShow() {
      this.showocr = true
    },
    ocrCallback() {
      this.showocr = false
    },
    getfontcolor(row) {
      let fontColor = ''
      if (row.salesProductInfo?.ecountId) { fontColor = 'colororangelight' }
      if (row.salesProductInfo?.bizMainId) { fontColor = 'colorGreen' }
      if (row.salesProductInfo?.seoId) { fontColor = 'colorFink' }
      if (row.salesProductInfo?.bizMainId && row.salesProductInfo?.bizMainId == row.seq) { fontColor = 'colorbluedeep' }
      return fontColor
    },

    insertUpdatebox(item) {
      this.selectItem = item
      this.cratevisible = true
    },

    showProductTypeWindow() {
      this.showProductType = true
    },

    productTypeCallback(data) {
      this.showProductType = false
      if (data.status) {
        this.selectItem.type = data.item.value
        this.onAction()
      }
    },

    createcallback(data) {
      this.cratevisible = false
      if (data) {
        this.read()
      }
    },

    async readMe() {
      const req = {
        objSearchMapper: {
          searchKeyword: null,
          creator: this.tmpCreator
        },
        page: 1,
        size: 100,
      }
      this.$http.post(this.$serverurl + "/api/getBizMainInfo", req, this.$setHeader())
        .then(res => {
          if (res.data.code != 200) {
            alert(res.data.message)
            return
          }
          this.totalCount = res.data.body.totalCount
          this.backupTableDataMe = res.data.body.results
          // 리스트 거꾸로 뒤집는 문구
          this.backupTableDataMe = this.backupTableDataMe.slice().reverse();
          this.tmpTableDataMe = this.backupTableDataMe
          setTimeout(() => {
            this.addtmpline()
          }, 100);
        })
        .catch(error => {
          if (error.response != undefined)
            this.$notifyVue("danger", error.response.data.msg)
          else
            console.log(error)
        })
    },

    async read(params) {
      if (!this.request.objSearchMapper.searchKeyword) {
        this.tmpTableData = []
        return
      }
      // this.request.size=100
      switch (params) {
        case 'searchDate': {
          this.request.objSearchMapper.startDate = this.$dateToYYYYMMDD(this.startDate)
          this.request.objSearchMapper.endDate = this.$dateToYYYYMMDD(this.endDate)
          this.request.size = 500
          break;
        }
        case 'searchUserAll': {
          // 정보검색: 사용자아이디로 모두검색
          this.request.objSearchMapper.searchKeyword = null
          break;
        }
        case 'searchUser': {
          // 정보검색: 사용자아이디로 검색
          const userInfo = this.tmpAccountInfo.filter(user => {
            return user.seq == this.request.objSearchMapper.creator
          })
          this.creatorUserName = userInfo[0]?.name
          this.request.objSearchMapper.startDate = null
          this.request.objSearchMapper.endDate = null
          this.searchDate = null
          break;
        }
        case 'search': {
          // 정보검색: 사용자아이디 백업 및 지움
          this.request.objSearchMapper.creator = null
          break;
        }
        case 'all': {
          // 모두보기: 
          this.request.objSearchMapper.creator = null
          this.request.objSearchMapper.searchKeyword = null
          this.request.objSearchMapper.startDate = null
          this.request.objSearchMapper.endDate = null
          break;
        }
        case 'back': {
          this.request.objSearchMapper.startDate = null
          this.request.objSearchMapper.endDate = null
          this.searchDate = null
          this.request.objSearchMapper.searchKeyword = null
          break;
        }
        default: {
          // 개인별 보기
          this.request.objSearchMapper.searchKeyword = null
          this.request.objSearchMapper.creator = this.tmpCreator
          break
        }
      }
      this.request.objSearchMapper.searchKeyword = this.request.objSearchMapper?.searchKeyword?.trim()
      // this.request.objSearchMapper.companyId = this.userlogininfo.companyId
      this.request.size = this.request.sizes
      this.$http.post(this.$serverurl + "/api/getBizMainInfo", this.request, this.$setHeader())
        .then(res => {
          if (res.data.code != 200) {
            alert(res.data.message)
            return
          }
          this.totalCount = res.data.body.totalCount
          this.backupTableData = res.data.body.results
          // 리스트 거꾸로 뒤집는 문구
          this.backupTableData = this.backupTableData.slice().reverse();
          this.tmpTableData = this.backupTableData
          this.request.objSearchMapper.searchKeyword = null
          // setTimeout(() => {
          //   this.addtmpline()
          // }, 100);
        })
        .catch(error => {
          if (error.response != undefined)
            this.$notifyVue("danger", error.response.data.msg)
          else
            console.log(error)
        })
    },
    addnewline() {
      const data = { lines: 30 }
      this.$http.post(`${this.$serverurl}/api/setBizMainNewLineInfo`, data, this.$setHeader()).then(res => {
        this.readMe()
      })
    },
    addtmpline() {
      this.modalByCreate.formData = [];
      while (this.newlineKeys < (this.newlineKey + 1)) {
        this.modalByCreateSubmit.formData.lineId = 'noSave' + this.newlineKeys
        this.modalByCreateSubmit.formData.updated = new Date().toISOString().substr(0, 10),
          this.backupTableDataMe.push(Object.assign({}, this.modalByCreateSubmit.formData))
        this.newlineKeys++;
      }
      this.newlineKey = this.newlineKeys
      this.tmpTableDataMe = this.backupTableDataMe
    },
    colhiddenFilter() { // 열 기본감춤 함수
      if (this.filter.colHiddenStatus == 0) {
      this.filter.colHiddenStatus = 1;
        this.filter.colHiddenTitle = 'ALLFIELD'
        try {
          this.$refs["dataList"].hideColumnsByKeys(['regProdButton']);
          this.$refs["dataList"].hideColumnsByKeys(['creatorName']);
          // this.$refs["dataList"].hideColumnsByKeys(['createdName']);
        this.$refs["dataListMe"].hideColumnsByKeys(['updatedDateName']);
          this.$refs["dataList"].hideColumnsByKeys(['updaterName']);
          this.$refs["dataList"].hideColumnsByKeys(['minQty']);
          this.$refs["dataList"].hideColumnsByKeys(['inQty']);
          // this.$refs["dataList"].hideColumnsByKeys(['inPrice']);
          this.$refs["dataList"].hideColumnsByKeys(['priceA']);
          this.$refs["dataList"].hideColumnsByKeys(['priceB']);
        } catch { }
        this.$refs["dataListMe"].hideColumnsByKeys(['regProdButton']);
        this.$refs["dataListMe"].hideColumnsByKeys(['creatorName']);
        // this.$refs["dataListMe"].hideColumnsByKeys(['createdName']);
        this.$refs["dataListMe"].hideColumnsByKeys(['updatedDateName']);
        this.$refs["dataListMe"].hideColumnsByKeys(['updaterName']);
        this.$refs["dataListMe"].hideColumnsByKeys(['minQty']);
        this.$refs["dataListMe"].hideColumnsByKeys(['inQty']);
        // this.$refs["dataListMe"].hideColumnsByKeys(['inPrice']);
        this.$refs["dataListMe"].hideColumnsByKeys(['priceA']);
        this.$refs["dataListMe"].hideColumnsByKeys(['priceB']);
      } else {
        this.filter.colHiddenStatus = 0;
        this.filter.colHiddenTitle = 'BASEFIELD'
        try {
          this.$refs.dataList.showColumnsByKeys(['regProdButton']);
          this.$refs.dataList.showColumnsByKeys(['creatorName']);
          // this.$refs.dataList.showColumnsByKeys(['createdName']);
        this.$refs["dataListMe"].hideColumnsByKeys(['updatedDateName']);
          this.$refs.dataList.showColumnsByKeys(['updaterName']);
          this.$refs.dataList.showColumnsByKeys(['minQty']);
          this.$refs.dataList.showColumnsByKeys(['inQty']);
          this.$refs.dataList.showColumnsByKeys(['priceA']);
          this.$refs.dataList.showColumnsByKeys(['priceB']);
        } catch { }

        this.$refs.dataListMe.showColumnsByKeys(['regProdButton']);
        this.$refs.dataListMe.showColumnsByKeys(['creatorName']);
        // this.$refs.dataListMe.showColumnsByKeys(['createdName']);
        this.$refs["dataListMe"].hideColumnsByKeys(['updatedDateName']);
        this.$refs.dataListMe.showColumnsByKeys(['updaterName']);
        this.$refs.dataListMe.showColumnsByKeys(['minQty']);
        this.$refs.dataListMe.showColumnsByKeys(['inQty']);
        this.$refs.dataListMe.showColumnsByKeys(['priceA']);
        this.$refs.dataListMe.showColumnsByKeys(['priceB']);
      }
    },

    async showSelectProduct(searchKeyword) {
      const res = await this.readProductInfo(searchKeyword)
      if ('CANCEL' != res.data.statusCode && searchKeyword) {
        this.tmpProductLists = res.data.body.results
        this.tmpProductResult = res.data
        // if (this.tmpProductLists.length == 1) {
        //   this.selectproductcallback({
        //     item: this.tmpProductLists[0],
        //     status: true
        //   })
        // }
        if (this.tmpProductLists.length > 0) {
          this.showProductList = true
        } else {
          if (!this.selectItem.baseId) this.saveProcess()
        }
      }
      else {
        this.saveProcess()
      }
    },
    async readProductInfo(searchKeyword) {
      const req = {
        objSearchMapper: {
          searchKeyword: searchKeyword
        }, page: 1, size: 10
      }
      return await this.$http.post(`${this.$serverurl}/api/getSalesProductInfo`, req)
    },

    selectproductcallback(data) {
      if (data.status && data.item) {
        this.selectItem.partsName = data.item.name
        this.selectItem.pid = data.item.pid
        this.selectItem.factoryNum = data.item.factoryNum
        this.selectItem.minQty = data.item.minAmount
        this.selectItem.invenQty = data.item.hasAmount
        this.selectItem.inPrice = data.item.inPrice
        this.selectItem.priceA = data.item.priceA
        this.selectItem.priceB = data.item.priceB
        this.selectItem.priceC = data.item.priceC
        this.selectItem.priceD = data.item.priceD
        this.selectItem.storageId = data.item.storageId
        this.selectItem.storageInfo = data.item.storageInfo
        this.selectItem.salesProductInfo = JSON.parse(JSON.stringify(data.item))
        this.selectItem.salesProductId = data.item.seq
        this.selectItem.baseId = data.item.baseId
        this.selectItem.baseInfo = data.item.baseInfo
        this.selectItem.type = data.item.type
      }
      this.saveProcess()
      this.showProductList = false
    },
    saveProcess() {
      //행별 항목 저장
      if (this.selectItem && this.selectItem.pid)
        this.selectItem.pid = this.selectItem.pid.replace(/[^a-zA-Z0-9]/g, '')   // 알파문자외 기타문자 필터링
      if (this.selectItem.pid && this.selectItem.pid.length > 20) {
        this.selectItem.pid = this.selectItem.pid ? this.selectItem.pid.substring(0, 20) : null
      }

      this.onAction()
    },
    onActionMulti() {
      this.saveMessage = '저장중입니다...'
      try {
        this.$http.post(this.$serverurl + "/api/setBizMainInfo", this.selectItems, this.$setHeader())
          .then(res => {
            if (201 != res.data.code) {
              this.saveMessage = res.data.message
              return;
            }
            // this.selectItem = res.data.body.results
            // this.saveMessage = res.data.message
            this.selectItems = []
            this.readMe()
          })
          .catch(error => {
            console.log(error)
            this.saveMessage = '오류 요청이 반영되지않았습니다.'

          })
        setTimeout(() => {
          this.saveMessage = ''
        }, 1000)
      } catch (error) {
        console.log(error)

      }
    },
    onAction() {
      this.saveMessage = '저장중입니다...'
      try {
        this.$http.post(this.$serverurl + "/api/setBizMainInfo", this.selectItem, this.$setHeader())
          .then(res => {
            if (201 != res.data.code) {
              this.saveMessage = res.data.message
              return;
            }
            this.selectItem = res.data.body.results
            this.saveMessage = res.data.message
            this.readMe()
          })
          .catch(error => {
            console.log(error)
            this.saveMessage = '오류 요청이 반영되지않았습니다.'

          })
        setTimeout(() => {
          this.saveMessage = ''
        }, 1000)
      } catch (error) {
        console.log(error)

      }
    },
    setBizToProductInfo() {
      this.saveMessage = '저장중입니다...'
      if (!this.selectItem.inPrice) { alert('입고단가를 설정하세요.'); return }
      if (!this.selectItem.priceD) { alert('판매단가를 설정하세요.'); return }
      try {
        this.$http.post(this.$serverurl + "/api/setBizToProductInfo", this.selectItem, this.$setHeader())
          .then(res => {
            if (201 != res.data.code) {
              this.saveMessage = res.data.message
              return
            }
            this.selectItem.saleSatus = res.data.body.results.saleStatus
            this.selectItem.salesProductId = res.data.body.results.salesProductId
            this.selectItem.salesProductInfo = res.data.body.results.salesProductInfo
            this.saveMessage = res.data.message
            // this.read()
          })
          .catch(error => {
            console.log(error)
            this.saveMessage = '오류 요청이 반영되지않았습니다.'

          })
        setTimeout(() => {
          this.saveMessage = ''
        }, 1000)
      } catch (error) {
        console.log(error)

      }
    },
    showRegProdbutton() {
      this.showRegProductList = true
    },
    regProductCallback(data) {
      if (data.status) {
        this.selectItem.saleStatus = data.item.value
        this.setBizToProductInfo()
      }
      this.showRegProductList = false
    },

    onKeyDown(event) {
      if (event.ctrlKey && event.code === 'KeyZ') {
        // ctrl + z 조합이 감지되면, 이전 상태로 롤백
        if (this.historyStack.length > 0) {
          this.tmpTableDataMe = JSON.parse(JSON.stringify(this.historyStack));
        }
      }
    },
    backupData() {
      // 이전 상태를 저장
      this.historyStack = JSON.parse(JSON.stringify(this.tmpTableDataMe));
    },
    closeDrawer() {
      this.tmpTableData = []
    },
    rePosition() {
      if (this.$refs.drawer.style.maxHeight != '200px') {
        this.$refs.drawer.style.maxHeight = '200px'
      } else {
        this.$refs.drawer.style.maxHeight = 'calc(50vh - 50px)'

      }
    },
    pushItems(item) {
      // 중복 여부를 확인하기 위한 함수
      const isDuplicate = this.selectItems.some(existingItem =>
        existingItem.seq === item.seq);

      // 중복되지 않은 경우에만 항목을 추가합니다.
      if (!isDuplicate) {
        this.selectItems.push(item);
      }
    }
  },
  directives: {
    drag: {
      bind: function (el) {
        let oDiv = el;
        oDiv.onmousedown = (e) => {
          let disY = e.clientY - (window.innerHeight - oDiv.offsetTop); // 하단에서의 거리를 계산합니다.
          document.onmousemove = (e) => {
            let bottom = window.innerHeight - e.clientY - disY; // bottom 속성을 계산합니다.
            let top = window.innerHeight - e.clientY - disY; // top 속성도 같은 값을 사용합니다.

            oDiv.style.bottom = bottom + 'px'; // bottom 속성을 설정합니다.
            oDiv.style.top = top + 'px'; // top 속성도 같은 값을 사용합니다.
          };
          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
  },
}
</script>
<style>
.drawer {
  position: fixed;
  bottom: 0;
  left: 16px;
  /* 수정: 오른쪽에 배치합니다. */
  width: calc(100vw - 32px);
  height: calc(50vh - 50px);
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10000;
  padding: 30px;
  padding-top: 10px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  /* 수정: 그림자를 오른쪽에 표시합니다. */
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  /* 수정: 오른쪽으로 이동하도록 변경합니다. */
}


.ve-table-container::-webkit-scrollbar {
  /* 스크롤바 스타일 지정 */
  height: 20 !important;
  width: 20 !important;
  display: block !important;
}

.ve-table-cell-indicator-active {
  background-color: rgba(150, 150, 155, 1) !important;
}

.ve-table .ve-table-container .ve-table-border-x tr:first-child>th,
.ve-table .ve-table-container .ve-table-border-x tr.ve-table-footer-tr:first-child>td {
  border-top: 3px rgba(155, 176, 190, 1) solid !important;
}

.ve-table .ve-table-container .ve-table-border-y th,
.ve-table .ve-table-container .ve-table-border-y td {
  border-right: 1px rgb(212, 212, 212) solid !important;
  border-bottom: 1px solid rgb(212, 212, 212);
  border-bottom: 1px solid rgb(212, 212, 212);
}

.ve-table .ve-table-container .ve-table-border-y th:first-child,
.ve-table .ve-table-container .ve-table-border-y td:first-child {
  border-left: 1px solid rgb(212, 212, 212);
}

.ve-table-body-td,
.ve-table-header-th {
  font-size: 12px !important;
  font-family: '맑은 고딕', 'Microsoft YaHei';
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  /* cursor: url("excel.png"), auto; */
  font-weight: bold !important;
  max-height: 30px !important;
}

/* .ve-table-header-tr {
  height: 30px !important;
}
.ve-table-body-tr {
  height: 30px !important;
} */


.ve-table .ve-table-container .ve-table-border-y th,
.ve-table .ve-table-container .ve-table-border-y td {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content thead.ve-table-header tr.ve-table-header-tr th.ve-table-header-th.ve-table-cell-indicator-active {
  background-color: rgba(0, 123, 255, 0.1) !important;
  color: #000 !important;
}

.ve-table-cell-indicator-active {
  background-color: rgba(0, 123, 255, 0.1) !important;
  color: #000 !important;
}
</style>
