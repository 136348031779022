<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'1000px'" :modal=true :show-close="false"
    :before-close="handleClose" v-draggable>
    <card>
      <el-row :gutter="10">
        <el-col :xl="24" :md="24">
          <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13"
          >
            <el-table-column label="ID" width="80" :align="'center'">
              <template slot-scope="scope">
                {{ scope.row.seq }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('TYPE')" width="100" :align="'center'">
              <template slot-scope="scope">
                {{ $t($accountBookType.find(item=>
                item.value== scope.row.mark).label) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('AMOUNT')" width="120" :align="'right'">
              <template slot-scope="scope">
                <span v-if="scope.row.pointPlus">
                  {{ $comma(scope.row.pointPlus) }}
                </span>
                <span v-else>
                  -{{ $comma(scope.row.pointMinus) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('BAL')" width="100" :align="'center'">
              <template slot-scope="scope">
                {{ $comma(scope.row.pointResidual) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('TXN.NO.')" width="180" :align="'center'">
              <template slot-scope="scope">
                <span v-if="scope.row.mark=='order'">
                  {{ scope.row.parentSn }}
                </span>
                <span v-if="scope.row.mark=='point'">
                  {{ scope.row.paymentSn }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('MEMO')">
              <template slot-scope="scope">
                {{ scope.row.memo }}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
            :page-size="request.size" layout="sizes, prev, pager, next" :total="totalCount">
          </el-pagination>
        </el-col>
      </el-row>
  
    </card>
    <div class="text-right">
      <el-button size="mini" type="" @click="handleClose">
        {{$t('CLOSE')}}</el-button>
    </div>
    <!-- <json-viewer :value="ioInfoList" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  </el-dialog>
</template>
<script>
export default {
  name: 'mallmanagerusedpointlist',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.itemInfo = newValue
        this.title = `${newValue.name}입출기록`
        this.request.objSearchMapper.companyId = newValue.seq
        this.read()

      }
    },
  },
  data() {
    return {
      visible: true,
      title: '',
      totalCount: 0,
      itemInfo: {},
      tmpTableData: [],
      request: {
        objSearchMapper: {
          searchKeyword: null,
          companyId: null,
        },
        page: 1,
        size: 15
      },
      pageSizes: [15, 50, 100],
    }
  },
  async created() {
  },
  methods: {
    notClose() { },
    handleUpdateClose() {
      const data = {
        item: this.itemInfo,
        status: true
      }
      this.$emit('callback', data)
    },
    handleClose() {
      const data = {
        status: false
      }
      this.$emit('callback', data)
    },

    async read() {
      await this.$http.post(`${this.$serverurl}/api/getMallPointAccountInfo`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.count = res.data.body.count
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
          } else {
            this.tmpTableData = []
          }
        })
    },
    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }
  }
}
</script>
<style></style>