<template>
  <div class="container mt-3 relative" :style="$pageWidth">
    <TopBar />
    <div class="mt-2">
      <el-button size="mini" v-for="item in salePrdPermis" :key="item.value"
        :type="activeName == item.value ? 'primary' : ''" @click="handleButtons(item.value)">
        {{ $t(item.label) }}
        ({{ count.length && count[item.value].count }})</el-button>
      <div class="mt-2">
        <salemanagerlist :salePermi="activeName" @callback="createcallback" />
      </div>
  
  
    </div>
  </div>
</template>
<script>

import TopBar from './component-top.vue'
import salemanagerlist from './component-sale-manager-list.vue'
export default {
  components: {
    TopBar, salemanagerlist
  },
  data() {
    return {
      viewvisible: false,
      selectItem: null,
      activeName: 1,
      count: [],
      salePrdPermis: [],
    }
  },
  created() {
    this.$salePrdPermis.map(item => {
      // console.log(item.value)
      if (item.value != 0) {
        this.salePrdPermis.push(item)
      }
    })
  },
  methods: {
    createcallback(data) {
      if (data.status) {
        this.count = data.item.body.count
      }
    },
    handleButtons(params) {
      this.activeName = params;
      this.selectItem = null
    },

  }
}
</script>
<style></style>
