<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%;margin-bottom:10px;">
      <el-row :gutter="10">
        <el-col :md="12">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="searchKeyword" style="max-width: 200px;" class="mr-2"
            @keyup.enter.native="searchRead(searchKeyword)">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="searchRead(searchKeyword)"></i>
          </el-input>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="searchRead(searchKeyword)">
          {{ $t('SEARCH') }}
          </el-button>
          <el-button type="primary" icon="el-icon-tickets" size="mini" v-if="searchKeyword"
            @click="searchRead(null)">{{ $t('VIEWALL') }}</el-button>
        </el-col>
        <el-col :md="12" class="right">
          <el-button type="primary" :icon="request.objSearchMapper.state ? 'el-icon-success' : 'el-icon-delete'"
            size="mini" @click="searchStateRead(request.objSearchMapper.state ? 0 : 1)">
            {{ request.objSearchMapper.state ? $t('NU') : $t('SU') }}</el-button>
        </el-col>
      </el-row>
    </card>
    <div>

      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
        :page-size="request.size" layout="total, sizes, prev, pager, next" :total="totalCount">
      </el-pagination>

      <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13">
        <el-table-column label="ID" width="80" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.seq }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('ACCOUNT')" width="150" :align="'left'">
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('NAME')" width="120" :align="'left'">
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('ACCOUNTC')" width="200" :align="'left'">
          <template slot-scope="scope">
            {{ scope.row.mallCompanyInfo ? scope.row.mallCompanyInfo.name : '-' }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('TEL')" width="100" :align="'center'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.phone || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('MAIL')" width="150" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.mail || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('AIP')" :align="'left'">
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.availableIp || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('STATUS')" width="80" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.state == 0 ? $t('N') : $t('S') }}
          </template>
        </el-table-column>
        <el-table-column label="수정" width="80" :align="'center'">
          <template slot="header">
            <i class="fas fa-user-edit"></i>
          </template>
          <template slot-scope="scope">
            <i class="fas fa-user-edit hand" @click="insertUpdatebox(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column label="정지/삭제" width="50" :align="'right'">
          <template slot="header">
            <i :class="'fas fa-trash-alt'"></i>
          </template>
          <template slot-scope="scope">
            <i class="hand" :class="scope.row.state == 0 ? 'fas fa-trash-alt' : 'fas fa-trash'"
              @click="stateinsertUpdatebox(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
        :page-size="request.size" layout="total, sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
    </div>
    <malllmemberCreate v-if="createvisible" :data="selectItem" @callback="createCallback" />
    <yesNoBox v-if="yesNoBoxvisible" @callback="deletecallback" :title="selectItem.name" :data="yesNoBoxContent" />
  </div>
</template>
<script>
import yesNoBox from './component-alert-continue.vue'
import malllmemberCreate from './component-mall-member-create.vue'
export default {
  name: 'mallmanagermember',
  components: {
    yesNoBox, malllmemberCreate
  },
  data() {
    return {
      createvisible: false,
      yesNoBoxvisible: false,
      yesNoBoxContent: {},
      selectItem: null,
      pageSizes: [15, 50, 100],
      searchKeyword: '',
      request: {
        objSearchMapper: {
          searchKeyword: '',
          state: 0,
        },
        page: 1,
        size: 15
      },
      tmpTableData: [],
      totalCount: 0,
    }
  },

  created() {
    this.read()
  },
  methods: {
    insertUpdatebox(item) {
      this.selectItem = item
      this.createvisible = true
    },
    createCallback(data) {
      if (data.status) {
        this.searchRead()
      }
      this.createvisible = false
    },
    stateinsertUpdatebox(item) {
      this.selectItem = item
      this.yesNoBoxvisible = true
      if (item.state) {
        this.yesNoBoxContent = {
          content: this.$t('Would you like to restore the item?'),
          contentSub: this.$t('The information is being used normally.')
        }
      } else {
        this.yesNoBoxContent = {
          content: this.$t('Would you like to delete the item?'),
          contentSub: this.$t('The information is being used normally.')
        }
      }
    },
    deletecallback(data) {
      if (data) {
        this.satateInfo()
      }
      this.yesNoBoxvisible = false
    },

    searchStateRead(params) {
      this.request.objSearchMapper.state = params
      this.request.page = 1
      this.read()
    },

    searchRead(searchKeyword) {
      this.searchKeyword = searchKeyword
      this.request.page = 1
      this.request.objSearchMapper.searchKeyword = this.$trimLR(searchKeyword)
      this.read()
    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getMallMemberInfo`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
          } else {
            this.tmpTableData = []
          }
        })
    },

    async satateInfo() {
      const formData = { seq: this.selectItem.seq }
      await this.$http.post(`${this.$serverurl}/api/stateDeleteMallMemberInfo`, formData, this.$setHeader())
        .then(res => {
          if (201 == res.data.code) {
            alert(res.data.message)
            this.read()
          }
        })
    },

    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }

  }
}
</script>
<style></style>
