<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'600px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable>
    <card>
      <el-row>
        <el-col :xl="4" :md="4" class="lh25">정품품번*</el-col>
        <el-col :xl="20" :md="20">
          <el-input placeholder="정품품번" maxlength="200" v-model="formData.pid" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">호환품번*</el-col>
        <el-col :xl="20" :md="20">
          <el-input placeholder="호환품번" maxlength="200" v-model="formData.pidList" size="mini">
          </el-input></el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">비고</el-col>
        <el-col :xl="20" :md="20">
          <el-input type="textarea" placeholder="비고" maxlength="200" v-model="formData.memo" size="mini">
          </el-input></el-col>
      </el-row>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">창닫기</el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange" @click="setSubstitute">정보제출</el-button>

      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'substitutecreate',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.formData = {
          pid: newValue.pid || '',
          pidList: newValue.pidList || '',
          memo: newValue.memo || '',
          seq: newValue.seq || null,
        }
      }
    },
  },
  data() {
    return {
      visible: true,
      title: '호환품번등록',
      total: 0,
      formData: {
        pid:'',
        pidList:'',
        memo:'',
        seq: null,
        state:0
      },
    }
  },
  async created() {
  },
  methods: {
    async setSubstitute() {
      if (this.formData.pid.trim() == '') {
        alert('메인품번을 입력하세요.')
        return
      }
      if (this.formData.pidList.trim() == '') {
        alert('호환품번을 입력하세요.')
        return
      }
      const res = await this.$http.post(`${this.$serverurl}/api/setSubstitute`, this.formData)
      if (201 == res.data.code) {
        alert(res.data.message)
        this.setCallback()
      }

    },
    setCallback() {
      this.$emit('callback', true)
    },
    notClose() { },
    handleClose(done) {
      // done()
      this.$emit('callback', false)
    },

  }
}
</script>
<style></style>