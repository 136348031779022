<template>
  <div>
    <card style="margin-bottom:10px;">
      <table class="table-params w100p">
        <tbody>
          <tr>
            <th>{{ $t('C.C') }}</th>
            <th>{{ $t('ST') }}</th>
            <th>{{ $t('CT') }}</th>
            <th>{{ $t('RA') }}</th>
            <th>{{ $t('S.C') }}</th>
            <th>{{ $t('LP') }}</th>
          </tr>
          <tr>
            <td class="hand" @click="show('totalpoint')" :class="activeName=='totalpointlist'?'bold underline':''">
              {{ $comma(tmpTableData&&tmpTableData.totalPoint) }}
              <i class="el-icon-arrow-right"></i>
            </td>
            <td class="hand" @click="show('totalorder')" :class="activeName=='totalorderlist'?'bold underline':''">
              {{ $comma(tmpTableData&&tmpTableData.totalOrder) }}
              <i class="el-icon-arrow-right"></i>
            </td>
            <td>{{ $comma(tmpTableData&&tmpTableData.totalCancel) }}</td>
            <td>{{ $comma(tmpTableData&&tmpTableData.totalApproval) }}</td>
            <td>{{ $comma(tmpTableData&&tmpTableData.totalShipping) }}</td>
            <td class="hand" @click="show('totallicense')" :class="activeName=='totallicenselist'?'bold underline':''">
              {{ $comma(tmpTableData&&tmpTableData.totalLicense) }}
              <i class="el-icon-arrow-right"></i>
            </td>
          </tr>
          <tr>
            <th colspan="5" class="center">{{ $t('BAL') }}</th>
            <th rowspan="2"></th>
          </tr>
          <tr>
            <td colspan="5" class="center">{{ $comma(tmpTableData&&(
              tmpTableData.totalPoint - tmpTableData.totalOrder
              )) }}</td>
          </tr>
        </tbody>
      </table>
      <div class="text-right mt-2">
        <el-button v-if="submitButtonDisabled" size="mini" icon="fa fa-refresh" @click="read">
          새로고침</el-button>
      </div>
    </card>
    <component :is="activeName"></component>
    <div>
      <!-- <json-viewer :value="formData" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
    </div>
  </div>
</template>
<script>
import totalpointlist from './component-mall-manager-cash-count-total-point.vue'
import totalorderlist from './component-mall-manager-cash-count-total-order.vue'
import totallicenselist from './component-mall-manager-cash-count-total-license.vue'
export default {
  name: 'mallmanagercashcount',
  components: { totalpointlist, totalorderlist,totallicenselist },
  data() {
    return {
      submitButtonDisabled: false,
      tmpTableData: null,
      activeName: null,

    }
  },
  async created() {
    this.read()
  },
  methods: {
    show(activeName) {
      this.activeName = `${activeName}list`
    },
    async read() {
      this.activeName = null
      this.submitButtonDisabled = false
      await this.$http.post(`${this.$serverurl}/api/countUsedPoint`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.count = res.data.body.count
            this.tmpTableData = res.data.body.results
          } else {
            this.tmpTableData = []
          }
        })
      this.submitButtonDisabled = true
    },


  }
}
</script>
<style></style>