/*!

=========================================================
* Vue Light Bootstrap Dashboard - v1.4.0
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import LightBootstrap from './light-bootstrap-main'
// Plugins
import App from './App.vue'

import i18n from '@/i18n'

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import directive from './util/drag/directive'
Vue.use(directive)

// router setup
import routes from './routes/routes'

import Vue2Editor from 'vue2-editor';
Vue.use(Vue2Editor);

// plugin setup
Vue.use(VueRouter)
Vue.use(LightBootstrap)

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
})

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ko'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, { locale })

import 'vue-easytable/libs/theme-default/index.css';
import VueEasytable from 'vue-easytable';
Vue.use(VueEasytable);
// import { VeContextmenu } from "vue-easytable";
// Vue.use(VeContextmenu)

import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)
// ------------------------------------------------------
// -- custom
// ------------------------------------------------------

// custom-axios
import axios from 'axios';

axios.defaults.baseURL = 'http://127.0.0.1:7090/'
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.timeout = 100000;
axios.defaults.headers.get['Cache-Control'] = 'no-cache';
axios.defaults.headers.get['Pragma'] = 'no-cache';
Vue.prototype.$http = axios

// custom-cookies
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
Vue.$cookies.config("1d");

const jwt = module.require('jsonwebtoken');
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('nestJsToken')
  if ((token == null || jwt.decode(token)?.exp < Math.floor(new Date().getTime() / 1000))
    && to.fullPath !== '/login' && 
  to.fullPath !== '/joinus' && 
  to.fullPath !== '/loginPhoto' && 
  to.fullPath !== '/loginPhotoSub' && 
  to.fullPath !== '/login_administrator') {
    // console.log(to.fullPath)
    Vue.prototype.$loginUserInfo = null
    localStorage.removeItem('accessToken')
    localStorage.removeItem('nestJsToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userlogininfo')
    console.log('main.js: 로그인실패')
    next('/login');
  }
  next()
})


// custom-ag-grid
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

// custom-wt
import "@/assets/css/custom_wt.css";
import common from "@/assets/js/custom_wt.js";
Vue.use(common)


// ------------------------------------------------------
// -- Vue
// ------------------------------------------------------
/* eslint-disable no-new */
new Vue({
  i18n,
  el: '#app',
  render: h => h(App),
  router
})
