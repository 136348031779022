<template>
  <div class="container mt-3 relative" :style="$pageWidth">
    <TopBar />
    <div class="mt-2">
        <saleapply v-if="activeName" :storageId="activeName" @callback="invenlistcallback" />
      </div>
  </div>
</template>
<script>
import saleapply from './component-sale-apply.vue'
import TopBar from './component-top.vue'
export default {
  components: {
    TopBar, saleapply

  },
  data() {
    return {
      showinvendetailview: false,
      selectItem: null,
      activeName: 0,
      tmpStorageData: null,
      request: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 100
      },
    }
  },
  created() {
    this.readStorageInfo()
  },
  methods: {
    inveindetailcallback(data){
      this.showinvendetailview=false
    },
    invenlistcallback(data) { // 상세보기콜백
      this.showinvendetailview =true
      this.selectItem = data.item
    },
    async readStorageInfo() {
      await this.$http.post(`${this.$serverurl}/api/getStorageInfo`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.tmpStorageData = res.data.body.results
            this.activeName = this.tmpStorageData[0].seq
          }
        })
    },

    handleButtons(params) {
      this.activeName = params
    },

  }
}
</script>
<style></style>
