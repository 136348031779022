<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%">
      <el-row :gutter="10">
        <el-col :md="12" style="min-width:200px;">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="request.objSearchMapper.searchKeyword"
            @keyup.enter.native="read()" style="max-width:200px;">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="read()"></i>
          </el-input>
          <el-button class="ml-2" type="primary" icon="el-icon-search" size="mini" @click="read()">
            {{ $t('SEARCH') }}
          </el-button>
        </el-col>
        <el-col :md="12" class="right">
          <el-button size="mini" :type="'primary'" @click="replyRead()">
            {{ request.objSearchMapper.replyNull?$t('PC'):$t('UPRCS')  }}
          </el-button>
        </el-col>
      </el-row>
    </card>
    <div>
      <!-- <el-pagination class="mt-2 mb-2 text-right" layout="prev, pager, next" :total="totalCount">
                        </el-pagination> -->
      <template>
        <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13">
          <el-table-column :label="$t('DATE')" width="100" :align="'center'">
            <template slot-scope="scope">
              {{ $YYYYMMDD(scope.row.created) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('AU')" width="100" :align="'center'">
            <template slot-scope="scope">
              <div style="max-width: 300px;" class="ellip">
                {{ scope.row.mallMemberInfo && scope.row.mallMemberInfo.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('TYPE')" width="100" show-overflow-tooltip :align="'center'">
            <template slot-scope="scope">
              <div style="max-width: 300px;" class="ellip">
                {{ scope.row.category? '광고문의' : '제휴문의' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('TEL')" width="120" :align="'center'">
            <template slot-scope="scope">
              <div class="ellip">
                {{ scope.row.mallMemberInfo && scope.row.mallMemberInfo.phone }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('CONTENT')" width="300">
            <template slot-scope="scope">
              <div class="ellip">
                {{ scope.row.content }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="답글">
            <template slot-scope="scope">
              <div style="max-width: 100px" class="ellip">
                {{ scope.row.reply }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="수정" width="50" :align="'center'">
            <template slot="header">
              <i class="fas fa-pen-to-square"></i>
            </template>
            <template slot-scope="scope">
              <i class="fas fa-pen-to-square hand" @click="insertUpdatebox(scope.row)"></i>
            </template>
          </el-table-column>
          <!-- <el-table-column label="정지/삭제" width="50" :align="'right'">
                <template slot="header">
                  <i class="fas fa-trash-alt"></i>
                </template>
                <template slot-scope="scope">
                  <i class="hand" :class="scope.row.state==0?'fas fa-trash-alt':'fas fa-trash'"
                    @click="stateinsertUpdatebox(scope.row)"></i>
                </template>
              </el-table-column> -->
        </el-table>
      </template>
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-size="request.size"
        :page-sizes="[15, 50, 100]" layout="total, sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
    </div>
    <!-- // 등록-갱신창 -->
    <mallqnacreate v-if="createvisible" :data="selectItem" @callback="createcallback" />
    <yesNoBox v-if="yesNoBoxvisible" @callback="deletecallback" :title="selectItem.name" :data="{
                      content: ' 항목을 삭제 하시겠습니까?',
                      contentSub: '삭제한 항목은 복구할수 없습니다.'
                    }" />
  </div>
</template>
<script>
import mallqnacreate from './component-mall-qna-create.vue'
import yesNoBox from './component-alert-continue.vue'
export default {
  name: 'mallqna',
  components: {
    yesNoBox, mallqnacreate
  },
  data() {
    return {
      yesNoBoxvisible: false,
      createvisible: false,
      request: {
        objSearchMapper: {
          searchKeyword: '',
          replyNull: true,
        },
        page: 1,
        size: 15
      },

      // tables
      tmpTableData: [],
      totalCount: null,
    }
  },

  created() {
    this.read()
  },
  methods: {
    insertUpdatebox(item) {
      this.selectItem = item
      this.createvisible = true
    },
    createcallback(data) {
      if (data.status) {
        this.read()
      }
      this.createvisible = false
    },
    replyRead() {
      this.request.objSearchMapper.replyNull = this.request.objSearchMapper.replyNull ? false : true
      this.read()
    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getMallPartnerInq`, this.request, this.$setHeader())
        .then(res => {
          this.totalCount = res.data.body.totalCount
          this.tmpTableData = res.data.body.results
        })
    },


    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }

  }
}
</script>
<style></style>
