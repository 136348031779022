<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'800px'" :modal=true :show-close="false"
    :before-close="handleClose" v-draggable>
    <card>
      <el-row :gutter="10">
        <el-col :xl="6" :md="6">
          <div class="mb-2">
            <img style="width:150px" :src="itemInfo.imglogo" />
          </div>
        </el-col>
        <el-col :xl="18" :md="18">
          <div style="height:300px;overflow: hidden;overflow-y: auto;">
            <table class="table-params w100p">
              <tbody>
                <tr>
                  <th class="w150">{{  $t('MN') }}</th>
                  <td>{{itemInfo.data.name}}</td>
                </tr>
                <tr>
                  <th>{{ $t('MADEYEAR') }}</th>
                  <td>{{itemInfo.data.year}}</td>
                </tr>
                <tr v-for="item,key in itemInfo.data.maindata" :key="key">
                  <th>{{key}}</th>
                  <td>{{item}}</td>
                </tr>
              </tbody>
            </table>
          </div></el-col>
      </el-row>
    </card>
    <div class="text-right">
      <el-button size="mini" type="" @click="handleClose">
        {{$t('CLOSE')}}</el-button>
    </div>
    <!-- <json-viewer :value="ioInfoList" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  </el-dialog>
</template>
<script>
export default {
  name: 'mallmanagerepcvinview',
  props: ['data',],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        console.log(newValue)
        this.itemInfo = newValue
        this.title = newValue.data.vin
      }
    },
  },
  data() {
    return {
      visible: true,
      title: '상세보기',
      itemInfo: {},
    }
  },
  async created() {
  },
  methods: {
    notClose() { },
    handleClose() {
      this.$emit('callback', false)
    }
  }
}
</script>
<style></style>