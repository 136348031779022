<template>
  <div class="container noselect" style="padding-top: 30px !important;">
    <div class="absolute printrotate" style="
      top:150px;left:calc(100vw / 2 - 250px);
      font-size: 72px;color:rgb(255,10,10,0.05);transform: rotate(2deg);">
      {{$t('PLOID')}}
    </div>
    <div class="absolute printrotate" style="
      top:230px;left:calc(100vw / 2 - 100px);
      font-size: 72px;color:rgb(20,0,150,0.05);transform: rotate(2deg);">
      {{infoContent.user}}
    </div>
    <div class="sticky-bottom w100p p-2 text-right noprint" style="background-color: #efefef;">
      <el-button size="mini" class="btn-small" icon="fa fa fa-print" ref="submitbutton" @click="$doPrint()">
        {{ $t('PRINT') }}
      </el-button>
    </div>
    <div style="border-bottom:3px #000 solid;">
      <img :src="generateImage($t('PROFITSTATUS'), 150, 30, '30px', 25, 'bold')" alt="">
      <div style="border-bottom:1px #000 solid;margin-bottom: 2px;" class="mt-2"></div>
    </div>
    <div class="right font15">
      {{ `${$t('SETSECTION')}: ${$YYYYMMDD(infoContent.startDate)} ~ ${$YYYYMMDD(infoContent.endDate)}` }}
    </div>
    <div class="right font15">
      {{ `${$t('OUTINFO')}: ${infoContent.user} ${$YYYYMMDD(new Date().toISOString())}` }}
    </div>
    <table class="table-black w100p font15 mt-2">
      <thead>
        <tr>
          <th width="100" class="right">{{ $t('IN.T') }}</th>
          <th width="100" class="right">{{ $t('OUT.T') }}</th>
          <th width="100" class="right">{{ $t('INCOME') }}</th>
          <th width="100" class="right">{{ $t('OUTCOME') }}</th>
          <th width="100" class="right">{{ $t('PROFITAMOUNT') }}</th>
          <th width="100" class="right">{{ $t('PROFITPRO') }}</th>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="right">
            {{ $comma(tradeCount('orderInItemInfo')) }}{{$t('PARCEL')}}
          </td>
          <td class="right">
            {{ $comma(tradeCount('orderOutItemInfo')) }}{{$t('PARCEL')}}
          </td>
          <td class="right">
            {{ $comma(infoContent.inSum) }}
          </td>
          <td class="right">
            {{ $comma(infoContent.outSum) }}
          </td>
          <td class="right">
            {{ $comma(infoContent.outSum-infoContent.inSum) }}
          </td>
          <td class="right">
            {{ $comma((infoContent.outSum-infoContent.inSum)/infoContent.outSum*100) }}%
          </td>
          <td class="right"></td>
        </tr>
      </tbody>
    </table>
    <table class="table-black w100p font13 mt-2">
      <thead>
        <tr>
          <th rowspan="2">{{ $t('DATE') }}</th>
          <th rowspan="2">{{ $t('FACTORYNUM') }}</th>
          <th rowspan="2">{{ $t('ITEM') }}</th>
          <th colspan="3" class="center">{{ $t('SALEINFO') }}</th>
          <th colspan="3" class="center">{{ $t('ORIGINPRICE') }}</th>
        </tr>
        <tr>
          <th class="right">{{ $t('QTY') }}</th>
          <th class="right">{{ $t('PRICE') }}</th>
          <th class="right">{{ $t('SUBTOTAL') }}</th>
          <th class="right">{{ $t('QTY') }}</th>
          <th class="right">{{ $t('PRICE') }}</th>
          <th class="right">{{ $t('SUBTOTAL') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item,index in infoContent.content" :key="index">
          <td>{{ $YYYYMMDD_HHMM(item.created) }}</td>
          <td>{{ item.baseInfo.factoryNum }}</td>
          <td>{{ item.baseInfo.name }}</td>
          <td class="right">{{ $comma(item.outQty) }}</td>
          <td class="right">{{ $comma(item.avgOutPrice) }}</td>
          <td class="right">{{ $comma(item.totalOutAmount) }}</td>
          <td class="right">{{ $comma(item.inQty) }}</td>
          <td class="right">{{ $comma(item.avgInPrice) }}</td>
          <td class="right">{{ $comma(item.totalInAmount) }}</td>
        </tr>
      </tbody>
    </table>
    <div class="noprint " style="height:100px;"></div>
    <!-- <json-viewer :value="infoContent" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode';
export default {
  components: {
    'barcode': VueBarcode,
  },
  props: {

    // items: {
    //     type: Array,
    //     default: () => [] // data list 
    // },
    itemsPerPage: {
      type: Number,
      default: 6
    }
  },
  data() {
    return {
      infoContent: {},
    }
  },
  created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    this.infoContent = JSON.parse(localStorage.getItem('printInfo'))
  },
  computed: {
    total() {
      return this.infoContent.orderOutItemInfo.reduce((acc, cur) => acc + cur.outQty * cur.outPrice, 0);
    },
    totalPages() {
      return Math.ceil(this.infoContent.orderOutItemInfo.length / this.itemsPerPage);
    },

  },

  methods: {
    tradeCount(tradeType) {
      return this.infoContent.content.reduce((acc, item) => {
        return acc + item[tradeType].length
      }, 0)
    },
    generateImage(text, width, height, fontSize, point, bold) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) {
        console.error("캔버스 2D 컨텍스트를 가져올 수 없습니다.");
        return null;
      }
      canvas.width = width;
      canvas.height = height;
      // 배경 이미지
      context.fillStyle = '#ffffff'; // 또는 'white'로 설정 가능
      context.fillRect(0, 0, canvas.width, canvas.height);
      // 상단내용
      context.fillStyle = 'rgba(0,0,0,1)';
      context.textAlign = 'left'
      // const fontSize = window.matchMedia('print').matches ? '20pt' : '16px';
      context.font = `${bold} ${fontSize} "Malgun Gothic"`;
      context.fillText(text, 10, point);

      // 이미지 데이터를 리턴
      return canvas.toDataURL('image/jpeg');
    },

    calcCountKor(num) { //숫자 한글변환
      num = parseInt((num + '').replace(/[^0-9]/g, ''), 10) + '';  // 숫자/문자/돈 을 숫자만 있는 문자열로 변환
      if (num == '0')
        return '영';
      var number = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
      var unit = ['', '만', '억', '조'];
      var smallUnit = ['천', '백', '십', ''];
      var result = [];
      var unitCnt = Math.ceil(num.length / 4);
      num = num.padStart(unitCnt * 4, '0')
      var regexp = /[\w\W]{4}/g;
      var array = num.match(regexp);
      for (var i = array.length - 1, unitCnt = 0; i >= 0; i--, unitCnt++) {
        var hanValue = _makeHan(array[i]);  //한글로 변환된 숫자
        if (hanValue == '')
          continue;
        result.unshift(hanValue + unit[unitCnt]);
      }
      function _makeHan(text) {
        var str = '';
        for (var i = 0; i < text.length; i++) {
          var num = text[i];
          if (num == '0')
            continue;
          str += number[num] + smallUnit[i];
        }
        return str;
      }
      return result.join('');
    },

    supply(item, amount, vat, field) {
      vat = vat == 0 ? 'Y' : 'N'
      amount = JSON.parse(JSON.stringify(this.$delComma(amount))) || 0
      const { supplyAmount, vatAmount } = this.$amountSplitTax(amount, vat)
      item[`supply_${field}`] = this.$comma(supplyAmount)
      item[`vat_${field}`] = this.$comma(vatAmount)
      item[field] = this.$comma(amount)
    },
    supplySub(item, amount, vat, field) {
      vat = vat == 0 ? 'Y' : 'N'
      amount = JSON.parse(JSON.stringify(this.$delComma(amount))) || 0
      item[`supply_${field}`] = this.$comma(amount)
      item[`vat_${field}`] = this.$comma(amount * 0.1)
      item[field] = this.$comma(amount * 1.1)
    },

  },



};
</script>
<style lang="scss">
.sticky-bottom {
  bottom: 0px;
  left: 0px;
  position: fixed;
  z-index: 1000;
}

@media print {
  .noprint {
    display: none;
  }

  .printrotate {
    font-size: 72px !important;
    z-index: 9999;
  }

  body * {
    padding-top: 0px;
    transform: scale(100%);
    // font-weight: normal !important;
    /* 크기 조절 (0.5는 50% 크기) */
    transform-origin: top left;
    /* 크기 조절의 기준점 설정 */
    font-size: 14pt !important;
  }

  .printfont14pt {
    font-size: 14pt !important;
  }

  .printcustomer {
    height: calc(100% - 56px) !important;
  }

  th {
    font-weight: bold;
    padding: 5px;
    // background-color: #f7f7f7 !important;
    border-right: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
  }

  td {
    height: 30px;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
    border-right: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
  }

  table {
    width: 100%;
    border-left: 1px #000 solid !important;
    border-top: 1px #000 solid !important;
  }
}
</style>
<style scoped>
body {
  font-size: 16px !important;
}

.table-bordereds-print th {
  /* text-align: center !important; */
  font-weight: 400;
  padding: 5px;
  /* background: #f7f7f7 !important; */
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.table-bordereds-print td {
  height: 20px;
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.tables-print {
  width: 100%;
  border-left: 1px #000 solid;
  border-top: 1px #000 solid;
}

.text16 {
  font-size: 16pt !important;
}
</style>