<template>
  <el-dialog center :title="$t(title)" :visible.sync="visible" :width="'600px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable style="z-index: 99999999;">
    <card>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('PRODUCT') }}*</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('PRODUCT')" maxlength="10" v-model="formData.name" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('PID') }}*</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('PID')" maxlength="50" v-model="formData.pid" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('FACTORYNUM') }}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('FACTORYNUM')" maxlength="50" v-model="formData.factoryNum" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('TYPE') }}</el-col>
        <el-col :xl="20" :md="20">
          <input class="inputboxborder" :placeholder="$t('TYPE')" maxlength="10" 
          :value="$t($productType.find(item=>item.value==formData.type).label)" disabled
            autocomplete="off" />
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('STORAGE') }}</el-col>
        <el-col :xl="20" :md="20">
          <input class="inputboxborder" :placeholder="$t('STORAGE')" maxlength="10" :value="formData.storageName"
            disabled autocomplete="off" />
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('WP') }}</el-col>
        <el-col :xl="20" :md="20">
          <input class="inputboxborder" :placeholder="$t('WP')" maxlength="10" v-model="formData.inPrice"
            autocomplete="off" @focus="() => { formData.inPrice = $numberFormat(formData, 'inPrice', 'focus') }"
            @input="() => { formData.inPrice = $numberFormat(formData, 'inPrice', 'input') }"
            @blur="() => { formData.inPrice = $numberFormat(formData, 'inPrice', 'blur') }" />
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('MINQTY') }}</el-col>
        <el-col :xl="20" :md="20">
          <input class="inputboxborder" :placeholder="$t('MINQTY')" maxlength="10" v-model="formData.minAmount"
            autocomplete="off" @focus="() => { formData.minAmount = $numberFormat(formData, 'minAmount', 'focus') }"
            @input="() => { formData.minAmount = $numberFormat(formData, 'minAmount', 'input') }"
            @blur="() => { formData.minAmount = $numberFormat(formData, 'minAmount', 'blur') }" />
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('IV.QTY') }}</el-col>
        <el-col :xl="20" :md="20">
          <input class="inputboxborder" :placeholder="$t('IV.QTY')" maxlength="10" v-model="formData.hasAmount"
            autocomplete="off" @focus="() => { formData.hasAmount = $numberFormat(formData, 'hasAmount', 'focus') }"
            @input="() => { formData.hasAmount = $numberFormat(formData, 'hasAmount', 'input') }"
            @blur="() => { formData.hasAmount = $numberFormat(formData, 'hasAmount', 'blur') }" />
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('AMOUNT') }}A</el-col>
        <el-col :xl="20" :md="20">
          <input class="inputboxborder" :placeholder="$t('AMOUNT') + 'A'" maxlength="10" v-model="formData.priceA"
            autocomplete="off" @focus="() => { formData.priceA = $numberFormat(formData, 'priceA', 'focus') }"
            @input="() => { formData.priceA = $numberFormat(formData, 'priceA', 'input') }"
            @blur="() => { formData.priceA = $numberFormat(formData, 'priceA', 'blur') }" />
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('AMOUNT') }}B</el-col>
        <el-col :xl="20" :md="20">
          <input class="inputboxborder" :placeholder="$t('AMOUNT') + 'B'" maxlength="10" v-model="formData.priceB"
            autocomplete="off" @focus="() => { formData.priceB = $numberFormat(formData, 'priceB', 'focus') }"
            @input="() => { formData.priceB = $numberFormat(formData, 'priceB', 'input') }"
            @blur="() => { formData.priceB = $numberFormat(formData, 'priceB', 'blur') }" />
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('AMOUNT') }}C</el-col>
        <el-col :xl="20" :md="20">
          <input class="inputboxborder" :placeholder="$t('AMOUNT') + 'C'" maxlength="10" v-model="formData.priceC"
            autocomplete="off" @focus="() => { formData.priceC = $numberFormat(formData, 'priceC', 'focus') }"
            @input="() => { formData.priceC = $numberFormat(formData, 'priceC', 'input') }"
            @blur="() => { formData.priceC = $numberFormat(formData, 'priceC', 'blur') }" />
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('AMOUNT') }}D*</el-col>
        <el-col :xl="20" :md="20">
          <input class="inputboxborder" :placeholder="$t('AMOUNT') + 'D'" maxlength="10" v-model="formData.priceD"
            autocomplete="off" @focus="() => { formData.priceD = $numberFormat(formData, 'priceD', 'focus') }"
            @input="() => { formData.priceD = $numberFormat(formData, 'priceD', 'input') }"
            @blur="() => { formData.priceD = $numberFormat(formData, 'priceD', 'blur') }" />
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('DESC') }}</el-col>
        <el-col :xl="20" :md="20">
          <el-input type="textarea" :placeholder="$t('DESC')" maxlength="200" v-model="formData.memo" size="mini">
          </el-input>
        </el-col>
      </el-row>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">{{ $t('CLOSE') }}</el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange" @click="setInfo">{{ $t('SUBMIT')
          }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'malllproductCreate',
  props: ['data',],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.formData = {
          storageName: newValue?.storageInfo?.name || '',
          inPrice: this.$comma(newValue?.inPrice) || 0,
          minAmount: (newValue?.minAmount) || 0,
          hasAmount: (newValue?.hasAmount) || 0,
          priceA: (newValue?.priceA) || 0,
          priceB: (newValue?.priceB) || 0,
          priceC: (newValue?.priceC) || 0,
          priceD: (newValue?.priceD) || 0,
          name: newValue?.name || '',
          pid: newValue?.pid || '',
          factoryNum: newValue?.factoryNum || '',
          memo: newValue?.memo || '',
          type: newValue?.type || 0,
          seq: newValue?.seq || null,

        }
      }
    },
  },
  data() {
    return {
      visible: true,
      title: 'PRODUCT.M',
      tmpCustomerList: [],
      total: 0,
      formData: {
        availableIp: '',
        mail: '',
        tel: '',
        phone: '',
        name: '',
        seq: null,
        type: 1,
      },
    }
  },
  async created() {
  },
  methods: {
    async setInfo() {
      if (this.formData.name.trim() == '') {
        alert('상품명을 입력하세요.')
        return
      }
      if (this.formData.pid.trim() == '') {
        alert('정품품번을 입력하세요.')
        return
      }
      if (this.formData.priceD.toString().trim() == '') {
        alert('판매단가D를 입력하세요.')
        return
      }
      const res = await this.$http.post(`${this.$serverurl}/api/setSalesProductInfo`, this.formData, this.$setHeader())
      if (201 == res.data.code) {
        alert(res.data.message)
        this.setCallback(res.data.body.result)
      }
    },
    setCallback(item) {
      this.$emit('callback', { item: item, status: true })
    },
    notClose() { },
    handleClose(done) {
      this.$emit('callback', { item: null, status: false })
    },
    // daum 주소검색
    loadDaumPostcodeScript() {
      const script = document.createElement('script')
      script.src =
        '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'
      script.async = true
      script.onload = () => {
        this.initializeDaumPostcode()
      }
      document.head.appendChild(script)
    },
    initializeDaumPostcode() {
      const daum = null
      new (window).daum.Postcode({
        oncomplete: (data) => {
          this.formData.postNumMain = data.zonecode
          this.formData.addrMain = data.address
        }
      }).open()
    },
  }
}
</script>
<style></style>