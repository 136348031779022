<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'300px'" :modal=true :show-close="false"
    :close-on-press-escape="true" v-draggable :before-close="handleClose">
    <table class="table-params table-hover w100p table-hover font13 mt-2">
      <tr>
        <th class="w50 center">선택</th>
      </tr>
      <tr v-for="(item, index) in  $saleProcess.filter(
        item => saleProcess.find(items => items.saleStatus == productInfo.saleStatus).buttons.includes(item.value))"
        :key="index">
        <td class="center">
          <span class="badge font12 badge-orange hand" @click="$emit('callback', { item, status: true })">
            {{ $t(item.label)}}
          </span>
        </td>
      </tr>
    </table>
    <div class="row mt-3">
      <div class="col right">
        <el-button size="mini" @click="$emit('callback', { item: null, status: false })">
          창닫기</el-button>

      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'regproduct',
  components: {  },
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.productInfo = newValue
      },
    },
  },
  data() {
    return {
      saleProcess:[
        {saleStatus:0,buttons:[1]},
        {saleStatus:1,buttons:[3]},
        {saleStatus:2,buttons:[5]},
        {saleStatus:3,buttons:[1]},
        {saleStatus:4,buttons:[1]},
        {saleStatus:5,buttons:[1]},
      ],
      visible: true,
      title: '상품등록관리',
      productInfo: null,
    }
  },
  async created() {  },
  methods: {
    handleClose(done){
      this.$emit('callback',{data:null,status:false})
    }
    },

  }

</script>
<style></style>