<template>
  <el-dialog center :title="$t(title)" :visible.sync="visible" :width="'600px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable style="z-index: 99999999;">
    <card>
      <el-row :gutter="10" class="mt-2 pb-2 borderbottom1px">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('VAT') }}</el-col>
        <el-col :xl="20" :md="20">
          <el-radio-group v-model="formData.vat">
            <el-radio :label="0">{{ '판매상품>부가세포함' }}</el-radio>
            <el-radio :label="1">{{ '판매상품>부가세미포함' }}</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <div class="mt-2 mb-2">
        판매하는 상품단가의 유형을 설정합니다.<br/>
        부가세미포함 시 플랫폼에서 자동으로 부가세를 계산합니다.<br/>
        설정후 수정할수 없습니다.
      </div>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">{{ $t('CLOSE') }}</el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange" @click="setInfo">{{ $t('SUBMIT')
          }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'erpcompanyvatupdate',
  props: ['data',],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.formData = {
          ceo: newValue?.ceo || '',
          tel: newValue?.tel || '',
          fax: newValue?.fax || '',
          phone: newValue?.phone || '',
          mail: newValue?.mail || '',
          postNum: newValue?.postNum || '',
          addr: newValue?.addr,
          addrSub: newValue?.addrSub || '',
          vat: newValue?.vat || 0,
        }
      }
    },
  },
  data() {
    return {
      visible: true,
      title: 'VAT',

      total: 0,
      formData: {
        ceo: '',
        tel: '',
        fax: '',
        phone: '',
        mail: '',
        postNumMain: '',
        addrMain: '',
        postNumSub: '',
        addrSub: '',
        vat: 0,
      },
    }
  },
  async created() {
  },
  methods: {
    async setInfo() {
      const res = await this.$http.post(`${this.$serverurl}/api/setMyCompanyInfo`, this.formData, this.$setHeader())
      if (201 == res.data.code) {
        alert(res.data.message)
        this.setCallback(res.data.body.results)
      }

    },
    setCallback(item) {
      this.$emit('callback', { item: item, status: true })
    },
    notClose() { },
    handleClose(done) {
      this.$emit('callback', { item: null, status: false })
    },
    // daum 주소검색
    loadDaumPostcodeScript() {
      const script = document.createElement('script')
      script.src =
        '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'
      script.async = true
      script.onload = () => {
        this.initializeDaumPostcode()
      }
      document.head.appendChild(script)
    },
    initializeDaumPostcode() {
      const daum = null
      new (window).daum.Postcode({
        oncomplete: (data) => {
          this.formData.postNumMain = data.zonecode
          this.formData.addrMain = data.address
        }
      }).open()
    },
  }
}
</script>
<style></style>