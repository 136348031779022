<template>
  <div class="container mt-3 relative" :style="$pageWidth">
    <TopBar />
    <div class="mt-2">
      <el-row>
        <el-col :xl="24" :md="24">
          <!-- 배송프로세서 -->
          <span v-for="(item, index) in $orderProcess" :key="index" class="mr-2">
            <el-button v-if="!item.hidden" size="mini" :type="activeName == item.value ? 'primary' : ''"
              @click="handleButtons(item.value)">
              {{ item.value }}.{{ $t(item.label) }}({{ statusCount.length && statusCount[index].count }})</el-button>
          </span>

        </el-col>
      </el-row>
      <div class="mt-2">
        <ordersalelist v-if="activeName != null || returnGoodsStatus != null" :status="activeName"
          :returnGoodsStatus="returnGoodsStatus" @callback="listcallback" />
      </div>
    </div>
  </div>
</template>
<script>
import ordersalelist from './component-order-sale-list.vue'
import TopBar from './component-top.vue'
export default {
  components: {
    TopBar, ordersalelist
  },
  data() {
    return {
      statusCount: [],
      selectItem: null,
      activeName: 0,
      returnGoodsStatus: null,
      tmpStorageData: null,
    }
  },
  created() {
  },
  methods: {

    listcallback(data) { // 목록보기콜백
      this.statusCount = data.item
    },

    handleButtons(params) {
      this.activeName = params
      this.returnGoodsStatus = null
    },
    handleReturnGoodsButtons(params) {
      this.returnGoodsStatus = params
      this.activeName = null
    },
  }
}
</script>
<style></style>
