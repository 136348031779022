import Vue from 'vue'
import VueI18n from "vue-i18n"
import en from "./locales/en.json"
import ko from "./locales/ko.json"
import cn from "./locales/cn.json"
import vtn from "./locales/vtn.json"


Vue.use(VueI18n)
var language = [
	{ label: "한국어", value: 0, file: 'ko' },
	{ label: "中国大陆", value: 1, file: 'cn' },
	{ label: "Việt Nam", value: 2, file: 'vtn' },
	{ label: "English", value: 3, file: 'en' },
]
var l = 'ko'
var f = 'ko'

var userinfo = localStorage.getItem('userlogininfo')
if (userinfo == undefined || userinfo == 'undefined' || userinfo == null || userinfo == 'null') {
	l = language[0].file
	f = language[0].file
} else {
	const tmpUserinfo = JSON.parse(userinfo)
	l = language[tmpUserinfo.language].file
	f = language[tmpUserinfo.language].file
}

// zh-CN: Simplified Chinese
// zh-TW: Traditional Chinese(Traditional)
// en-US: English
export default new VueI18n({
	locale: l,
	fallbackLocale: f,
	messages: { en, ko, cn, vtn }
})
