<template>
    <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
        <card style="min-width: 100%;margin-bottom:10px;">
            <el-row :gutter="10">
                <el-col :md="12">
                    <el-input :placeholder="$t('SEARCH')" size="mini" v-model="searchKeyword" style="max-width: 200px;"
                        class="mr-2" @keyup.enter.native="searchRead(searchKeyword)">
                        <i slot="prefix" class="el-input__icon el-icon-search hand" @click="searchRead(searchKeyword)"></i>
                    </el-input>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="searchRead(searchKeyword)">
                        {{ $t('SEARCH') }}
                    </el-button>
                    <el-button type="primary" icon="el-icon-tickets" size="mini" v-if="searchKeyword"
                        @click="searchRead(null)">{{
                        $t('VIEWALL') }}</el-button>
                </el-col>
                <!-- <el-col :md="12" class="right">
                    <el-button type="primary" :icon="request.objSearchMapper.state ? 'el-icon-success' : 'el-icon-delete'"
                        size="mini" @click="searchStateRead(request.objSearchMapper.state ? 0 : 1)">
                        {{ request.objSearchMapper.state ? $t('NU') : $t('SU') }}</el-button>
                </el-col> -->
            </el-row>
        </card>
        <div>
            <el-row>
                <!-- 배송등록,반품승인,거래취소 버튼 -->
                <el-col :xl="15" :md=15 class="lh30 mb-2">
                    <el-button size="mini" v-for="item,index in $orderProcesssub" :key="index"
                        :type="item.value==request.objSearchMapper.status?'primary':''"
                        @click="request.objSearchMapper.status=item.value;read()">
                        {{ $t(item.label) }}({{ count[`count${item.value}`] }}건)
                    </el-button>
                </el-col>
                <el-col :xl="9" :md=9 class="lh30 right mb-2">
                    <!-- <div :calss="sortClass(0)" @click="sortRead(0)"
                        class="middlebadge-blue fas fa-arrow-up-wide-short fontsize14 hand mr-1" style="width:20px"></div>
                    <div :calss="sortClass(0)" @click="sortRead(1)"
                        class="middlebadge-blue fas fa-arrow-up-1-9 fontsize14 hand mr-1" style="width:20px"></div>
                    <div :calss="sortClass(0)" @click="sortRead(2)"
                        class="middlebadge-blue fas fa-arrow-up-9-1 fontsize14 hand" style="width:20px"></div> -->
                </el-col>
            </el-row>
            <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13">
                <el-table-column label="ID" width="80" :align="'center'">
                    <template slot-scope="scope">
                        {{ scope.row.seq }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('UPDATED')" width="120" :align="'left'">
                    <template slot-scope="scope">
                        <span class="ellip">{{ $YYYYMMDD_HHMM(scope.row.updated) }}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('ITEM')" :align="'left'" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span class="ellip">{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('PID')" width="120" :align="'left'" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span class="ellip">{{ scope.row.pid }}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('FACTORYNUM')" width="120" :align="'left'" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span class="ellip">{{ scope.row.factoryNum }}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('STORAGE')" width="100" :align="'left'" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div v-html="scope.row.whDes"></div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('COUNT')" width="60" :align="'center'">
                    <template slot-scope="scope">
                        <div v-html="scope.row.orderProductList.length"></div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
                :page-size="request.size" layout="total, sizes, prev, pager, next" :total="totalCount">
            </el-pagination>
        </div>
        <!-- <mallmanagerepcvinview :data="vinItem" v-if="vinItem" @callback="vinviewCallback"/> -->
    </div>
</template>
<script>
// import mallmanagerepcvinview from './component-mall-manager-epc-vin-view.vue'
import { Loading } from 'element-ui';
export default {
  name: 'mallmanagersearchvin',
  components: {
    //  mallmanagerepcvinview
  },
  data() {
    return {
      createvisible: false,
      selectItem: null,
      pageSizes: [15, 50, 100],
      searchKeyword: '',
      request: {
        objSearchMapper: {
          searchKeyword: '',
          state: 0,
          sort: 0,
          status: 2,
        },
        page: 1,
        size: 15
      },
      tmpTableData: [],
      totalCount: 0,
      count: {},
    }
  },

  created() {
    this.read()
  },
  methods: {
    vinviewCallback(data) {
      this.vinItem = null
    },
    insertUpdatebox(item) {
      this.selectItem = item
      this.createvisible = true
    },

    searchStateRead(params) {
      this.request.objSearchMapper.state = params
      this.request.page = 1
      this.read()
    },

    searchRead(searchKeyword) {
      this.searchKeyword = searchKeyword
      this.request.page = 1
      this.request.objSearchMapper.searchKeyword = this.$trimLR(searchKeyword)
      this.read()
    },
    async read() {
      this.loading = Loading.service({ fullscreen: true })
      await this.$http.post(`${this.$serverurl}/api/mallSalesCount`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
            this.count = res.data.body.count
          } else {
            this.tmpTableData = []
            this.totalCount = 0
            this.count = res.data.body.count
          }
        })
      this.$overay(this.loading)
    },


    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    },
    sortClass(params) {
      if (this.request.objSearchMapper.sort == params) {
        return 'bigbadge-blue-select'
      } else {
        return 'bigbadge-blue'
      }
    },
    sortRead(params) {
      this.request.objSearchMapper.sort = params
      this.read()
    },

  }
}
</script>
<style></style>
