<template>
  <div class="container mt-3 relative" :style="$pageWidth">
    <TopBar />
    <div class="mt-2">
      <span v-for="item,index in tabs" :key="index" class="mr-2">
        <el-button size="mini" :type="activeName == item.route ? 'primary' : ''"
          v-if="userlogininfo.companyInfo.level==0" 
          @click="handleButtons(item.route)">
          {{item.label}}
        </el-button>
        <el-button size="mini" :type="activeName == item.route ? 'primary' : ''"
          v-if="!item.auth && userlogininfo.companyInfo.level==1" 
          @click="handleButtons(item.route)">
          {{item.label}}
        </el-button>
      </span>
      <div class="mt-2">
        <component :is="activeName"></component>
      </div>
    </div>
    <!-- <json-viewer :value="userlogininfo" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  </div>
</template>
<script>
import TopBar from './component-top.vue'
import baseitemlist from './component-basic-item-list.vue'
import customerinfo from './component-customer-list.vue'
import sotragelist from './component-storage-list.vue'
import invoiceCreate from './component-invoice-info.vue'
import substitutelist from './component-sub-stitute-list.vue'
import mycompany from './component-my-company.vue'
export default {
  components: {
    TopBar, baseitemlist, customerinfo, sotragelist, invoiceCreate, substitutelist, mycompany
  },
  data() {
    return {
      activeName: 'baseitemlist',
      tabs: [
        // { value: 0, label: '기초관리 메인', route: '' },
        { value: 1, label: this.$t('BIM'), route: 'baseitemlist' }, // 기초항목관리
        { value: 2, label: this.$t('CRM'), route: 'customerinfo' }, // 거래처관리
        { value: 3, label: this.$t('WM'), route: 'sotragelist' }, // 창고관리
        { value: 4, label: this.$t('IS'), route: 'invoiceCreate' }, // 명세서설정
        { value: 5, label: this.$t('CAE'), route: 'mycompany' }, // 회사정보&이카운트설정
        { value: 6, label: this.$t('CMP'), route: 'substitutelist', auth: true }, // 호환품번관리
      ]
    }
  },
  created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
  },
  methods: {
    handleButtons(params) {
      this.activeName = params;
    },

  }
}
</script>
<style></style>
