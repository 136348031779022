<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'1000px'" :modal=true :show-close="false"
    :before-close="handleClose" v-draggable>
    <card>
      <el-row :gutter="10">
        <el-col :xl="24" :md="24">
          <table class="table-params w100p">
            <tbody>
              <tr>
                <th rowspan="2" class="w100">{{$t('DATE')}}</th>
                <th rowspan="2" class="w130">{{$t('CUSTOMER')}}</th>
                <th rowspan="2" class="w100">{{$t('STORAGE')}}</th>
                <th colspan="3" class="w100">{{$t('IN')}}</th>
                <th colspan="3" class="w100">{{$t('OUT')}}</th>
              </tr>
              <tr>
                <th class="right">{{$t('AMOUNT')}}</th>
                <th class="right">{{$t('QTY')}}</th>
                <th class="right">{{$t('SUBTOTAL')}}</th>
                <th class="right">{{$t('AMOUNT')}}</th>
                <th class="right">{{$t('QTY')}}</th>
                <th class="right">{{$t('SUBTOTAL')}}</th>
              </tr>
              <tr v-for="(item, index) in ioInfoList" :key="index">
                <td> {{ item.tradeDate.toISOString().substr(0, 10) }}</td>
                <td> {{ item.customerName }}</td>
                <td> {{ item.storageName }}</td>
                <td class="right"> {{ $comma(item.inPrice) || '-' }}</td>
                <td class="right"> {{ $comma(item.inQty) || '-' }}</td>

                <td class="right" v-if="item.inPrice"> {{ $comma(item.inPrice * item.inQty || '-') }}</td>
                <td class="right" v-else>-</td>
                
                <td class="right"> {{ $comma(item.outPrice) || '-' }}</td>
                <td class="right"> {{ $comma(item.outQty) || '-' }}</td>
                <td class="right" v-if="item.outPrice"> {{ $comma(item.outPrice * item.outQty || '-') }}</td>
                <td class="right" v-else> -</td>
              </tr>
            </tbody>
          </table>
        </el-col>
      </el-row>
      <!-- <table class="table-params w100p">
            <tbody>
              <tr>
                <th class="w100"> 거래날자</th>
                <td calss="w150"> {{ $YYYYMMDD(itemInfo.tradeDate, '-') }} </td>
                <th class="w100"> 거래구분</th>
                <td> {{ $vatType[itemInfo.vat].label }} </td>
              </tr>
              <tr>
                <th class="w100"> 거래처</th>
                <td calss="w150"> {{ itemInfo.customerInfo.name }} </td>
                <th class="w100"> 출하창고</th>
                <td> {{ itemInfo.storageInfo.name }} </td>
              </tr>
              <tr>
                <th class="w100"> 거래비고</th>
                <td colspan="4"> {{ itemInfo.memoA }} </td>
              </tr>
              <tr>
                <th class="w100">거래금액</th>
                <td calss="w150"> {{ $comma(subreduce(itemInfo)) }} </td>
                <th class="w100"> 부가세</th>
                <td> {{ $comma(subreduce(itemInfo, 'vatonly')) }}</td>
              </tr>
            </tbody>
          </table> -->
    </card>
    <div class="text-right">
      <el-button size="mini" type="" @click="handleClose">
        {{$t('CLOSE')}}</el-button>
    </div>
    <!-- <json-viewer :value="ioInfoList" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  </el-dialog>
</template>
<script>
export default {
  name: 'invendetailview',
  props: ['data', 'tmpStorageData'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.tmpStorageDataList = this.tmpStorageData
        this.itemInfo = newValue
        this.입출고기록생성(newValue)
        this.title = newValue.name
      }
    },
  },
  data() {
    return {
      ioInfoList: [],
      tmpStorageDataList: [],
      visible: true,
      title: '입출기록',
      total: 0,
      itemInfo: {},
      request: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 10
      },
    }
  },
  async created() {
  },
  methods: {
    notClose() { },
    handleUpdateClose() {
      const data = {
        item: this.itemInfo,
        status: true
      }
      this.$emit('callback', data)
    },
    handleClose() {
      const data = {
        status: false
      }
      this.$emit('callback', data)
    },
    subreduce(item, type) {
      switch (type) {
        case 'total': {
          return item.orderOutItemInfo.reduce((acc, items) =>
            acc + (items.outQty * items.outPrice + items.outQty * items.outPrice * 0.1), 0
          )
          // break
        }
        case 'vatonly': {
          return item.orderOutItemInfo.reduce((acc, items) =>
            acc + (items.outQty * items.outPrice * 0.1), 0
          )
          // break
        }
        default: {
          return item.orderOutItemInfo.reduce((acc, items) =>
            acc + (items.outQty * items.outPrice), 0
          )
          // break
        }
      }
    },
    입출고기록생성(row) {
      const ioInfoList = []
      row.orderInItemInfo.forEach((입고항목낱개) => {
        // ioInfoList.inList.push({
        const tmpStorageInfo = this.tmpStorageDataList.find(item => {
          return item.seq == 입고항목낱개.storageId
        })
        ioInfoList.push({
          returnGoodsId: 입고항목낱개.returnGoodsId,
          customerName: 입고항목낱개.customerInfo.name,
          seq: 입고항목낱개.seq,
          name: 입고항목낱개.name,
          pid: 입고항목낱개.pid,
          factoryNum: 입고항목낱개.factoryNum,
          tradeDate: new Date(입고항목낱개.orderInInfo.tradeDate),
          // storageName: this.readStorageInfo(입고항목낱개.storageId).name,
          storageInfo: tmpStorageInfo,
          storageName: tmpStorageInfo?.name,
          inPrice: 입고항목낱개.inPrice,
          inQty: 입고항목낱개.inQty,
        });
      });
      // ioInfoList.inList.sort((a, b) => new Date(a.tradeDate) - new Date(b.tradeDate));
      ioInfoList.sort((a, b) => new Date(a.tradeDate) - new Date(b.tradeDate));
      row.orderOutItemInfo.forEach((출고항목낱개) => {
        // ioInfoList.outList.push({
        const tmpStorageInfo = this.tmpStorageDataList.find(item => {
          return item.seq == 출고항목낱개.storageId
        })
        ioInfoList.push({
          returnGoodsId: 출고항목낱개.returnGoodsId,
          customerName: 출고항목낱개.customerInfo.name,
          seq: 출고항목낱개.seq,
          name: 출고항목낱개.name,
          pid: 출고항목낱개.pid,
          factoryNum: 출고항목낱개.factoryNum,
          tradeDate: new Date(출고항목낱개.orderOutInfo.tradeDate),
          // storageName: this.readStorageInfo(출고항목낱개.storageId).name,
          storageInfo: tmpStorageInfo,
          storageName: tmpStorageInfo?.name,
          outPrice: 출고항목낱개.outPrice,
          outQty: 출고항목낱개.outQty,
        });
      });

      let totalInQtyCount = row.totalInQtyCount
      let totalInAmountCount = row.totalInAmountCount
      let totalOutQtyCount = row.totalOutQtyCount
      let totalOutAmountCount = row.totalOutAmountCount
      ioInfoList.totalInQtyCount = totalInQtyCount
      ioInfoList.totalInAmountCount = totalInAmountCount
      ioInfoList.totalOutQtyCount = totalOutQtyCount
      ioInfoList.totalOutAmountCount = totalOutAmountCount
      // ioInfoList.outList.sort((a, b) => new Date(a.tradeDate) - new Date(b.tradeDate));
      ioInfoList.sort((a, b) => new Date(a.tradeDate) - new Date(b.tradeDate));
      this.ioInfoList = ioInfoList;
    },
  }
}
</script>
<style></style>