<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%;margin-bottom:10px;">
      <el-row :gutter="10">
        <el-col :md="12">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="searchKeyword" style="max-width: 200px;" class="mr-2"
            @keyup.enter.native="searchRead(searchKeyword)">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="searchRead(searchKeyword)"></i>
          </el-input>
          <el-button type="primary" icon="el-icon-search" size="mini"
            @click="searchRead(searchKeyword)">{{$t('SEARCH')}}</el-button>
          <el-button type="primary" icon="el-icon-tickets" size="mini" v-if="searchKeyword"
            @click="searchRead(null)">{{$t('VIEWALL')}}</el-button>
        </el-col>
        <el-col :md="12" class="right">
          <el-button type="primary" icon="el-icon-printer" size="mini"
            @click="$printView('자사재고출력', '자사재고출력', request.objSearchMapper.storageId, 1100, 850)"
            v-if="tmpTableData.length">
            {{$t('PRINT')}}</el-button>
  
        </el-col>
      </el-row>
    </card>
    <div>
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
        :page-size="request.size" layout="total, sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
      <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13">
        <el-table-column label="ID" width="100" :align="'left'">
          <template slot-scope="scope">
            {{ scope.row.seq }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('ITEM')" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.baseInfo.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('PID')" width="150" :align="'left'">
          <template slot-scope="scope">
            {{ scope.row.baseInfo.pid }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('FACTORYNUM')" width="150" :align="'left'">
          <template slot-scope="scope">
            {{ scope.row.baseInfo.factoryNum }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('TYPE')" width="100" :align="'center'">
          <template slot-scope="scope">
            {{ $t($productType[scope.row.baseInfo.type].label) }}
          </template>
        </el-table-column>
        <!-- <el-table-column :label="$t('AMOUNT')" width="100" :align="'right'">
          <template slot-scope="scope">
            {{ $comma(scope.row.inPrice) }}
          </template>
        </el-table-column> -->
        <el-table-column :label="$t('I.QTY')" width="80" :align="'right'">
          <template slot-scope="scope">
            {{ $comma(scope.row.inQty) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('O.QTY')" width="80" :align="'right'">
          <template slot-scope="scope">
            {{ $comma(scope.row.outQty) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('IV.QTY')" width="70" :align="'right'">
          <template slot-scope="scope">
            {{ $comma(scope.row.inQty - scope.row.outQty) }}
          </template>
        </el-table-column>
         <el-table-column :label="$t('PR')" width="100" :align="'center'"
          v-if="
          (userlogininfo.authority.salePermi==3 && userlogininfo.authority.ecountPermi!=0) || 
          userlogininfo.authority.accountPermi==0 ">
          <template slot-scope="scope">
            <div v-if="scope.row.salesProductInfo">
              <el-button size="mini" class="btn-mini btn-blue" v-if="scope.row.salesProductInfo.saleStatus == 0"
                disabled>요청중</el-button>
              <el-button size="mini" class="btn-mini btn-blue" v-if="scope.row.salesProductInfo.saleStatus == 1"
                disabled>확인중</el-button>
              <el-button size="mini" class="btn-mini btn-blue" v-if="scope.row.salesProductInfo.saleStatus == 2"
                disabled>판매중</el-button>
              <el-button size="mini" class="btn-mini btn-blue" v-if="scope.row.salesProductInfo.saleStatus > 2"
                @click="showcreateProduct(scope.row)">
                재요청</el-button>
            </div>
           <div v-if="!scope.row.salesProductInfo || !scope.row.salesProductInfo.saleStatus">
            <div v-if="scope.row.inQty - scope.row.outQty > 0">
              <el-button size="mini" class="btn-mini btn-blue"
                @click="showcreateProduct(scope.row)">{{$t('PR')}}</el-button>
            </div>
            <div v-else>
              <el-button size="mini" class="btn-mini btn-blue" disabled>{{$t('PR')}}</el-button>
            </div>
           </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('DETAIL')" width="80" :align="'center'">
          <template slot-scope="scope">
            <el-button size="mini" class="btn-mini btn-blue"
              v-if="scope.row.orderInItemInfo.length || scope.row.orderOutItemInfo.length" @click="orderView(scope.row)">
              {{$t('DETAIL')}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
  
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
        :page-size="request.size" layout="total, sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
    </div>
    <!-- <json-viewer :value="tmpTableData" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
    <createProduct v-if="showCreateProduct" :data="selectItem" @callback="createProductCallbacak" />
  </div>
</template>
<script>
import createProduct from './component-product-create.vue'
import { Loading } from 'element-ui';
export default {
  name: 'invenlist',
  components: {
    createProduct,
  },
  props: ['storageId'],
  watch: {
    storageId: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.request.objSearchMapper.storageId = newValue
        this.read()
      }
    },
  },
  data() {
    return {
      selectItem: null,
      showCreateProduct: false,
      inAssets: 0,
      searchKeyword: null,
      request: {
        objSearchMapper: {
          storageId: null,
          searchKeyword: null,
          noEmtpy: true,
        },
        page: 1,
        size: 15
      },
      pageSizes: [15, 30, 50, 100],

      // tables
      tmpTableData: [],
      totalCount: null,
      loading: null,
      userlogininfo: null,
    }
  },

  created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
  },
  methods: {
    createProductCallbacak(data) {
      this.showCreateProduct = false
      this.read()
    },
    showcreateProduct(item) {
      this.selectItem = item
      // this.selectItem.storageId = this.storageId
      this.showCreateProduct = true
    },
    orderView(item) {
      this.$emit('callback', { item, status: true })
    },

    searchRead(searchKeyword) {
      this.searchKeyword = searchKeyword
      this.request.objSearchMapper.searchKeyword = this.$trimLR(searchKeyword)
      this.read()
    },
    async read() {
      this.loading = Loading.service({ fullscreen: true })
      // await this.$http.post(`${this.$serverurl}/api/getSelectBaseExInfo`, this.request, this.$setHeader())
      await this.$http.post(`${this.$serverurl}/api/getInvenCount`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
          } else {
            this.tmpTableData = []
            this.totalCount = null
          }
        })
      this.$overay(this.loading)
    },


    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    },

    avgInPrice(itemInfo) {
      const totalPrice = itemInfo.orderInItemInfo.reduce((total, item) =>
        total + item.inQty * item.inPrice, 0)
      const totalQty = itemInfo.orderInItemInfo.reduce((total, item) =>
        total + item.inQty, 0)
      if (!totalPrice) {
        return itemInfo.inPrice
      }
      return totalPrice / totalQty
    }

  }
}
</script>
<style></style>
