<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%;margin-bottom:20px;">
      <el-row :gutter="10">
        <el-col :md="12">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="searchKeyword" style="max-width: 200px;" class="mr-2"
            @keyup.enter.native="searchRead(searchKeyword)">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="searchRead(searchKeyword)"></i>
          </el-input>
          <el-button type="primary" icon="el-icon-search" size="mini"
            @click="searchRead(searchKeyword)">{{$t('SEARCH')}}</el-button>
          <el-button type="primary" icon="el-icon-tickets" size="mini" v-if="searchKeyword"
            @click="searchRead(null)">{{$t('VIEWALL')}}</el-button>
        </el-col>
      </el-row>
    </card>
    <div>
      <el-table :data="tmpTableData" border style="width: 100%" class="font13">
        <el-table-column label="ID" width="50" :align="'center'">
          <template slot-scope="scope">
            <input type="checkbox" @change="pushId(scope.row, $event.target.checked)">
          </template>
        </el-table-column>
        <el-table-column :label="$t('DATE')" width="120" :align="'center'">
          <template slot-scope="scope">
            {{ $YYYYMMDD_HHMM(scope.row.created) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('STATUS')" width="200" :align="'center'">
          <template slot-scope="scope">
            {{ getPermiLabel(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('ACCOUNTC')">
          <template slot-scope="scope">
            {{ scope.row.companyInfo.name }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('CEO')" width="180">
          <template slot-scope="scope">
            {{ scope.row.companyInfo.ceo }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('USER')" width="150">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('ACCOUNT')" width="150">
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('TEL')" width="120" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.phone }}
          </template>
        </el-table-column>
      </el-table>
      <el-row :gutter="10">
        <el-col :xl="14" :md="14">
          <div v-if="checkedIds.length" class="mt-2">
            <el-button size="mini"
              v-for="(item, index) in salePrdPermis.filter(
                                                    permiItem => salePrdPermis.find(
                                                      items => items.value == request.objSearchMapper.salePermi).buttons.includes(permiItem.value))"
              :key="index" @click="handleUpdate(item.value)">
              {{ item.label }}
            </el-button>
          </div>
        </el-col>
        <el-col :xl="checkedIds.length ? 10 : 24" :md="checkedIds.length ? 10 : 24" class="right">
          <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="[10, 25, 50, 100]"
            :page-size="request.size" layout="sizes, prev, pager, next" :total="totalCount">
          </el-pagination>
        </el-col>
      </el-row>
    </div>
  
    <!-- <json-viewer :value="checkedIds" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  </div>
</template>
<script>
export default {
  name: 'salemanagerlist',
  components: {
  },
  props: ['salePermi'],
  watch: {
    salePermi: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.request.objSearchMapper.salePermi = newValue
        this.checkedIds = []
        this.tmpTableData = []
        this.read()

      },
    }
  },
  data() {
    return {
      buttonLists: [
        { label: '판매자신청', }
      ],
      salePrdPermis: [
        { value: 0, label: '미처리', buttons: [1, 2, 3, 5] },
        { value: 1, label: '판매자신청', buttons: [2, 3, 5, 6] },
        { value: 2, label: '판매자승인-이카운트재고운영', buttons: [4] },
        { value: 3, label: '판매자승인-자사재고운영', buttons: [4] },
        { value: 6, label: '판매자승인-서오재고운영', buttons: [4] },
        { value: 4, label: '판매자취소', buttons: [1] },
        { value: 5, label: '신청취소', buttons: [1, 2, 3] },
      ],
      searchKeyword: null,
      checkedIds: [],
      request: {
        objSearchMapper: {
          salePermi: null,
          searchKeyword: null,
        },
        page: 1,
        size: 10
      },

      // tables
      tmpTableData: [],
      totalCount: null,
    }
  },

  created() {
    // this.read()
  },
  methods: {
    getPermiLabel(item) {
      const permi = item?.authority?.salePermi ? item.authority.salePermi : 0
      return this.$t(this.$salePrdPermis.find(item => item.value == permi).label)
    },
    callback(item) {
      this.$emit('callback', { item, status: true })
    },
    searchRead(searchKeyword) {
      this.searchKeyword = searchKeyword
      this.request.objSearchMapper.searchKeyword = this.$trimLR(searchKeyword)
      this.read()
    },
    async read() {
      this.tmpTableData=[]
      this.request.objSearchMapper.searchKeyword = this.$trimLR(this.searchKeyword)
      await this.$http.post(`${this.$serverurl}/api/getPlatformUser_new`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.body.total
            this.callback(res.data)
          } else {
            this.tmpTableData = []
            this.callback(res.data)
          }
        })
    },

    async handleUpdate(salePermi) {
      // salePermi 값
      // 2 이카운트
      // 3 자사재고
      // 6 서오재고
      let auth = {}
      switch (salePermi) {
        case 1: { // 판매자신청 사용안함
          auth = {
            seoStockPermi: 1,
            ecountPermi: 1,   // 이카운트 사용
            deliPermi: 1,     // 배송관리 사용
            salePermi: 1      // 판매권한 사용
          }
          break;
        }
        case 2: { // 판매자승인 이카운트
          auth = {
            seoStockPermi: 1,
            ecountPermi: 0,   // 이카운트 사용
            deliPermi: 0,     // 배송관리 사용
            salePermi: 2,     // 판매권한 사용
            inPermi: 1,          // 1 입고거래
            outPermi: 0,      // 2 출고거래
            baseitemPermi: 1,        // 2 출고거래
          }
          break;
        }
        case 3: { // 판매자승인 자사재고
          auth = {
            seoStockPermi: 1,
            ecountPermi: 1,   // 이카운트 금지
            deliPermi: 0,     // 배송관리 사용
            salePermi: 3,     // 판매권한 사용
            inPermi: 0,          // 1 입고거래
            outPermi: 0,        // 2 출고거래
            baseitemPermi: 0,        // 2 출고거래
          }
          break;
        }
        case 6: { // 판매자승인 서오재고
          auth = {
            seoStockPermi: 0,
            ecountPermi: 1,   // 이카운트 금지
            deliPermi: 0,     // 배송관리 사용
            salePermi: 6,     // 판매권한 사용.
            inPermi: 1,          // 1 입고거래
            outPermi: 0,        // 2 출고거래
            baseitemPermi: 1,        // 2 출고거래
          }
          break;
        }
        case 4: { // 판매자승인 취소
          auth = {
            seoStockPermi: 1, ecountPermi: 1, deliPermi: 1, salePermi: 4,
            inPermi: 0,          // 1 입고거래
            outPermi: 0,      // 2 출고거래
            baseitemPermi: 0,        // 2 출고거래
          }

          break;
        }
        case 5: { // 판매자신청 취소
          auth = {
            seoStockPermi: 1, ecountPermi: 1, deliPermi: 1, salePermi: 5,
            inPermi: 0,          // 1 입고거래
            outPermi: 0,       // 2 출고거래
            baseitemPermi: 0,        // 2 출고거래
          }
          break;
        }
      }
      for (var item of this.checkedIds) {
        item.authority.seoStockPermi = auth.seoStockPermi
        item.authority.ecountPermi = auth.ecountPermi
        item.authority.deliPermi = auth.deliPermi
        item.authority.salePermi = auth.salePermi

        item.authority.inPermi = auth.inPermi
        item.authority.outPermi = auth.outPermi
        item.authority.baseitemPermi = auth.baseitemPermi


        // item.authority.inPermi = 1          // 1 입고거래
        // item.authority.outPermi = 1         // 2 출고거래
        item.authority.bizPermi = 1         // 3 영업통합관리
        // item.authority.deliPermi = 1        // 4 배송관리
        item.authority.mallPermi = 1        // 5 쇼핑몰관리
        // item.authority.salePermi = 0        // 6 판매자권한
        item.authority.stockPremi = 0       // 7 재고관리
        item.authority.accessStore = 0      // 8 창고관리
        // item.authority.accountType = 1      // 9 대표계정
        // item.authority.ecountPermi = 1      // 10 이카운트
        item.authority.memberPermi = 1      // 11 쇼핑몰회원관리
        item.authority.saleManager = 1      // 12 판매자관리
        item.authority.accountPermi = 1     // 13 통합관리권한
        item.authority.salePrdPermi = 1     // 14 판매상품관리
        // item.authority.baseitemPermi = 1    // 15 기초항목관리
        item.authority.accountBookPermi = 1 // 16 장부관리
        // item.authority.seoStockPermi = 1 // 17 서오재고

      }
      await this.$http.post(`${this.$serverurl}/api/setSalePrdPermi`, this.checkedIds, this.$setHeader())
        .then(res => {
          if (201 == res.data.code) {
            alert(res.data.message)
            this.checkedIds = []
            this.read()
          }
        })
    },

    pushId(seq, isChecked) {
      const exists = this.checkedIds.includes(seq);
      if (isChecked && !exists) {
        this.checkedIds.push(seq);
      } else if (!isChecked && exists) {
        const index = this.checkedIds.indexOf(seq);
        this.checkedIds.splice(index, 1);
      }
    },

    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }

  }
}
</script>
<style></style>
