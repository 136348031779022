<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'500px'" :modal=true :show-close="false"
    :before-close="handleClose" :close-on-press-escape="true" v-draggable>
    <div class="borderbox-dark1px pl-4 pr-4" style="word-spacing: 2px;">
      <el-row :gutter="10" class="mb-2">
        <el-col :xl="6" :md="6"> 사업자등록번호 </el-col>
        <el-col :xl="18" :md="18" class="right"> {{ tmpDataList.license }} </el-col>
      </el-row>
      <el-row :gutter="10" class="mb-1">
        <el-col :xl="6" :md="6"> 상호 </el-col>
        <el-col :xl="18" :md="18" class="right"> {{ tmpDataList.name }} </el-col>
      </el-row>
      <el-row :gutter="10" class="mb-1">
        <el-col :xl="6" :md="6"> 대표자 </el-col>
        <el-col :xl="18" :md="18" class="right"> {{ tmpDataList.ceo }} </el-col>
      </el-row>
      <el-row :gutter="10" class="mb-1">
        <el-col :xl="6" :md="6"> 주소 </el-col>
        <el-col :xl="18" :md="18" class="right"> {{ `${tmpDataList.addr} ${tmpDataList.addrSub}` }} </el-col>
      </el-row>
      <el-row :gutter="10" class="mb-1">
        <el-col :xl="6" :md="6"> 이메일 </el-col>
        <el-col :xl="18" :md="18" class="right"> {{ `${$nulltom(tmpDataList.mail)}` }} </el-col>
      </el-row>
      <el-row :gutter="10" class="mb-1">
        <el-col :xl="6" :md="6"> 연락처 </el-col>
        <el-col :xl="18" :md="18" class="right"> {{ `${$nulltom(tmpDataList.tel)}` }} </el-col>
      </el-row>
      <el-row :gutter="10" class="mb-1">
        <el-col :xl="6" :md="6"> 팩스 </el-col>
        <el-col :xl="18" :md="18" class="right"> {{ `${$nulltom(tmpDataList.fax)}` }} </el-col>
      </el-row>
      <el-row :gutter="10" class="mb-1">
        <el-col :xl="6" :md="6"> 모바일 </el-col>
        <el-col :xl="18" :md="18" class="right"> {{ `${$nulltom(tmpDataList.phone)}` }} </el-col>
      </el-row>
      <div class="borderbottom1px mb-1"></div>
      <table class="font13 mt-2 w100p">
      <tr>
        <th class="w50">ID</th>
        <th class="w120">계정</th>
        <th class="w100">이름</th>
        <th class="w100">전화</th>
        <th class="right">상태</th>
      </tr>
      <tr v-for="(item, index) in tmpMemberInfo" :key="index">
        <td>{{ item.seq }}</td>
        <td>{{item.id }}</td>
        <td>{{item.name }}</td>
        <td>{{ item.phone }}</td>
        <td class="right">{{ item.state == 0 ? $t('N') : $t('S') }}</td>
      </tr>
      <tr v-if="!tmpDataList">
        <td colspan="6" class="center">검색된 정보가 없습니다.</td>
      </tr>
    </table>
    </div>

    <div class="row mt-3">
      <div class="col right">
        <el-button size="mini" @click="handleClose">창닫기</el-button>
  
      </div>
    </div>
    <customerCreate v-if="showcustomerCreate" @callback="callbackCustomerCreate" />
  </el-dialog>
</template>
<script>
export default {
  name: 'memberlist',
  components: {},
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.tmpDataList = newValue
        this.tmpMemberInfo = newValue.accountInfo
        this.title = this.tmpDataList.name
        this.request.totalCount = this.tmpMemberInfo.length
      }
    }
  },
  data() {
    return {
      showcustomerCreate: false,
      visible: true,
      title: '회사세부정보',
      tmpMemberInfo: [],
      tmpDataList: null,
      request: {
        page: 1,
        size: 100,
        totalCount: 0,
        pageSize: [100],
      }
    }
  },
  async created() {
  },
  methods: {
    // async read() {
    //   const res = await this.$http.post(`${this.$serverurl}/api/getMallMemberInfo`, this.request)
    //   if (200 == res.data.code) {
    //     this.tmpDataList = res.data.body.results
    //     this.totalCount = res.data.totalCount
    //     if (this.tmpDataList.length == 1) {
    //     }
    //   } else {
    //     this.tmpDataList = []
    //     this.totalCount = 0
    //   }

    // },
    handleClose(done) {
      this.$emit('callback', { item: null, status: false, new: false })
    },

    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }
  }
}
</script>
<style></style>