<template>
  <div class="container mt-3 relative" :style="$pageWidth">
    <TopBar />
    <div class="mt-2">
      <el-row>
        <el-col :xl="14" :md="14">
          <el-button size="mini" v-for="item, index in $saleProcess" :key="index"
            :type="activeName == index ? 'primary' : ''" @click="handleButtons(index)">{{ $t(item.label) }}({{
            getCount(index) }})</el-button>
        </el-col>
        <el-col :xl="10" :md="10" class="right">
          <el-button
          size="mini" :type="zeroQty == 1 ? 'danger' : ''" @click="zeroFilter(1)">
            <i :class="zeroQty==1?'fa-solid fa-square-check':'fa-regular fa-square-check'"></i>
            0재고(주문미확인)
          </el-button>
          <el-button 
          size="mini" :type="zeroQty == 2 ? 'danger' : ''" @click="zeroFilter(2)">
            <i :class="zeroQty==2?'fa-solid fa-square-check':'fa-regular fa-square-check'"></i>
            0재고(주문완료)
          </el-button>
          <el-button size="mini" :type="'danger'" @click="showecoungconfig(2)">
            이카운트연동설정
          </el-button>
        </el-col>
      </el-row>
      <div class="mt-2">
        <ecountlist v-if="!zeroQty" :append="activeName" @callback="ecountListCallback" />
        <ecountfilterlist v-if="zeroQty" :zeroQty="zeroQty" />
      </div>
    </div>
    <ecountconfig v-if="ecountconfigvisible" @callback="ecountconfigCallback" />
  </div>
</template>
<script>
import sotragelist from './component-storage-list.vue'
import customerinfo from './component-customer-list.vue'
import TopBar from './component-top.vue'
import ecountlist from './component-ecount-list.vue'
import ecountfilterlist from './component-ecount-filter-list.vue'
import ecountconfig from './component-ecount-config.vue'
export default {
  components: {
    TopBar, customerinfo, sotragelist, ecountlist, ecountfilterlist, ecountconfig
  },
  data() {
    return {
      ecountconfigvisible: false,
      zeroQty: 0,
      viewvisible: false,
      selectItem: null,
      activeName: 0,
      countList: [],
    }
  },
  created(){
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    this.authority = this.userlogininfo.authority
  },
  methods: {
    showecoungconfig(params) {
      this.ecountconfigvisible = params
    },
    ecountconfigCallback(data) {
      if (data) {
        this.$router.go(0)
      }
      this.ecountconfigvisible = false
    },
    zeroFilter(value) {
      if (this.zeroQty != value) {
        this.zeroQty = value
      } else {
        this.zeroQty = 0
      }
    },
    viewallback(data) {
      if (data.status) {
        this.selectItem = data.item
        this.activeName = 'orderininsert'
      }
      this.viewvisible = false
    },
    handleButtons(params) {
      this.zeroQty = 0
      this.activeName = params;
      this.selectItem = null
    },
    ecountListCallback(data) {
      if (data.status) {
        this.viewvisible = true
        this.selectItem = data.item
      } else {
        this.countList = data.item
      }

      // this.activeName = 'orderincreate'
    },
    getCount(index) {
      return this.countList[index]?.count
    },
    handleClick(tab, event) { },
  }
}
</script>
<style></style>
