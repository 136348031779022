<template>
  <div class="container mt-3 relative" :style="$pageWidth">
    <TopBar />
    <div class="mt-2">
      <el-row>
        <el-col :xl="24" :md="24">
          <el-button v-for="(item, index) in categorys" :key="index" size="mini"
            :type="activeName == item.route ? 'primary' : ''" @click="handleButtons(item.route)">
            {{ $t(item.label) }}
          </el-button>
        </el-col>
      </el-row>
        <div class="mt-2">
        <component :is="activeName"></component>
      </div>
    </div>
  </div>
</template>
<script>
import erpmanagercompany from './component-erp-manager-company.vue'
import TopBar from './component-top.vue'
export default {
  components: {
    TopBar,  erpmanagercompany
  },
  data() {
    return {
      activeName: 'erpmanagercompany',
      categorys: [
        { value: 1, label: 'COMPANY.M', route: 'erpmanagercompany' }, //회사관리
      ]
    }
  },
  created() { },
  methods: {

    handleButtons(params) {
      this.activeName = params
    },

  }
}
</script>
<style></style>
