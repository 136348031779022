<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'800px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable>
    <card>
      <el-row>
        <el-col :xl="4" :md="4" class="lh25">{{$t('TITLE')}}*</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('TITLE')" maxlength="100" v-model="formData.title" size="mini" show-word-limit>
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('TYPE')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-select size="mini" v-model="formData.type" class="me-3 w100p">
            <el-option v-for="item in $noticeTypeList" 
            :key="item.value" 
            :label="$t(item.label)" 
            :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('CATEGORY')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-select size="mini" v-model="formData.category" class="me-3 w100p">
            <el-option v-for="item in $noticeCategory" :key="item.value" :label="$t(item.label)" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('CONTENT')}}*</el-col>
        <el-col :xl="20" :md="20">
          <vue-editor :editor-toolbar="customToolbar"
            style="height: 200px; margin-bottom: 10px; border-radius: 5px !important;"
            v-model="formData.content"></vue-editor>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-5">
        <el-col :xl="4" :md="4" class="lh25">{{$t('TAG')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input type="text" :placeholder="$t('TAG')" maxlength="100" v-model="formData.tag" size="mini" show-word-limit>
          </el-input></el-col>
      </el-row>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">
          {{$t('CLOSE')}}
        </el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange" @click="setBaseInfo">
          {{$t('SUBMIT')}}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'mallmanagernoticecreate',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.formData = {
          type: newValue?.type || 0,
          category: newValue?.category || 0,
          content: newValue?.content || '',
          title: newValue?.title || '',
          tag: newValue?.tag || '',
          seq: newValue?.seq || null,
        }
      }
    },
  },
  data() {
    return {
      visible: true,
      title: '공지등록',
      tmpCustomerList: [],
      total: 0,
      formData: {
        type: 0,
        category: 0,
        content: '',
        title: '',
        tag: '',
        seq: null,
      },
      customToolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],    // 제목 크기
        ['bold', 'italic', 'underline', 'strike'],    // 굵게, 기울임, 밑줄, 취소선
        // [{ 'list': 'ordered' }, { 'list': 'bullet' }], // 순서 있는 목록, 순서 없는 목록
        // [{ 'indent': '-1' }, { 'indent': '+1' }],      // 들여쓰기, 내어쓰기
        [{ 'align': [] }],                            // 정렬: 왼쪽, 가운데, 오른쪽, 양쪽
        ['link', ],                  //'image', 'video' 링크, 이미지, 동영상 삽입
        [{ 'color': [] }, { 'background': [] }],     // 글자색, 배경색 설정
        // ['blockquote', 'code-block'],                 // 인용구, 코드 블록
        // ['clean']                                     // 모든 스타일 제거
      ]
    }
  },
  async created() {
  },
  methods: {
    async setBaseInfo() {
      if (this.formData.title.trim() == '') {
        alert('제목을 입력하세요.')
        return
      }
      if (this.formData.content == 0) {
        alert('내용을 입력하세요.')
        return
      }
      const res = await this.$http.post(`${this.$serverurl}/api/setMallBoardInfo`, this.formData)
      if (201 == res.data.code) {
        alert(res.data.message)
        this.setCallback()
      }

    },
    setCallback() {
      this.$emit('callback', true)
    },
    notClose() { },
    handleClose(done) {
      // done()
      this.$emit('callback', false)
    },

  }
}
</script>
<style></style>