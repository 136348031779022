<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%;margin-bottom:20px;">
      <el-row :gutter="10">
        <el-col :md="12">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="searchKeyword" style="max-width: 200px;" class="mr-2"
            @keyup.enter.native="searchRead(searchKeyword)">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="searchRead(searchKeyword)"></i>
          </el-input>
          <el-button type="primary" icon="el-icon-search" size="mini"
            @click="searchRead(searchKeyword)">{{$t('SEARCH')}}</el-button>
          <el-button type="primary" icon="el-icon-tickets" size="mini" v-if="searchKeyword"
            @click="searchRead(null)">{{$t('VIEWALL')}}</el-button>
        </el-col>
        <el-col :md="12" class="right">
          <el-date-picker size="mini" v-model="startDate" type="date" :placeholder="$t('STARTDATE')" format="yyyy-MM-dd"
            style="max-width: 200px;">
          </el-date-picker>
          ~
          <el-date-picker size="mini" v-model="endDate" type="date" :placeholder="$t('ENDDATE')" class="mr-2"
            style="max-width: 200px;">
          </el-date-picker>
          <el-button type="" size="mini" @click="read()">{{$t('SEARCH')}}</el-button>
        </el-col>
      </el-row>
    </card>
    <div>
      <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13">
        <el-table-column :label="$t('ENDDATE')" width="120" :align="'center'">
          <template slot-scope="scope">
            {{ $YYYYMMDD_HHMM(scope.row.created) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('CUSTOMER')" width="150" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.customerInfo.name }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('CUSTOMER')">
          <template slot-scope="scope">
            <span class="ellip"> {{ $listMoreBaseItems(scope.row.orderInItemInfo) }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('AMOUNT')" width="100" :align="'right'">
          <template slot-scope="scope">
            {{ $comma($subreduce(scope.row.orderInItemInfo, 'inPrice', 'inQty')) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('TYPE')" width="100" :align="'center'">
          <template slot-scope="scope">
            {{ $t($vatType[scope.row.vat].label )}}
          </template>
        </el-table-column>
        <el-table-column :label="$t('STORAGE')" width="100" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.storageInfo.name }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="명세서" width="80" :align="'center'">
              <template>
                <el-button size="mini" class="btn-mini"
                >미완성</el-button>
              </template>
            </el-table-column> -->
        <el-table-column :label="$t('DETAIL')" width="80" :align="'center'">
          <template slot-scope="scope">
            <el-button size="mini" class="btn-mini" @click="orderView(scope.row)">
              {{ $t('DETAIL') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="[10, 25, 50, 100]"
        :page-size="request.size" layout="sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
    </div>
    <!-- <json-viewer :value="tmpTableData" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  
  </div>
</template>
<script>
export default {
  name: 'orderinlist',
  components: {
  },
  data() {
    return {
      formData: {
        tradeDate: null,
        vat: 0,
        custoemrId: null,
      },
      startDate: null,
      endDate: null,
      searchKeyword: null,
      request: {
        objSearchMapper: {
          startDate: null,
          endDate: null,
          searchKeyword: null,
        },
        page: 1,
        size: 10
      },

      // tables
      tmpTableData: [],
      totalCount: null,
    }
  },

  created() {
    this.read()
  },
  methods: {
    orderView(item) {
      this.$emit('callback', { item, status: true })
    },
    searchRead(searchKeyword) {
      this.searchKeyword = searchKeyword
      this.request.objSearchMapper.searchKeyword = this.$trimLR(searchKeyword)
      this.read()
    },
    async read() {
      this.request.objSearchMapper.searchKeyword = this.$trimLR(this.searchKeyword)
      if (this.startDate && this.endDate) {
        this.request.objSearchMapper.startDate = this.$dateToYYYYMMDD(this.startDate)
        this.request.objSearchMapper.endDate = this.$dateToYYYYMMDD(this.endDate)
      }
      await this.$http.post(`${this.$serverurl}/api/getOrderInInfo`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.body.total
          }
        })
    },


    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }

  }
}
</script>
<style></style>
