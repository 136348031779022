<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'600px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable>
    <card>
      <el-row>
        <el-col :xl="4" :md="4" class="lh25">{{$t('COMP.CODE')}}*</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('COMP.CODE')" maxlength="10" v-model="formData.ecountCode" size="mini"
            show-word-limit>
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('ACCOUNT')}}*</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('ACCOUNT')" maxlength="20" v-model="formData.ecountId" size="mini" show-word-limit>
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">API KEY</el-col>
        <el-col :xl="20" :md="20">
          <el-input type="text" placeholder="API KEY" maxlength="100" v-model="formData.ecountKey" size="mini"
            show-word-limit>
          </el-input></el-col>
      </el-row>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">창닫기</el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange" @click="setEcountSessionEx">정보제출</el-button>
  
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'ecountconfig',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.formData = {
          ecountCode: '',
          ecountId: '',
          ecountKey: '',
        }
      }
    },
  },
  data() {
    return {
      visible: true,
      title: '이카운트연동설정',
      total: 0,
      formData: {
        ecountCode: '',
        ecountId: '',
        ecountKey: '',
      },
    }
  },
  async created() {
    this.read()
  },
  methods: {
    async setEcountSessionEx() {
      if (this.formData.ecountCode.toString().trim() == '') {
        alert('회사코드를 입력하세요.')
        return
      }
      if (this.formData.ecountId.trim() == '') {
        alert('이카운트계정을 입력하세요.')
        return
      }
      if (this.formData.ecountKey.trim() == '') {
        alert('API KEY를 입력하세요.')
        return
      }
      // await this.$http.post(`${this.$serverurl}/api/setEcountSessionEx`, this.formData, this.$setHeader())
      //   .then(res => {
      //     if (201 == res.data.code) {
      //       alert(res.data.message)
      //       this.setCallback()
      //     }
      //   })

        await this.$http.post(`${this.$springurl}/api/setEcountSessionEx`, this.formData, this.$setHeader())
        .then(res => {
          if (201 == res.data.code) {
            alert(res.data.message)
            // this.setCallback()
          }
        })

    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getMyCompanyInfo`, null, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            const tmpData = res.data.body.results
            this.formData = {
              ecountCode: tmpData.ecountSessionEx[0]?.ecountCode || '',
              ecountId: tmpData.ecountSessionEx[0]?.ecountId || '',
              ecountKey: tmpData.ecountSessionEx[0]?.ecountKey || '',
              seq: tmpData.ecountSessionEx[0]?.seq || null
            }
          }
        })
    },
    
    setCallback() {
      this.$emit('callback', true)
    },
    notClose() { },
    handleClose(done) {
      // done()
      this.$emit('callback', false)
    },

  }
}
</script>
<style></style>