<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%;margin-bottom:10px;">
      <el-row :gutter="10">
        <el-col :md="12">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="searchKeyword" style="max-width: 200px;" class="mr-2"
            @keyup.enter.native="searchRead(searchKeyword)">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="searchRead(searchKeyword)"></i>
          </el-input>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="searchRead(searchKeyword)">
            {{$t('SEARCH')}}
          </el-button>
          <el-button type="primary" icon="el-icon-tickets" size="mini" v-if="searchKeyword"
            @click="searchRead(null)">{{$t('VIEWALL')}}</el-button>
        </el-col>
        <el-col :md="12" class="right">
        </el-col>
      </el-row>
    </card>
    <div>
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
        :page-size="request.size" layout="sizes, prev, pager, next" :total="totalCount" v-if="tmpTableData.length">
      </el-pagination>
      <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13">
        <el-table-column label="ID" width="70" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.seq }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('ITEM')" :align="'left'">
          <template slot-scope="scope">
            {{ getItemTitle(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('AMOUNT')" width="100" :align="'right'">
          <template slot-scope="scope">
            {{ $comma(getItemSupplyPrice(scope.row)) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('LC')" width="90" :align="'center'">
          <template slot-scope="scope">
            {{ $deliType.find(items => items.value == scope.row.shippingValue).label }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('TYPE')" width="90" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.shippingPayType == 0 ? '선불' : '착불' }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('PUR')" width="150" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.companyInfo.name }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('RCP')" width="100" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('SHIPPING.COPY')" width="100" :align="'center'">
          <template slot="header">
            <span class="font10">{{ $t('SHIPPING.COPY') }}</span>
          </template>
          <template slot-scope="scope">
            <el-button size="mini" class="badge hand el-btn-red" slot="reference" @click="copyContent(scope.row)">
              <span class="font10">{{ $t('SHIPPING.COPY') }}</span>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column :label="$t('OR')" width="80" :align="'center'">
          <template slot-scope="scope">
            <el-button size="mini" class="btn-mini hand" slot="reference"
              @click="orderView(scope.row)">{{$t('DETAIL')}}</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column label="출고" width="80" :align="'center'" v-if="status == 1">
                            <template slot-scope="scope">
                              <el-button size="mini" class="btn-mini hand" slot="reference" @click="orderView(scope.row)">보기</el-button>
                            </template>
                          </el-table-column>
                          <el-table-column label="배송" width="80" :align="'center'" v-if="status == 2">
                            <template slot-scope="scope">
                              <el-button size="mini" class="btn-mini hand" slot="reference" @click="orderView(scope.row)">보기</el-button>
                            </template>
                          </el-table-column> -->
      </el-table>
  
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
        :page-size="request.size" layout="sizes, prev, pager, next" :total="totalCount" v-if="tmpTableData.length">
      </el-pagination>
    </div>
    <ordersaleview v-if="showorderdetailview" :data="selectItem" @callback="ordersalesviewcallback" />
  
    <!-- <json-viewer :value="tmpTableData" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  </div>
</template>
<script>
import ordersaleview from './component-order-sale-view.vue'
import copy from 'copy-text-to-clipboard'
import { Loading } from 'element-ui'
export default {
  name: 'ordersaleist',
  components: { ordersaleview },
  props: ['status', 'returnGoodsStatus'],
  watch: {
    status: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue != null) {
          this.request.objSearchMapper.status = newValue
          this.request.objSearchMapper.returnGoodsStatus = null
          this.read()
        }
      }
    },
    returnGoodsStatus: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue != null) {
          this.request.objSearchMapper.returnGoodsStatus = newValue
          this.request.objSearchMapper.status = null,
            this.read()
        }
      }
    },
  },
  data() {
    return {
      showorderdetailview: false,
      shippingInfo: null,
      selectItem: null,
      searchKeyword: null,
      request: {
        objSearchMapper: {
          returnGoodsStatus: null,
          status: 0,
          searchKeyword: null,
        },
        page: 1,
        size: 15
      },
      pageSizes: [15, 30, 50, 100],

      // tables
      tmpTableData: [],
      totalCount: null,
      loading: null,
      userlogininfo: null,
    }
  },

  created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
  },
  methods: {

    // 상세보기
    orderView(item) {
      this.showorderdetailview = true
      this.selectItem = item
    },
    ordersalesviewcallback(data) {
      if (data.status) { this.read() }
      this.showorderdetailview = false
    },
    // 검색함수
    searchRead(searchKeyword) {
      this.searchKeyword = searchKeyword
      this.request.objSearchMapper.searchKeyword = this.$trimLR(searchKeyword)
      this.read()
    },
    // 데이터 리드
    async read() {
      this.loading = Loading.service({ fullscreen: true })
      await this.$http.post(`${this.$serverurl}/api/getMallOrderInfo_new`, this.request, this.$setHeader())
        .then(res => {
          if ('OK' == res.data.statusCode) {
            this.tmpTableData = res.data.body.results
            if (this.returnGoodsStatus < 3) {
              this.tmpTableData = this.tmpTableData.map(order => {
                order.mallOrderProductInfo = order.mallOrderProductInfo.filter(item => !item.returnGoodsItem);
                return order;
              }).filter(order => order.mallOrderProductInfo.length > 0);
            }
            this.totalCount = res.data.body.results.length
          } else {
            this.tmpTableData = []
            this.totalCount = null
          }
          this.$emit('callback', { item: res.data.body.status, status: true })
        })
      this.$overay(this.loading)
    },



    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    },

    getItemTitle(params) {
      if (params.mallOrderProductInfo?.length > 1) {
        const itemName = params.mallOrderProductInfo[0].salesProductInfo?.name
        return itemName + ' ' + ' 외 ' + (params.mallOrderProductInfo?.length - 1) + '건'
      } else {
        if (params.mallOrderProductInfo.length == 0) { return '-' }
        const itemName = params.mallOrderProductInfo[0].salesProductInfo?.name
        return itemName
      }
    },
    getItemSupplyPrice(params) {
      if (params.mallOrderProductInfo?.length > 0) {
        const tmpCount = Object.values(params.mallOrderProductInfo).reduce((total, itemInfo) => {
          return total + (this.userlogininfo.companyInfo.vat==0?itemInfo.price:Math.round(itemInfo.price*1.1)) * itemInfo.qty;
        }, 0);
        return tmpCount
      } else {
        return 0
      }
    },
    shippingInfoView(item) {
      this.shippingInfo = `${item.arriveName} ${item.arrivePhone}<br>${item.arrivePostNum}`
    },

    copyContent(params, evnet) {
      this.visible = false
      let orderProduct = '⏩주문내역\n'
      for (var item of params.mallOrderProductInfo) {
        orderProduct += '✅ ' + item.salesProductInfo.name + ' ' + item.salesProductInfo.pid + '\n'
      }
      const 공급받는자 = `${orderProduct.toString()}
      ⏩ 공급받는자
          ✅ 사업자번호: ${(params.platformCompanyInfo.license)}
          ✅ 상호: ${params.platformCompanyInfo.name}(${params.platformCompanyInfo.ceo}) ${params.platformCompanyInfo.phone}
          ✅ 주소: ${params.platformCompanyInfo.addr}\n
          `
      const 도착지주소 = `
      ⏩ 도착지주소
          ✅ 우편번호: ${params.postNum}
          ✅ 수취인: ${params.name}
          ✅ 연락처: ${params.phone}
          ✅ 주소:${params.address} ${params.addrSub}
      `
      const 발송자정보 = `
      ⏩ 발송자정보
          ✅ 발송자: ${params.fromName}
          ✅ 연락처: ${params.fromPhone}
      `
      const 통관신고회사 = `
      ⏩ 통관신고회사
          ✅ 사업자등록번호: ${params.companyInfo.license}
          ✅ 상호: ${params.companyInfo.name}
          ✅ 소재지:  ${params.companyInfo.addrMain} ${params.companyInfo.addrSub}
          ✅ 연락번호: ${params.companyInfo.phone}
      `

      const 배송방식 = `
      ⏩ 배송정보
          ✅ 부가정보: ${params.barcode}
          ✅ 배송사: ${this.$deliType.find(item => item.value == params.shippingValue).label}
          ✅ 결제구분: ${this.$shippingtype.find(item => item.value == params.shippingPayType).label}
      `

      let copyText = 공급받는자 + 배송방식 + 발송자정보
      if (params.shippingValue != 0) {
        copyText += 도착지주소
      }
      if (this.userlogininfo.companyInfo.level == 0 && params.mallOrderProductInfo[0].storageInfo.import==1) { copyText += 통관신고회사 }
      copy(copyText + '')
      let color = Math.floor((Math.random() * 4) + 1)
      this.$notify.success({
        title: '',
        message: '배송정보가 복사되었습니다.',
        position: 'bottom-right',
        showClose: true,
        duration: 1000,
      });
    },

  }
}
</script>
<style></style>
