<template>
  <div class="container mt-3 relative noselect" :style="$pageWidth">
    <TopBar @sendToParent="callback" />
    <!-- <div v-if="userlogininfo &&userlogininfo.authority.accountPermi==0" @click="seoOrderStatus('cancel')" class="btn">
      서오모니터링 거래취소</div>
    @
    <div v-if="userlogininfo &&userlogininfo.authority.accountPermi==0" @click="seoOrderStatus('confirm')" class="btn">
      서오모니터링 주문확인</div> -->
   
    <div v-if="tmpTableData">
      <el-row :gutter="10">
        <el-col>
          <div class="gradient-box-blue mt-4" v-if="userlogininfo.authority.salePermi!=0">
            <i class="fa-solid fa-universal-access mr-2"></i>
            {{salePermiStatus()}}
          </div>
          <div v-else class="hand gradient-box-blue mt-4" @click="$changeRoute('saleApply')">
            <i class="fa-solid fa-universal-access mr-3"></i>
            {{salePermiStatus()}}
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="4">
          <div class="gradient-box-dark mt-3">
            <i class="fa-solid fa-user-tie mr-3"></i>
            <span class="mr-3"> 등록계정 </span>
            {{$comma(tmpTableData.accountTotal)}}건
          </div>
        </el-col>
        <el-col :span="4" v-if="tmpTableData.productTotal">
          <div class="gradient-box-dark mt-3">
            <i class="fa-solid fa-shirt mr-2"></i>
            <span class="mr-3">판매상품 </span>
            {{$comma(tmpTableData.productTotal.totalQty)}}건
          </div>
        </el-col>
      </el-row>
    </div>
  
    <el-backtop>
    </el-backtop>
  </div>
</template>

<script>
import TopBar from './component-top.vue'
export default {
  // mounted() {
  //   // 실시간 디비읽어오는 코드
  //   this.$setHeader()
  //   const eventSource = new EventSource(
  //     'http://localhost:1234/sse?ExJwtAuthorization='+
  //   this.$http.defaults.headers.common['ExJwtAuthorization']);
  //   eventSource.onmessage = (event) => {
  //     console.log(event.data)
  //   };
  //   eventSource.onerror = (error) => {
  //     console.error('EventSource failed:', error);
  //     eventSource.close();
  //   };
  // },
  components: {
    TopBar,
  },
  data() {
    return {
      req: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 12
      },
      tmpTableData: [],
      subList: [],
      totalCount: 0,
    };
  },
  beforeDestroy() {
  },

  async created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    this.read()
  },
  methods: {
    async read() {
      await this.$http.get(`${this.$serverurl}/sse?ExJwtAuthorization`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
          }
        })
    },

    async seoOrderStatus(param) {
      const params = {
        order_number: '20240702-46', // [필수] 주문번호
        order_status: param,      // [필수] recep confirm cancel 접수 확인 취소 
        order_deli_code: 'Bus', // [선택] 배송정보 배송코드는 서오에서 제공한 코드사용
        order_deli_order: '01055513210' || null // [선택] 운송장번호
      }
      this.$http.defaults.headers.common['Authorization'] = `59546f65a1c82e0972ce744334f38da778b20e53aeddbf85c8ad6023402f997f4a9eeacd55842ed639f1a6c8bf6ced6a6ee637622d9a42d8a1f4dd858ba57ffc`
      const url = `${this.$serverurl}/api/seoOrderStatus`

      await this.$http.get(url, { params })
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
          }
        })
    },


    salePermiStatus() {
      switch (this.userlogininfo.authority.salePermi) {
        case 0: { return '판매자신청하기' }
        case 1: { return '판매자 신청중' }
        case 2: { return '판매권한 - ECOUNT' }
        case 6: { return '판매권한 - 서오재고' }
        case 3: { return '판매권한 - 자사재고' }
        case 4: { return '판매자 승인취소' }
        case 5: { return '판매자 신청취소' }
      }
    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getTotalCountInfo`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
          }
        })
    },


    callback(data) {
      this.req.objSearchMapper.searchKeyword = data
      this.read()
    },

    handleClick(seq) {
      // if (this.$isMobile) {
      this.$router.push('/productView/' + seq)
      // }
    },


  }

}
</script>

<style>
.mobile-scroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.carousel-container .VueCarousel-inner {
  max-width: 280px;
}

.carousel-container .VueCarousel-slide {
  width: 140px;
  margin: 5px;
}

.carousel-container .VueCarousel-pagination .VueCarousel-dot-container {
  margin: 0px !important;
}

.carousel-container .VueCarousel-pagination .VueCarousel-dot-container .VueCarousel-dot {
  margin: 0px !important;
}

@media (max-width: 767px) {
  .carousel-container .productView {
    min-width: 90px;
  }

  .carousel-container .VueCarousel-inner {
    max-width: 198px;
  }

  .carousel-container .VueCarousel-slide {
    max-width: 100px;
    margin: 5px;
  }

  .carousel-container .VueCarousel-dot-container {
    display: none !important;
  }
}

@media (min-width: 768px) {

  /* // 일반화면 */
  .mobile-scroll {
    overflow-x: auto;
  }
}
</style>
