<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'1000px'" :modal=true :show-close="false"
    :before-close="handleClose" v-draggable>
    <card>
      <el-row :gutter="10">
        <el-col :xl="24" :md="24">
          <table class="table-params w100p" v-if="itemInfo.io=='out'">
            <tbody>
              <tr>
                <th class="center" width="120">{{$t('DATE')}}</th>
                <th class="" width="150">{{$t('CUSTOMER')}}</th>
                <th class="" width="120">{{$t('FACTORYNUM')}}</th>
                <th class="">{{$t('ITEM')}}</th>
                <th class="right" width="80">{{$t('AMOUNT')}}</th>
                <th class="right" width="50">{{$t('QTY')}}</th>
                <th class="right" width="80">{{$t('SUPPLY.P')}}</th>
                <th class="right" width="80">{{$t('VAT')}}</th>
                <th class="right" width="80">{{$t('SUBTOTAL')}}</th>
              </tr>
              <tr v-for="(item, index) in itemInfo.orderOutItemInfo" :key="index">
                <td class="center"> {{ $YYYYMMDD_HHMM(item.created)}}</td>
                <td> {{ item.customerInfo.name }} </td>
                <td> {{ itemInfo.baseInfo.factoryNum }} </td>
                <td> <span class="ellip">
                  {{ itemInfo.baseInfo.name }} 
                </span></td>
                <td class="right"> {{ $comma(item.outPrice) || '-' }}</td>
                <td class="right"> {{ $comma(item.outQty) || '-' }}</td>
                <td class="right"> {{ $comma($amountSplitTax(item.outPrice,'Y').supplyAmount) || '-' }}</td>
                <td class="right"> {{ $comma($amountSplitTax(item.outPrice,'Y').vatAmount) || '-' }}</td>
                <td class="right"> {{ $comma(item.outPrice * item.outQty || '-') }}</td>
              </tr>
            </tbody>
          </table>
          <table class="table-params w100p" v-if="itemInfo.io=='in'">
            <tbody>
              <tr>
                <th class="center" width="120">{{$t('DATE')}}</th>
                <th class="" width="150">{{$t('CUSTOMER')}}</th>
                <th class="" width="120">{{$t('FACTORYNUM')}}</th>
                <th class="">{{$t('ITEM')}}</th>
                <th class="right" width="80">{{$t('AMOUNT')}}</th>
                <th class="right" width="50">{{$t('QTY')}}</th>
                <th class="right" width="80">{{$t('SUPPLY.P')}}</th>
                <th class="right" width="80">{{$t('VAT')}}</th>
                <th class="right" width="80">{{$t('SUBTOTAL')}}</th>
              </tr>
              <tr v-for="(item, index) in itemInfo.orderInItemInfo" :key="index">
                <td class="center"> {{ $YYYYMMDD_HHMM(item.created)}}</td>
                <td> {{ item.customerInfo.name }} </td>
                <td> {{ itemInfo.baseInfo.factoryNum }} </td>
                <td> <span class="ellip">
                  {{ itemInfo.baseInfo.name }} 
                </span></td>
                <td class="right"> {{ $comma(item.inPrice) || '-' }}</td>
                <td class="right"> {{ $comma(item.inQty) || '-' }}</td>
                <td class="right"> {{ $comma($amountSplitTax(item.inPrice,'Y').supplyAmount) || '-' }}</td>
                <td class="right"> {{ $comma($amountSplitTax(item.inPrice,'Y').vatAmount) || '-' }}</td>
                <td class="right"> {{ $comma(item.inPrice * item.inQty || '-') }}</td>
              </tr>
            </tbody>
          </table>
        </el-col>
      </el-row>
    </card>
    <div class="text-right">
      <el-button size="mini" type="" @click="handleClose">
        {{$t('CLOSE')}}</el-button>
    </div>
    <!-- <json-viewer :value="ioInfoList" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  </el-dialog>
</template>
<script>
export default {
  name: 'countslistview',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.itemInfo = newValue
        this.title = newValue.io == 'out' ? '판매현황' : '매입현황'
      }
    },
  },
  data() {
    return {
      tmpStorageDataList: [],
      visible: true,
      title: '##',
      total: 0,
      itemInfo: {},
      request: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 10
      },
    }
  },
  async created() {
  },
  methods: {
    notClose() { },
    handleUpdateClose() {
      const data = {
        item: this.itemInfo,
        status: true
      }
      this.$emit('callback', data)
    },
    handleClose() {
      const data = {
        status: false
      }
      this.$emit('callback', data)
    },

  }
}
</script>
<style></style>