<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'300px'" :modal=true :show-close="false"
    :close-on-press-escape="true" v-draggable :before-close="handleClose">
    <table class="table-params table-hover w100p table-hover font13 mt-2">
      <tr>
        <th class="w50 center">선택</th>
        <th>구분</th>
      </tr>
      <tr v-for="(item, index) in $productType" :key="index">
        <td class="center">
          <span class="badge font12 badge-orange hand" @click="$emit('callback', { item, status: true })">선택</span>
        </td>
        <td>{{ $t(item.label) }}</td>
      </tr>
    </table>
    <div class="row mt-3">
      <div class="col right">
        <el-button size="mini" @click="$emit('callback', { item: null, status: false })">창닫기</el-button>

      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'selectsalesproducttype',
  components: {  },
  props: ['prodType'],
  watch: {
    prodType: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.prdType = newValue
      },
    },
  },
  data() {
    return {
      visible: true,
      title: '제품구분선택',
      prdType:null,
    }
  },
  async created() {  },
  methods: {
    handleClose(done){
      this.$emit('callback',{data:null,status:false})
    }
    },

  }

</script>
<style></style>