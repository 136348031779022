<template>
  <div>
    <card style="width: 800px;margin-bottom:20px;">
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">
          {{ $t('PTC') }}
        </el-col>
        <el-col :md="20">
          <el-input type="textarea" rows="5" placeholder="약관정보" v-model="formData.buyPolicy" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">
          {{ $t('CATEGORY') }}
        </el-col>
        <el-col :md="20">
          <el-input type="text" maxlength="1024" placeholder="카테고리" v-model="formData.mallCategory" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">
          {{ $t('IW') }}
        </el-col>
        <el-col :md="20">
          <el-input type="text" maxlength="20" placeholder="워터마크문자" v-model="formData.watermarkText" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">
          {{ $t('NCA') }}
        </el-col>
        <el-col :md="20">
          <el-select v-model="formData.subAccountQty" size="mini" placeholder="선택하세요" class="w100p">
            <el-option v-for="item in 10" :key="item" :label="'최대' + item + '명'" :value="item">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <!-- json -->
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">
          {{ $t('BRN') }}
        </el-col>
        <el-col :md="20">
          <el-input type="text" placeholder="사업자등록번호" v-model="formData.siteInfo.사업자등록번호" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">
          {{ $t('COMPANYNAME') }}
        </el-col>
        <el-col :md="20">
          <el-input type="text" placeholder="상호" v-model="formData.siteInfo.상호" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">
          {{ $t('ADDR') }}
        </el-col>
        <el-col :md="20">
          <el-input style="max-width: 100px;" type="text" placeholder="우편번호" v-model="formData.siteInfo.우편번호" size="mini" class="mr-2">
          </el-input>
          <el-input style="max-width: calc(100% - 110px);" type="text" placeholder="주소" v-model="formData.siteInfo.회사주소" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">
          {{ $t('CEO') }}
        </el-col>
        <el-col :md="20">
          <el-input type="text" placeholder="대표자" v-model="formData.siteInfo.대표자" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">
          {{ $t('PPM') }}
        </el-col>
        <el-col :md="20">
          <el-input type="text" placeholder="개인정보보호책임자" v-model="formData.siteInfo.개인정보보호책임자" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">
          {{ $t('TSBRN') }}
        </el-col>
        <el-col :md="20">
          <el-input type="text" placeholder="통신파매업신고번호" v-model="formData.siteInfo.통신파매업신고번호" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">
          {{ $t('TEL') }}
        </el-col>
        <el-col :md="20">
          <el-input type="text" style="max-width: 150px;" placeholder="대표전화" v-model="formData.siteInfo.대표전화" size="mini" class="mr-2">
          </el-input>
          <el-input type="text" style="max-width: 150px;" placeholder="팩스" v-model="formData.siteInfo.팩스" size="mini" class="mr-2">
          </el-input>
          <el-input type="text" style="max-width: 150px;" placeholder="사이트이용문의" v-model="formData.siteInfo.사이트이용문의" size="mini" class="mr-2">
          </el-input>
          <el-input type="text" style="max-width: calc(100% - 490px);" placeholder="서비스전화" v-model="formData.siteInfo.서비스전화" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">
          {{ '사이트링크' }}
        </el-col>
        <el-col :md="20">
          <el-input type="text" style="max-width: 307px;" placeholder="도메인" v-model="formData.siteInfo.도메인" size="mini" class="mr-2">
          </el-input>
          <el-input type="text" style="max-width: calc(100% - 320px);" placeholder="이메일" v-model="formData.siteInfo.이메일" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <!-- json -->
  
      <el-row :gutter="10" class="mt-2" v-if="formData.vinSearchConfig">
        <el-col :xl="4" :md="4" class="lh25">
          {{ $t('ESO') }}
        </el-col>
        <el-col :md="10">
          <el-select v-model="formData.vinSearchConfig.limit" size="mini" placeholder="선택하세요" class="w100p">
            <el-option v-for="item in options" :key="item" :label="item + '회'" :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :md="10">
          <el-select v-model="formData.vinSearchConfig.repeat" size="mini" placeholder="선택하세요" class="w100p">
            <el-option v-for="item,index in searchRepatOption" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">
          {{ $t('DPL') }}
        </el-col>
        <el-col :xl="20" :md="20">
          <el-select v-model="formData.licenseValidDays" size="mini" placeholder="선택하세요" class="w100p">
            <el-option v-for="item in tmpTableData.licenseValidBuy" :key="item.days"
              :label="item.title + ' ' + item.days + $t('DAY')" :value="item.days">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">
          {{ $t('LICENSE') }}
        </el-col>
        <el-col :xl="20" :md="20">
          <table class="table-params w100p">
            <tbody>
              <tr>
                <th width="150" class="center">{{ $t('LICENSE') }}</th>
                <th width="100" class="center">{{ $t('VP') }}({{ $t('DAY') }})</th>
                <th width="120" class="center">{{ $t('AMOUNT') }}</th>
                <th class="center">{{ $t('DESC') }}</th>
                <th width="50" class="center">
                  <i class="fas fa-trash-alt colorgray"></i>
                </th>
              </tr>
              <tr v-for="item, index in tmpBackTableData.licenseValidBuy" :key="index">
                <td>
                  <el-input type="text" :placeholder="$t('LICENSE')" maxlength="20" size="mini" v-model="item.title"
                    show-word-limit>
                  </el-input>
                </td>
                <td>
                  <el-input type="text" :placeholder="$t('VP')" maxlength="3" size="mini" v-model="item.days"
                    show-word-limit autocomplete="off" @focus="() => { item.days = $numberFormat(item, 'days', 'focus') }"
                    @input="() => { item.days = $numberFormat(item, 'days', 'input') }"
                    @blur="() => { item.days = $numberFormat(item, 'days', 'blur') }">
                  </el-input>
                </td>
                <td>
                  <el-input type="text" :placeholder="$t('AMOUNT')" maxlength="10" size="mini" v-model="item.price"
                    show-word-limit autocomplete="off"
                    @focus="() => { item.price = $numberFormat(item, 'price', 'focus') }"
                    @input="() => { item.price = $numberFormat(item, 'price', 'input') }"
                    @blur="() => { item.price = $numberFormat(item, 'price', 'blur') }">
                  </el-input>
                </td>
                <td>
                  <el-input type="text" :placeholder="$t('DESC')" maxlength="50" size="mini" v-model="item.content"
                    show-word-limit>
                  </el-input>
                </td>
                <td class="center">
                  <i class="hand fas fa-trash-alt" @click="deletLine(index)"></i>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                </td>
                <td class="center"> <!--행추가-->
                  <i class="hand fas fa-circle-plus bigbadge-orange" @click="addline"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </el-col>
      </el-row>
    </card>
    <div class="text-right" style="max-width:800px;">
      <el-button v-if="!submitButtonDisabled" size="mini" icon="fa fa-refresh" @click="read">
        새로고침</el-button>
      <el-button v-if="!submitButtonDisabled" size="mini" type="primary" icon="el-icon-success" @click="handleUpdate">
        정보등록</el-button>
      <el-button v-else size="mini" type="info" icon="el-icon-success">
        정보등록</el-button>
    </div>
    <div>
      <!-- <json-viewer :value="formData" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
    </div>
  </div>
</template>
<script>

export default {
  name: 'mallmanagerconfig',
  components: {},
  data() {
    return {
      searchRepatOption: [
        {
          value: 0,
          label: '중복포함'
        },
        {
          value: 1,
          label: '신규검색'
        },
      ],
      options: Array.from({ length: 20 }, (_, i) => (i + 1) * 5),
      submitButtonDisabled: false,
      tmpBackTableData: [],
      tmpTableData: [],
      formData: {
        buyPolicy: '',
        mallCategory: '',
        licenseValidDays: 90,
        subAccountQty: 1,
        watermarkText: '',
        licenseValidBuy: [],
        vinSearchConfig: {
          limit: 0,
          repeat: 1,
        },
        siteInfo:{
          사업자등록번호:'',
          상호:'',
          우편번호:'',
          회사주소:'',
          대표자:'',
          개인정보보호책임자:'',
          통신파매업신고번호:'',
          대표전화:'',
          팩스:'',
          도메인:'',
          이메일:'',
          서비스전화:'',
          사이트이용문의:'',
          운영시간:'',
        },
      },
      request: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 10
      },
      randomlist: [
        '드림사용권', '웅이네예매권', '여시네입장권', '다람총무료권',
        '토끼이용권', 'VIP이용권', '오션관람권', '다이소무료권'
      ],
      randomAds: [
        '지금구매!!!', '대박찬스!!!', '어서오시개', '구매의달인에 도전!',
        '충동구매!!!'
      ]
    }
  },
  async created() {

    this.read()
  },
  methods: {
    addline() {
      this.tmpBackTableData.licenseValidBuy.push({
        days: 15,
        price: 15000,
        content: this.randomAds[Math.floor(Math.random() * this.randomAds.length)],
        title: this.randomlist[Math.floor(Math.random() * this.randomlist.length)],
      })
    },
    deletLine: function (index) {
      if (this.tmpBackTableData.licenseValidBuy.length <= 1) {
        alert('한계에 도달하였습니다.')
        return
      }
      this.tmpBackTableData.licenseValidBuy.splice(index, 1)
    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getMallConfigInfo`, this.request)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpBackTableData = res.data.body.results
            this.tmpTableData = JSON.parse(JSON.stringify(this.tmpBackTableData))
            this.formData = {
              buyPolicy: this.tmpTableData.buyPolicy,
              licenseValidDays: this.tmpTableData.licenseValidDays,
              subAccountQty: this.tmpTableData.subAccountQty,
              watermarkText: this.tmpTableData.watermarkText,
              mallCategory: this.tmpTableData.mallCategory,
              vinSearchConfig: this.tmpTableData.vinSearchConfig ? this.tmpTableData.vinSearchConfig : { limit: 5, repeat: 1 },
              siteInfo:{},
            }
          }
        })
    },
    async handleUpdate() {
      this.tmpBackTableData.licenseValidBuy.map(item => {
        item.days = Number(item.days)
      })
      this.submitButtonDisabled = true
      this.formData.licenseValidBuy = this.tmpBackTableData.licenseValidBuy
      await this.$http.post(`${this.$serverurl}/api/setMallConfigInfo`, this.formData)
        .then(res => {
          if (201 == res.data.code) {
            alert(res.data.message)
            this.read()
          }
        })
      this.submitButtonDisabled = false
    },



  }
}
</script>
<style></style>