<template>
  <div class="container noselect" style="padding-top: 30px !important;">
    <div class="sticky-bottom w100p p-2 text-right noprint" style="background-color: #efefef;">
      <label style="margin-right:20px;">
        <input type="checkbox" @click="복사본포함()" />
        {{ $t('COPYI') }}
      </label>
      <el-button size="mini" class="btn-small" icon="fa fa fa-print" ref="submitbutton" @click="$doPrint()">
        {{ $t('PRINT') }}
      </el-button>
    </div>
    <div v-for="(invoice, index) in visibleItems.items" :key="index" class="">
      <div v-if="index > 0" :style="index > 0 ? { 'margin-top': '50px', 'margin-bottom': '50px' } : ''"
        class="printfont2 noprint text-center">
        <barcode :value="infoContent.sn" format="CODE128" :font="'Consolas'" :fontSize=12 :height=10 :text="' '"
          :lineColor="'#000'">
          BARCODE code128
        </barcode>
      </div>
      <div v-for="i in icopy" :key="i" :style="{ 'page-break-after': i == 2 ? 'always' : 'auto' }">
        <!--  헤더 -->
        <div>
          <div class="row mb-3">
            <div class="col" style="padding-right:5px;">
              <div class="mb-2 text-center">
                <img :src="generateImage(`${$t('TRANSACTIONDETAIL')}`, 110, 30, '20px', 25, 'bold')" alt="">
              </div>
              <table class="tables-print table-bordereds-print printcustomer" style="height: calc(100% - 36px);">
                <tr>
                  <td>
                    <div class="text-center">
                      {{ infoContent.customerInfo.name }}
                      <span class="text-bold">貴中</span>
                    </div>
                    <div class="text-center"> {{ infoContent.customerInfo.addr }}</div>
                    <div class="text-center">☎ {{ $formatTel(infoContent.customerInfo.phone) }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-7 relative">
              <barcode class="mt-2 absolute" :value="infoContent.sn" format="CODE128" :font="'Consolas'" :fontSize=20
                :height=8 :width=1 style="top: 5px;right:25px;mix-blend-mode: multiply;" :text="' '" :lineColor="'#000'">
                BARCODE code128
              </barcode>
              <table class="tables-print table-bordereds-print w100p" style="height:130px;">
                <tr>
                  <th rowspan="5" class="w30 text-direction" style="letter-spacing:15px;">
                    {{$t('SPR')}}</th>
                </tr>
                <tr>
                  <th class="text-center w100">
                    {{$t('TXN.NO.')}}
                  </th>
                  <td colspan="3">
                    {{ $snPlusFor(infoContent.sn) + '(' + (index + 1) + ')' }}
                  </td>
  
                </tr>
                <tr>
                  <th class="text-center">{{$t('BRN')}}</th>
                  <td class="center w150">{{ $formatLicense(infoContent.companyInfo.license) }}</td>
                  <th class="text-center w50">{{$t('NAME')}}</th>
                  <td>{{ infoContent.companyInfo.ceo }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{$t('COMPANYNAME')}}</th>
                  <td class="text-left">{{ infoContent.companyInfo.name }}</td>
                  <th class="text-center w50">{{$t('TEL')}}</th>
                  <!-- <td>{{ $formatTel(infoContent.companyInfo.tel) }}</td> -->
                  <td class="w100">{{ infoContent.companyInfo.tel }}</td>
                </tr>
                <tr>
                  <th class="text-center ">{{$t('ADDR')}}</th>
                  <td colspan="3" class="text-left">{{ infoContent.companyInfo.addr }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- 총금액 -->
        <div class="row mb-3">
          <div class="col">
            <div class="row border-all-dark-2px fontsize16all text-bold" style="margin:0px 0px;padding:5px 5px;">
              <div class="col-1"></div>
              <div class="col">
                <img :src="generateImage(([$t('AMOUNT')]) + ' : ' + calcCountKor(visibleItems.totalAmount )
                            + ([$t('WJ')]), 340, 30, '16pt', 25, 'bold')
                            " alt="">
              </div>
              <div class="col-4 text-right">
                <img :src="generateImage(' (₩ ' + $comma(visibleItems.totalAmount) + ')', 200, 30, '16pt', 25, 'bold')
                            " alt="">
              </div>
            </div>
            <div class="border-all-dark-1px p-1 pl-5" v-if="infoContent.mallOrderInfo">
              <span>
                {{ $shippingtype[infoContent.mallOrderInfo.shippingPayType].label }}
              </span>
              <span>
                / {{ infoContent.mallOrderInfo.name }}
                / {{ infoContent.mallOrderInfo.phone }}
                / ({{ infoContent.mallOrderInfo.postNum }})
                / {{ infoContent.mallOrderInfo.address }}
                {{ infoContent.mallOrderInfo.addrSub }}
              </span>
            </div>
          </div>
        </div>
        <!-- 항목body -->
        <table class="tables-print table-bordereds-print mt-3">
          <thead>
            <tr>
              <th class="text-center">{{$t('DATE')}}</th>
              <th>{{$t('FACTORYNUM')}}</th>
              <th>{{$t('ITEM')}}</th>
              <th>{{$t('QTY')}}</th>
              <th>{{$t('PRICE')}}</th>
              <th>{{$t('SUPPLY.P')}}</th>
              <th>{{$t('VAT')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, itemIndex) in visibleItems.items[index].itemsList" :key="itemIndex">
              <td class="text-center">
                {{ infoContent.tradeDate.substr(0, 10).split('-')[1] }}/{{
                infoContent.tradeDate.substr(0,
                10).split('-')[2] }}
              </td>
              <td>{{ item.baseInfo.pid }}</td>
              <td>{{ item.baseInfo.name }}</td>
              <td>{{ $comma(item.outQty) }}</td>
              <td>{{ item.ourPrice }}</td>
              <td>{{ item.supply_ourPrice }}</td>
              <td>{{ item.vat_ourPrice }}</td>
            </tr>
          </tbody>
        </table>
        <table class="tables-print table-bordereds-print mt-3" style="border-top:1px #000 solid;">
          <tr>
            <td class="w50">{{$t('QTY')}}</td>
            <td class="w100">{{ visibleItems.items[index].pageIncount }}</td>
            <td class="w100">{{'SUPPLY.P'}}</td>
            <td class="w100">{{ $comma(visibleItems.items[index].pageInSum) }}</td>
            <td class="w50">{{ $t('VAT') }}</td>
            <td class="">
              {{ visibleItems.items[index].orderOutInfo }}
              {{
              infoContent.vat === 0 ?
              $comma(visibleItems.items[index].pageInSum * 0.1) : 0
              }}
            </td>
            <td class="w50">{{$t('TOTAL')}}</td>
            <td class="">
              {{
              infoContent.vat === 0 ? $comma(visibleItems.items[index].pageInSum +
              visibleItems.items[index].pageInSum * 0.1)
              : visibleItems.items[index].pageInSum
              }}
            </td>
            <td class="w100">
              <div class="row">
                <div class="col pr-0">{{$t('TAKEOVER')}}</div>
                <div class="col text-right">{{$t('MARKY')}}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="10">{{ tmpInVoiceInfo.contentB }}</td>
          </tr>
          <tr>
            <td colspan="10">{{ tmpInVoiceInfo.contentD }}</td>
          </tr>
        </table>
        <div class="text-center" v-if="i < 2" style="margin-top: 70px; margin-bottom:70px;">
          ✂️------------------------------------------------------------
          page: {{ index + 1 + '(' + i + ')' }}/{{ totalPages }}
          ------------------------------------------------------------✂️
        </div>
        <div :style="index > 0 ? { 'margin-top': '50px', 'margin-bottom': '50px' } : ''" class=" noprint text-center">
          <!-- 시각적띄여쓰기 -->
        </div>
      </div>
    </div>
    <div class="noprint " style="height:100px;"></div>
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode';
export default {
  components: {
    'barcode': VueBarcode,
  },
  props: {

    // items: {
    //     type: Array,
    //     default: () => [] // data list 
    // },
    itemsPerPage: {
      type: Number,
      default: 6
    }
  },
  data() {
    return {
      icopy: 1,
      tmpInVoiceInfo: {},
      currentPage: 1,
      request: {
        page: 1,
        size: 1,
        objSearchMapper: {
          state: null,
          saleStatus: null,
          searchType: 'total',
          searchKeyword: '',
          startDate: null,
          endDate: null
        }
      },
    }
  },

  computed: {
    total() {
      return this.infoContent.orderOutItemInfo.reduce((acc, cur) => acc + cur.outQty * cur.outPrice, 0);
    },
    totalPages() {
      return Math.ceil(this.infoContent.orderOutItemInfo.length / this.itemsPerPage);
    },
    visibleItems() {
      var totalAmount = 0
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = Math.min(startIndex + this.itemsPerPage, this.infoContent.orderOutItemInfo.length);
      const items = this.infoContent.orderOutItemInfo.slice(startIndex, endIndex);
      const emptyRows = Array.from({ length: Math.max(0, this.itemsPerPage - items.length) }, () => ({
        baseInfo: [],
      }));
      const pages = [];
      for (let i = 0; i < this.totalPages; i++) {
        const startIndex = i * this.itemsPerPage;
        const endIndex = Math.min(startIndex + this.itemsPerPage, this.infoContent.orderOutItemInfo.length);
        const items = this.infoContent.orderOutItemInfo.slice(startIndex, endIndex);
        const emptyRows = Array.from({ length: Math.max(0, this.itemsPerPage - items.length) }, () => ({
          baseInfo: [],
        }));
        for (var x of items) {
          this.supply(x, x.outPrice, this.infoContent.vat, 'ourPrice')
        }
        const pageInSum = items.reduce((acc, cur) => acc + cur.outQty * this.delComma(cur.outPrice), 0);
        const pageIncount = items.reduce((acc, cur) => acc + cur.outQty, 0);
        totalAmount += pageInSum
        pages.push({
          itemsList: items.concat(emptyRows),
          pageInSum: pageInSum,
          pageIncount: pageIncount,
        });
      }
      for (var item of pages) {
      }
      return {
        items: pages,
        currentPage: this.currentPage,
        totalAmount: totalAmount,
        totalAmountVat: this.infoContent.vat == 0 ? totalAmount * 0.1 : 0
      };
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(newVal) {
        if (this.infoContent && this.infoContent.orderOutItemInfo.length > 10) {
          this.currentPage = 1;
        }

      }
    }
  },
  methods: {
    delComma(params) {
      params = params || 0;
      const returnValue = (params + '').replace(/[^\d]/g, '');
      const value = isNaN(returnValue) ? '' : returnValue;
      return parseFloat(value)
    },
    generateImage(text, width, height, fontSize, point, bold) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) {
        console.error("캔버스 2D 컨텍스트를 가져올 수 없습니다.");
        return null;
      }
      canvas.width = width;
      canvas.height = height;
      // 배경 이미지
      context.fillStyle = '#ffffff'; // 또는 'white'로 설정 가능
      context.fillRect(0, 0, canvas.width, canvas.height);
      // 상단내용
      context.fillStyle = 'rgba(0,0,0,1)';
      context.textAlign = 'left'
      // const fontSize = window.matchMedia('print').matches ? '20pt' : '16px';
      context.font = `${bold} ${fontSize} "Malgun Gothic"`;
      context.fillText(text, 10, point);

      // 이미지 데이터를 리턴
      return canvas.toDataURL('image/jpeg');
    },
    readInVoiceInfo() {
      //명세서설정정보
      this.request.objSearchMapper.companyId = this.userlogininfo.companyId;
      this.$http.post(this.$serverurl + "/api/getInVoiceConfig", this.request, this.$setHeader())
        .then(res => {
          // this.$chLogOut(res.data.code)
          this.tmpInVoiceInfo = res.data.body.results;
        })
        .catch(error => { })
        .then(() => { });
    },
    calcCountKor(num) { //숫자 한글변환
      num = parseInt((num + '').replace(/[^0-9]/g, ''), 10) + '';  // 숫자/문자/돈 을 숫자만 있는 문자열로 변환
      if (num == '0')
        return this.$t('영');
      var number = [
        this.$t('영'), this.$t('일'), this.$t('이'), this.$t('삼'), this.$t('사'),
        this.$t('오'), this.$t('육'), this.$t('칠'), this.$t('팔'), this.$t('구')
      ];
      var unit = ['', this.$t('만'), this.$t('억'), this.$t('조')];
      var smallUnit = [this.$t('천'), this.$t('백'), this.$t('십'),''];
      var result = [];
      var unitCnt = Math.ceil(num.length / 4);
      num = num.padStart(unitCnt * 4, '0')
      var regexp = /[\w\W]{4}/g;
      var array = num.match(regexp);
      for (var i = array.length - 1, unitCnt = 0; i >= 0; i--, unitCnt++) {
        var hanValue = _makeHan(array[i]);  //한글로 변환된 숫자
        if (hanValue == '')
          continue;
        result.unshift(hanValue + unit[unitCnt]);
      }
      function _makeHan(text) {
        var str = '';
        for (var i = 0; i < text.length; i++) {
          var num = text[i];
          if (num == '0')
            continue;
          str += number[num] + smallUnit[i];
        }
        return str;
      }
      return result.join('');
    },
    comma(params) {
      params = params || 0;
      if (params == 0) { return null }
      return (params + '').length >= 4 ? params.toLocaleString('ko-KR', 0).split('.')[0] : params;
    },
    getProductType(params) {
      const typeInfo = this.$baseTypes.find((item) => item.value === params);
      return typeInfo?.label ?? ''
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    복사본포함() {
      if (this.icopy == 1) {
        this.icopy = 2
      } else { this.icopy = 1 }
    },
    supply(item, amount, vat, field) {
      vat = vat == 0 ? 'Y' : 'N'
      amount = JSON.parse(JSON.stringify(this.$delComma(amount))) || 0
      const { supplyAmount, vatAmount } = this.$amountSplitTax(amount, vat)
      item[`supply_${field}`] = this.$comma(supplyAmount)
      item[`vat_${field}`] = this.$comma(vatAmount)
      item[field] = this.$comma(amount)
    },
    supplySub(item, amount, vat, field) {
      vat = vat == 0 ? 'Y' : 'N'
      amount = JSON.parse(JSON.stringify(this.$delComma(amount))) || 0
      item[`supply_${field}`] = this.$comma(amount)
      item[`vat_${field}`] = this.$comma(amount * 0.1)
      item[field] = this.$comma(amount * 1.1)
    },

  },
  created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    this.infoContent = JSON.parse(localStorage.getItem('printInfo'))
    this.readInVoiceInfo()
  },


};
</script>
<style lang="scss">
.sticky-bottom {
  bottom: 0px;
  left: 0px;
  position: fixed;
  z-index: 1000;
}

@media print {
  .noprint {
    display: none;
  }

  body * {
    padding-top: 0px;
    transform: scale(100%);
    // font-weight: normal !important;
    /* 크기 조절 (0.5는 50% 크기) */
    transform-origin: top left;
    /* 크기 조절의 기준점 설정 */
    font-size: 14pt !important;
  }

  .printfont14pt {
    font-size: 14pt !important;
  }

  .printcustomer {
    height: calc(100% - 56px) !important;
  }

  th {
    font-weight: bold;
    padding: 5px;
    // background-color: #f7f7f7 !important;
    border-right: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
  }

  td {
    height: 30px;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
    border-right: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
  }

  table {
    width: 100%;
    border-left: 1px #000 solid !important;
    border-top: 1px #000 solid !important;
  }
}
</style>
<style scoped>
body {
  font-size: 16px !important;
}

.table-bordereds-print th {
  /* text-align: center !important; */
  font-weight: 400;
  padding: 5px;
  /* background: #f7f7f7 !important; */
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.table-bordereds-print td {
  height: 20px;
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.tables-print {
  width: 100%;
  border-left: 1px #000 solid;
  border-top: 1px #000 solid;
}

.text16 {
  font-size: 16pt !important;
}
</style>