<template>
    <div class="container noselect">
        <div class="row mb-3">
        </div>
        <div class="sticky-bottom w100p p-2 text-right noprint" style="max-width:100% !important;background-color: #efefef;">
            <div class="col text-right">
                <el-button size="mini" class="btn-mini" icon="el-icon-printer" @click="doPrint">재고장출력</el-button>
            </div>
        </div>
        <div id="printArea" class="p-3" style="max-width:900px !important;">

            <inven_area_print_page_head></inven_area_print_page_head>
            <!--리스트 Start-->
            <inven_area_print_head />
            <div :key="i" v-for="(items, i) in tmpTableData">

                <div v-if="null != items">
                    <div v-if="showheadLine(i)">
                        <div class="row"><!--페이지-->
                            <div class="col text-center fontsize14 mt-3">
                                -{{ Math.floor(i / 51) }}-
                            </div>
                        </div>
                        <inven_area_print_page_head></inven_area_print_page_head>
                        <inven_area_print_head class="mt-3" />
                    </div>
                    <div class="row border-bottom-dark border-right-dark" style="line-height:25px;">

                        <div class="col fontsize14 border-right-dark border-left-dark"
                            style="min-width:350px !important; max-width:350px;">
                            {{ items.baseInfo.name }}
                        </div>
                        <div class="col ellip fontsize14 border-right-dark" style="max-width:150px !important">
                            {{ items.baseInfo.pid }}
                        </div>
                        <div class="col ellip fontsize14 border-right-dark" style="max-width:150px;">
                            {{ items.baseInfo.factoryNum }}
                        </div>
                        <div class="col ellip fontsize14 border-right-dark" style="max-width:80px;">
                            {{ items.inQty - items.outQty }}
                        </div>
                        <div class="col ellip fontsize14">
                            
                        </div>

                    </div>
                </div>
            </div>
            <!--리스트 End-->
        </div>


    </div>
</template>
<script>

//출력페이지 제목파일
import inven_area_print_page_head from "./inven_area_print_page_head.vue"

//표각 헤드부분 파일
import inven_area_print_head from "./inven_area_print_head.vue"

let pid = '';
import { Loading } from 'element-ui';
import 'vue-loading-overlay/dist/vue-loading.css';
// get 파라미터 얻기
export default {
    components: {
        Loading, inven_area_print_head, inven_area_print_page_head
    },
    data() {
        return {
            tmpTableData: [],
            totalCount:null,
            request: {
                objSearchMapper: {
                    storageId: null,
                    searchKeyword: null,
                    noEmtpy: true,
                },
                page: 1,
                size: 10000
            },
        }
    },
    created() {
        this.request.objSearchMapper.storageId= JSON.parse(localStorage.getItem('printInfo'))
        this.read()
    },
    computed: {
    },
    methods: {
        async read() {
            this.request.objSearchMapper.searchKeyword = this.$trimLR(this.searchKeyword)
            this.loading = Loading.service({ fullscreen: true })
            await this.$http.post(`${this.$serverurl}/api/getInvenCount`, this.request, this.$setHeader())
                .then(res => {
                    if (200 == res.data.code) {
                        this.tmpTableData = res.data.body.results
                        this.totalCount = res.data.totalCount
                    }
                })
            this.$overay(this.loading)
        },
        //출력페이지분할
        showheadLine(para) {
            const pageQtys = [51, 102, 153, 204, 255, 306, 357, 408, 459, 510]
            for (const pageQtyItems of pageQtys) {
                if (para == pageQtyItems) { return true; }
            }
        },
        //출력
        doPrint() {
            window.print();
        },
    }
}

</script>
<style scoped>
.border-left-dark {
  border-left: 1px #000 solid;
}
.border-right-dark {
  border-right: 1px #000 solid;
}

.border-bottom-dark {
    border-bottom: 1px #000 solid;
}

div {
    font-family: 'Malgun Gothic', 'Microsoft YaHei' !important;
}

th {
    background-color: #fff !important;
    border-bottom: 2px #000 solid;
    font-weight: 600;
}

td {
    border-bottom: 1px #000 solid;
}

.ve-table-body-tr {
    height: 25px !important;
}

@media Print {
    .noprint {
        display: none;
    }
}
</style>