<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'600px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable style="z-index: 99999999;">
    <card style="margin-bottom:20px;">
      <div class="productImg"></div>
    </card>
    <card style="margin-bottom:20px;">
      <el-row>
        <el-col :xl="4" :md="4" class="lh25">상품명*</el-col>
        <el-col :xl="20" :md="20">
          <el-input placeholder="상품명" maxlength="50" v-model="formData.name" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">정품품번</el-col>
        <el-col :xl="20" :md="20">
          <el-input placeholder="정품품번" maxlength="50" v-model="formData.pid" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">공장품번</el-col>
        <el-col :xl="20" :md="20">
          <el-input placeholder="공장품번" maxlength="50" v-model="formData.factoryNum" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">구분</el-col>
        <el-col :xl="20" :md="20">
          {{ formData.type&& $t( $productType[formData.type].label) }}
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">출하지</el-col>
        <el-col :xl="20" :md="20">
          {{formData.storageInfo&& formData.storageInfo.name }}
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">판매수량</el-col>
        <el-col :xl="5" :md="5">
          <el-input placeholder="판매수량" :min="1" :max="tmpTableData.stockQty" v-model="formData.hasAmount"
            @focus="() => { formData.hasAmount = $numberFormat(formData, 'hasAmount', 'focus') }"
            @input="() => { formData.hasAmount = $numberFormat(formData, 'hasAmount', 'input',1,tmpTableData.stockQty) }"
            @blur="() => { formData.hasAmount = $numberFormat(formData, 'hasAmount', 'blur',1,tmpTableData.stockQty) }"
            size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">도매가</el-col>
        <el-col :xl="5" :md="5">
          <el-input placeholder="도매가" v-model="formData.priceC"
            @focus="() => { formData.priceC = $numberFormat(formData, 'priceC', 'focus') }"
            @input="() => { formData.priceC = $numberFormat(formData, 'priceC', 'input',1,10000000) }"
            @blur="() => { formData.priceC = $numberFormat(formData, 'priceC', 'blur',1,10000000) }" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">소매가</el-col>
        <el-col :xl="5" :md="5">
          <el-input placeholder="도매가" v-model="formData.priceD"
            @focus="() => { formData.priceD = $numberFormat(formData, 'priceD', 'focus') }"
            @input="() => { formData.priceD = $numberFormat(formData, 'priceD', 'input',1,10000000) }"
            @blur="() => { formData.priceD = $numberFormat(formData, 'priceD', 'blur',1,10000000) }" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">간단설명</el-col>
        <el-col :xl="20" :md="20">
          <el-input type="textarea" height="100" placeholder="간단설명" maxlength="50" v-model="formData.detailInfo"
            size="mini">
          </el-input>
        </el-col>
      </el-row>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">창닫기</el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange"
          @click="setInvenToProductInfo">정보제출</el-button>
      </div>
    </div>
    <!-- <json-viewer :value="formData" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  </el-dialog>
</template>
<script>
export default {
  name: 'productCreate',
  props: ['data',],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.tmpTableData = JSON.parse(JSON.stringify(newValue))
        await this.getStorageInfo()
        this.formData = {
          baseInfo: newValue.baseInfo,
          name: newValue?.baseInfo.name || '',
          pid: newValue?.baseInfo.pid || '',
          factoryNum: newValue?.baseInfo.factoryNum || '',
          hasAmount: newValue?.inQty - newValue.outQty || 1,
          type: newValue?.baseInfo.type || 0,
          saleStatus: 1,
          inPrice: newValue.inprice || 0,
          priceA: 0,
          priceB: 0,
          priceC: this.$comma(newValue.baseInfo.priceC) || 0,
          priceD: this.$comma(newValue.baseInfo.priceD) || 0,
          minAmount: 1,
          storageInfo: this.tmpStorageInfo.find(item => item.seq == newValue.storageId),
          storageId: newValue.storageId,
          baseId: newValue.basicItemId,
          bizMainId: newValue.seq,
          optType: 1,
          seq: newValue.seq
        }
      }
    },
  },
  data() {
    return {
      visible: true,
      title: '상품등록',
      tmpTableData: null,
      tmpStorageInfo: [],
      total: 0,
      formData: {
        name: '',
        pid: '',
        factoryNum: '',
        hasAmount: 0,
        type: '',
        detailInfo: '',
        priceA: 0,
        priceB: 0,
        priceC: 0,
        priceD: 0,
        minAmount: 1,
        storageId: 0,
        baseid: 0,
        bizMainId: 0,
        optType: 1,
        seq: null,
      }
    }
  },
  async created() {
  },
  methods: {
    async setInvenToProductInfo() {
      if (this.formData.name.trim() == '') {
        alert('상품명을 입력하세요.')
        return
      }
      const res = await this.$http.post(`${this.$serverurl}/api/setInvenToProductInfo`, this.formData)
      if (201 == res.data.code) {
        alert(res.data.message)
        this.setCallback(res.data.body)
      }

    },
    async getStorageInfo() {
      const res = await this.$http.post(`${this.$serverurl}/api/getStorageInfo`, {
        objSearchMapper: {}, page: 1, size: 100
      })
      if (200 == res.data.code) {
        this.tmpStorageInfo = res.data.body.results
      }

    },
    setCallback(item) {
      this.$emit('callback', { item: item, status: true })
    },
    notClose() { },
    handleClose(done) {
      this.$emit('callback', { item: null, status: false })
    },
  }
}
</script>
<style scope lang="scss">
.productImg {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  background: rgba(230, 236, 238, 0.5);

  &::after {
    content: '130*130';
    color: #000;
    text-align: center;
  }
}
</style>