<template>
  <div class="container mt-3 relative" :style="$pageWidth">
    <TopBar />
    <div class="mt-2">
      <el-row>
        <el-col :xl="14" :md="14">
          <el-button size="mini" v-for="item, index in $saleProcess.filter(item=>item.seo)" :key="index"
            :type="activeName == item.value ? 'primary' : ''" @click="handleButtons(item.value)">
            {{ $t(item.label) }}
            ({{getCount(item.value) }})
          </el-button>
        </el-col>
      </el-row>
      <div class="mt-2">
        <seolist v-if="!zeroQty" :append="activeName" @callback="seolistCallback" />
      </div>
    </div>
  </div>
</template>
<script>
import sotragelist from './component-storage-list.vue'
import customerinfo from './component-customer-list.vue'
import TopBar from './component-top.vue'
import seolist from './component-seo-list.vue'
export default {
  components: {
    TopBar, customerinfo, sotragelist, seolist
  },
  data() {
    return {
      ecountconfigvisible: false,
      zeroQty: 0,
      viewvisible: false,
      selectItem: null,
      activeName: 0,
      countList: [],
    }
  },
  methods: {
    showecoungconfig(params) {
      this.ecountconfigvisible = params
    },
    ecountconfigCallback(data) {
      if (data) {
        this.$router.go(0)
      }
      this.ecountconfigvisible = false
    },
    zeroFilter(value) {
      if (this.zeroQty != value) {
        this.zeroQty = value
      } else {
        this.zeroQty = 0
      }
    },
    viewallback(data) {
      if (data.status) {
        this.selectItem = data.item
        this.activeName = 'orderininsert'
      }
      this.viewvisible = false
    },
    handleButtons(params) {
      this.zeroQty = 0
      this.activeName = params;
      this.selectItem = null
    },
    seolistCallback(data) {
      if (data.status) {
        this.viewvisible = true
        this.selectItem = data.item
      } else {
        this.countList = data.item
      }

      // this.activeName = 'orderincreate'
    },
    getCount(index) {
      return this.countList[index]?.count
    },
    handleClick(tab, event) { },
  }
}
</script>
<style></style>
