<template>
  <el-dialog center :title="$t(title)" :visible.sync="visible" :width="'600px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable style="z-index: 99999999;">
    <card>
      <el-row>
        <el-col :xl="4" :md="4" class="lh25">{{$t('BRN')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('BRN')" maxlength="10" v-model="formData.license" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('COMPANYNAME')}}*</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('BRN')" maxlength="50" v-model="formData.name" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('ADDR')}}</el-col>
        <el-col :xl="5" :md="5">
          <el-input :placeholder="$t('P.C.')" v-model="formData.postMain" size="mini" @click.native="loadDaumPostcodeScript"
            class="hand" readonly>
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="loadDaumPostcodeScript"></i>
          </el-input>
        </el-col>
        <el-col :xl="15" :md="15">
          <el-input :placeholder="$t('ADDR')" maxlength="200" v-model="formData.addrMain" readonly="" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('DET.ADDR.')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('DET.ADDR.')" maxlength="200" v-model="formData.addrSub" size="mini">
          </el-input></el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('CEO')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('CEO')" maxlength="20" v-model="formData.ceo" size="mini">
          </el-input></el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('TEL')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('TEL')" maxlength="12" v-model="formData.tel" size="mini">
          </el-input></el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('FAX')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('FAX')" maxlength="12" v-model="formData.fax" size="mini">
          </el-input></el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('MOBILE')}}*</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('MOBILE')" maxlength="12" v-model="formData.phone" size="mini">
          </el-input></el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('MAIL')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('MAIL')" maxlength="50" v-model="formData.mail" size="mini">
          </el-input></el-col>
      </el-row>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">{{$t('CLOSE')}}</el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange" @click="setInfo">{{$t('SUBMIT')}}</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'malllcompanyCreate',
  props: ['data',],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.formData = {
          license: newValue?.license || '',
          ceo: newValue?.ceo || '',
          name: newValue?.name || '',
          bizType: newValue?.bizType || 0,
          bizClass: newValue?.bizClass || '',
          tel: newValue?.tel || '',
          fax: newValue?.fax || '',
          phone: newValue?.phone || '',
          mail: newValue?.mail || '',
          postMain: newValue?.postMain || '',
          addrMain: newValue?.addrMain,
          postNumSub: newValue?.postNumSub || '',
          addrSub: newValue?.addrSub || '',
          vat: newValue?.vat || 0,
          seq: newValue?.seq || null,
        }
      }
    },
  },
  data() {
    return {
      visible: true,
      title: 'CIS',
      tmpCustomerList: [],
      total: 0,
      formData: {
        license: '',
        ceo: '',
        name: '',
        bizType: 0,
        bizClass: '',
        tel: '',
        fax: '',
        phone: '',
        mail: '',
        postNumMain: '',
        addrMain: '',
        postNumSub: '',
        addrSub: '',
        vat: 0,
        seq: null,
      }
    }
  },
  async created() {
  },
  methods: {
    async setInfo() {
      if (this.formData.name.trim() == '') {
        alert('상호를 입력하세요.')
        return
      }
      if (this.formData.phone.trim() == '') {
        alert('휴대전화를 입력하세요.')
        return
      }
      const res = await this.$http.post(`${this.$serverurl}/api/setMallCompanyInfo_new`, this.formData)
      if (201 == res.data.code) {
        alert(res.data.message)
        this.setCallback(res.data.body.result)
      }

    },
    setCallback(item) {
      this.$emit('callback', { item: item, status: true })
    },
    notClose() { },
    handleClose(done) {
      this.$emit('callback', { item: null, status: false })
    },
    // daum 주소검색
    loadDaumPostcodeScript() {
      const script = document.createElement('script')
      script.src =
        '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'
      script.async = true
      script.onload = () => {
        this.initializeDaumPostcode()
      }
      document.head.appendChild(script)
    },
    initializeDaumPostcode() {
      const daum = null
      new (window).daum.Postcode({
        oncomplete: (data) => {
          this.formData.postNumMain = data.zonecode
          this.formData.addrMain = data.address
        }
      }).open()
    },
  }
}
</script>
<style></style>