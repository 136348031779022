<template>
  <div>
    <card>
      <el-row>
        <el-col :xl="4" :md="4" class="lh25">{{$t('COMPANYNAME')}}</el-col>
        <el-col :xl="20" :md="20">
          {{ userlogininfo.companyInfo.name }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :xl="4" :md="4" class="lh25">{{$t('ACCOUNT')}}</el-col>
        <el-col :xl="20" :md="20">
          {{ userlogininfo.id }}
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('LANGUAGE')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-select size="mini" @change="setLang"
                           :placeholder="$t('LANGUAGE')"
                           v-model="formData.language">
                  <el-option v-for="option in $language"
                             :value="option.value"
                             :label="option.label"
                             :key="option.label">
                  </el-option>
                </el-select>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('MOBILE')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('MOBILE')" maxlength="12" v-model="formData.phone" show-word-limit size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('MAIL')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('MAIL')" maxlength="50" v-model="formData.mail" size="mini"  show-word-limit >
          </el-input></el-col>
      </el-row>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange" @click="setMyInfo">
        {{ $t('SUBMIT') }}
        </el-button>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'setmyinfo',
  data() {
    return {
      formData:{
        language:0,
        phone:'',
        mail:''
      }
    }
  },
  async created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    this.formData.language = this.userlogininfo.language
    this.formData.phone = this.userlogininfo.phone
    this.formData.mail = this.userlogininfo.mail
  },
  methods: {
    async setMyInfo() {
      this.userlogininfo.language =this.formData.language 
      this.userlogininfo.phone=this.formData.phone
      this.userlogininfo.mail= this.formData.mail 
      const res = await this.$http.post(`${this.$serverurl}/api/setMyInfo`, this.formData,this.$setHeader())
      if (201 == res.data.code) {
        localStorage.setItem('userlogininfo',JSON.stringify(this.userlogininfo))
        alert(res.data.message)
      }
    },
    async setLang(event){
      switch (event) {
        case 0: {
          this.$i18n.locale = 'ko'
          break
        }
        case 1: {
          this.$i18n.locale = 'cn'
          break
        }
        case 2: {
          this.$i18n.locale = 'vtn'
          break
        }
        case 3: {
          this.$i18n.locale = 'en'
          break
        }
        default: {
          this.$i18n.locale = 'ko'
        }
      }
    }
  }
}
</script>
<style></style>