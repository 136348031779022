<template>
  <div>
    <card style="width: 1000px;margin-bottom:20px;">
      <el-row :gutter="10">
        <el-col :xl="3" :md="3" class="lh25">
          {{$t('DATE')}}
        </el-col>
        <el-col :md="9">
          <el-date-picker v-model="formData.tradeDate" size="mini" type="date" :placeholder="$t('DS')"
            :format="$t('YYYYMMDD')" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
          </el-date-picker>
        </el-col>
        <el-col :xl="3" :md="3" class="lh25">
          {{$t('TYPE')}}
        </el-col>
        <el-col :md="9">
          <el-select v-model="formData.vat" size="mini" class="w100p">
            <el-option v-for="item in $vatType" :key="item.value" :label="$t(item.label)" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="3" :md="3" class="lh25">
          {{$t('CUSTOMER')}}
        </el-col>
        <el-col :md="9">
          <el-input :placeholder="$t('CS')" v-model="formData.customerInfo.name" size="mini"
            @dblclick.native="customerSync = true" @keyup.enter.native="showCustomerList(formData.customerInfo.name)">
            <i slot="prefix" class="el-input__icon el-icon-search hand"
              @click="showCustomerList(formData.customerInfo.name)"></i>
          </el-input>
        </el-col>
        <el-col :xl="3" :md="3" class="lh25">
          {{$t('STORAGE')}}
        </el-col>
        <el-col :xl="9" :md="9">
          <el-select v-model="formData.storageId" size="mini" :placeholder="$t('SS')" class="w100p">
            <el-option v-for="item in tmpStorageInfo" :key="item.seq" :label="item.name" :value="item.seq">
            </el-option>
          </el-select>
          <span class="badge badge-primary absolute hand" style="right:35px;top:7px;"
            @click="storageCreateSync = true">{{$t('WHS.REG.')}}</span>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="3" :md="3" class="lh25">
          {{$t('MEMO')}}
        </el-col>
        <el-col :xl="21" :md="21">
          <el-input :placeholder="$t('MEMO')" maxlength="200" v-model="formData.memo" size="mini">
          </el-input>
        </el-col>
      </el-row>
    </card>
    <card style="width:1000px;margin-bottom:20px;">
      <table class="table-params w100p">
        <tbody>
          <tr>
            <th class="text-center w100">{{$t('IVL')}}</th>
            <th class="text-center w100">{{$t('PID')}}</th>
            <th class="text-center w100">{{$t('FACTORYNUM')}}</th>
            <th class="text-center">{{$t('ITEM')}}</th>
            <th class="text-center w100">{{$t('TYPE')}}</th>
            <th class="text-center w50">{{$t('QTY')}}</th>
            <th class="text-center w90">{{$t('AMOUNT')}}</th>
            <th class="text-center w90">{{$t('SUPPLY.P')}}</th>
            <th class="text-center w90">{{$t('VAT')}}</th>
            <th class="text-center w50">{{$t('DELETE')}}</th>
          </tr>
          <tr v-for="(item, index) in formData.orderInItemInfo" :key="index">
            <td>
              <input class="inputbox" :placeholder="$t('IVL')" maxlength="20" v-model="item.storagePosition"
                @click="lastAppendLines(formData.orderInItemInfo.length, index)" />
            </td>
            <td>
              <input class="inputbox" :placeholder="$t('PID')" maxlength="20" v-model="item.baseInfo.pid"
                @click="lastAppendLines(formData.orderInItemInfo.length, index)"
                @keyup.enter="showBaseExList(item,item.baseInfo.pid)"
                @dblclick="showBaseExList(item,item.baseInfo.pid)" />
            </td>
            <td>
              <input class="inputbox" :placeholder="$t('FACTORYNUM')" maxlength="20" v-model="item.factoryNum"
                @click="lastAppendLines(formData.orderInItemInfo.length, index)"
                @keyup.enter="showBaseExList(item,item.baseInfo.factoryNuym)"
                @dblclick="showBaseExList(item,item.baseInfo.factoryNuym)" />
            </td>
            <td>
              <input class="inputbox" :placeholder="$t('ITEM')" maxlength="20" v-model="item.baseInfo.name"
                @click="lastAppendLines(formData.orderInItemInfo.length, index)"
                @keyup.enter="showBaseExList(item,item.baseInfo.name)"
                @dblclick="showBaseExList(item,item.baseInfo.name)" />
            </td>
            <td>
              <input class="inputbox" :placeholder="$t('TYPE')" maxlength="20"
                :value="$t($productType[item.baseInfo.type].label)" readonly
                @click="lastAppendLines(formData.orderInItemInfo.length, index)" />
            </td>
            <td>
              <input class="inputbox" :placeholder="$t('QTY')" maxlength="20" v-model="item.inQty" autocomplete="off"
                @focus="() => { item.inQty = $numberFormat(item, 'inQty', 'focus') }"
                @input="() => { item.inQty = $numberFormat(item, 'inQty', 'input') }"
                @blur="() => { item.inQty = $numberFormat(item, 'inQty', 'blur') }"
                @click="lastAppendLines(formData.orderInItemInfo.length, index)" />
            </td>
            <td>
              <input class="inputbox right" style="max-width: 80px;" :placeholder="$t('AMOUNT')" maxlength="20"
                v-model="item.inPrice" autocomplete="off" @input="()=>{supply(item,item.inPrice,formData.vat,'inPrice')}"
                @click="lastAppendLines(formData.orderInItemInfo.length, index)" />
            </td>
            <td>
              <input class="inputbox right" style="max-width: 80px;" :placeholder="$t('SUPPLY.P')" maxlength="20"
                v-model="item.supply_inPrice" autocomplete="off"
                @input="()=>{supplySub(item,item.supply_inPrice,formData.vat,'inPrice')}"
                @click="lastAppendLines(formData.orderInItemInfo.length, index)" />
            </td>
            <td>
              <input class="inputbox right" style="max-width: 80px;" :placeholder="$t('VAT')" maxlength="20"
                v-model="item.vat_inPrice" autocomplete="off"
                @click="lastAppendLines(formData.orderInItemInfo.length, index)" />
            </td>
            <td class="text-center">
              <i class="el-icon-error hand" v-if="!item.seq" @click="deletData(index)"></i>
              <i class="el-icon-error hand colorgraylight" v-else></i>
            </td>
          </tr>
        </tbody>
      </table>
    </card>
    <div class="text-right" style="max-width:800px;">
      <el-button v-if="!submitButtonDisabled" size="mini" type="primary" icon="el-icon-success" @click="handleUpdate">
        {{$t('SUBMIT')}}</el-button>
      <el-button v-else size="mini" type="info" icon="el-icon-success">
        {{$t('SUBMIT')}}</el-button>
    </div>
    <div>
      <!-- <json-viewer :value="formData" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
    </div>
    <selectCustomer v-if="customerSync" :data="searchCusStr" @callback="updatecustomercallback" />
    <customerCreate v-if="customerCreateSync" @callback="updatecustomercreatecallback" />
    <storagecreate v-if="storageCreateSync" @callback="storagecreatecallback" />
    <selectBaseExInfo v-if="baseExSync" :data="searchStr" @callback="selectBaseExInfocallback" />
    <basicitemcreate v-if="createvisible" @callback="basicitemcreatecallback" />
  
  </div>
</template>
<script>
import customerCreate from './component-customer-create.vue'
import selectCustomer from './component-select-customer.vue'
import storagecreate from './component-storage-create.vue'
import selectBaseExInfo from './component-select-baseExInfo-list.vue'
import basicitemcreate from './component-basic-item-create.vue'

export default {
  name: 'orderincreate',
  components: { selectCustomer, customerCreate, storagecreate, selectBaseExInfo, basicitemcreate },
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (!newValue) { return }
        this.formData = newValue
        for (var item of this.formData.orderInItemInfo) {
          item.inQty = item.inQty || 0
          item.inPrice = item.inPrice || 0
          this.supply(item, item.inPrice, this.formData.vat, 'inPrice')
        }
      }
    },
    'formData.vat': function (newVal, oldVal) {
      for (var item of this.formData.orderInItemInfo) {
        item.inQty = item.inQty || 0;
        item.inPrice = item.inPrice || 0;
        this.supply(item, item.inPrice, newVal, 'inPrice');
      }
    }
  },

  data() {
    return {
      createvisible: false,
      pickerOptions: {
        disabledDate(time) {
          // return time.getTime() < Date.now() - 8.64e7; //이전날자disable 오늘날자미포
          // time.getTime() > Date.now() - 8.64e7 // .. 오늘날자포함
          return time.getTime() > Date.now();
        },
      },
      searchStr: '',
      searchCusStr: '',
      submitButtonDisabled: false,
      baseExSync: false,
      storageCreateSync: false,
      customerCreateSync: false,
      customerSync: false,
      visible: true,
      title: '거래수정',
      tmpStorageInfo: [],
      selectItem: {},
      total: 0,
      formData: {
        seq: null,
        tradeDate: new Date(),
        vat: 0,
        customerId: null,
        memo: null,
        storageId: null,
        customerInfo: {
          name: null,
        },
        orderInItemInfo: [
          {
            baseId: 0,
            baseInfo: { type: 1, name: '' },
            companyId: 0,
            companyInfo: {},
            customerInfo: {},
            inPrice: 0,
            inQty: 0,
            memo: '',
            parentId: 0,
            state: 0,
            storageId: null,
            storagePosition: '',
            tradeType: 0, //new Date(),
            supply_inPrice: 0,
            vat_inPrice: 0,

          }
        ],
      },
      request: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 10
      },
    }
  },
  async created() {
    if (this.formData.orderInItemInfo.length == 1) {
      this.appendLines(5)
    }
    this.readStorageInfo()
  },

  methods: {
    showBaseExList(item, searchStr) {
      this.searchStr = searchStr
      this.selectItem = item
      this.baseExSync = true
    },
    showCustomerList(searchCusStr) {
      this.searchCusStr = searchCusStr
      this.customerSync = true
    },

    deletData: function (index) {
      if (this.formData.orderInItemInfo.length <= 1) {
        alert('한계에 도달하였습니다.')
        return
      }
      this.formData.orderInItemInfo.splice(index, 1)
    },
    lastAppendLines(a, b) {
      if (a - b < 3) {
        this.appendLines(2)
      }
    },
    appendLines(line) {
      if (this.formData.orderInItemInfo.length >= 50) {
        alert('한계에 도달하였습니다.')
        return
      }
      for (let a = 1; a <= line; a++) {
        this.formData.orderInItemInfo.push({
          baseId: 0,
          baseInfo: { type: 1, name: '' },
          companyId: 0,
          companyInfo: {},
          customerInfo: {},
          inPrice: 0,
          inQty: 0,
          memo: '',
          parentId: 0,
          state: 0,
          storageId: null,
          storagePosition: null,
          tradeType: 0, //new Date(),
          supply_inPrice: 0,
          vat_inPrice: 0,
        });
      }
    },
    updatecustomercallback(data) {
      if (data.item) {
        this.formData.customerInfo.name = data.item.name
        this.formData.customerId = data.item.seq
      }
      if (data.new) {
        this.customerCreateSync = true
      }
      this.customerSync = false
    },

    updatecustomercreatecallback(data) {
      if (data.item) {
        this.formData.customerInfo.name = data.item.name
        this.formData.customerId = data.item.seq
      }
      this.customerCreateSync = false
    },
    async storagecreatecallback(data) {
      if (data.status) {
        await this.readStorageInfo()
        this.formData.storageId = data.item.seq
      }
      this.storageCreateSync = false
    },

    async selectBaseExInfocallback(data) {
      if (data.status && data.item) {
        this.selectItem.baseId = data.item?.seq
        this.selectItem.pid = data.item?.pid
        this.selectItem.factoryNum = data.item?.factoryNum
        this.selectItem.baseInfo = data.item
        this.selectItem.inQty = 1
        this.selectItem.inPrice = this.$comma(data.item?.inPrice)
      }
      if (data.new) {
        this.createvisible = true
      }
      this.baseExSync = false
    },

    basicitemcreatecallback(data) {
      if (data.status) {
        this.selectItem.baseId = data.item?.seq
        this.selectItem.pid = data.item?.pid
        this.selectItem.factoryNum = data.item?.factoryNum
        this.selectItem.baseInfo = data.item
        this.selectItem.inQty = 1
        this.selectItem.inPrice = this.$comma(data.item?.inPrice)
      }
      this.createvisible = false
    },


    async readStorageInfo() {
      await this.$http.post(`${this.$serverurl}/api/getStorageInfo`, this.request)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpStorageInfo = res.data.body.results
            this.formData.storageId =this.tmpStorageInfo[0].seq
          }
        })
    },

    async handleUpdate() {
      if (!this.formData.tradeDate) {
        alert('거래날자를 선택하세요.')
        return
      }
      if (!this.formData.customerId) {
        alert('거래처를 선택하세요.')
        return
      }
      if (!this.formData.storageId) {
        alert('창고를 선택하세요.')
        return
      }
      this.submitButtonDisabled = true
      const orderInItemInfo = this.formData.orderInItemInfo.filter(item => item.baseId)
      if (orderInItemInfo.length < 1) {
        alert('입고항목을 입력하세요.')
        return
      }
      this.formData.orderInItemInfo = orderInItemInfo
      await this.$http.post(`${this.$serverurl}/api/setOrderInInfo`, this.formData)
        .then(res => {
          if (201 == res.data.code) {
            alert(res.data.message)
            if (!this.formData.seq) {
              this.formData = {
                seq: null,
                tradeDate: null,
                vat: 0,
                customerId: null,
                memo: null,
                storageId: null,
                customerInfo: {
                  name: null,
                },
                orderInItemInfo: [{
                  baseId: 0,
                  baseInfo: { type: 1, name: '' },
                  companyId: 0,
                  companyInfo: {},
                  customerInfo: {},
                  inPrice: 0,
                  inQty: 0,
                  memo: '',
                  parentId: 0,
                  state: 0,
                  storageId: null,
                  storagePosition: null,
                  tradeType: 0, // new Date(),
                }],
              }
              this.appendLines(5)
            } else {
              this.appendLines(0)
            }
          }
        })
      this.submitButtonDisabled = false
    },
    supply(item, amount, vat, field) {
      vat = vat == 0 ? 'Y' : 'N'
      amount = JSON.parse(JSON.stringify(this.$delComma(amount))) || 0
      const { supplyAmount, vatAmount } = this.$amountSplitTax(amount, vat)
      item[`supply_${field}`] = this.$comma(supplyAmount)
      item[`vat_${field}`] = this.$comma(vatAmount)
      item[field] = this.$comma(amount)
    },
    supplySub(item, amount, vat, field) {
      vat = vat == 0 ? 'Y' : 'N'
      amount = JSON.parse(JSON.stringify(this.$delComma(amount))) || 0
      item[`supply_${field}`] = this.$comma(amount)
      item[`vat_${field}`] = this.$comma(amount * 0.1)
      item[field] = this.$comma(amount * 1.1)
    },
  }
}
</script>
<style></style>