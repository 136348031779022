<template>
  <div>
    <card>
      <el-row>
        <el-col :xl="4" :md="4" class="lh25">{{$t('SR')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('SR')" maxlength="100" v-model="formData.contentA" size="mini" show-word-limit >
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('VATA')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('VATA')" maxlength="100" v-model="formData.contentB" show-word-limit size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('CA')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('CA')" maxlength="100" v-model="formData.contentC" size="mini" show-word-limit >
          </el-input></el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('OM')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input type="textarea" :placeholder="$t('OM')" maxlength="200" v-model="formData.contentD" size="mini" show-word-limit >
          </el-input></el-col>
      </el-row>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange" @click="setInVoiceConfig">{{$t('SUBMIT')}}</el-button>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'invoiceCreate',
  data() {
    return {
      visible: true,
      total: 0,
      formData: {
        seq: null,
        contentA: '',
        contentB: '',
        contentC: '',
        contentD: '',
        contentE: '',
      }
    }
  },
  async created() {
    this.read()
  },
  methods: {
    async setInVoiceConfig() {
      const res = await this.$http.post(`${this.$serverurl}/api/setInVoiceConfig`, this.formData)
      if (201 == res.data.code) {
        alert(res.data.message)
      }
    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getInVoiceConfig`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.formData = {
              seq: this.tmpTableData.seq,
              contentA: this.tmpTableData.contentA,
              contentB: this.tmpTableData.contentB,
              contentC: this.tmpTableData.contentC,
              contentD: this.tmpTableData.contentD,
              contentE: this.tmpTableData.contentE,
            }
          }
        })
    },

  }
}
</script>
<style></style>