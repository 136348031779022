<template>
  <el-dialog center :title="$t(title)" :visible.sync="visible" :width="'600px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable>
    <card style="margin-bottom: 20px;">
      <el-row>
        <el-col :xl="4" :md="4" class="lh25">{{ $t('LANGUAGE') }}</el-col>
        <el-col :xl="20" :md="20">
          <el-select size="mini" v-model="formData.language" class="me-3 w100p">
            <el-option v-for="item, index in $language" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="mt-2" :gutter="10">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('NAME') }}*</el-col>
        <el-col :xl="10" :md="10">
          <el-select size="mini" v-model="formData.level" class="me-3 w100p">
            <el-option v-for="item, index in $levels" :key="index" :label="$t(item.label)" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xl="10" :md="10">
          <el-input :placeholder="$t('NAME')" maxlength="20" v-model="formData.name" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2" :gutter="10">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('TEL') }}*</el-col>
        <el-col :xl="10" :md="10">
          <el-input :placeholder="$t('TEL')" maxlength="11" v-model="formData.phone" size="mini">
          </el-input>
        </el-col>
        <el-col :xl="10" :md="10">
          <el-input :placeholder="$t('EC')" maxlength="11" v-model="formData.tel" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('MAIL') }}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('MAIL')" maxlength="20" v-model="formData.mail" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('MEMO') }}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('MEMO')" maxlength="100" v-model="formData.memo" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <div v-if="!formData.seq">
        <el-row class="mt-2">
          <el-col :xl="4" :md="4" class="lh25">{{ $t('ACCOUNT') }}*</el-col>
          <el-col :xl="20" :md="20">
            <el-input :placeholder="$t('ACCOUNT')" maxlength="20" v-model="formData.id" size="mini">
            </el-input>
          </el-col>
        </el-row>
        <el-row class="mt-2">
          <el-col :xl="4" :md="4" class="lh25">{{ $t('PW') }}*</el-col>
          <el-col :xl="10" :md="10">
            <form @submit.prevent>
              <el-input type="password" :placeholder="$t('PW')" maxlength="20" v-model="formData.pw" size="mini">
              </el-input>
            </form>
          </el-col>
          <el-col :xl="10" :md="10">
  
          </el-col>
        </el-row>
        <el-row class="mt-2">
          <el-col :xl="4" :md="4" class="lh25">{{ $t('CPW') }}*</el-col>
          <el-col :xl="20" :md="20">
            <form @submit.prevent>
              <el-input type="password" :placeholder="$t('CPW')" maxlength="20" v-model="pw" size="mini">
              </el-input>
            </form>
          </el-col>
        </el-row>
      </div>
    </card>
    <card v-if="userlogininfo.authority.accountPermi == 0"> 
      <div class="font13 bold mb-2">{{ $t('AUTHORITYSET') }}</div>
      <el-row :gutter="10" class="font12">
        <!-- 재고권한 -->
        <el-col :span="6">
          <el-checkbox size="mini" :label="$t('I.S')" :border="true" @change="updatePremi(formData, 'stockPremi', $event)"
            style="min-width: 120px;" :value="checkvalue(formData, 'stockPremi')"></el-checkbox>
        </el-col>
        <!-- 이카운트연동 판매자 승인 시에만 사용가능 -->
        <el-col :span="6" v-if="userlogininfo.authority.salePermi==2 || userlogininfo.authority.accountPermi==0">
          <el-checkbox size="mini" :label="$t('EC-I')+'*'" :border="true" style="min-width: 120px;"
            @change="updatePremi(formData, 'ecountPermi', $event)"
            :value="checkvalue(formData, 'ecountPermi')"></el-checkbox>
        </el-col>
        <!-- 구성설정 -->
        <el-col :span="6">
          <el-checkbox size="mini" :label="$t('BS-S')" :border="true" style="min-width: 120px;"
            @change="updatePremi(formData, 'baseitemPermi', $event)"
            :value="checkvalue(formData, 'baseitemPermi')"></el-checkbox>
        </el-col>
        <!-- 입고거래 -->
        <el-col :span="6">
          <el-checkbox size="mini" :label="$t('IN.T')" :border="true" @change="updatePremi(formData, 'inPermi', $event)"
            style="min-width: 120px;" :value="checkvalue(formData, 'inPermi')"></el-checkbox>
        </el-col>
        <!-- 출고거래 -->
        <el-col :span="6">
          <el-checkbox size="mini" :label="$t('OUT.T')" :border="true" @change="updatePremi(formData, 'outPermi', $event)"
            style="min-width: 120px;" :value="checkvalue(formData, 'outPermi')"></el-checkbox>
        </el-col>
        <!-- 배송관리 -->
        <el-col :span="6" v-if="userlogininfo.authority.salePermi==2 || userlogininfo.authority.accountPermi==0">
          <el-checkbox size="mini" :label="$t('DM')+'*'" :border="true"
            @change="updatePremi(formData, 'deliPermi', $event)" style="min-width: 120px;"
            :value="checkvalue(formData, 'deliPermi')"></el-checkbox>
        </el-col>
        <!-- 대표계정 -->
        <el-col :span="6" v-if="userlogininfo.authority.accountType == 0">
          <el-checkbox size="mini" :label="$t('MA')" :border="true" @change="updatePremi(formData, 'accountType', $event)"
            style="min-width: 120px;" :value="checkvalue(formData, 'accountType')"></el-checkbox>
        </el-col>
        <!-- 판매자관리 -->
        <el-col :span="6" v-if="userlogininfo.authority.accountPermi == 0">
          <el-checkbox size="mini" :label="$t('SM')+'*'" :border="true"
            @change="updatePremi(formData, 'saleManager', $event)" style="min-width: 120px;"
            :value="checkvalue(formData, 'saleManager')"></el-checkbox>
        </el-col>
        <!-- 쇼핑몰관리 -->
        <el-col :span="6" v-if="userlogininfo.authority.accountPermi == 0">
          <el-checkbox size="mini" :label="$t('SMM')+'*'" :border="true"
            @change="updatePremi(formData, 'mallPermi', $event)" style="min-width: 120px;"
            :value="checkvalue(formData, 'mallPermi')"></el-checkbox>
        </el-col>
        <!-- 통합권한 -->
        <el-col :span="6" v-if="userlogininfo.authority.accountPermi == 0">
          <el-checkbox size="mini" :label="$t('IA')+'*'" :border="true"
            @change="updatePremi(formData, 'accountPermi', $event)" style="min-width: 120px;"
            :value="checkvalue(formData, 'accountPermi')"></el-checkbox>
        </el-col>
        <!-- 영업통합관리 -->
        <el-col :span="6" v-if="userlogininfo.authority.accountPermi == 0">
          <el-checkbox size="mini" :label="$t('SMI')+'*'" :border="true"
            @change="updatePremi(formData, 'bizPermi', $event)" style="min-width: 120px;"
            :value="checkvalue(formData, 'bizPermi')"></el-checkbox>
        </el-col>
        <!-- 장부통계 -->
        <el-col :span="6" v-if="userlogininfo.authority.accountType == 0">
          <el-checkbox size="mini" :label="$t('A.C')" :border="true"
            @change="updatePremi(formData, 'accountBookPermi', $event)" style="min-width: 120px;"
            :value="checkvalue(formData, 'accountBookPermi')"></el-checkbox>
        </el-col>
        <!-- 서오서플라이 -->
        <el-col :span="6" v-if="userlogininfo.authority.accountPermi == 0">
          <el-checkbox size="mini" :label="$t('SEO')" :border="true"
            @change="updatePremi(formData, 'seoStockPermi', $event)" style="min-width: 120px;"
            :value="checkvalue(formData, 'seoStockPermi')"></el-checkbox>
        </el-col>
      </el-row>
  
        <div class="font13 bold mt-2">{{ $t('DW') }}</div>
      <el-select size="mini" v-model="formData.authority.accessStore" class="me-3 w100p mt-2">
        <el-option v-for="item, index in storageInfo" :key="index" :label="item.name" :value="item.seq">
        </el-option>
      </el-select>
    </card>
    <div class="mt-1">
      <el-row>
        <el-col :xl="15" :md="15">
          {{$t('CIWBRATNL')}}
        </el-col>
        <el-col :xl="9" :md="9" class="right">
          <el-button size="mini" icon="el-icon-success" @click="handleClose">
          {{ $t('CLOSE') }}</el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange" @click="setAccountInfo">{{ $t('SUBMIT')
          }}</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- <json-viewer :value="userlogininfo" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer>
                                <json-viewer :value="formData" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
  </el-dialog>
</template>
<script>
export default {
  name: 'usercreate',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.formData = {
          companyId: newValue?.companyId || this.userlogininfo?.companyId,
          authority: {
            inPermi: newValue?.authority?.inPermi ?? 1,
            bizPermi: newValue?.authority?.bizPermi ?? 1,
            outPermi: newValue?.authority?.outPermi ?? 1,
            deliPermi: newValue?.authority?.deliPermi ?? 1,
            mallPermi: newValue?.authority?.mallPermi ?? 1,
            salePermi: newValue?.authority?.salePermi ?? 1, // 판매자권한
            stockPremi: newValue?.authority?.stockPremi ?? 1,  // 재고권한
            accessStore: newValue?.authority?.accessStore ?? 0, // 권한창고 id
            accountType: newValue?.authority?.accountType ?? 1,  // 대표계정
            ecountPermi: newValue?.authority?.ecountPermi ?? 1,  // 이카운트
            // memberPermi: newValue?.authority?.memberPermi ?? 1,  // 몰회원관리
            saleManager: newValue?.authority?.saleManager ?? 1, // 판매자관리
            accountPermi: newValue?.authority?.accountPermi ?? 1, // 통합권한
            salePrdPermi: newValue?.authority?.salePrdPermi ?? 1,  // 판매상품관리
            baseitemPermi: newValue?.authority?.baseitemPermi ?? 1,  // 기초항목
            accountBookPermi: newValue?.authority?.accountBookPermi ?? 1, // 장부
            seoStockPermi: newValue?.authority?.seoStockPermi ?? 1, // 서오
          },
          name: newValue?.name || '',
          language: newValue?.language || 0,
          level: newValue?.level || 0,
          id: newValue?.id || '',
          pw: newValue?.pw || '',
          phone: newValue?.phone || '',
          tel: newValue?.tel || '',
          mail: newValue?.mail || '',
          memo: newValue?.memo || '',
          seq: newValue?.seq || null,
        }
      },
    },
  },
  data() {
    return {
      title: 'ACCOUNTR',
      visible: true,
      userlogininfo: null,
      total: 0,
      companyInfo: {},
      storageInfo: [],
      formData: {
        companyId: null,
        authority: {
        },
        language: 0, // 언어
        level: 0,// 직급
        id: '',
        pw: '',
        name: '',
        phone: '',
        tel: '',
        mail: '',
        memo: '', // 직책
        seq: null,
      },
      pw: '',
    }
  },
  async created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    this.storageInfo = this.userlogininfo?.storageInfo
    const exists = this.storageInfo.some(item => item.name === this.$t('ALLWAREHOUSE'));
    if (!exists) {
      this.storageInfo.push({ seq: 0, name: this.$t('ALLWAREHOUSE') });
    }
    this.companyInfo = this.userlogininfo?.companyInfo
  },
  methods: {
    checkvalue(item, field) {
      if (item.authority != null) {
        if (item.authority[field] == 0) {
          return true
        } else { return false }
      } else {
        return false
      }
    },
    updatePremi(item, field, event) {
      if (!item.authority) {
        item.authority = {}
        item.authority[field] = event ? 0 : 1
      } else {
        item.authority[field] = event ? 0 : 1
      }
    },

    async setAccountInfo() {
      if (this.formData.name.trim() == '') {
        alert('사용자이름을 입력하세요.')
        return
      }
      if (this.formData.phone.trim() == '') {
        alert('연락처를 입력하세요.')
        return
      }
      if (!this.formData.seq && this.formData.id.trim() == '') {
        alert('로그인계정을 입력하세요.')
        return
      }
      if (!this.formData.seq && this.formData.pw.trim() == '') {
        alert('비밀번호를 입력하세요.')
        return
      }
      if (!this.formData.seq && this.pw.trim() == '') {
        alert('확인비번을 입력하세요.')
        return
      }
      const res = await this.$http.post(`${this.$serverurl}/api/setAccountInfo_new`, this.formData)
      if (201 == res.data.code) {
        alert(res.data.message)
        if (this.userlogininfo.seq == res.data.body.results.seq) {
          this.userlogininfo.authority = res.data.body.results.authority
          localStorage.setItem('userlogininfo', JSON.stringify(this.userlogininfo))
        }
        this.setCallback()
      } else {
        alert(res.data.message)
      }

    },
    setCallback() {
      this.$emit('callback', true)
    },
    notClose() { },
    handleClose(done) {
      // done()
      this.$emit('callback', false)
    },
    Empty() { }
  }
}
</script>
<style></style>