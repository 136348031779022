<template>
  <el-dialog center :title="$t(title)" :visible.sync="visible" :width="'800px'" :modal=true :show-close="false"
    :before-close="handleClose" :close-on-press-escape="true" v-draggable>
    <el-row :gutter="10">
      <el-col :xl="12" :md="12">
        <el-input :placeholder="$t('SEARCH')" v-model="request.objSearchMapper.searchKeyword" size="mini"
          @keyup.enter.native="read()">
          <i slot="prefix" class="el-input__icon el-icon-search hand" @click="read()"></i>
        </el-input>
      </el-col>
    </el-row>
    <table class="table-params table-hover w100p table-hover font12 mt-2">
      <tr>
        <th class="w50 center">{{$t('SELECT')}}</th>
        <th>{{$t('ITEM')}}</th>
        <th class="w120">{{$t('PID')}}</th>
        <th class="w120">{{$t('FACTORYNUM')}}</th>
        <th class="w70">{{$t('TYPE')}}</th>
        <th class="w100">{{$t('WP')}}</th>
        <th class="w100">{{$t('OUT.AMOUNT')}}</th>
        <th class="w50">{{$t('QTY')}}</th>
        <th class="w70">{{$t('STORAGE')}}</th>
      </tr>
      <tr v-for="(item, index) in tmpTableData" :key="index">
        <td class="center">
          <span class="badge font12 badge-orange hand" @click="updateCallback(item)">
            {{ $t('SELECT') }}
          </span>
        </td>
        <td>
          <div class="ellip" style="max-width: 200px;">
            {{ item.name }}
          </div>
        </td>
        <td>{{ item.pid }}</td>
        <td>{{ item.factoryNum }}</td>
        <td>{{ $t($productType.find(items=>items.value==item.type).label) }}</td>
        <td>{{ $comma(item.inPrice) }}</td>
        <td>{{ $comma(item.priceD) }}</td>
        <td>{{ $comma(item.hasAmount) }}</td>
        <td>{{ item.storageInfo.name }}</td>
      </tr>
      <tr v-if="!tmpTableData.length">
        <td colspan="6" class="center">{{$t('NIF')}}</td>
      </tr>
    </table>
    <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page="request.page" :page-size="request.size"
      :page-sizes="[15, 50, 100]" layout="  prev, pager, next" :total="totalCount">
    </el-pagination>
    <div class="row mt-3">
      <div class="col right">
        <el-button size="mini" @click="handleClose">{{$t('CLOSE')}}</el-button>
  
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'selectsalesproduct',
  components: {},
  props: ['pid', 'list'],
  watch: {
    pid: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.request.objSearchMapper.searchKeyword = newValue
      },
    },
    list: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.tmpProductResult = newValue
        this.tmpTableData = this.tmpProductResult.body.results
        this.totalCount = this.tmpProductResult.body.total
      },
    },
  },
  data() {
    return {
      visible: true,
      title: 'SPS',
      tmpTableData: [],
      tmpProductResult: {},
      totalCount: 0,
      request: {
        objSearchMapper: {
          searchKeyword: '',
        },
        page: 1,
        size: 10
      },
    }
  },
  async created() { },
  methods: {

    async read() {
      const res = await this.$http.post(`${this.$serverurl}/api/getSalesProductInfo`, this.request)
      if (200 == res.data.code) {
        this.tmpTableData = res.data.body.results
        this.totalCount = res.data.body.total
        // this.handleClose()
      } else {
        this.tmpTableData = []
        this.totalCount = 0
      }
    },
    updateCallback(item) {
      this.$emit('callback', { item, status: true })
    },
    handleClose(done) {
      this.$emit('callback', { item: null, status: false })
    },

    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }
  }
}
</script>
<style></style>