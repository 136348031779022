<template>
    <el-dialog center :visible.sync="visible" :width="'350px'" :modal='false' :show-close="false"
        :before-close="notClose" v-draggable>
        <div class="mb-3 pl-2">
            <h5 class="bold ellip">{{ title }}</h5>
            <div class="bold mb-2">{{ data.content }}</div>
            <div v-if="data.contentSub">
                {{ data.contentSub }}
            </div>
        </div>
        <div class="right mb-3">
            <el-button size="mini" type="" @click="cancelClose">
                {{$t('CANCEL')}}
            </el-button>
            <el-button size="mini" type="danger" @click="handleContinue">
                {{$t('CONTINUE')}}

            </el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'yesNoBox',
    props: ['title', 'data'],
    components: {

    },
    data() {
        return {
            visible: true
        }
    },
    async created() {
    },
    methods: {
        notClose(){},
        cancelClose() {
            this.$emit('callback', false)
        },
        handleContinue() {
            this.$emit('callback', true)
        },


    }
}
</script>

<style scoped></style>