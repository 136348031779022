<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'600px'" :modal=true :show-close="false"
    :before-close="handleClose" v-draggable>
    <el-row :gutter="10">
      <el-col :xl="12" :md="12">
        <el-input :placeholder="$t('SBP')" v-model="request.objSearchMapper.searchKeyword" size="mini"
          @keyup.enter.native="read()">
          <i slot="prefix" class="el-input__icon el-icon-search hand" @click="read()"></i>
        </el-input>
      </el-col>
      <el-col :xl="12" :md="12" class="text-right">
        <el-button size="mini" type="primary" @click="showcustomerCreatebox">{{$t('ADD')}}</el-button>
      </el-col>
    </el-row>
    <table class="table-params table-hover w100p table-hover font13 mt-2">
      <tr>
        <th class="w50 center">{{$t('SELECT')}}</th>
        <th class="w100 center">{{$t('BRN')}}</th>
        <th>{{$t('COMPANYNAME')}}</th>
        <th class="w150">{{$t('TEL')}}</th>
      </tr>
      <tr v-for="(item, index) in tmpCustomerList" :key="index">
        <td class="center">
          <span class="badge font12 badge-orange hand" @click="updateCallback(item)">{{$t('SELECT')}}</span>
        </td>
        <td class="center">{{ item.license }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.phone }}</td>
      </tr>
      <tr v-if="!tmpCustomerList.length">
        <td colspan="4" class="center">{{ $t('NIF') }}</td>
      </tr>
    </table>
    <div class="row mt-3">
      <div class="col right">
        <el-button size="mini" @click="handleClose">
          {{ $t('CLOSE') }}
        </el-button>

      </div>
    </div>
    <customerCreate v-if="showcustomerCreate" @callback="callbackCustomerCreate" />
  </el-dialog>
</template>
<script>
export default {
  name: 'selectCustomer',
  components: {  },
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.request.objSearchMapper.searchKeyword = newValue
        await this.read()
      }
    }
  },
  data() {
    return {
      showcustomerCreate: false,
      visible: true,
      title: this.$t('CS'),
      tmpCustomerList: [],
      total: 0,
      request: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 10
      },
    }
  },
  async created() {
    await this.read()
  },
  methods: {
    showcustomerCreatebox() {
      this.showcustomerCreate = true
      this.$emit('callback', { item: null, status: true })
    },
    callbackCustomerCreate(data) {
      this.showcustomerCreate = false
      if (data) this.read()
    },
    async read() {
      const res = await this.$http.post(`${this.$serverurl}/api/getCustomerInfo`, this.request)
      if (200 == res.data.code) {
        this.tmpCustomerList = res.data.body.results
        this.total = res.data.body.results.total
        if (this.tmpCustomerList.length == 1) {
          this.updateCallback(this.tmpCustomerList[0], false)
        }
      }else{
        this.tmpCustomerList = []
        this.totalCount = 0
        this.updateCallback(null, true)
      }

    },
    updateCallback(item, newStatus) {
      this.$emit('callback', { item, status: true, new: newStatus })
    },
    handleClose(done) {
      this.$emit('callback', { item: null, status: false })
    },
  }
}
</script>
<style></style>