<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%">
      <el-row :gutter="10">
        <el-col :md="12" style="min-width:200px;">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="request.objSearchMapper.searchKeyword"
            @keyup.enter.native="read()" style="max-width:200px;">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="read()"></i>
          </el-input>
          <el-button class="ml-2" type="primary" icon="el-icon-search" size="mini" @click="read()">
            {{$t('SEARCH')}}</el-button>
        </el-col>
        <el-col :md="12" class="right">
          <el-button type="primary" icon="el-icon-success" size="mini"
            @click="insertUpdatebox()">{{$t('ADD')}}</el-button>
  
        </el-col>
      </el-row>
    </card>
    <div>
      <template>
        <el-table :data="tmpTableData" border style="width: 100%" class="font13">
          <el-table-column :label="$t('DATE')" width="100" align="center">
            <template slot-scope="scope">
              {{ $YYYYMMDD(scope.row.created) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('NAME')" width="130">
            <template slot-scope="scope">
              <div class="ellip">
                <span>
                  {{ scope.row.name }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('COMPANYNAME')">
            <template slot-scope="scope">
              <div class="ellip">
                <span>
                  {{ scope.row.companyInfo.name }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('LEVEL')" width="100" :align="'center'">
            <template slot-scope="scope">
              <div class="ellip">
                <span>
                  {{ $getlevel(scope.row.level) }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('ACCOUNT')" width="130">
            <template slot-scope="scope">
              <div class="ellip">
                <span>
                  {{ scope.row.id }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('AUTHORITY')" width="70" :align="'center'">
            <template slot-scope="scope">
              <div class="ellip">
                <span class="el-icon-s-custom"
                  :class="scope.row.authority.accountPermi==0?'middlebadge-orange':'middlebadge-light'">
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('CEO')" width="70" :align="'center'">
            <template slot-scope="scope">
              <div class="ellip">
                <span class="el-icon-s-custom"
                  :class="scope.row.authority.accountType==0?'middlebadge-orange':'middlebadge-light'">
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('TEL')" width="130" :align="'center'">
            <template slot-scope="scope">
              <div class="ellip">
                <span>
                  {{ scope.row.phone }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('MAIL')">
            <template slot-scope="scope">
              <div style="max-width: 300px;" class="ellip">
                <span>
                  {{ scope.row.mail }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="50" align="right">
            <template slot="header">
              <i class="fas fa-user-edit"></i>
            </template>
            <template slot-scope="scope">
              <i class="fas fa-user-edit hand" @click="insertUpdatebox(scope.row)"></i>
            </template>
          </el-table-column>
          <el-table-column width="50" align="right">
            <template slot="header">
              <i class="fas fa-trash-alt hand"></i>
            </template>
            <template slot-scope="scope">
              <i class="fas fa-trash-alt hand" @click="stateinsertUpdatebox(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-size="request.size"
        :page-sizes="[15, 50, 100]" layout="total, sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
    </div>
  
    <!-- <json-viewer :value="tmpTableData" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
    <!-- // 등록-갱신창 -->
    <usercreate v-if="cratevisible" :data="selectItem" @callback="createcallback" />
    <yesNoBox v-if="yesNoBoxvisible" @callback="deletecallback" :title="selectItem.name" :data="{
            content: $t('Would you like to delete the item?'),
            contentSub: $t('Deleted items cannot be recovered.')
          }" />
  </div>
</template>
<script>
import usercreate from './component-user-create.vue'
import yesNoBox from './component-alert-continue.vue'
export default {
  name: 'userItem',
  components: {
    usercreate, yesNoBox
  },
  data() {
    return {
      yesNoBoxvisible: false,
      selectItem: null,
      cratevisible: false,
      request: {
        objSearchMapper: {
          state: 0,
          searchKeyword: null,
        },
        page: 1,
        size: 15
      },
      formData: [],
      // tables
      tmpTableData: [],
      totalCount: null,
    }
  },

  created() {
    this.read()
  },
  methods: {
    checkvalue(item, field) {
      if (item.authority != null) {
        if (item.authority[field] == 0) {
          return true
        } else { return undefined }
      } else {
        return false
      }
    },

    insertUpdatebox(item) {
      this.selectItem = item
      this.cratevisible = true
    },
    deletecallback(data) {
      this.yesNoBoxvisible = false
      if (data) {
        this.stateBaseInfo(this.selectItem)
      }
    },
    createcallback(data) {
      this.cratevisible = false
      if (data) {
        this.read()
      }
    },
    emits(item) {
      this.$emit('callback', item)
    },

    async read() {
      await this.$http.post(`${this.$serverurl}/api/getAccountInfo`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
          }
        })
    },
    stateinsertUpdatebox(item) {
      this.selectItem = item
      this.yesNoBoxvisible = true
    },
    async stateBaseInfo(item) {
      item.state = item.state ? 0 : 1
      this.$http.post(`${this.$serverurl}/api/stateAccountInfo`, item, this.$setHeader())
        .then(res => {
          alert(res.data.message)
          this.read();
        })
        .catch(error => this.$axiosCatch(error))
        .then(() => { this.chkDoubleSubmit = false; });
    },

    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }

  }
}
</script>
<style></style>
