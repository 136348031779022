<template>
  <el-dialog center :title="$t(title)" :visible.sync="visible" :width="'600px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable>
    <card>
      <el-row>
        <el-col :xl="4" :md="4" class="lh25">{{$t('STORAGENAME')}}*</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('STORAGENAME')" maxlength="200" v-model="formData.name" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('TYPE')}}*</el-col>
        <el-col :xl="20" :md="20">
          <el-select size="small" v-model="formData.import" placeholder="판매원" class="w100p" @change="setProductImport(scope.row)">
              <el-option :label="$t('DT')" :value="0"></el-option>
              <el-option :label="$t('IC')" :value="1"></el-option>
            </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('WA')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('WA')" maxlength="200" v-model="formData.addr" size="mini">
          </el-input></el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('MEMO')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input type="textarea" :placeholder="$t('MEMO')" maxlength="200" v-model="formData.memo" size="mini">
          </el-input></el-col>
      </el-row>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">{{ $t('CLOSE') }}</el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange" @click="setStorageInfo">
          {{$t('SUBMIT')}}</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'storagecreate',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.formData = {
          addr: newValue?.addr || '',
          name: newValue?.name || '',
          memo: newValue?.memo || '',
          state: newValue?.state || 0,
          userId: newValue?.userId || 0,
          bizUsage: newValue?.bizUsage || 1,
          seq: newValue?.seq || null,
          import:0,
        }
      }
    },
  },
  data() {
    return {
      visible: true,
      title: 'WHS.REG.',
      tmpCustomerList: [],
      total: 0,
      formData: {
        addr: '',
        name: '',
        memo: '',
        state: 0,
        userId: 0,
        bizUsage: 1,
        seq: null,
      },
    }
  },
  async created() {
  },
  methods: {
    async setStorageInfo() {
      if (this.formData.name.trim() == '') {
        alert('창고이름을 입력하세요.')
        return
      }
      if (this.$removeSpace(this.formData.name) == '자동창고') {
        alert('시스템이 사용하는 키워드입니다.')
        return
      }
      const res = await this.$http.post(`${this.$serverurl}/api/setStorageInfo`, this.formData)
      if (201 == res.data.code) {
        alert(res.data.message)
        this.setCallback(res.data.body.results)
      }
    },
    setCallback(item) {
      this.$emit('callback', { item: item, status: true })
    },
    notClose() { },
    handleClose(done) {
      // done()
      this.$emit('callback', { item: null, status: false })
    },

  }
}
</script>
<style></style>