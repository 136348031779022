import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'

// Pages
import Login from 'src/pages/Dashboard/Pages/Login.vue'
import LoginSuper from 'src/pages/Dashboard/Pages/Login_administartor.vue'
import JoinUs from 'src/pages/Dashboard/Pages/Joinus.vue'
import Main from 'src/pages/Dashboard/Pages/Main.vue'

//입고
import OrderIn from 'src/pages/Dashboard/Pages/orderin.vue'
import BasicSetting from 'src/pages/Dashboard/Pages/basicSetting.vue'

import OrderOut from 'src/pages/Dashboard/Pages/orderout.vue'
import Inven from 'src/pages/Dashboard/Pages/inven.vue'

import orderOutExport from 'src/pages/Dashboard/Pages/orderOutExport.vue'
import InvenAreaPrint from 'src/pages/Dashboard/Pages/inven-area-print.vue'

import OrderSale from 'src/pages/Dashboard/Pages/ordersale.vue'

import UserList from 'src/pages/Dashboard/Pages/userlist.vue'

import Seo from 'src/pages/Dashboard/Pages/seo.vue'
import Ecount from 'src/pages/Dashboard/Pages/Ecount.vue'
import Bizmain from 'src/pages/Dashboard/Pages/bizmain.vue'
import saleApply from 'src/pages/Dashboard/Pages/saleApply.vue'
import SaleManager from 'src/pages/Dashboard/Pages/salemanager.vue'
import SetMyInfo from 'src/pages/Dashboard/Pages/setMyInfo.vue'
import MallManager from 'src/pages/Dashboard/Pages/mallManager.vue'
import ErpManager from 'src/pages/Dashboard/Pages/erpManager.vue'
import EpcQna from 'src/pages/Dashboard/Pages/epcqna.vue'
import MallQna from 'src/pages/Dashboard/Pages/mallqna.vue'
import CountList from 'src/pages/Dashboard/Pages/component-counts-list.vue'


import loginPhoto from 'src/pages/Dashboard/Pages/Login_photo.vue'
import loginPhotoSub from 'src/pages/Dashboard/Pages/Login_photo_sub.vue'


// 이익현황출력
import countOutExport from 'src/pages/Dashboard/Pages/countOutExport.vue'


let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}
let loginSuperPage = {
  path: '/login_administrator',
  name: 'LoginSuper',
  component: LoginSuper
}
let JoinUsPage = {
  path: '/joinUs',
  name: 'JoinUs',
  component: JoinUs
}
let mainPage = {
  path: '/main',
  name: 'main',
  component: Main
}
let OrderInPage = {
  path: '/orderin',
  name: 'orderin',
  component: OrderIn
}

let OrderOutPage = {
  path: '/orderout',
  name: 'orderout',
  component: OrderOut
}

let basicSettingPage = {
  path: '/basicSetting',
  name: 'basicSetting',
  component: BasicSetting
}
let invenPage = {
  path: '/inven',
  name: 'inven',
  component: Inven
}
let orderOutExportPage = {
  path: '/orderoutexport/:id?',
  name: '거래명세서출력',
  component: orderOutExport
}
let countOutExportPage = {
  path: '/countOutExport/:id?',
  name: '이익현황출력',
  component: countOutExport
}
let InvenAreaPrintPage = {
  path: '/invenareaprint/:id?',
  name: '자사재고출력',
  component: InvenAreaPrint
}
let orderSalePage = {
  path: '/ordersale',
  name: 'ordersale',
  component: OrderSale
}
let UserListPage = {
  path: '/userlist',
  name: 'userlist',
  component: UserList
}
let EcountPage = {
  path: '/ecount',
  name: 'ecount',
  component: Ecount
}
let BizmainPage = {
  path: '/bizmain',
  name: 'bizmain',
  component: Bizmain
}
let saleApplyPage = {
  path: '/saleapply',
  name: 'saleapply',
  component: saleApply
}
let SaleManagerPage = {
  path: '/salemanager',
  name: 'salemanager',
  component: SaleManager
}
let setMyInfoPage = {
  path: '/setmyInfo',
  name: 'setmyinfo',
  component: SetMyInfo
}
let mallmanagerPage = {
  path: '/mallmanager',
  name: 'mallmanager',
  component: MallManager
}
let erpmanagerPage = {
  path: '/erpmanager',
  name: 'erpmanager',
  component: ErpManager
}
let EpcQnaPage = {
  path: '/epcqna',
  name: 'epcqna',
  component: EpcQna
}

let MallQnaPage = {
  path: '/mallqna',
  name: 'mallqna',
  component: MallQna
}

let loginPhotoPage = {
  path: '/loginPhoto',
  name: 'loginPhoto',
  component: loginPhoto
}

let loginPhotoSubPage = {
  path: '/loginPhotoSub',
  name: 'loginPhotoSub',
  component: loginPhotoSub
}
let seoPage = {
  path: '/seo',
  name: 'seo',
  component: Seo
}
let countListPage = {
  path: '/countlist',
  name: 'countlist',
  component: CountList
}
const routes = [
  {
    path: '/',
    redirect: '/main'
  },
  countOutExportPage,
  countListPage,
  erpmanagerPage,
  seoPage,
  loginPhotoPage,
  loginPhotoSubPage,
  loginSuperPage,
  MallQnaPage,
  EpcQnaPage,
  mallmanagerPage,
  setMyInfoPage,
  SaleManagerPage,
  saleApplyPage,
  BizmainPage,
  EcountPage,
  UserListPage,
  orderSalePage,
  InvenAreaPrintPage,
  orderOutExportPage,
  invenPage,
  basicSettingPage,
  loginPage,
  JoinUsPage,
  mainPage,

  OrderInPage,
  OrderOutPage,
  { path: '*', component: NotFound }
]

export default routes
