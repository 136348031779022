<template>
  <div class="container mt-3 relative" :style="$pageWidth">
    <TopBar />
    <card class="mt-2" style="min-width: 100%;margin-bottom:20px;">
      <el-row :gutter="10">
        <el-col :md="20">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="searchKeyword" style="max-width: 200px;" class="mr-2"
            @keyup.enter.native="searchRead(searchKeyword)">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="searchRead(searchKeyword)"></i>
          </el-input>
          <el-date-picker size="mini" v-model="startDate" type="date" :placeholder="$t('STARTDATE')" format="yyyy-MM-dd"
            style="max-width: 200px;">
          </el-date-picker>
          ~
          <el-date-picker size="mini" v-model="endDate" type="date" :placeholder="$t('ENDDATE')" class="mr-2"
            style="max-width: 200px;">
          </el-date-picker>
          <el-button type="primary" icon="el-icon-search" size="mini"
            @click="searchRead(searchKeyword)">{{$t('SEARCH')}}</el-button>
          <el-button type="primary" icon="el-icon-tickets" size="mini" v-if="searchKeyword || startDate || endDate "
            @click="searchRead(null)">{{$t('VIEWALL')}}</el-button>
        </el-col>
        <el-col :md="4" class="right">
          <el-button type="primary" size="mini" v-if="(startDate && endDate) " @click="printView()">
            <i class="fas fa-chart-line mr-1"></i>
            {{$t('PRINT')}}
          </el-button>
        </el-col>
      </el-row>
    </card>
    <div>
      <div class="mb-2 right font15" v-if="body">
        <span class="mr-2 underline">{{ $t('P.TOTAL') + ': ' + $comma(body.inSum) }}</span>
        <span class="mr-2 underline">{{ $t('S.TOTAL') + ': ' + $comma(body.outSum) }}</span>
        <span class="underline">{{ $t('PROFIT') + ': ' + $comma(body.outSum - body.inSum) }}</span>
      </div>
      <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13">
        <el-table-column :label="$t('DATE') " width="120" :align="'center'">
          <template slot-scope="scope">
            {{ $YYYYMMDD_HHMM(scope.row.created) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('FACTORYNUM')" width="120" :align="'left'">
          <template slot-scope="scope">
            {{ scope.row.baseInfo.factoryNum }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('ITEM') " :align="'left'">
          <template slot-scope="scope">
            {{ scope.row.baseInfo.name }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('SALEINFO')" :align="'center'">
          <el-table-column :label="$t('QTY')" width="80" :align="'right'">
            <template slot-scope="scope">
              {{$comma(saleQty(scope.row.orderOutItemInfo)) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('PRICE')" width="100" :align="'right'">
            <template slot-scope="scope">
              <span class="underline hand colorbluedeep" @click="showviewoutpop(scope.row,'out')">
                {{$comma(salePrice(scope.row.orderOutItemInfo)) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('SUBTOTAL')" width="100" :align="'right'">
            <template slot-scope="scope">
              {{$comma(salePrice(scope.row.orderOutItemInfo)*saleQty(scope.row.orderOutItemInfo)) }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('ORIGINPRICE')" :align="'center'">
          <el-table-column :label="$t('PRICE')" width="100" :align="'right'">
            <template slot-scope="scope">
              <span class="underline hand colorbluedeep" @click="showviewoutpop(scope.row,'in')">
                {{$comma(buyPrice(scope.row.orderInItemInfo))}}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('SUBTOTAL')" width="100" :align="'right'">
            <template slot-scope="scope">
              {{$comma(buyPrice(scope.row.orderInItemInfo)*saleQty(scope.row.orderOutItemInfo))}}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('PROFIT')" :align="'center'">
          <el-table-column :label="$t('PRICE')" width="100" :align="'right'">
            <template slot-scope="scope">
              <span>
                {{ $comma(
                salePrice(scope.row.orderOutItemInfo)
                -
                buyPrice(scope.row.orderInItemInfo)
                )
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('SUBTOTAL')" width="100" :align="'right'">
            <template slot-scope="scope">
              {{ $comma(
              (salePrice(scope.row.orderOutItemInfo)
              -
              buyPrice(scope.row.orderInItemInfo)
              ) * saleQty(scope.row.orderOutItemInfo)
              )
              }}
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <!-- <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSize" :page-size="request.size"
                                    layout=" sizes, prev, pager, next" :total="totalCount">
                                  </el-pagination> -->
    </div>
    <!-- <json-viewer :value="tmpTableData" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
    <countsoutlistview v-if="selectItem" :data="selectItem" @callback="viewcallback" />
  </div>
</template>
<script>
import TopBar from './component-top.vue'
import countsoutlistview from './component-counts-out-list-view.vue'
export default {
  name: 'countslist',
  components: { TopBar, countsoutlistview },
  data() {
    return {
      selectItem: null,
      pageSize: [10000, 500000, 1000000],
      formData: {
        tradeDate: null,
        vat: 0,
        custoemrId: null,
      },
      startDate: null,
      endDate: null,
      searchKeyword: null,
      request: {
        objSearchMapper: {
          noEmtpy: true,
          startDate: null,
          endDate: null,
          searchKeyword: null,
        },
        page: 1,
        size: 10000
      },

      // tables
      tmpTableData: [],
      totalCount: null,
      body: null,
      userlogininfo: null,
    }
  },

  created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    const {sDate,eDate} = this.$dateArea(1)
    this.startDate = sDate
    this.endDate = eDate
    this.read()

  },
  methods: {
    printView() {
      const routerUrl = this.$router.resolve({
        name: '이익현황출력',
        params: this.tmpTableData.length
      })
      const data = {
        inSum: this.body.inSum,
        outSum: this.body.outSum,
        startDate: this.startDate,
        endDate: this.endDate,
        content: this.tmpTableData,
        user: this.userlogininfo.name
      }
      localStorage.setItem('printInfo', JSON.stringify(data))
      window.open(routerUrl.href, '이익현황', 'width=800,height=700')
    },

    showviewoutpop(item, io) {
      this.selectItem = item
      this.selectItem.io = io
    },
    viewcallback(data) {
      this.selectItem = null
    },
    saleQty(items) {
      return items.reduce((acc, item) => {
        return acc + item.outQty
      }, 0) || 0
    },
    salePrice(items) {
      const qty = items.reduce((acc, item) => {
        return acc + item.outQty
      }, 0) || 0
      const priceTotal = items.reduce((acc, item) => {
        return acc + item.outQty * item.outPrice
      }, 0) || 0
      if (!qty || !priceTotal) { return 0 }
      return priceTotal / qty
    },
    buyPrice(items) {
      const qty = items.reduce((acc, item) => {
        return acc + item.inQty
      }, 0) || 0
      const priceTotal = items.reduce((acc, item) => {
        return acc + item.inQty * item.inPrice
      }, 0) || 0
      if (!qty || !priceTotal) { return 0 }
      return priceTotal / qty
    },

    searchRead(searchKeyword) {
      this.searchKeyword = searchKeyword
      this.request.objSearchMapper.searchKeyword = this.$trimLR(searchKeyword)
      if (this.startDate && this.endDate) {
        const timeDifference = this.endDate.getTime() - this.startDate.getTime();
        const dayDifference = timeDifference / (1000 * 60 * 60 * 24)
        if (dayDifference > 90) {
          alert('90일이내 기록만 열람가능합니다.')
          return
        }
        this.request.objSearchMapper.startDate = this.$dateToYYYYMMDD(this.startDate)
        this.request.objSearchMapper.endDate = this.$dateToYYYYMMDD(this.endDate)
      } else {
        this.request.objSearchMapper.startDate = null
        this.request.objSearchMapper.endDate = null
        this.startDate = null
        this.endDate = null
      }
      this.read()
    },
    async read() {
      this.request.objSearchMapper.searchKeyword = this.$trimLR(this.searchKeyword)

      await this.$http.post(`${this.$serverurl}/api/getIOAccountBook`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.body = res.data.body
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.body.total
          } else {
            this.tmpTableData = []
            this.totalCount = 0
          }
        })
    },


    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    },

  }
}
</script>
<style></style>
