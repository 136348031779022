<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <label class="bold font15">{{ $t('LP') }}</label>
    <card style="min-width: 100%;margin-bottom:10px;">
      <el-row :gutter="10">
        <el-col :md="14">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="searchKeyword" style="max-width: 200px;" class="mr-2"
            @keyup.enter.native="searchRead(searchKeyword)">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="searchRead(searchKeyword)"></i>
          </el-input>
          <el-date-picker size="mini" style="max-width:150px;" v-model="request.objSearchMapper.startDate" type="date"
            format="yyyy 년 MM 월 dd 일" value-format="yyyy-MM-dd" placeholder="검색날자">
          </el-date-picker> ~
          <el-date-picker class="mr-2" size="mini" style="max-width:150px;" v-model="request.objSearchMapper.endDate"
            type="date" format="yyyy 년 MM 월 dd 일" value-format="yyyy-MM-dd" placeholder="검색날자">
          </el-date-picker>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="searchRead(searchKeyword)">
            {{$t('SEARCH')}}
          </el-button>
          <el-button type="primary" icon="el-icon-tickets" size="mini" v-if="searchKeyword"
            @click="searchRead(null)">{{$t('VIEWALL')}}</el-button>
        </el-col>
        <el-col :md="10" class="right bold pt-1" v-if="sumInfo">
          <span class="mr-2">{{ $t('AMOUNT') }}: {{ $comma(sumInfo.amount) }}</span>
          <span class="mr-2">{{ $t('SUPPLY.P') }}: {{ $comma(sumInfo.supply) }}</span>
          <span>{{ $t('VAT') }}: {{ $comma(sumInfo.vat) }}</span>
        </el-col>
      </el-row>
    </card>
    <div>
  
      <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13">
        <el-table-column label="ID" width="80" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.seq }}
          </template>
        </el-table-column>
        <el-table-column :label="'거래날자'" width="120" :align="'center'">
          <template slot-scope="scope">
            {{ $YYYYMMDD_HHMM(scope.row.transaction_date) }}
          </template>
        </el-table-column>
        <el-table-column :label="'결제구분'" width="120" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">
              <!-- {{ scope.row.pgcode=='creditcard'?'카드결제':'인터넷뱅킹' }} -->
                {{ scope.row.service_name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TYPE')" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ $nulltom(scope.row.pay_info) }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'상호'" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'이름'" width="120" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.user_name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'계정'" width="100" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.user_id }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="'거래번호'" width="160" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.order_no }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('SUPPLY.P')" width="100" :align="'right'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ $comma(scope.row.amount - scope.row.tax_amount) }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TAX')" width="80" :align="'right'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ $comma(scope.row.tax_amount) }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('SUBTOTAL')" width="80" :align="'right'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ $comma(scope.row.amount) }}</span>
          </template>
        </el-table-column>
      </el-table>
  
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
        :page-size="request.size" layout="total, sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: 'totallicenselist',
  components: {
  },
  data() {
    return {
      count: [],
      pageSizes: [12, 50, 100],
      searchKeyword: '',
      request: {
        objSearchMapper: {
          searchKeyword: '',
          startDate: null,
          endDate: null,
        },
        page: 1,
        size: 12
      },
      tmpTableData: [],
      totalCount: 0,
      sumInfo: null,
    }
  },

  created() {
    this.read()
    const { sDate, eDate } = this.$dateArea(1)
    this.request.objSearchMapper.startDate = this.$dateToYYYYMMDD(sDate)
    this.request.objSearchMapper.endDate = this.$dateToYYYYMMDD(eDate)
  },
  methods: {

    searchRead(searchKeyword) {
      this.searchKeyword = searchKeyword
      this.request.page = 1
      this.request.objSearchMapper.searchKeyword = this.$trimLR(searchKeyword)
      if (this.request.objSearchMapper.startDate && !this.request.objSearchMapper.endDate) {
        this.request.objSearchMapper.endDate = this.$dateToYYYYMMDD(new Date())
      }
      if (!this.request.objSearchMapper.startDate && this.request.objSearchMapper.endDate) {
        this.request.objSearchMapper.startDate = this.$dateToYYYYMMDD(new Date())
      }
      this.read()
    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/buyLicenseList`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.count = res.data.body.count
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
            this.sumInfo = res.data.body.sumInfo
          } else {
            this.tmpTableData = []
          }
        })
    },

    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }

  }
}
</script>
<style></style>
