<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%;margin-bottom:20px;">
      <el-row :gutter="10">
        <el-col :md="12">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="searchKeyword" style="max-width: 200px;" class="mr-2"
            @keyup.enter.native="searchRead(searchKeyword)">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="searchRead(searchKeyword)"></i>
          </el-input>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="searchRead(searchKeyword)">
            {{ $t('SEARCH') }}</el-button>
          <el-button type="primary" icon="el-icon-tickets" size="mini" v-if="searchKeyword" @click="searchRead(null)">{{
            $t('VIEWALL') }}</el-button>
        </el-col>
        <el-col :md="12" class="right">
          <el-button type="primary" :icon="request.objSearchMapper.state ? 'el-icon-success' : 'el-icon-delete'"
            size="mini" @click="searchStateRead(request.objSearchMapper.state ? 0 : 1)">
            {{ request.objSearchMapper.state ? $t('AI') : $t('DI') }}</el-button>
  
          <el-button v-if="authority.devPermi==0" type="primary" size="mini" @click="setEcountInfoAsyncAll()">
            모든상품갱신</el-button>
        </el-col>
      </el-row>
    </card>
    <div class="relative">
      <div class="right">
        <div :calss="sortClass(0)" @click="sortRead(0)"
          class="middlebadge-blue fa fa-solid fa-arrow-up-a-z fontsize14 hand" style="width:20px"></div>
        <div :calss="sortClass(1)" @click="sortRead(1)"
          class="ml-1 middlebadge-blue fa fa-solid fa-arrow-down-z-a fontsize14 hand" style="width:20px"></div>
        <div :calss="sortClass(2)" @click="sortRead(2)" class="ml-1 middlebadge-blue fa fa-arrow-down-9-1 fontsize14 hand"
          style="width:20px"></div>
        <div :calss="sortClass(3)" @click="sortRead(3)" class="ml-1 middlebadge-blue fa fa-arrow-down-1-9 fontsize14 hand"
          style="width:20px"></div>
      </div>
      <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13 mt-2">
        <el-table-column label="선택" width="50" :align="'center'">
          <template slot="header" slot-scope="scope">
            <span class=" hand font15" :class="!checkedIds.length? 
                          'far fa-square-check':'fas fa-square-check colorbluedeep'
                          " @click="checkAll()"></span>
          </template>
          <template slot-scope="scope">
            <input type="checkbox" :checked="checkedIds.find(item => item.seq == scope.row.seq)"
              @change="pushId(scope.row, $event.target.checked)">
          </template>
        </el-table-column>
        <el-table-column label="ID" width="80" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.seq }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('ITEM')" style="max-width: 300px;" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip"> {{ scope.row.prodDes }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('PID')" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip"> {{ scope.row.sizeDes }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('FACTORYNUM')" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip"> {{ scope.row.prodCd }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TYPE')" width="100" :align="'center'">
          <template slot-scope="scope">
            {{ $t($getProdType(scope.row)) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('IV.QTY')" width="100" :align="'right'">
          <template slot-scope="scope">
            {{ scope.row.balQtyEx }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('IN.AMOUNT')" width="100" :align="'right'">
          <template slot-scope="scope">
            {{ $comma(scope.row.inPrice) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('OUT.AMOUNT')" width="100" :align="'right'">
          <template slot-scope="scope">
            {{ $comma(scope.row.outPrice) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('STORAGE')" width="100" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.whDes }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('STATUS')" width="90" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.state ? $t('DELETED') : $t('USED') }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('SYNC')" width="80" :align="'center'">
          <template slot-scope="scope">
            <i class="el-icon-refresh hand middlebadge-orange" @click="ecountAsync(scope.row)"></i>
          </template>
        </el-table-column>
  
        <el-table-column label="정지/삭제" width="50" :align="'right'">
          <template slot="header">
            <i :class="'fas fa-trash-alt'"></i>
          </template>
          <template slot-scope="scope">
            <i class="hand" :class="scope.row.state == 0 ? 'fas fa-trash-alt' : 'fas fa-trash'"
              @click="stateinsertUpdatebox(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt-2">
        <el-button size="mini" v-if="판매요청버튼유효성()" @click="setEcountToProductMulti(1)">
          {{ $t('SALER') }}
        </el-button>
        <el-button size="mini" v-if="요청취소버튼유효성()" @click="setEcountToProductMulti(3)">
          {{ $t('RC') }}
        </el-button>
        <el-button size="mini" v-if="판매취소버튼유효성()" @click="setEcountToProductMulti(5)">
          {{ $t('SC') }}
        </el-button>
      </div>
      <el-pagination v-if="tmpTableData.length" background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
        :page-size="request.size" layout="sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
    </div>
  
    <!-- <json-viewer :value="tmpTableData" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
    <yesNoBox v-if="yesNoBoxvisible" @callback="deletecallback" :title="selectItem.prodDes" :data="yesNoBoxContent" />
  </div>
</template>
<script>
import { Loading } from 'element-ui';
import yesNoBox from './component-alert-continue.vue'
export default {
  name: 'ecountlist',
  components: {
    yesNoBox
  },
  props: ['append'],
  watch: {
    append: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.request.objSearchMapper.append = newValue
        this.request.page = 1
        this.read()
      }
    },
  },
  data() {
    return {
      pageSizes: [12, 50, 100],
      checkedIds: [],
      formData: {
        tradeDate: null,
        vat: 0,
        custoemrId: null,
      },
      yesNoBoxvisible: false,
      yesNoBoxContent: null,
      startDate: null,
      endDate: null,
      searchKeyword: null,
      request: {
        objSearchMapper: {
          sort: 0,
          balQtyExNe: 0,
          append: 0,
          state: 0,
          saleStatus: null,
          searchKeyword: null,
          // startDate: null,
          // endDate: null,
          ecountListExFilter: false,

        },
        page: 1,
        size: 15
      },
      // tables
      tmpTableData: [],
      totalCount: null,
      tmpAppendCount: [],
      authority: null,
    }
  },

  created() {
    const userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    this.authority = userlogininfo.authority
    this.read()
  },
  methods: {
    searchStateRead(params) {
      this.request.objSearchMapper.state = params
      this.request.page = 1
      this.read()
    },

    sortRead(params) {
      this.request.objSearchMapper.sort = params
      this.read()
    },
    searchRead(searchKeyword) {
      this.searchKeyword = searchKeyword
      this.request.objSearchMapper.searchKeyword = this.$trimLR(searchKeyword)
      this.read()
    },
    async read() {
      if (this.request.objSearchMapper.append == 2) { this.request.objSearchMapper.balQtyExNe = '' } else {
        this.request.objSearchMapper.balQtyExNe = 0
      }
      this.loading = Loading.service({ fullscreen: true })
      this.checkedIds = [] // 선택항목초기화
      this.request.objSearchMapper.searchKeyword = this.$trimLR(this.searchKeyword)
      if (this.startDate && this.endDate) {
        this.request.objSearchMapper.startDate = this.$dateToYYYYMMDD(this.startDate)
        this.request.objSearchMapper.endDate = this.$dateToYYYYMMDD(this.endDate)
      }
      await this.$http.post(`${this.$serverurl}/api/getEcountListEx`, this.request, this.$setHeader())
        .then(res => {
          this.tmpAppendCount = res?.data?.body?.count
          this.callback(this.tmpAppendCount, false)
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
          } else {
            this.tmpTableData = []
            // this.tmpAppendCount = res.data.body.appendCount
            this.totalCount = 0
          }
        })
      this.$overay(this.loading)
    },
    deletecallback(data) {
      this.yesNoBoxvisible = false
      if (data) {
        this.stateEcountListEx(this.selectItem)
      }
    },
    stateinsertUpdatebox(item) {
      this.selectItem = item
      this.yesNoBoxvisible = true
      if (item.state) {
        this.yesNoBoxContent = {
          content: this.$t('Would you like to restore the item?'),
          contentSub: this.$t('The information is being used normally.')
        }
      } else {
        this.yesNoBoxContent = {
          content: this.$t('Would you like to delete the item?'),
          contentSub: this.$t('Deleted items cannot be recovered.')
        }
      }
    },
    async stateEcountListEx(item) {
      this.loading = Loading.service({ fullscreen: true })
      var params = {};
      params.seq = item.seq
      params.state = item.state
      this.$http.post(`${this.$serverurl}/api/stateEcountListEx`, params, this.$setHeader())
        .then(res => {
          alert(res.data.message)
          this.read();
        })
        .catch(error => this.$axiosCatch(error))
        .then(() => { this.chkDoubleSubmit = false; });
      this.$overay(this.loading)
    },
    ecountAsync(row) { //낱개 수량동기화
      const urlStr = `${this.$serverurl}/api/setEcountInfoAsyncOne`
      this.$http.post(
        urlStr,
        row,
        this.$setHeader()).then(res => {
          if (res.data.code == 201) {
            if (res.data.body.results.sync) {
              this.read()
            }
          }
        })
    },

    setEcountInfoAsyncAll() {
      this.$http.post(`${this.$serverurl}/api/setEcountInfoAsyncAll`, null, this.$setHeader())
        .then(res => {
          alert(res.data.message)
        })
        .catch(error => this.$axiosCatch(error))
        .then(() => { this.chkDoubleSubmit = false; });
    },
    async setEcountToProductMulti(status) {
      this.loading = Loading.service({ fullscreen: true })
      for (var item of this.checkedIds) {
        item.append = status
      }
      const url = `${this.$serverurl}/api/setEcountExToProductInfo`
      this.$http.post(url, this.checkedIds, this.$setHeader()).then(res => {
        if (res.data.code == 201) {
          alert(res.data.message)
          this.read()
        }
      })
      this.$overay(this.loading)
    },


    callback(item, status) {
      this.$emit('callback', {
        item, status: status
      })
    },
    pushId(item, isChecked) {
      let pattern = new RegExp(`(^|,)${item.seq}($|,)`);
      const exists = this.checkedIds.some(id => pattern.test(id.seq))
      if (isChecked && !exists) {
        this.checkedIds.push(item)
      } else if (!isChecked && exists) {
        const index = this.checkedIds.findIndex(id => pattern.test(id.seq))
        if (index !== -1) {
          this.checkedIds.splice(index, 1)
        }
      }
    },
    // pushId(seq, isChecked) {
    //   const exists = this.checkedIds.includes(seq);
    //   if (isChecked && !exists) {
    //     this.checkedIds.push(seq);
    //   } else if (!isChecked && exists) {
    //     const index = this.checkedIds.indexOf(seq);
    //     this.checkedIds.splice(index, 1);
    //   }
    // },
    checkAll() {
      if (this.checkedIds.length !== this.tmpTableData.length) {
        this.checkedIds = this.tmpTableData.filter(item => item.seq)
      } else {
        this.checkedIds = []
      }
    },
    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    },

    판매요청버튼유효성() {
      for (var tmpitems of this.checkedIds) {
        if (!tmpitems.balQtyEx) { return false }
      }
      return (
        this.request.objSearchMapper.append == 0 ||
        this.request.objSearchMapper.append == 3 ||
        this.request.objSearchMapper.append == 5)
        && this.checkedIds.length > 0
    },

    요청취소버튼유효성() {
      return this.request.objSearchMapper.append == 1 && this.checkedIds.length > 0
    },
    판매취소버튼유효성() {
      return this.request.objSearchMapper.append == 2 && this.checkedIds.length > 0
    },

    sortClass(params) {
      if (this.request.objSearchMapper.sort == params) {
        return 'middlebadge-blue-select'
      } else {
        return 'middlebadge-blue'
      }
    }
  }
}
</script>
<style></style>
