<template>
  <div class="container mt-3 relative" :style="$pageWidth">
    <TopBar />
    <div class="mt-2">
      <div class="mt-2">
        <epcqna  @callback="listcallback" />
      </div>
  
    </div>
  </div>
</template>
<script>
import epcqna from './component-epc-qna-list.vue'
import TopBar from './component-top.vue'
export default {
  components: {
    TopBar, epcqna
  },
  data() {
    return {
      viewvisible: false,
      tabs: [{}],
      initem: {},
    }
  },
  methods: {
    listcallback() {

    },
    viewallback(data) {
      if (data.status) {
        this.selectItem = data.item
        this.activeName = 'orderininsert'
      }
      this.viewvisible = false
    },
    handleButtons(params) {
      this.activeName = params;
      this.selectItem = null
    },
    handleClick(tab, event) { },
  }
}
</script>
<style></style>
