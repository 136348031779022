<template>
  <el-dialog center :title="title" :visible.sync="visible" :width="'600px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable>
    <el-table :data="listData" :border="true" style="width: 100%" class="font13 mt-2">
      <el-table-column :label="$t('FACTORYNUM')" width="120" :align="'center'">
        <template slot-scope="scope">
          {{ scope.row.factoryNum }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('pid')" width="120" :align="'center'">
        <template slot-scope="scope">
          {{ scope.row.pid }}
        </template>
      </el-table-column>
      <el-table-column label="log's" :align="'left'">
        <template slot-scope="scope">
          {{ scope.row.systemMemo }}
        </template>
      </el-table-column>
    </el-table>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">{{ $t('CLOSE') }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'seologview',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.title =  newValue.item_name
        this.listData = newValue.logs
      }
    },
  },
  data() {
    return {
      visible: true,
      title: 'SEO LOGS',
      listData: [],
    }
  },
  async created() {
  },
  methods: {
    notClose() { },
    handleClose(done) {
      this.$emit('callback', { item: null, status: false })
    },

  }
}
</script>
<style></style>