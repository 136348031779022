<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%">
      <el-row :gutter="10">
        <el-col :md="12" style="min-width:200px;">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="request.objSearchMapper.searchKeyword"
            @keyup.enter.native="read()" style="max-width:200px;">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="read()"></i>
          </el-input>
          <el-button class="ml-2" type="primary" icon="el-icon-search" size="mini" @click="read()">{{$t('SEARCH')}}</el-button>
        </el-col>
        <el-col :md="12" class="right">
          <el-button type="primary" icon="el-icon-success" size="mini" @click="insertUpdatebox()">{{$t('ADD')}}</el-button>
        </el-col>
      </el-row>
    </card>
    <div>
      <!-- <el-pagination class="mt-2 mb-2 text-right" layout="prev, pager, next" :total="totalCount">
      </el-pagination> -->
      <template>
        <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13">
          <el-table-column :label="$t('DATE')" width="100" :align="'center'">
            <template slot-scope="scope">
              {{ $YYYYMMDD(scope.row.created) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('ITEM')" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="max-width: 300px;" class="ellip">
                <span>
                  {{ scope.row.name }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('TYPE')" width="80" align="center">
            <template slot-scope="scope">
              <div>
                {{ $productType.find(item => item.value == scope.row.type).label }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('PID')" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="max-width: 100px;" class="ellip">
                {{ scope.row.pid }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('FACTORYNUM')" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="max-width: 100px;" class="ellip">
                {{ scope.row.factoryNum }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('CPN')" width="90" align="center">
            <template slot-scope="scope">
              <el-popover v-if="scope.row.pidList" placement="bottom" trigger="click" :content="scope.row.pidList">
                <i class="el-icon-platform-eleme hand colororange font20" slot="reference"></i>
              </el-popover>
              <i v-else class="el-icon-platform-eleme colorlight font20"></i>
            </template>
          </el-table-column>
          <el-table-column :label="$t('WP')" width="90" :align="'right'">
            <template slot-scope="scope">
              <div style="max-width: 100px;">
                {{ $comma(scope.row.inPrice) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('RP')+'A'" width="90" :align="'right'"
          v-if="userlogininfo.authority.accountPermi==0 || userlogininfo.companyInfo.level==0">
            <template slot-scope="scope">
              <div style="max-width: 100px;">
                {{ $comma(scope.row.priceA) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('RP')+'B'" width="90" :align="'right'"
          v-if="userlogininfo.authority.accountPermi==0 || userlogininfo.companyInfo.level==0">
            <template slot-scope="scope">
              <div style="max-width: 100px;">
                {{ $comma(scope.row.priceB) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="'도매가'" width="90" :align="'right'">
            <template slot-scope="scope">
              <div style="max-width: 100px;">
                {{ $comma(scope.row.priceC) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="'소매가'" width="90" :align="'right'">
            <template slot-scope="scope">
              <div style="max-width: 100px;">
                {{ $comma(scope.row.priceD) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="수정" width="50" :align="'center'">
          <template slot="header">
            <i class="fas fa-pen-to-square"></i>
          </template>
          <template slot-scope="scope">
            <i class="fas fa-pen-to-square hand" @click="insertUpdatebox(scope.row)"></i>
          </template>
        </el-table-column>
          <el-table-column width="50" align="right">
            <template slot="header">
              <i class="fas fa-trash-alt hand"></i>
            </template>
            <template slot-scope="scope">
              <i class="fas fa-trash-alt hand" @click="stateinsertUpdatebox(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-size="request.size"
        :page-sizes="[15, 50, 100]" layout="total, sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
    </div>
    <!-- // 등록-갱신창 -->
    <basicitemcreate v-if="cratevisible" :data="selectItem" @callback="createcallback" />
    <yesNoBox v-if="yesNoBoxvisible" @callback="deletecallback" :title="selectItem.name" :data="{
    content: $t('Would you like to delete the item?'),
    contentSub: $t('Deleted items cannot be recovered.')
  }" />
  </div>
</template>
<script>
import basicitemcreate from './component-basic-item-create.vue'
import yesNoBox from './component-alert-continue.vue'
export default {
  name: 'baseitem',
  components: {
    basicitemcreate, yesNoBox
  },
  data() {
    return {
      yesNoBoxvisible: false,
      selectItem: null,
      cratevisible: false,
      request: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 15
      },

      // tables
      tmpTableData: [],
      totalCount: null,
      userlogininfo:null,
    }
  },

  created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    this.read()
  },
  methods: {
    insertUpdatebox(item) {
      this.selectItem = item
      this.cratevisible = true
    },
    deletecallback(data) {
      this.yesNoBoxvisible = false
      if (data) {
        this.stateBaseInfo(this.selectItem)
      }
    },
    createcallback(data) {
      this.cratevisible = false
      if (data) {
        this.read()
      }
    },
    emits(item) {
      this.$emit('callback', item)
    },

    async read() {
      await this.$http.post(`${this.$serverurl}/api/getBaseInfo`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
          }
        })
    },
    stateinsertUpdatebox(item) {
      this.selectItem = item
      this.yesNoBoxvisible = true
    },
    async stateBaseInfo(item) {
      this.$http.post(`${this.$serverurl}/api/stateBaseInfo`, item, this.$setHeader())
        .then(res => {
          alert(res.data.message)
          this.read();
        })
        .catch(error => this.$axiosCatch(error))
        .then(() => { this.chkDoubleSubmit = false; });
    },

    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }

  }
}
</script>
<style></style>
