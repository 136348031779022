<template>
  <el-dialog center :title="$t('BI.REG.')" :visible.sync="visible" :width="'650px'" :modal=true :show-close="false"
    :before-close="notClose" v-draggable>
    <card>
      <el-row>
        <el-col :xl="4" :md="4" class="lh25">{{$t('ITEM')}}*</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('ITEM')" maxlength="200" v-model="formData.name" size="mini">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('TYPE') }}</el-col>
        <el-col :xl="20" :md="20">
          <el-select size="mini" v-model="formData.type" class="me-3 w100p">
            <el-option v-for="item in $productType" :key="item.value" :label="$t(item.label)" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('PID') }}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('PID')" maxlength="200" v-model="formData.pid" size="mini">
          </el-input></el-col>
      </el-row>
  
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('FACTORYNUM') }}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('FACTORYNUM')" maxlength="20" v-model="formData.factoryNum" size="mini">
          </el-input></el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('MINQTY') }}</el-col>
        <el-col :xl="20" :md="20">
          <el-input :placeholder="$t('MINQTY')" maxlength="10" v-model="formData.minQty" size="mini"
            @focus="() => { formData.minQty = $numberFormat(formData, 'minQty', 'focus') }"
            @input="() => { formData.minQty = $numberFormat(formData, 'minQty', 'input') }"
            @blur="() => { formData.minQty = $numberFormat(formData, 'minQty', 'blur') }">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('WP') }}</el-col>
        <el-col :xl="20" :md="20">
          <span class="mr-2">{{ $t('PRICE') }}</span>
          <el-input class="w100 mr-2" :placeholder="$t('WP')" maxlength="10" v-model="formData.inPrice" size="mini"
            @input="()=>{supply(formData.inPrice,'Y','inPrice')}">
          </el-input>
          <span class="mr-2">{{ $t('SUPPLY.P') }}</span>
          <el-input class="w100 mr-2" :placeholder="$t('SUPPLY.P')" maxlength="10" v-model="formData.supply_inPrice"
            size="mini" @input="()=>{supplySub(formData.supply_inPrice,'Y','inPrice')}">
          </el-input>
          <span class="mr-2">{{ $t('VAT') }}</span>
          <el-input style="max-width: 90px;" :placeholder="$t('SUPPLY.P')" maxlength="10" v-model="formData.vat_inPrice"
            size="mini" readonly="">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2"
        v-if="userlogininfo.authority.accountPermi==0 || userlogininfo.companyInfo.level==0">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('RP')+'A' }}</el-col>
        <el-col :xl="20" :md="20">
          <span class="mr-2">{{ $t('PRICE') }}</span>
          <el-input class="w100 mr-2" :placeholder="$t('WP')" maxlength="10" v-model="formData.priceA" size="mini"
            @input="()=>{supply(formData.priceA,'Y','priceA')}">
          </el-input>
          <span class="mr-2">{{ $t('SUPPLY.P') }}</span>
          <el-input class="w100 mr-2" :placeholder="$t('SUPPLY.P')" maxlength="10" v-model="formData.supply_priceA"
            size="mini" @input="()=>{supplySub(formData.supply_priceA,'Y','priceA')}">
          </el-input>
          <span class="mr-2">{{ $t('VAT') }}</span>
          <el-input style="max-width: 90px;" :placeholder="$t('SUPPLY.P')" maxlength="10" v-model="formData.vat_priceA"
            size="mini" readonly="">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2"
        v-if="userlogininfo.authority.accountPermi==0 || userlogininfo.companyInfo.level==0">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('RP')+'B' }}</el-col>
        <el-col :xl="20" :md="20">
          <span class="mr-2">{{ $t('PRICE') }}</span>
          <el-input class="w100 mr-2" :placeholder="$t('WP')" maxlength="10" v-model="formData.priceB" size="mini"
            @input="()=>{supply(formData.priceB,'Y','priceB')}">
          </el-input>
          <span class="mr-2">{{ $t('SUPPLY.P') }}</span>
          <el-input class="w100 mr-2" :placeholder="$t('SUPPLY.P')" maxlength="10" v-model="formData.supply_priceB"
            size="mini" @input="()=>{supplySub(formData.supply_priceB,'Y','priceB')}">
          </el-input>
          <span class="mr-2">{{ $t('VAT') }}</span>
          <el-input style="max-width: 90px;" :placeholder="$t('SUPPLY.P')" maxlength="10" v-model="formData.vat_priceB"
            size="mini" readonly="">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('WPRICE')}}</el-col>
        <el-col :xl="20" :md="20">
          <span class="mr-2">{{ $t('PRICE') }}</span>
          <el-input class="w100 mr-2" :placeholder="$t('WP')" maxlength="10" v-model="formData.priceC" size="mini"
            @input="()=>{supply(formData.priceC,'Y','priceC')}">
          </el-input>
          <span class="mr-2">{{ $t('SUPPLY.P') }}</span>
          <el-input class="w100 mr-2" :placeholder="$t('SUPPLY.P')" maxlength="10" v-model="formData.supply_priceC"
            size="mini" @input="()=>{supplySub(formData.supply_priceC,'Y','priceC')}">
          </el-input>
          <span class="mr-2">{{ $t('VAT') }}</span>
          <el-input style="max-width: 90px;" :placeholder="$t('SUPPLY.P')" maxlength="10" v-model="formData.vat_priceC"
            size="mini" readonly="">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{$t('RPRICE')}}*</el-col>
        <el-col :xl="20" :md="20">
          <span class="mr-2">{{ $t('PRICE') }}</span>
          <el-input class="w100 mr-2" :placeholder="$t('WP')" maxlength="10" v-model="formData.priceD" size="mini"
            @input="()=>{supply(formData.priceD,'Y','priceD')}">
          </el-input>
          <span class="mr-2">{{ $t('SUPPLY.P') }}</span>
          <el-input class="w100 mr-2" :placeholder="$t('SUPPLY.P')" maxlength="10" v-model="formData.supply_priceD"
            size="mini" @input="()=>{supplySub(formData.supply_priceD,'Y','priceD')}">
          </el-input>
          <span class="mr-2">{{ $t('VAT') }}</span>
          <el-input style="max-width: 90px;" :placeholder="$t('SUPPLY.P')" maxlength="10" v-model="formData.vat_priceD"
            size="mini" readonly="">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :xl="4" :md="4" class="lh25">{{ $t('CPN')}}</el-col>
        <el-col :xl="20" :md="20">
          <el-input type="textarea" :placeholder="$t('CPN')" maxlength="200" v-model="formData.pidList" size="mini">
          </el-input></el-col>
      </el-row>
    </card>
    <div class="row mt-1">
      <div class="col right">
        <el-button size="mini" @click="handleClose">{{ $t('CLOSE') }}</el-button>
        <el-button size="mini" icon="el-icon-success" class="el-btn-orange" @click="setBaseInfo">{{ $t('SUBMIT')
          }}</el-button>
  
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'basicitemcreate',
  props: ['data'],
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.formData = {
          inPrice: this.$comma(newValue?.inPrice) || 0,
          minQty: newValue?.minQty || 1,
          name: newValue?.name || '',
          pid: newValue?.pid || '',
          factoryNum: newValue?.factoryNum || '',
          priceA: this.$comma(newValue?.priceA) || 0,
          priceB: this.$comma(newValue?.priceB) || 0,
          priceC: this.$comma(newValue?.priceC) || '10,000',
          priceD: this.$comma(newValue?.priceD) || '10,000',
          state: 0,
          tradeType: 0,
          type: newValue?.type || 1,
          view: 0,
          seq: newValue?.seq || null,
          pidList: newValue?.pidList || '',
        }
        this.supply(this.formData.inPrice, 'Y', 'inPrice')
        this.supply(this.formData.priceA, 'Y', 'priceA')
        this.supply(this.formData.priceB, 'Y', 'priceB')
        this.supply(this.formData.priceC, 'Y', 'priceC')
        this.supply(this.formData.priceD, 'Y', 'priceD')
      }
    },
  },
  data() {
    return {
      userlogininfo: null,
      visible: true,
      title: 'BI.REG.',
      tmpCustomerList: [],
      total: 0,

      formData: {
        inPrice: 0,
        minQty: 0,
        name: '',
        pid: '',
        factoryNum: '',
        priceA: 0,
        priceB: 0,
        priceC: 0,
        priceD: 0,
        state: 0,
        tradeType: 0,
        type: 0,
        view: 0,
        seq: null,
        pidList: '',

      }
    }
  },
  async created() {
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
  },
  methods: {
    async setBaseInfo() {
      if (this.formData.name.trim() == '') {
        alert('품목명을 입력하세요.')
        return
      }
      if (this.formData.priceD == 0) {
        alert('판매단가를 입력하세요.')
        return
      }
      const res = await this.$http.post(`${this.$serverurl}/api/setBaseInfo`, this.formData)
      if (201 == res.data.code) {
        alert(res.data.message)
        this.setCallback(res.data.body.results)
      }

    },
    setCallback(item) {
      this.$emit('callback', { status: true, item })
    },
    notClose() { },
    handleClose(done) {
      // done()
      this.$emit('callback', { status: false, item: null })
    },
    supply(amount, vat, field) {
      amount = this.$delComma(amount)
      const { supplyAmount, vatAmount } = this.$amountSplitTax(amount, vat)
      this.formData[`supply_${field}`] = this.$comma(supplyAmount)
      this.formData[`vat_${field}`] = this.$comma(vatAmount)
      this.formData[field] = this.$comma(amount)
    },
    supplySub(amount, vat, field) {
      amount = JSON.parse(JSON.stringify(this.$delComma(amount)))
      this.formData[`supply_${field}`] = this.$comma(amount)
      this.formData[`vat_${field}`] = this.$comma(amount * 0.1)
      this.formData[field] = this.$comma(amount * 1.1)
    },
  }
}
</script>
<style></style>