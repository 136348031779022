<template>
  <div class="container mt-3 relative" :style="$pageWidth">
    <TopBar />
    <div class="mt-2">
      <el-button size="mini" :type="activeName == 'orderinlist' ? 'primary' : ''"
        @click="handleButtons('orderinlist')">{{$t('IL')}}</el-button>
      <el-button v-if="authority.salePermi==3 || authority.salePermi==0" size="mini" :type="activeName == 'orderininsert' ? 'primary' : ''"
        @click="handleButtons('orderininsert')">{{$t('IR')}}</el-button>
      <el-button v-if="authority.baseitemPermi==0" size="mini" :type="activeName == 'baseiteminfo' ? 'primary' : ''"
        @click="handleButtons('baseiteminfo')">{{$t('BIM')}}</el-button>
        <el-button v-if="authority.baseitemPermi==0" size="mini" :type="activeName == 'storageinfo' ? 'primary' : ''"
        @click="handleButtons('storageinfo')">{{$t('WM')}}</el-button>
      <el-button v-if="authority.baseitemPermi==0" size="mini" :type="activeName == 'customerinfo' ? 'primary' : ''"
        @click="handleButtons('customerinfo')">{{$t('CRM')}}</el-button>
      <div class="mt-2">
        <div v-if="activeName == 'orderinlist'">
          <orderinlist @callback="orderinlistcallback" />
        </div>
        <div v-if="activeName == 'orderininsert'">
          <orderincreate :data="selectItem" @callback="createcallback" />
        </div>
        <div v-if="activeName == 'baseiteminfo'">
          <baseitemlist />
        </div>
        <div v-if="activeName == 'customerinfo'">
          <customerinfo />
        </div>
        <div v-if="activeName == 'storageinfo'">
          <sotragelist />
        </div>
      </div>
      <orderincview v-if="viewvisible" @callback="viewallback" :data="selectItem" />

    </div>
  </div>
</template>
<script>
import sotragelist from './component-storage-list.vue'
import customerinfo from './component-customer-list.vue'
import baseitemlist from './component-basic-item-list.vue'
import orderincview from './component-orderin-view.vue'
import TopBar from './component-top.vue'
import orderinlist from './component-orderin-list.vue'
import orderincreate from './component-orderin-create.vue'
import { selection } from 'd3'
export default {
  components: {
    TopBar, orderinlist, orderincreate, orderincview,baseitemlist,customerinfo,sotragelist
  },
  data() {
    return {
      viewvisible: false,
      selectItem: null,
      activeName: 'orderinlist',
      tabs: [{}],
      initem: {},
      userlogininfo: null,
      authority: null,
    }
  },
  created(){
    this.userlogininfo = JSON.parse(localStorage.getItem('userlogininfo'))
    this.authority = this.userlogininfo.authority
  },
  methods: {
    createcallback() {

    },
    viewallback(data) {
      if(data.status){
        this.selectItem = data.item
        this.activeName='orderininsert'
      }
      this.viewvisible = false
    },
    handleButtons(params) {
      this.activeName = params;
      this.selectItem=null
    },
    orderinlistcallback(data) {
      if (data.status) {
        this.viewvisible = true
        this.selectItem = data.item
      }

      // this.activeName = 'orderincreate'
    },
    handleClick(tab, event) { },
  }
}
</script>
<style></style>
