<template>
  <div class="pt-3">
    <div class="font15 bold mb-2">
      {{$t('MEMBER.MI')}}
    </div>
    <div class="p-2 mb-2">
  
      <el-row :gutter="5">
        <el-col :xs="24" :md="24" :sm="24">
          <div class="input-container">
            <input ref="name" type="text" maxlength="20" class="formtextbox" autocomplete="off" v-model="formData.name"
              @focus="onFocus('name')" @blur="onBlur('name')" />
            <label class="placeholder coloryellow" :class="{ 'active': isFocused.name }">{{$t('NAME')}}*</label>
            <div v-html="check('name')" class="absolute colorred nowrap" style="right:0px;bottom:10px;"></div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col :xs="24" :md="24" :sm="24">
          <div class="input-container">
            <div class="input-container">
              <input ref="id" type="text" maxlength="20" class="formtextbox" autocomplete="off" v-model="formData.id"
                @focus="onFocus('id')" @blur="onBlur('id')" />
              <label class="placeholder coloryellow" :class="{ 'active': isFocused.id }">{{$t('ACCOUNT')}}*</label>
              <div v-html="check('id')" class="absolute colorred nowrap" style="right:0px;bottom:10px;"></div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col :xs="24" :md="24" :sm="24">
          <div class="input-container">
            <div class="input-container">
              <input ref="pw" type="password" maxlength="20" class="formtextbox" autocomplete="off" v-model="formData.pw"
                @focus="onFocus('pw')" @blur="onBlur('pw')" />
              <label class="placeholder coloryellow" :class="{ 'active': isFocused.pw }">{{$t('PW')}}*</label>
              <span class="absolute" style="bottom:10px;right:0px;">
                <i class="fa-sharp fa-solid fa-badge-check"></i>
                <div v-html="check('pw')" class="absolute colorred nowrap" style="right:0px;bottom:10px;"></div>
              </span>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col :xs="24" :md="24" :sm="24">
          <div class="input-container">
            <div class="input-container">
              <input ref="rpw" type="password" maxlength="20" class="formtextbox" autocomplete="off"
                v-model="formData.rpw" @focus="onFocus('rpw')" @blur="onBlur('rpw')" />
              <label class="placeholder coloryellow" :class="{ 'active': isFocused.rpw }">{{$t('CPW')}}*</label>
              <div v-html="check('rpw')" class="absolute colorred nowrap" style="right:0px;bottom:10px;"></div>
            </div>
          </div>
        </el-col>
      </el-row>
  
      <el-row :gutter="5">
        <el-col :xs="24" :md="24" :sm="24">
          <div class="input-container">
            <input ref="tel" type="text" maxlength="11" class="formtextbox" autocomplete="off" v-model="formData.phone"
              @focus="onFocus('phone')" @blur="onBlur('phone')" @keyup="onlyNumber(formData.phone)" />
            <label class="placeholder coloryellow" :class="{ 'active': isFocused.phone }">{{$t('MOBILE')}}</label>
            <div v-html="check('phone')" class="absolute colorred nowrap" style="right:0px;bottom:10px;"></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col class="center">
        <el-button type="info" @click="sendData(1,false)">
          {{$t('PREV.STEP')}}
        </el-button>
        <el-button type="primary" v-if="checkValid()" @click="submit()">
          {{$t('COMPLETESIGNUP')}}
        </el-button>
        <el-button type="info" disabled v-if="!checkValid()">
          {{$t('COMPLETESIGNUP')}}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>

export default {
  name: 'member',
  props: ['companyInfo'],
  watch: {
    companyInfo: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.newcompanyinfo = newValue
        this.formData.phone = newValue.phone
        this.isFocused.phone = true
      }
    },
  },
  components: {

  },
  data() {
    return {
      newcompanyinfo: null,
      isFocused: {
        name: false,
        id: false,
        pw: false,
        rpw: false,
        phone: false,

      },
      formData: {
        name: '',
        id: '',
        pw: '',
        rpw: '',
        phone: '',
      }
    }
  },
  created() {
  },
  methods: {
    sendData(step, status) {
      this.$emit('callback', {
        step: step,
        companyInfo: this.formData,
        status: status
      })
    },
    check(item) {
      const itemValue = this.formData[item].trim()
      const itemLength = this.formData[item].trim().length

      switch (item) {
        case 'name': {
          if (itemValue != '' && (itemLength >= 2 && itemLength <= 20)) {
            return `<i class='el-icon-success absolute colorblue' style='right:0px;bottom:10px;'></i>`
          } else {
            return this.$transToInput(['2~20'], this.$t('ENTER-CHARACTERINFORMATION.')) //`2~5자리 정보를 입력하세요.`
          }
        }
        case 'phone': {
          if (itemValue != '' && (itemLength == 11)) {
            return `<i class='el-icon-success absolute colorblue' style='right:0px;bottom:10px;'></i>`
          } else {
            return this.$transToInput(['11'], this.$t('ENTER-DIGITNUMBER.')) //`11자리 숫자를 입력하세요.`
          }
        }
        case 'rpw': {
          if (itemLength >= 5 && itemLength <= 20) {
            if (this.formData.pw === itemValue) {
              return `<i class='el-icon-success absolute colorblue' style='right:0px;bottom:10px;'></i>`
            } else {
              return this.$t('PASSWORDSDONOTMATCH.')
            }
          } else {
            return this.$transToInput(['5~20'], this.$t('ENTER-CHARACTERINFORMATION.'))
          }
        }
        default: {
          if (itemLength >= 5 && itemLength <= 20) {
            return `<i class='el-icon-success absolute colorblue' style='right:0px;bottom:10px;'></i>`
          } else {
            return this.$transToInput(['5~20'], this.$t('ENTER-CHARACTERINFORMATION.'))
          }
        }
      }
    },
    checkValid() {
      if (
        this.formData.name.trim() != '' &&
        (this.formData.name.trim().length >= 2 && this.formData.name.trim().length <= 20) &&
        this.formData.id.trim() != '' &&
        (this.formData.id.trim().length >= 5 && this.formData.id.trim().length <= 20) &&
        this.formData.pw.trim() != '' &&
        (this.formData.pw.trim().length >= 5 && this.formData.pw.trim().length <= 20) &&
        this.formData.rpw.trim() != '' &&
        (this.formData.rpw.trim().length >= 5 && this.formData.rpw.trim().length <= 20) &&
        this.formData.phone.trim() != '' &&
        (this.formData.phone.trim().length >= 11 && this.formData.phone.trim().length <= 11)
      ) { return true } else { return false }
    },
    async submit() {
      if (this.formData.pw != this.formData.rpw) {
        alert(this.$t('PASSWORDSDONOTMATCH.'))
        return
      }
      const formData = {
        companyInfo: this.newcompanyinfo,
        memberInfo: this.formData
      }
      const res = await this.$http.post(`${this.$serverurl}/api/joinUs_new`, formData)
      if (201 == res.data.code) {
        alert(res.data.message)
        this.$changeRoute('login')
      } else {
        alert(res.data.message)
      }

    },
    onFocus(field) {
      this.isFocused[field] = true;
    },
    onBlur(field) {
      if (!this[field] && !this.formData[field]) {
        this.isFocused[field] = false;
      }
    },
    onlyNumber(params, field) {
      this.formData[field] = params.replace(/[^0-9]/g, '')
    },

    loadDaumPostcodeScript() {
      const script = document.createElement('script')
      script.src =
        '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'
      script.async = true
      script.onload = () => {
        this.initializeDaumPostcode()
      }
      document.head.appendChild(script)
    },
    initializeDaumPostcode() {
      const daum = null
      new (window).daum.Postcode({
        oncomplete: (data) => {
          this.formData.postNum = data.zonecode
          this.formData.addr = data.address
        }
      }).open()
    },
  }
}
</script>

<style scope>

</style>
