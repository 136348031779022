<template>
  <div class="container mt-3 relative" :style="$pageWidth" style="padding-left:0px;padding-right:0px;">
    <card style="min-width: 100%;margin-bottom:10px;">
      <el-row :gutter="10">
        <el-col :md="12">
          <el-input :placeholder="$t('SEARCH')" size="mini" v-model="searchKeyword" style="max-width: 200px;" class="mr-2"
            @keyup.enter.native="searchRead(searchKeyword)">
            <i slot="prefix" class="el-input__icon el-icon-search hand" @click="searchRead(searchKeyword)"></i>
          </el-input>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="searchRead(searchKeyword)">
            {{$t('SEARCH')}}
          </el-button>
          <el-button type="primary" icon="el-icon-tickets" size="mini" v-if="searchKeyword"
            @click="searchRead(null)">{{$t('VIEWALL')}}</el-button>
        </el-col>
        <el-col :md="12" class="right">
          <el-button type="primary" :icon="request.objSearchMapper.state ? 'el-icon-success' : 'el-icon-delete'"
            size="mini" @click="searchStateRead(request.objSearchMapper.state ? 0 : 1)">
            {{ request.objSearchMapper.state ? $t('NU') : $t('SU') }}</el-button>
        </el-col>
      </el-row>
    </card>
    <div>
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
        :page-size="request.size" layout="total, sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
  
      <el-table :data="tmpTableData" :border="true" style="width: 100%" class="font13">
        <el-table-column label="ID" width="80" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.seq }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('BRN')" width="120" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.license }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('COMPANYNAME')" width="160" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('CEO')" width="80" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.ceo }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('ADDR')" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">
              {{ scope.row.addrMain }}
              {{ ' ' + scope.row.addrSub }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TEL')" width="100" :align="'left'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="ellip">{{ scope.row.phone }}</span>
          </template>
        </el-table-column>

        <el-table-column :label="$t('ACCOUNT')" width="60" :align="'right'">
          <template slot-scope="scope">
            <span>
              {{ $comma(scope.row.mallMemberInfo.length) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('CASH')" width="120" :align="'right'">
          <template slot-scope="scope">
            <span class="bigbadge-green hand" @click="showUsedPointList(scope.row)">
              {{ $comma(scope.row.point) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('LICENSE')" width="90" :align="'right'">
          <template slot-scope="scope">
            <span class="bigbadge-blue hand" @click="updateLicense(scope.row)">
              {{ scope.row.companyLicenseInfo ? scope.row.companyLicenseInfo.remaDays : 0 }}{{$t('DAY')}}
            </span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('LEVE')" width="80" :align="'center'">
          <template slot-scope="scope">
            <span class="bigbadge-orange hand" @click="updatelevel(scope.row)">
              {{ $companyLevel.find(item=>item.level==scope.row.level).label }}{{ $t('LEVE') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="상세보기" width="50" :align="'center'">
          <template slot="header">
            <i class="fas fa-eye"></i>
          </template>
          <template slot-scope="scope">
            <i class="fas fa-eye hand" @click="showMemberList(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column label="수정" width="50" :align="'center'">
          <template slot="header">
            <i class="fas fa-pen-to-square"></i>
          </template>
          <template slot-scope="scope">
            <i class="fas fa-pen-to-square hand" @click="insertUpdatebox(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column label="정지/삭제" width="50" align="right">
          <template slot="header">
            <i class="fas fa-trash-alt"></i>
          </template>
          <template slot-scope="scope">
            <i class="hand" :class="scope.row.state==0?'fas fa-trash-alt':'fas fa-trash'"
              @click="stateinsertUpdatebox(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
  
      <el-pagination background class="mt-2 mb-2 text-right" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="request.page" :page-sizes="pageSizes"
        :page-size="request.size" layout="total, sizes, prev, pager, next" :total="totalCount">
      </el-pagination>
    </div>
    <memberlist v-if="memberlistvisible" :data="selectItem" @callback="memberListCallback" />
    <mallmanagerusedpointlist v-if="usedpointlistvisible" :data="selectItem" @callback="usedPointListCallback" />
    <malllcompanyCreate v-if="createvisible" :data="selectItem" @callback="createCallback" />
    <malllcompanylicenseCreate v-if="licensevisible" :data="selectItem" @callback="licnseCallback" />
    <malllcompanysetlevel v-if="levelvisible" :data="selectItem" @callback="levelCallback" />
    <yesNoBox v-if="yesNoBoxvisible" @callback="deletecallback" :title="selectItem.name" :data="yesNoBoxContent" />
  </div>
</template>
<script>
import malllcompanyCreate from './component-mall-company-create.vue'
import malllcompanylicenseCreate from './component-mall-company-license-create.vue'
import malllcompanysetlevel from './component-mall-company-set-level.vue'
import yesNoBox from './component-alert-continue.vue'
import mallmanagerusedpointlist from './component-mall-manager-used-point-list.vue'
import memberlist from './component-select-mall-member-list.vue'
export default {
  name: 'mallmanagercompany',
  components: {
    malllcompanyCreate, malllcompanylicenseCreate, yesNoBox, malllcompanysetlevel,
    mallmanagerusedpointlist, memberlist
  },
  data() {
    return {
      count: [],
      countButtons: [
        { value: 0, label: '사용중회사' },
        { value: 1, label: '정지된회사' }
      ],
      memberlistvisible: false,
      usedpointlistvisible: false,
      levelvisible: false,
      licensevisible: false,
      createvisible: false,
      yesNoBoxvisible: false,
      yesNoBoxContent: {},
      selectItem: null,
      pageSizes: [15, 50, 100],
      searchKeyword: '',
      request: {
        objSearchMapper: {
          state: 0,
          searchKeyword: '',
        },
        page: 1,
        size: 15
      },
      tmpTableData: [],
      totalCount: 0,
    }
  },

  created() {
    this.read()
  },
  methods: {

    stateinsertUpdatebox(item) {
      this.selectItem = item
      this.yesNoBoxvisible = true
      if (item.state) {
        this.yesNoBoxContent = {
          content: this.$t('Would you like to restore the item?'),
          contentSub: this.$t('The information is being used normally.')
        }
      } else {
        this.yesNoBoxContent = {
          content: this.$t('Would you like to delete the item?'),
          contentSub: this.$t('ACAS')
        }
      }
    },
    deletecallback(data) {
      if (data) {
        this.satateInfo()
      }
      this.yesNoBoxvisible = false
    },
    showMemberList(item) {
      this.selectItem = item
      this.memberlistvisible = true
    },
    showUsedPointList(item) {
      this.selectItem = item
      this.usedpointlistvisible = true
    },
    updateLicense(item) {
      this.selectItem = item
      this.licensevisible = true
    },
    licnseCallback(data) {
      if (data.status) {
        this.searchRead()
      }
      this.licensevisible = false
    },
    updatelevel(item) {
      this.selectItem = item
      this.levelvisible = true
    },
    levelCallback(data) {
      if (data.status) {
        this.searchRead()
      }
      this.levelvisible = false
    },
    insertUpdatebox(item) {
      this.selectItem = item
      this.createvisible = true
    },
    usedPointListCallback(data) {
      this.usedpointlistvisible = false
    },
    memberListCallback(data){
      this.memberlistvisible=false
    },
    createCallback(data) {
      if (data.status) {
        this.searchRead()
      }
      this.createvisible = false
    },

    searchStateRead(params) {
      this.request.objSearchMapper.state = params
      this.request.page = 1
      this.read()
    },
    searchRead(searchKeyword) {
      this.searchKeyword = searchKeyword
      this.request.page = 1
      this.request.objSearchMapper.searchKeyword = this.$trimLR(searchKeyword)
      this.read()
    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getMallCompanyInfo_new`, this.request, this.$setHeader())
        .then(res => {
          if (200 == res.data.code) {
            this.count = res.data.body.count
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.totalCount
          } else {
            this.tmpTableData = []
          }
        })
    },
    async satateInfo() {
      const formData = { seq: this.selectItem.seq }
      await this.$http.post(`${this.$serverurl}/api/stateDeleteMallCompanyInfo`, formData, this.$setHeader())
        .then(res => {
          if (201 == res.data.code) {
            alert(res.data.message)
            this.read()
          }
        })
    },
    handleSizeChange(val) {
      this.request.size = val
      this.read()
    },
    handleCurrentChange(val) {
      this.request.page = val
      this.read()
    }

  }
}
</script>
<style></style>
